import React from "react";
import useGetData from "../../hooks/useGetData";

export default function InterviewSchedule({ jobEmpId }) {
  const times = [
    "12pm-01pm",
    "02pm-4pm",
    "04pm-05pm",
    "06pm-07pm",
    "02pm-4pm",
    "04pm-05pm",
    "06pm-07pm",
    "02pm-4pm",
    "04pm-05pm",
    "06pm-07pm",
  ];
  const AvailabilityUrl = `availability/applicationlog/237`;
  const { data } = useGetData(AvailabilityUrl);

  return (
    <div className="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
      <h2 className="my-4 font-xl-semibold">Interview Schedule</h2>
      <div className="line"></div>
      <h2 className="my-4 font-md">
        Choose time for the interview{" "}
        <span className="text-gray-500">
          (you can select only one time duration)
        </span>
      </h2>
      <h2 className="my-4 font-lg-semibold text-blue-500">
        Tuesday, 03/01/2023
      </h2>
      <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-7 gap-4 mb-4">
        {times.map((time) => (
          <div className="text-blue-500 border-2 border-blue-500 py-2 px-4 my-2 text-center font-semibold  rounded-md hover:bg-blue-500 hover:text-white cursor-pointer w-44 mr-2">
            {time}
          </div>
        ))}
      </div>
      <div className="line"></div>
      <div className="pt-4 flex flex-row justify-between">
        <div></div>
        <div className="flex flex-row">
          <button className="w-48 py-4 font-semibold rounded border-2 border-blue-500 text-blue-500 mr-2 hover:bg-blue-100">
            Cancel
          </button>
          <button className="w-48 py-4 font-semibold rounded btn-main hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
