import React from "react";
import EmployeeOverview from "./EmployeeOverview";
import JobApplications from "./JobApplications";
import SearchMap from "./SearchMap";
import RecentInterview from "../../elements/RecentInterview";

function EmployeeDashboard() {
  return (
    <div className="w-full p-5 rounded-md shadow-lg border">
      <div className="w-full my-5">
        <EmployeeOverview />

        <div className="my-10">
          <div className="xl:flex xl:gap-4">
            <div className="w-full">
              <JobApplications />
            </div>
            <div className="flex md:hidden flex-col md:flex-row mb-4 bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
              <RecentInterview showInvites={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeDashboard;
