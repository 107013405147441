import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Bell from "../../images/bell.png";
import PlayButton from "../../images/playbutton.png";
import usePostData from "../../hooks/usePostData";
import useGetData from "../../hooks/useGetData";
import Select from "react-select";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function HomeJobAlert({ setShowJobAlertModal, formatOptionLabel }) {
  const [values, setValue] = useState("");

  const [formData, setFormData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  //Common code starts here..
  const url = "new-jobs-email-pop-up";
  const { data, error, message } = usePostData(url, formData);
  const [positionAll, setPositionAll] = useState([{}]);

  useEffect(() => {
    if (data) {
      setMessage({ message: data.message, class: "success" });
      setValue({ position: "", email: "", address: "" });
      setCookie("isJobAlertShow", "true", 365);
      setTimeout(() => {
        setShowJobAlertModal(false);
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({
        message: error.non_field_errors,
        class: "error",
      });
    }
    if (error?.message) {
      setMessage({ message: error.message, class: "error" });
    }
  }, [error]);

  const processForm = (e) => {
    e.preventDefault();
    let finalData = { ...values };
    finalData.position = values?.position?.name;
    setFormData(finalData);
  };
  //Common code ends here..

  //Cookie function declaration
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  //Hide the popup once the modal closed by the user without submit it
  const closePopup = () => {
    setCookie("isJobAlertShow", "true", 1);
  };

  //Job position field code starts here..
  const CatUrl = "category-list";
  const { data: CategoriesData } = useGetData(CatUrl);

  useEffect(() => {
    if (CategoriesData) {
      setPositionAll(CategoriesData.data);
    }
  }, [CategoriesData]);

  const updatePositionSelectValue = (value) => {
    setValue({ ...values, position: value });
  };

  //sort job positions in ascending order
  const sortedPositionData = CategoriesData?.data.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
  //Job position field code ends here..

  // Email field code starts here..
  const updateValue = (e) => {
    setValue({ ...values, email: e.target.value });
  };
  // Email field code ends here..

  // Google Place API starts here..
  const [markerLocation, setMarkerLocation] = useState({
    lat: "",
    lng: "",
  });
  const [address, setAddress] = useState("");

  const handleChangeLocation = (address) => {
    setAddress(address);
    setValue((currentVal) => ({
      ...currentVal,
      address: address,
      latitude: "",
      longtitude: "",
    }));
  };

  const handleSelectLocation = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        const result = results[0].address_components;
        const country = result.find((item) => item.types.includes("country"));
        const postcode = result.find((item) =>
          item.types.includes("postal_code")
        );
        const state = result.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        const city = result.find((item) =>
          item.types.includes("administrative_area_level_2")
        );

        setValue((currentVal) => ({
          ...currentVal,
          address: address,
          city: city?.long_name,
          country: country?.long_name,
          state: state?.long_name,
          postal_code: postcode ? postcode.long_name : null,
        }));

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        getSelectedLocation(latLng);
        setValue((currentVal) => ({
          ...currentVal,
          latitude: latLng ? latLng.lat : "",
          longtitude: latLng ? latLng.lng : "",
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  const customFilter = (option, searchText) => {
    const getSearctextPos = (string) => {
      return string?.name?.toLowerCase().indexOf(searchText.toLowerCase());
    };
    let temp = positionAll?.sort((a, b) => {

      return(getSearctextPos(a) - getSearctextPos(b))});
    setPositionAll(temp)
    if (
      option?.data?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data?.alias.includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="sample">
      <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
        <div className="relative w-auto md:mx-auto md:my-6 lg:max-w-lg m-auto">
          <div className="relative p-10 flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none space-y-5">
            <div className="w-16 h-16 bg-blue-50 rounded-full mx-auto relative">
              <img src={Bell} className="w-8 h-auto absolute m-auto inset-0" />
            </div>
            <h3 className="text-lg font-bold md:text-3xl text-black">
              Job Alerts
            </h3>
            <p>
              Now you can receive job alerts directly to your emails.
              <br />
              Please fill the form below and stay updated!
            </p>
            <button
              className="absolute -top-2 right-2 font-semibold w-auto text-white rounded-full border-4 border-main"
              onClick={() => {
                setShowJobAlertModal(false);
                closePopup();
              }}
            >
              <AiOutlineClose className="text-white bg-main" size="1.2em" />
            </button>

            <div>
              <form>
                <div className="mb-3">
                  <Select
                    className="react-select-container text-left custom-job-alert rounded-md border-2 font-medium"
                    classNamePrefix="react-select"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    formatOptionLabel={formatOptionLabel}
                    options={positionAll}
                    name="position"
                    id="position"
                    value={values.position}
                    onChange={updatePositionSelectValue}
                    placeholder="Job Position"
                    filterOption={customFilter}
                  />

                  <span className="normal-case form_error_message block text-left mt-1">
                    {errors?.position}
                  </span>
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    id="email_address"
                    name="email_address"
                    value={values.email}
                    class="block w-full rounded-md border-2 h-14 p-5 focus:outline-none focus:shadow-outline text-black font-medium"
                    placeholder="Email"
                    onChange={(e) => updateValue(e)}
                  />
                  <span className="normal-case form_error_message block text-left mt-1">
                    {errors?.email}
                  </span>
                </div>

                <div className="mb-3">
                  <PlacesAutocomplete
                    value={values.address}
                    onChange={handleChangeLocation}
                    onSelect={handleSelectLocation}
                    onError={(err) => console.log(err)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          placeholder="Location"
                          {...getInputProps({
                            type: "text",
                            autoComplete: "off",
                            className:
                              "block w-full rounded-md border-2 h-14 p-5 focus:outline-none focus:shadow-outline text-black font-medium relative",
                          })}
                        />
                        <div className="w-full relative">
                          <div className={`${suggestions}` ? "border-2 mt-1 h-28 overflow-y-scroll text-left absolute w-full" : "" }>
                            <div className={`${suggestions}` ? "text-left" : "text-left"}>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "bg-gray-700 p-2"
                                  : "p-2";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#5cb5ed",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                if (suggestion.terms.length > 1) {
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <p className="normal-case form_error_message block text-left mt-1">
                    {errors?.address}
                  </p>
                </div>
              </form>
            </div>

            <button
              className="bg-main text-white px-10 py-4 border rounded-xl mx-auto font-semibold"
              onClick={processForm}
            >
              <div className="flex gap-2">
                Submit
                {/* <img src={PlayButton} className="w-6 h-auto" /> */}
              </div>
            </button>

            {commonMessage && (
              <p className={`${commonMessage.class}`}>
                {commonMessage.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeJobAlert;
