import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function JobStatistics() {
  return (
    <>
      <div className="mb-6">
        <h1 className="text-lg font-semibold text-black mb-2 md:mb-0">
          Job Statistics
        </h1>
      </div>
      <div className="p-5 border rounded-xl bg-white mb-6"></div>
    </>
  );
}

export default JobStatistics;