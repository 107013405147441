import React, { useLayoutEffect, useContext } from "react";
import useGetData from "../../hooks/useGetData";
import { useHistory, useParams } from "react-router-dom";
import defaultlogo from "../../images/JobDetails/hospital-default.jpg";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { BsThreeDots } from "react-icons/bs";
import { IoMdGlobe } from "react-icons/io";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import RohLogo from "../../images/ROH-logo.png";
import CandidatePagePopup from "../elements/CandidatePagePopup";

let old_phoneformat = "";
let new_phoneformat = "";
function CandidateList() {
  const [candidateData, setCandidateData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const history = useHistory();
  const [singleJobDetails, setSingleJobDetails] = useState(
    history.location.jobDetails
  );
  const [detailUrl, setDetailUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const { jobid } = useParams();

  const { username } = useContext(UserContext);

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!singleJobDetails) {
      setDetailUrl(`jobs/${jobid}`);
    }
  }, []);

  const { data: jobDetails, error: joberr } = useGetData(detailUrl);

  useEffect(() => {
    if (jobDetails?.data) {
      setSingleJobDetails(jobDetails.data);
    }
  }, [jobDetails]);

  // useEffect(() => {
  //   if(singleJobDetails && !username) {
  //     window.location.href = "/login";
  //   }
  // }, [singleJobDetails, username])

  const [url, setUrl] = useState(
    `job-posts/${jobid}/applied-employees?page=1&page_size=8`
  );

  const { data: candidateList, error: err } = useGetData(url, "main", refresh);

  const usersPerPage = 8;
  const pageCount = Math.ceil(candidateData?.count / usersPerPage);

  let posted_date = singleJobDetails?.created_at;
  let date_only = posted_date?.slice(0, 10);

  let format_min_ypay = singleJobDetails?.min_yearly_pay;
  let result_min_ypay = format_min_ypay?.toLocaleString();
  let format_max_ypay = singleJobDetails?.max_yearly_pay;
  let result_max_ypay = format_max_ypay?.toLocaleString();

  if (singleJobDetails) {
    old_phoneformat = singleJobDetails?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  const handlePageData = (selected) => {
    setUrl(`job-posts/${jobid}/applied-employees?page=${selected}&page_size=8`);
    // url = `job-posts/${jobid}/applied-employees?page=${selected}`;
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    handlePageData(selected + 1);
  };

  useLayoutEffect(() => {
    if (candidateList) {
      setLoading(false);
      setCandidateData(candidateList);
    } else {
      setLoading(true);
    }
  }, [candidateList]);

  // useEffect(() => {
  //   if (err) {
  //     window.location.href = "/404";
  //   }
  // }, [err]);

  useEffect(() => {
    console.log("error code..", err);
    if (err === "Authentication credentials were not provided.") {
      window.location.href = "/login";
      localStorage.setItem("path", window.location.href);
      setLoading(true);
    }
  }, [err]);

  useEffect(() => {
    console.log("job error..", joberr);
    if (joberr === "Not found." || err?.non_field_error) {
      window.location.href = "/404";
      setLoading(true);
    }
  }, [joberr, err]);

  return (
    <div className="minHeight inner-wrapper relative overflow-hidden xl:w-5/6 lg:mx-auto my-10 p-6">
      {!loading ? (
        <>
          <h2 className="lg:text-4xl font-semibold mb-12 text-3xl">
            Candidates List
          </h2>

          {/* <div
      //   style={{
      //     gridTemplateColumns: "repeat(auto-fill, minmax(300px,1fr))",
      //   }}
      //   className="grid custom_shadow rounded-lg"
      // >
      //   <div className="p-3">
      //     <h5 className="text-gray-800 font-bold text-2xl pb-1">Staff Nurse</h5>
      //     <h5 className="text-gray-500 text-sm pb-1">
      //       Date Posted : 13/09/2021
      //     </h5>
      //     <h5 className="font-semibold pb-1">Hourly Salary: $62 - $62 </h5>
      //     <h5 className="font-semibold">Annual Salary: $129,456 - $129,456 </h5>
      //   </div>
      //   <div className="p-3 justify-self-end">
      //     <h5 className="text-gray-800 font-bold text-2xl pb-1">Staff Nurse</h5>
      //     <h5 className="text-gray-500 text-sm pb-1">
      //       Date Posted : 13/09/2021
      //     </h5>
      //     <h5 className="font-semibold pb-1">Hourly Salary: $62 - $62 </h5>
      //     <h5 className="font-semibold">Annual Salary: $129,456 - $129,456 </h5>
      //   </div>
      //   <div className="self-center">
      //     <img src={defaultlogo} alt="" className="block" width="100px" />
      //   </div>
      // </div> */}
          <div className="my-5 custom_shadow jobTitleContainer">
            <div className="flex flex-col justify-between px-5 py-3 pt-6 space-y-6 md:space-y-0 md:flex-row">
              <div className="flex flex-col space-y-3 md:flex-row md:space-x-3 md:space-y-0">
                <div>
                  {!singleJobDetails?.image &&
                  !singleJobDetails?.category_unverified_image &&
                  !singleJobDetails?.category_verified_image ? (
                    <img
                      src={defaultlogo}
                      alt={singleJobDetails?.id}
                      title={singleJobDetails?.id}
                      loading="lazy"
                      className="block md:self-center lg:self-center lg:mr-3 xl:mr-0"
                      width="100px"
                    />
                  ) : (
                    <img
                      src={
                        singleJobDetails?.image
                          ? singleJobDetails?.image
                          : singleJobDetails?.emp_id == null
                          ? singleJobDetails?.category_unverified_image
                          : singleJobDetails?.category_verified_image
                      }
                      alt={singleJobDetails.id}
                      title={singleJobDetails.id}
                      loading="lazy"
                      className="block md:self-center lg:self-center lg:mr-3 xl:mr-0"
                      width="100px"
                    />
                  )}
                </div>
                <div className="space-y-2 details md:max-w-xl">
                  {/* {singleJobDetails?.is_owner_premium == true && (
                  <div className="block sm:hidden">
                    <img src={RohLogo} className="w-6 h-full" />
                  </div>
                )} */}
                  <h2
                    className={`relative items-center font-xl-semibold ${
                      singleJobDetails?.is_owner_premium == true &&
                      "job-details-title"
                    }`}
                  >
                    {singleJobDetails?.position_label}
                  </h2>
                  <p className="text-gray-500 font-sm-semibold">
                    Date Posted: {date_only}
                    {/* {date_only} */}
                  </p>

                  {Object.values(
                    singleJobDetails?.salary_type
                      ? singleJobDetails?.salary_type
                      : {}
                  )[0] === "Fixed" && (
                    <p className="flex flex-wrap font-semibold">
                      {Object.values(singleJobDetails?.salary_subtype)[0]}{" "}
                      Salary :{" $"}
                      {singleJobDetails?.max_salary?.toLocaleString()}
                    </p>
                  )}

                  {Object.values(
                    singleJobDetails?.salary_type
                      ? singleJobDetails?.salary_type
                      : {}
                  )[0] !== "Fixed" && (
                    <p>
                      {singleJobDetails?.min_salary &&
                        singleJobDetails?.max_salary && (
                          <p className="flex flex-wrap font-semibold">
                            Hourly Salary: $
                            {parseFloat(singleJobDetails?.min_salary)
                              .toFixed(2)
                              .replace(/\.00$/, "")}{" "}
                            - $
                            {parseFloat(singleJobDetails?.max_salary)
                              .toFixed(2)
                              .replace(/\.00$/, "")}
                          </p>
                        )}
                      {format_min_ypay && format_max_ypay && (
                        <p className="font-semibold">
                          Annual Salary: ${result_min_ypay} - ${result_max_ypay}
                        </p>
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex font-semibold text-gray-500  md:text-right md:items-center details font_small_light">
                <div className="space-y-1">
                  {singleJobDetails?.emp_id == null ? (
                    <Link
                      to={`/employers-details/${singleJobDetails?.company_name
                        ?.replaceAll(" ", "-")
                        ?.toLowerCase()}`}
                    >
                      <h2 className="text-black font-xl-semibold md:text-right">
                        {singleJobDetails?.company_name
                          ? singleJobDetails?.company_name
                          : "Company Name"}
                      </h2>
                    </Link>
                  ) : (
                    <Link to={`/employers/${singleJobDetails?.emp_id}`}>
                      <h2 className="text-black font-xl-semibold md:text-right">
                        {singleJobDetails?.company_name
                          ? singleJobDetails?.company_name
                          : "Company Name"}
                      </h2>
                    </Link>
                  )}
                  {singleJobDetails?.location_details?.address ? (
                    <p className="flex justify-start md:justify-end break-all">
                      <span className="mt-1 md:mt-0">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <div>{singleJobDetails?.location_details?.address}</div>
                    </p>
                  ) : (
                    <p className="flex justify-start md:justify-end">
                      <span className="mt-1 md:mt-0">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {singleJobDetails?.city
                        ? `, ${singleJobDetails?.city}`
                        : ""}
                      <br />
                      {singleJobDetails?.country}
                      {singleJobDetails?.zipcode
                        ? `, ${singleJobDetails?.zipcode}`
                        : ""}
                    </p>
                  )}
                  {/* {!singleJobDetails?.address ? (
                <p className="flex justify-start md:items-center">
                  <span className="mt-1 md:mt-0">
                    <GoLocation color="007fee" className="mr-1" size="1.2em" />
                  </span>
                  {singleJobDetails?.city}, {singleJobDetails?.country}
                  {singleJobDetails?.zipcode}
                </p>
              ) : (
                <p class="ml-6 md:ml-0">
                  {singleJobDetails?.city}, {singleJobDetails?.country}
                  {singleJobDetails?.zipcode}
                </p>
              )} */}

                  {/* <div className="flex flex-wrap justify-start md:justify-end md:flex-nowrap md:space-x-3"> */}
                  {singleJobDetails?.phone && (
                    <p className="flex items-top m-0 md:justify-end break-all">
                      <span className="mt-1">
                        <HiOutlinePhone
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <div>{new_phoneformat}</div>
                    </p>
                  )}
                  {singleJobDetails?.email && (
                    <p className="flex items-top md:justify-end m-0 break-all">
                      <span className="mt-1">
                        <HiOutlineMail
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <div>{singleJobDetails?.email}</div>
                    </p>
                  )}
                  {/* </div> */}
                  {singleJobDetails?.website && (
                    <p className="flex items-top justify-start md:justify-end break-all">
                      <span className="mt-1">
                        <IoMdGlobe
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <div>{singleJobDetails?.website}</div>
                    </p>
                  )}
                  <p>
                    {" "}
                    Current Job Openings - {singleJobDetails?.job_post_count}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="block custom_shadow rounded-lg">
            <div className="w-full px-5 pt-8 pb-5 jobDescription custom_shadow font-base-light">
              {/* map the card with this one */}
              {!loading ? (
                candidateData?.results?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-3 lg:grid-cols-4">
                    {candidateData.results.map((item, index) => (
                      <div
                        className="relative rounded shadow-md profileCards flex flex-col justify-between"
                        key={index}
                      >
                        <div className="relative">
                          <Link
                            to={`/employees/${item.employee}?job_id=${jobid}`}
                          >
                            <img
                              src={
                                item?.image
                                  ? item?.image
                                  : "/images/employee-default.png"
                              }
                              alt={item.employee}
                              title={item.employee}
                              loading="lazy"
                              width="100%"
                              style={{ minHeight: "130px" }}
                            />
                          </Link>

                          <Link
                            to={`/employees/${item.employee}?job_id=${jobid}`}
                          >
                            <div className="p-2">
                              <div className="flex space-x-4">
                                <p className="my-2 font-semibold ">
                                  {item.first_name} {item.last_name}
                                </p>
                                {item.premium == true && (
                                  <img
                                    src={RohLogo}
                                    className="w-5 h-full"
                                    alt="ROH premium logo"
                                    title="ROH premium logo"
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Link
                            to={`/employees/${item.employee}?job_id=${jobid}`}
                          >
                            <div className="p-2 flex-1 relative">
                              <p className="my-2 text-gray-500 ">
                                {item.position}
                              </p>
                              <p className="mt-2 font-bold text-main mb-12 text-sm">
                                $
                                {item?.desired_pay != null
                                  ? item?.desired_pay
                                  : "0"}{" "}
                                /hr
                              </p>
                              <div className="my-2 absolute bottom-0 w-11/12">
                                <button
                                  type="submit"
                                  className="w-full py-1 font-semibold rounded btn-main"
                                  onClick={() =>
                                    history.push(
                                      `/employees/${item.employee}?job_id=${jobid}`
                                    )
                                  }
                                >
                                  View Profile
                                </button>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="absolute right-0 ">
                          <CandidatePagePopup
                            emp_id={item.emp_id}
                            setRefresh={setRefresh}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    No Candidates found !
                  </h5>
                )
              ) : (
                <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
              )}

              {candidateData?.count > 0 && (
                <div className="mt-12">
                  <ReactPaginate
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={
                      "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                    }
                    previousLabel="<"
                    previousLinkClassName={
                      "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                    }
                    nextLabel=">"
                    nextLinkClassName={
                      "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                    }
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
      )}
    </div>
  );
}

export default CandidateList;
