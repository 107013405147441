import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
// import { AiFillEnvironment } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import axios from "axios";
import { useLayoutEffect } from "react";
// import './map.css'

const LocationPin = ({ text }) => (
  <div className="pin">
    {/* <Icon icon={locationIcon} className="pin-icon" /> */}
    {/* <AiFillEnvironment /> */}
    <ImLocation2 size={20} color="crimson" />
    <p className="pin-text font-bold">{text}</p>
  </div>
);

const map_options = {
  scrollwheel: true,
};

const Map = ({
  zoomLevel,
  handleMapAddress,
  geolocationMarker,
  geolocationError,
  marker,
  setMarkerLocation,
  address,
  setAddress,
  markerFlag,
  // markerLat,
  // markerLng,
  // setMarkerLat,
  // setMarkerLng,
}) => {
  // const [markerLat, setMarkerLat] = useState();
  // const [markerLng, setMarkerLng] = useState();
  // const [address, setAddress] = useState();
  //   const renderMarkers = (map, maps) => {
  //     let marker = new maps.Marker({
  //       position: location,
  //       map,
  //       title: "Hello World!",
  //     });
  //   };
  const [currentLatitiude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");

  const location = {
    address: "",
    lat: currentLatitiude,
    lng: currentLongitude,
  };

  useEffect(() => {
    if (markerFlag) {
      if (!marker.lat || !marker.lng) {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              geolocationMarker(
                position.coords.latitude,
                position.coords.longitude
              );
              setCurrentLatitude(position.coords.latitude);
              setCurrentLongitude(position.coords.longitude);
              handleGeolocation(
                position.coords.latitude,
                position.coords.longitude
              );
            },
            function (error) {
              axios
                .post(
                  `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_MAP_KEY}`
                )
                .then((res) => {
                  geolocationMarker(
                    res.data.location.lat,
                    res.data.location.lng
                  );
                  setCurrentLatitude(res.data.location.lat);
                  setCurrentLongitude(res.data.location.lng);
                  handleGeolocation(
                    res.data.location.lat,
                    res.data.location.lng
                  );
                })
                .catch((err) => {
                  console.log(err);
                  geolocationError(error.message);
                });
            }
          );
        } else {
          console.log("Not Available");
        }
      } else {
        // setCurrentLatitude(marker.lat);
        // setCurrentLongitude(marker.lng);
      }
    }
  }, [markerFlag]);

  const handleGeolocation = (lat, lng) => {
    if (lat && lng) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
            lat ? lat : currentLatitiude
          },${lng ? lng : currentLongitude}&key=${
            process.env.REACT_APP_MAP_KEY
          }`
        )
        .then((res) => {
          const data = res.data.results;

          const code = data.find((item) => item.types.includes("plus_code"));
          const route = data.find((item) => item.types.includes("route"));
          const country = data.find((item) => item.types.includes("country"));
          const postcode = data.find((item) =>
            item.types.includes("postal_code")
          );
          const state = data.find((item) =>
            item.types.includes("administrative_area_level_1")
          );
          const city = data.find((item) =>
            item.types.includes("administrative_area_level_2")
          );

          let addressVal = "";
          if (code.address_components.length > 1) {
            const data = code.address_components.filter((item) => {
              return (
                item.types.includes("locality") ||
                item.types.includes("administrative_area_level_2") ||
                item.types.includes("administrative_area_level_1")
              );
            });
            addressVal = data.reverse().reduce((str, item) => {
              return item.long_name + " " + str;
            }, "");
            setAddress(addressVal);
          } else if (route) {
            addressVal = route.formatted_address;
            setAddress(addressVal);
          } else {
            addressVal =
              city.address_components[0].long_name +
              " " +
              state.address_components[0].long_name;
            setAddress(addressVal);
          }
        })
        .catch((err) => {
          setAddress("");
        });
    } else {
      setAddress("Location not available");
    }
  };

  const handleClick = (lat, lng) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: lat,
      lng: lng,
    }));
    if (lat && lng) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
            lat ? lat : currentLatitiude
          },${lng ? lng : currentLongitude}&key=${
            process.env.REACT_APP_MAP_KEY
          }`
        )
        .then((res) => {
          const data = res.data.results;
          const code = data.find((item) => item.types.includes("plus_code"));
          const route = data.find((item) => item.types.includes("route"));
          const country = data.find((item) => item.types.includes("country"));
          const postcode = data.find((item) =>
            item.types.includes("postal_code")
          );
          const state = data.find((item) =>
            item.types.includes("administrative_area_level_1")
          );
          const city = data.find((item) =>
            item.types.includes("administrative_area_level_2")
          );

          const locality = data.find((item) => item.types.includes("locality"));

          const sublocality = data.find((item) =>
            item.types.includes("sublocality")
          );

          let addressVal = "";
          if (code.address_components.length > 1 || route) {
            const addres = route.address_components.filter((item) => {
              return (
                item.types.includes("establishment") ||
                item.types.includes("neighborhood") ||
                item.types.includes("premise") ||
                item.types.includes("street") ||
                item.types.includes("route") ||
                item.types.includes("locality") 
              );
            });
            addressVal = addres.reverse().reduce((str, item) => {
              return item.long_name + ", " + str;
            }, "");
            addressVal = addressVal.replace(/,\s*$/, "").trim();
            setAddress(addressVal);
          } else {
            addressVal =
              city.address_components[0].long_name +
              " " +
              state.address_components[0].long_name;
            setAddress(addressVal);
          }
          handleMapAddress(
            // route ? route.formatted_address : addressVal,
            addressVal,
            country ? country.address_components[0] : "",
            postcode ? postcode.address_components[0] : "",
            state ? state.address_components[0] : "",
            city
              ? city.address_components[0].long_name.includes("County")
                ? locality
                  ? locality.address_components[0]
                  : sublocality
                  ? sublocality.address_components[0]
                  : city.address_components[0]
                : city.address_components[0]
              : "",
            lat,
            lng
          );
        })
        .catch((err) => {
          setAddress("");
        });
    } else {
      setAddress("Location not available");
    }
  };

  // useEffect(() => {
  //   if (currentLongitude || marker.lng) {
  //     axios
  //       .get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
  //           marker.lat ? marker.lat : currentLatitiude
  //         },${
  //           marker.lng ? marker.lng : currentLongitude
  //         }&key=AIzaSyAPq1HZdMUfs8Tzq1FBGa78WCjRGMp4pYU`
  //       )
  //       .then((res) => {
  //         const data = res.data.results;

  //         const code = data.find((item) => item.types.includes("plus_code"));
  //         const route = data.find((item) => item.types.includes("route"));
  //         const country = data.find((item) => item.types.includes("country"));
  //         const postcode = data.find((item) =>
  //           item.types.includes("postal_code")
  //         );
  //         const state = data.find((item) =>
  //           item.types.includes("administrative_area_level_1")
  //         );
  //         const city = data.find((item) =>
  //           item.types.includes("administrative_area_level_2")
  //         );

  //         let addressVal = "";
  //         if (code.address_components.length > 1) {
  //           const data = code.address_components.filter((item) => {
  //             return (
  //               item.types.includes("locality") ||
  //               item.types.includes("administrative_area_level_2") ||
  //               item.types.includes("administrative_area_level_1")
  //             );
  //           });
  //           addressVal = data.reverse().reduce((str, item) => {
  //             return item.long_name + " " + str;
  //           }, "");
  //           setAddress(addressVal);
  //         } else if (route) {
  //           addressVal = route.formatted_address;
  //           setAddress(addressVal);
  //         } else {
  //           addressVal =
  //             city.address_components[0].long_name +
  //             " " +
  //             state.address_components[0].long_name;
  //           setAddress(addressVal);
  //         }
  //         handleMapAddress(
  //           route ? route.formatted_address : addressVal,
  //           country ? country.address_components[0] : "",
  //           postcode ? postcode.address_components[0] : "",
  //           state ? state.address_components[0] : "",
  //           city ? city.address_components[0] : ""
  //         );
  //       })
  //       .catch((err) => {
  //         setAddress("");
  //       });
  //   } else {
  //     setAddress("Location not available");
  //   }
  // }, [currentLongitude, refresh, marker]);

  // const handleReverseGeoCode = (lat, lng) => {

  // };

  return (
    <>
      {/* <h2 className="map-h2">Come Visit Us At Our Campus</h2> */}

      <div className="google-map h-full w-full" >
        <GoogleMapReact
          options={map_options}
          bootstrapURLKeys={{ key: "AIzaSyDdKq2J2Dt60dlI0_PdKAk4UMV3-wBpTw0" }}
          defaultCenter={location}
          center={marker.lat !== "" ? marker : location}
          defaultZoom={zoomLevel}
          onClick={({ x, y, lat, lng, event }) => {
            setMarkerLocation && handleClick(lat, lng);
          }}

          //   yesIWantToUseGoogleMapApiInternals
          //   onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <LocationPin
            lat={marker.lat ? marker.lat : currentLatitiude}
            lng={marker.lng ? marker.lng : currentLongitude}
            text={address}
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default Map;
