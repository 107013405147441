import React, { useEffect, useState, useContext } from "react";
import Chart from "react-google-charts";
import useGetData from "../../../hooks/useGetData";
import { JobContext } from "./EmployerDashboard";
import { useHistory } from "react-router-dom";
import { MapContext } from "../../../App";

function LocationMap() {
  const user_id = localStorage.getItem("user_id");
  const history = useHistory();
  const [url, setUrl] = useState(
    `employers/${user_id}/location-vice-employee-count/list`
  );
  const [
    jobIdC,
    setJobIdC,
    positionId,
    setPositionId,
    positionlabel,
    setPositionLabel,
  ] = useContext(JobContext);
  const mapContextData = useContext(MapContext);

  const loader = (
    <span className="text-base p-5 block font-semibold">Loading...</span>
  );

  useEffect(() => {
    if (jobIdC) {
      setPositionId([]);
    }
  }, [jobIdC]);

  useEffect(() => {
    if (positionId && positionId[0] != 0) {
      setUrl(
        `employers/${user_id}/location-vice-employee-count/list?position=${positionId}`
      );
    } else {
      setUrl(`employers/${user_id}/location-vice-employee-count/list`);
    }
  }, [positionId]);

  const [datatest, SetDatatest] = useState([]);
  const { data: stateWiseCount, loading } = useGetData(url);

  useEffect(() => {
    if (jobIdC && stateWiseCount) {
      let result = stateWiseCount?.map(({ abbreviation, count }) => [
        `US-${abbreviation}`,
        count,
      ]);
      result.unshift(["State", "Count"]);
      SetDatatest(result);
    }
  }, [stateWiseCount]);

  useEffect(() => {
    mapContextData[11]((prev) => ({
      ...prev,
      dashboard_position_label: positionlabel,
    }));
  }, [positionlabel]);

  const chartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;
        const region = datatest[selection[0].row + 1];
        if (region) {
          mapContextData[13](12);
          mapContextData[11]((prev) => ({
            ...prev,
            dashboard_location: region[0],
            // dashboard_position_label : positionlabel
          }));
          history.push("/hireSearch");
          // window.location.href = "/hireSearch";
        }
      },
    },
  ];

  var options = {
    region: "US",
    displayMode: "regions",
    resolution: "provinces",
    colorAxis: {
      colors: ["#ebebff", "#0000ff"],
    },
    legend: "none",
  };
  return (
    <div>
      {loading
        ? loader
        : datatest.length > 0 && (
            <Chart
              rootProps={{ "data-testid": "1" }}
              // mapsApiKey="AIzaSyAPq1HZdMUfs8Tzq1FBGa78WCjRGMp4pYU"
              chartEvents={chartEvents}
              chartType="GeoChart"
              width="100%"
              // height="400px"
              data={datatest}
              options={options}
              className="sample"
            />
          )}
    </div>
  );
}

export default LocationMap;
