import React, { useState, useEffect, useCallback, useRef } from "react";
import Sidebar from "./Sidebar";
import useGetData from "../../hooks/useGetData";
import ReactPaginate from "react-paginate";
import { GoLocation } from "react-icons/go";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineEllipsis } from "react-icons/ai";
import CancelApplication from "../modals/Employee/CancelApplication";
import UnSaveJob from "../modals/Employee/UnSaveJob";
import ReportJob from "../modals/Employee/ReportJob";
import defaultBuilding from "../../images/JobDetails/hospital-default.jpg";
import RohLogo from "../../images/ROH-logo.png";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment/moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import "../../../calendar.css";
import {
  MdVideocam,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";

const AppliedJoblist = () => {
  const user_id = localStorage.getItem("user_id");
  const [pageNumber, setPageNumber] = useState(0);
  const [show, setShow] = useState("applied");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [updateJobID, setUpdateJobID] = useState(0);

  const [cancelModal, setCancelModal] = useState(false);
  const [cancelDropdown, setCancelDropdown] = useState(0);
  const [unsaveModal, setUnSaveModal] = useState(false);
  const [savedDropdown, setSavedDropdown] = useState(0);
  const [reportModal, setReportModal] = useState(false);
  const [reportDropdown, setReportDropdown] = useState(0);
  const [jobData, setJobData] = useState([]);
  const [calendarDisplay, setCalendarDisplay] = useState(false);
  const [date, setDate] = useState(new Date());

  /* Applied job list */
  const [appliedurl, setAppliedUrl] = useState(
    `employees/${user_id}/job-applications`
  );
  const { data: AppliedJobs } = useGetData(appliedurl);
  const { data: reportList } = useGetData(`reports/jobpost`);

  const usersPerPage = 6;
  const appliedPageCount = Math.ceil(AppliedJobs?.count / usersPerPage);
  const handleAppliedData = (selected) => {
    setAppliedUrl(`employees/${user_id}/job-applications?page=${selected}`);
  };
  const appliedChangePage = ({ selected }) => {
    setPageNumber(selected);
    handleAppliedData(selected + 1);
  };

  /* Saved job list */
  const [savedurl, setSavedUrl] = useState(
    `employees/${user_id}/saved-job-posts`
  );
  const { data: SavedJobs } = useGetData(savedurl);

  const savedPageCount = Math.ceil(SavedJobs?.count / usersPerPage);
  const handleSavedData = (selected) => {
    setSavedUrl(`employees/${user_id}/saved-job-posts?page=${selected}`);
  };
  const savedChangePage = ({ selected }) => {
    setPageNumber(selected);
    handleSavedData(selected + 1);
  };

  /* Interview List */
  const [interviewUrl, setInterviewUrl] = useState("");
  const { data: interviewData } = useGetData(interviewUrl);
  console.log("date", date);
  const newDate = moment(date).format("YYYY-MM-DD");
  console.log("new date", newDate);

  useEffect(() => {
    setInterviewUrl(`availability/my-interviews?event_date=${newDate}`);
  }, [interviewUrl, date]);

  const formatDate = (dateinf) => {
    let eventDate = moment(dateinf).format("ll");
    return eventDate;
  };

  const formatTime = (timeinf) => {
    console.log('timeinf', timeinf)
    const timeString12hr = new Date(
      "1970-01-01T" + timeinf + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    return timeString12hr;
  };

  const jobListRefresh = (id) => {
    const jobs = jobData?.results;
    const filteredjobs = jobs.filter((item) => item.job !== id);
    console.log("filteredJobs", filteredjobs);
    setJobData((prev) => ({ ...prev, results: filteredjobs }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (AppliedJobs) {
      setLoading(false);
      setJobData(AppliedJobs);
    } else {
      setLoading(true);
    }
  }, [AppliedJobs]);

  const appliedClick = (job) => {
    if (job !== cancelDropdown) {
      setCancelDropdown(job);
      setUpdateJobID(job);
    } else {
      setCancelDropdown(null);
    }
  };

  const savedClick = (jobpost) => {
    if (jobpost !== savedDropdown) {
      setSavedDropdown(jobpost);
      setUpdateJobID(jobpost);
    } else {
      setSavedDropdown(null);
    }
  };

  /* Close the dropdown on outside click */
  function popupHide() {
    setCancelDropdown(null);
    setSavedDropdown(null);
  }
  useEffect(() => {
    if (cancelDropdown || savedDropdown) {
      window.addEventListener("click", popupHide);

      // unmound the eventlistner
      return () => {
        window.removeEventListener("click", popupHide);
      };
    }
  }, [cancelDropdown, savedDropdown]);

  const dropdownRef = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        calendarDisplay &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setCalendarDisplay(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [calendarDisplay]);

  return (
    <div className="p-5 bg-white rounded-lg shadow-lg">
      <h2 className="font-semibold mb-12 text-3xl">Applications</h2>
      <div className="flex justify-around gap-5">
        <div className="w-full">
          <div class="flex items-center justify-items-start">
            <div
              className={`cursor-pointer px-3 py-1 sm:px-6 sm:py-3 font-semibold	rounded-t-md ${
                show == "applied" ? "bg-main text-white" : "bg-gray-200"
              }`}
              onClick={() => setShow("applied")}
            >
              Applied
            </div>
            <div
              className={`cursor-pointer px-2 py-1 sm:px-4 lg:px-6 sm:py-3 font-semibold target-ph rounded-t-md ml-1 md:ml-5 text-xs md:text-base ${
                show == "interview" ? "bg-main text-white" : "bg-gray-200"
              }`}
              onClick={() => setShow("interview")}
            >
              Interviews
            </div>
            <div
              className={`cursor-pointer px-3 py-1 sm:px-6 sm:py-3 font-semibold	rounded-t-md ml-5 ${
                show == "saved" ? "bg-main text-white" : "bg-gray-200"
              }`}
              onClick={() => setShow("saved")}
            >
              Saved
            </div>
          </div>

          <div className="w-auto p-5 rounded-md custom_shadow">
            {show === "applied" && (
              <>
                {!loading ? (
                  jobData?.results?.length > 0 ? (
                    <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                      {jobData?.results?.map((single_applied_item, index) => {
                        let salarySubType = Object.keys(
                          single_applied_item.salary_subtype
                        ).map((key) => {
                          return single_applied_item.salary_subtype[key];
                        });
                        let salaryType = Object.values(
                          single_applied_item.salary_type
                        );
                        return (
                          <div
                            className="rounded shadow-md profileCards flex flex-col justify-between"
                            key={index}
                          >
                            <div className="relative">
                              <Link
                                to={`/job-details/${single_applied_item.job}`}
                              >
                                {!single_applied_item.image &&
                                !single_applied_item.category_unverified_image &&
                                !single_applied_item.category_verified_image ? (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={defaultBuilding}
                                    alt={single_applied_item.job}
                                    title={single_applied_item.job}
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={
                                      single_applied_item.image
                                        ? single_applied_item.image
                                        : single_applied_item.emp_id == null
                                        ? single_applied_item.category_unverified_image
                                        : single_applied_item.category_verified_image
                                    }
                                    alt={single_applied_item.job}
                                    title={single_applied_item.job}
                                    loading="lazy"
                                  />
                                )}
                              </Link>

                              <Link
                                to={`/job-details/${single_applied_item.job}`}
                              >
                                <div className="p-2">
                                  <div className="flex space-x-4">
                                    <p className="mt-2 mb-3 font-semibold h-auto">
                                      {single_applied_item.job_title
                                        ? single_applied_item.job_title
                                        : single_applied_item.position}
                                    </p>
                                    {single_applied_item.is_owner_premium ==
                                      true && (
                                      <img
                                        src={RohLogo}
                                        className="w-5 h-full"
                                        alt="ROH premium logo"
                                        title="ROH premium logo"
                                        loading="lazy"
                                      />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              {/* action buttons */}
                              <div className="absolute top-0 right-1">
                                <CancelApplication
                                  cancelModal={cancelModal}
                                  setCancelModal={setCancelModal}
                                  singleJobId={updateJobID}
                                  jobListRefresh={jobListRefresh}
                                />
                                <ReportJob
                                  reportModal={reportModal}
                                  setReportModal={setReportModal}
                                  singleJobId={updateJobID}
                                  reportList={reportList}
                                />
                                <div>
                                  <button
                                    type="button"
                                    className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                    id="menu-button"
                                    onClick={() => {
                                      appliedClick(single_applied_item.job);
                                    }}
                                  >
                                    <AiOutlineEllipsis
                                      className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                      size="2em"
                                    />
                                  </button>
                                </div>

                                <div
                                  className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                    cancelDropdown === single_applied_item.job
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <div className="divide-y rounded-md shadow-lg">
                                    <button
                                      onClick={() => {
                                        setCancelModal(true);
                                        setCancelDropdown(false);
                                      }}
                                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                                    >
                                      Cancel Application
                                    </button>
                                    <button
                                      onClick={() => {
                                        setReportModal(true);
                                        setCancelDropdown(false);
                                      }}
                                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                                    >
                                      Report
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <Link
                              to={`/job-details/${single_applied_item.job}`}
                            >
                              <div className="p-2">
                                <p className="text-gray-500 mb-2">
                                  {single_applied_item.company_name}
                                </p>
                                <div className="flex items-top">
                                  <div className="mt-1">
                                    <GoLocation color="gray" size="1em" />
                                  </div>
                                  <p className="mx-1 my-0 text-gray-500">
                                    {single_applied_item.job_location}
                                  </p>
                                </div>
                                {salaryType[0] === "Fixed" && (
                                  <p className="my-2 font-bold text-main">
                                    {/* {single_emp_item.min_salary ? "$" +single_emp_item.min_salary+"  " :""}
                                  {(single_emp_item.min_salary && single_emp_item.max_salary) ? " - ":""} */}
                                    {single_applied_item.max_salary
                                      ? "$" +
                                        parseInt(
                                          single_applied_item.max_salary
                                        ).toLocaleString()
                                      : ""}
                                    {single_applied_item.max_salary ? "/" : ""}
                                    {salarySubType[0]}
                                  </p>
                                )}
                                {salaryType[0] !== "Fixed" && (
                                  <p className="my-2 font-bold text-main">
                                    {single_applied_item?.min_salary ||
                                    single_applied_item?.max_salary ? (
                                      <>
                                        {single_applied_item?.min_salary
                                          ? "$" +
                                            parseFloat(
                                              single_applied_item?.min_salary
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "") +
                                            "/Hr"
                                          : ""}
                                        {single_applied_item?.min_salary &&
                                          single_applied_item?.max_salary &&
                                          "-"}
                                        {single_applied_item?.max_salary
                                          ? "$" +
                                            parseFloat(
                                              single_applied_item?.max_salary
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "") +
                                            "/Hr"
                                          : ""}
                                      </>
                                    ) : single_applied_item?.min_yearly_pay ||
                                      single_applied_item?.max_yearly_pay ? (
                                      <>
                                        {single_applied_item?.min_yearly_pay
                                          ? "$" +
                                            parseFloat(
                                              single_applied_item?.min_yearly_pay
                                            ).toLocaleString() +
                                            "/Yr"
                                          : ""}
                                        {single_applied_item?.min_yearly_pay &&
                                          single_applied_item?.max_yearly_pay &&
                                          "-"}
                                        {single_applied_item?.max_yearly_pay
                                          ? "$" +
                                            parseFloat(
                                              single_applied_item?.max_yearly_pay
                                            ).toLocaleString() +
                                            "/Yr"
                                          : ""}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                )}
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    onClick={() => {
                                      history.push(
                                        `/job-details/${single_applied_item.job}`
                                      );
                                    }}
                                    className="py-1 my-2 font-semibold rounded btn-main px-6 w-full"
                                  >
                                    View Job
                                  </button>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <h5 className="p-3 text-gray-600 font-semibold">
                      You don't have any applied jobs yet!
                    </h5>
                  )
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    Loading...
                  </h5>
                )}

                {jobData?.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={appliedPageCount}
                      onPageChange={appliedChangePage}
                      containerClassName={"paginationBttns flex justify-center"}
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}

            {show === "interview" && (
              <>
                <div className="relative pb-4">
                  <div className="flex flex-row">
                    <FaCalendarAlt
                      onClick={() => setCalendarDisplay(!calendarDisplay)}
                      className="text-white bg-blue-700 py-2 w-10 h-10 rounded-md "
                      size="2em"
                    />

                    <h1 className="text-center p-2 font-bold">
                      {moment(date).format("DD MMMM YYYY")}
                    </h1>

                    <MdKeyboardArrowLeft
                      onClick={() => {
                        let temp = moment(date).subtract(1, "d");
                        setDate(temp);
                      }}
                      className="text-black bg-gray-200 rounded-full h-10 w-10 p-2 mx-1 hover:bg-gray-400"
                      size="1.5em"
                    />
                    <MdKeyboardArrowRight
                      onClick={() => {
                        let temp = moment(date).add(1, "d");
                        setDate(temp);
                      }}
                      className="text-black bg-gray-200 rounded-full h-10 w-10 p-2 mx-1 hover:bg-gray-400"
                      size="1.5em"
                    />
                  </div>
                  {calendarDisplay && (
                    <div className="calendar-container w-full lg:w-4/12" ref={dropdownRef}>
                      <Calendar onChange={setDate} value={new Date(date)} />
                    </div>
                  )}

                  {/* <div>
                    <h1 className="text-gray-500 text-sm py-2">Job Title</h1>
                  </div> */}
                </div>
                <div className=" border-b-2 bg-gray-400 mb-4"></div>
                {interviewData?.data?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                    {interviewData?.data?.map(
                      (single_interview_item, index) => (
                        <Link
                          to={`/job-details/${single_interview_item?.job?.id}`}
                        >
                          <div
                            className="rounded shadow-md profileCards flex flex-col justify-between border-2"
                            key={index}
                          >
                            <div className="relative">
                              <img
                                className="w-24 pt-4 px-4 rounded-md h-full"
                                src={
                                  !single_interview_item?.job?.image &&
                                  !single_interview_item?.job
                                    ?.category_unverified_image &&
                                  !single_interview_item?.job
                                    ?.category_verified_image
                                    ? "/images/hospital-default.jpg"
                                    : single_interview_item?.job?.image
                                    ? single_interview_item?.job?.image
                                    : single_interview_item?.job?.emp_id == null
                                    ? single_interview_item?.job
                                        ?.category_unverified_image
                                    : single_interview_item?.job
                                        ?.category_verified_image
                                }
                                alt={single_interview_item?.job?.id}
                                title={single_interview_item?.job?.id}
                                loading="lazy"
                              />

                              <div className="">
                                <div className="flex  pl-4">
                                  <p className="mt-2 mb-3 font-semibold h-auto">
                                    {single_interview_item?.job?.company_name
                                      ? single_interview_item?.job?.company_name
                                      : single_interview_item?.job?.job_title}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <p className="text-red-500 mb-2 text-sm px-4">
                                {single_interview_item?.job?.position_label}
                              </p>

                              <div className="flex flex-row justify-between px-4">
                                <p className="text-sm text-gray-500">
                                  {single_interview_item?.job?.location_details
                                    ?.city !== null
                                    ? single_interview_item?.job
                                        ?.location_details?.city + ","
                                    : ""}
                                  {single_interview_item?.job?.location_details
                                    ?.state !== null
                                    ? single_interview_item?.job
                                        ?.location_details?.state
                                    : ""}
                                </p>

                                <p className="font-bold text-main">
                                  {"$" +
                                    single_interview_item?.job?.min_salary +
                                    "/hr"}{" "}
                                  -{" "}
                                  {"$" +
                                    single_interview_item?.job?.max_salary +
                                    "/hr"}
                                </p>
                              </div>

                              <br />

                              <div className="mx-4 border-b-2 bg-gray-400"></div>
                              <div className="flex flex-row m-4 justify-between">
                                <button
                                  type="submit"
                                  className="flex flex-row rounded-lg px-3 py-1  btn-main"
                                >
                                  <MdVideocam
                                    className="text-white"
                                    size="1.5em"
                                  />
                                  <p className="pl-2">
                                    {formatTime(
                                      single_interview_item?.from_hour
                                    )}
                                  </p>
                                </button>
                                <h1 className="text-sm text-gray-500 py-1 font-medium">
                                  {formatDate(
                                    single_interview_item?.event_date
                                  )}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    )}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    You don't have any interviews yet!
                  </h5>
                )}
              </>
            )}

            {show === "saved" && (
              <>
                {SavedJobs?.results?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                    {SavedJobs?.results?.map((single_saved_item, index) => {
                      let salarySubType = Object.keys(
                        single_saved_item.salary_subtype
                      ).map((key) => {
                        return single_saved_item.salary_subtype[key];
                      });
                      let salaryType = Object.values(
                        single_saved_item.salary_type
                      );
                      return (
                        <div
                          className="rounded shadow-md profileCards flex flex-col justify-between"
                          key={index}
                        >
                          <div className="relative">
                            <Link
                              to={`/job-details/${single_saved_item.jobpost}`}
                            >
                              {!single_saved_item.image &&
                              !single_saved_item.category_unverified_image &&
                              !single_saved_item.category_verified_image ? (
                                <img
                                  className="object-cover w-full h-auto rounded-t-md"
                                  src={defaultBuilding}
                                  alt={single_saved_item.jobpost}
                                  title={single_saved_item.jobpost}
                                  loading="lazy"
                                />
                              ) : (
                                <img
                                  className="object-cover w-full h-auto rounded-t-md"
                                  src={
                                    single_saved_item.image
                                      ? single_saved_item.image
                                      : single_saved_item.emp_id == null
                                      ? single_saved_item.category_unverified_image
                                      : single_saved_item.category_verified_image
                                  }
                                  alt={single_saved_item.jobpost}
                                  title={single_saved_item.jobpost}
                                  loading="lazy"
                                />
                              )}
                            </Link>

                            <Link
                              to={`/job-details/${single_saved_item.jobpost}`}
                            >
                              <div className="p-2">
                                <div className="flex space-x-4">
                                  <p className="mt-2 mb-3 font-semibold h-auto">
                                    {single_saved_item.job_title
                                      ? single_saved_item.job_title
                                      : single_saved_item.position}
                                  </p>
                                  {single_saved_item.is_owner_premium ==
                                    true && (
                                    <img
                                      src={RohLogo}
                                      className="w-5 h-full"
                                      alt="ROH premium logo"
                                      title="ROH premium logo"
                                      loading="lazy"
                                    />
                                  )}
                                </div>
                              </div>
                            </Link>

                            {/* action buttons */}
                            <div className="absolute top-0 right-1">
                              <UnSaveJob
                                unsaveModal={unsaveModal}
                                setUnSaveModal={setUnSaveModal}
                                singleJobId={updateJobID}
                              />
                              <ReportJob
                                reportModal={reportModal}
                                setReportModal={setReportModal}
                                singleJobId={updateJobID}
                                reportList={reportList}
                              />
                              <div>
                                <button
                                  type="button"
                                  className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                  id="menu-button"
                                  onClick={() => {
                                    savedClick(single_saved_item.jobpost);
                                  }}
                                >
                                  <AiOutlineEllipsis
                                    className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                    size="2em"
                                  />
                                </button>
                              </div>

                              <div
                                className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                  savedDropdown === single_saved_item.jobpost
                                    ? "block"
                                    : "hidden"
                                }`}
                              >
                                <div className="divide-y rounded-md shadow-lg">
                                  <button
                                    onClick={() => {
                                      setUnSaveModal(true);
                                      setSavedDropdown(false);
                                    }}
                                    className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                                  >
                                    Unsave the Job
                                  </button>
                                  <button
                                    onClick={() => {
                                      setReportModal(true);
                                      setCancelDropdown(false);
                                    }}
                                    className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                                  >
                                    Report
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Link
                            to={`/job-details/${single_saved_item.jobpost}`}
                          >
                            <div className="p-2">
                              <p className="text-gray-500 mb-2">
                                {single_saved_item.company_name}
                              </p>
                              <div className="flex items-top">
                                <div className="mt-1">
                                  <GoLocation color="gray" size="1em" />
                                </div>
                                <p className="mx-1 my-0 text-gray-500">
                                  {single_saved_item.location}
                                </p>
                              </div>
                              {salaryType[0] === "Fixed" && (
                                <p className="my-2 font-bold text-main">
                                  {single_saved_item.max_salary
                                    ? "$" +
                                      parseInt(
                                        single_saved_item.max_salary.split(
                                          "."
                                        )[0]
                                      ).toLocaleString() +
                                      "  "
                                    : ""}
                                  {single_saved_item.max_salary ? "/" : ""}
                                  {salarySubType[0]}
                                </p>
                              )}
                              {salaryType[0] !== "Fixed" && (
                                <p className="my-2 font-bold text-main">
                                  {single_saved_item?.min_salary ||
                                  single_saved_item?.max_salary ? (
                                    <>
                                      {single_saved_item?.min_salary
                                        ? "$" +
                                          parseFloat(
                                            single_saved_item?.min_salary
                                          )
                                            .toFixed(2)
                                            .replace(/\.00$/, "") +
                                          "/Hr"
                                        : ""}
                                      {single_saved_item?.min_salary &&
                                        single_saved_item?.max_salary &&
                                        "-"}
                                      {single_saved_item?.max_salary
                                        ? "$" +
                                          parseFloat(
                                            single_saved_item?.max_salary
                                          )
                                            .toFixed(2)
                                            .replace(/\.00$/, "") +
                                          "/Hr"
                                        : ""}
                                    </>
                                  ) : single_saved_item?.min_yearly_pay ||
                                    single_saved_item?.max_yearly_pay ? (
                                    <>
                                      {single_saved_item?.min_yearly_pay
                                        ? "$" +
                                          parseFloat(
                                            single_saved_item?.min_yearly_pay
                                          ).toLocaleString() +
                                          "/Yr"
                                        : ""}
                                      {single_saved_item?.min_yearly_pay &&
                                        single_saved_item?.max_yearly_pay &&
                                        "-"}
                                      {single_saved_item?.max_yearly_pay
                                        ? "$" +
                                          parseFloat(
                                            single_saved_item?.max_yearly_pay
                                          ).toLocaleString() +
                                          "/Yr"
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              )}
                              <div className="text-center">
                                <button
                                  type="submit"
                                  onClick={() => {
                                    history.push(
                                      `/job-details/${single_saved_item.jobpost}`
                                    );
                                  }}
                                  className="py-1 my-2 font-semibold rounded btn-main px-6 w-full"
                                >
                                  View Job
                                </button>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    You don't have any saved jobs yet!
                  </h5>
                )}

                {SavedJobs?.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={savedPageCount}
                      onPageChange={savedChangePage}
                      containerClassName={
                        "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                      }
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedJoblist;
