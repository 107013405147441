import React, { useState, useEffect } from "react";
import Select from "react-select";
import AvailabilityWeek from "../../elements/AvailabilityWeek";
import usePostData from "../../../hooks/usePostData";
import useGetData from "../../../hooks/useGetData";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export default function Availability() {
  const [formData, setFormData] = useState(null);
  let history = useHistory();
  const clock = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];
  const [refresh, setRefresh] = useState(false);
  const [notification, setNotification] = useState({
    status: false,
    data: "Slot Update",
  });

  // const current_plan = localStorage.getItem("plan_value");
  // console.log("current_plan", current_plan);

  const week = [
    { value: "Sunday", weekday: 1 },
    { value: "Monday", weekday: 2 },
    { value: "Tuesday", weekday: 3 },
    { value: "Wednesday", weekday: 4 },
    { value: "Thursday", weekday: 5 },
    { value: "Friday", weekday: 6 },
    { value: "Saturday", weekday: 7 },
  ];
  const AvailabilityUrl = "availability/";
  const { data: AvailabilityData } = useGetData(
    AvailabilityUrl,
    "main",
    refresh
  );

  useEffect(() => {
    if (notification.status) {
      setTimeout(() => {
        setNotification((prev) => ({ ...prev, status: false }));
      }, 3000);
    }
  }, [notification]);

  const redirectMe = () => {
    window.location.href = "/plans-and-pricing";
    localStorage.setItem("availability-path", window.location.href);
  };

  const loader = (
    <span className="text-main text-lg font-semibold">Loading...</span>
  );

  //User active plan API
  const activePlanUrl = "user-active-plan";
  const { data: ActivePlanData, loading: activePlanLoad } =
    useGetData(activePlanUrl);
  const userCurrentPlan = ActivePlanData?.data?.plan;
  console.log("userCurrentPlan", userCurrentPlan);

  const { data, error, message } = usePostData(AvailabilityUrl, formData);

  const redirect = () => {
    let active = localStorage.getItem("redirection_availability");
    if (active === "true") {
      localStorage.setItem("redirection_availability", false);
      history.goBack();
    }
  };

  console.log(refresh, "sima");

  return (
    <>
      {activePlanLoad ? (
        <div className="mx-5">{loader}</div>
      ) : userCurrentPlan === null ? (
        redirectMe()
      ) : (
        <div
          className="bg-white rounded-lg shadow-lg pb-1 md:pb-5 xl:flex-initial"
          style={{ maxWidth: "750px" }}
        >
          <div className="flex flex-col mb-6 mx-6 relative">
            <h1 className="text-xl font-semibold text-black mb-2 mt-10 lg:mb-0 text-left">
              Availability
            </h1>
            {notification?.status && (
              <h1 className="absolute top-6 right-6 text-blue-400">
                {notification?.data}
              </h1>
            )}
            <h1 className="text-gray-700">
              Confirm availability for interview
            </h1>

            <div className="my-4 mx-2">
              {AvailabilityData &&
                week.map((item) => {
                  const dataAccoringtoDay = AvailabilityData?.data?.filter(
                    (element) => {
                      return item.weekday === element.weekday;
                    }
                  );

                  return (
                    <AvailabilityWeek
                      key={item.weekday}
                      index={item.weekday}
                      clock={clock}
                      day={item.value}
                      data={dataAccoringtoDay}
                      setRefresh={() => setRefresh(!refresh)}
                      setNotification={setNotification}
                    />
                  );
                })}
            </div>
            {/* <div className="w-full h-0.5 bg-gray-300 mt-10"></div> */}
            <div className="pt-6 flex flex-row justify-between">
              <div></div>
              <button
                className="w-48 py-4 font-semibold rounded btn-main hover:bg-blue-700"
                onClick={redirect}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
