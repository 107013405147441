import { Link, useHistory, useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import defaultlogo from "../../../images/JobDetails/hospital-default.jpg";
import defaultImgEmployee from "../../../images/employee-default.png";
import RohLogo from "../../../images/ROH-logo.png";
import { ImLocation2 } from "react-icons/im";
import { GoLocation } from "react-icons/go";
import NullJobIdEmployerDetails from "../../pages/NullJobIdEmployerDetails";
const href = window.location.href

const MultipleJobs = ({
  setShowModal,
  details,
  lookingToHire,
  handleViewJob,
  handleViewProfile,
}) => {
  const history = useHistory();
  // let randomStr =(Math.random() + 1).toString(36).substring(7);
  let randomStr = "XXXXX XXXX";
  if (!lookingToHire && !details.sameLocationData.modal_data) return null;
  return (
    <div>
      <div
        className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none"
        onClick={() => {
          setShowModal(false);
        }}
      >
        {/* backdrop */}
        <div className="relative job-modal m-auto w-4/5 max-w-2xl h-3/4 z-70">
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none pb-5">
            {/*header*/}
            <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
              <h3 className="text-xl font-semibold md:text-2xl">
                {details?.sameLocationData?.length}{" "}
                {(lookingToHire && details?.sameLocationData.modal_data[0].user_id) ? "Hires" : "Jobs"} Available
              </h3>
              {/* <p className="text-red-600 font-base-semibold">1/3</p> */}
              <button
                className="flex items-center justify-center font-semibold text-gray-500 w-auto"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <AiOutlineClose className="text-gray-600" size="1.2em" />
              </button>
            </div>

            {/* content */}
            {lookingToHire ? (
              <div className="job-modal-inner h-96 overflow-y-scroll overflow-x-hidden mt-5">
                <div className="flex flex-col m-3 mb-10">
                  <div className="grid grid-cols-1 gap-4 capitalize md:grid-cols-3">
                    {details?.sameLocationData?.modal_data?.map(
                      (detail, index) => {
                        if (detail.user_id) {
                          return (
                            // <Link to={`/job-details/${i.id}`}>
                            <div
                              key={index}
                              className="rounded shadow-md profileCards flex flex-col justify-between"
                            >
                              <div className="relative">
                                <img
                                  src={
                                    detail.image
                                      ? detail.image
                                      : defaultImgEmployee
                                  }
                                  alt=""
                                  className={`object-cover w-full h-auto ${
                                    !detail.name && "blurimage"
                                  }`}
                                />
                                {detail.is_premium == true && (
                                  <div className="px-2">
                                    <img
                                      src={RohLogo}
                                      className="w-auto h-7 mt-2"
                                    />
                                  </div>
                                )}
                                <h6
                                  className={`my-2 font-semibold text-left p-2 ${
                                    !detail.name && "blurtext"
                                  }`}
                                >
                                  {detail.name ? detail.name : randomStr}
                                </h6>
                                <p className="mx-2 my-2 text-gray-500 text-left">
                                  {detail.position}
                                </p>
                              </div>
                              <div className="p-2 text-left">
                                <div className="flex items-top">
                                  <div className="mt-0">
                                    <GoLocation
                                      color="gray"
                                      size="1em"
                                      className="self-start mt-1"
                                    />
                                  </div>

                                  <p className="mx-1 my-0 text-gray-500 ">
                                    {detail.address}
                                  </p>
                                </div>
                                <p className="my-2 mx-4 font-bold text-main">
                                  $
                                  {detail?.desired_pay
                                    ? detail?.desired_pay + "/hr"
                                    : ""}
                                </p>

                                {detail.name ? (
                                  <button
                                    type="submit"
                                    className="w-full py-1 font-semibold rounded btn-main"
                                    onClick={() =>
                                      history.push(
                                        `/employees/${detail?.user_id}`
                                      )
                                    }
                                  >
                                    View Profile
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="w-full py-1 font-semibold rounded text-white bg-tooltip"
                                    onClick={() =>
                                      history.push("/profile/my-plan")
                                    }
                                  >
                                    Upgrade
                                  </button>
                                )}
                              </div>
                            </div>
                            // </Link>
                          );
                        } else {
                          let salarySubType = Object.values(
                            detail.salary_subtype ? detail.salary_subtype : {}
                          );
                          let salaryType = Object.values(
                            detail.salary_type ? detail.salary_type : {}
                          );
                          return (
                            // <Link to={`/job-details/${i.id}`}>
                            <div
                              key={index}
                              className="rounded shadow-md profileCards flex flex-col justify-between"
                            >
                              <Link to={`/job-details/${detail.id}`}>
                                <div className="relative">
                                  {!detail.image &&
                                  !detail.category_unverified_image &&
                                  !detail.category_verified_image ? (
                                    <img
                                      src={defaultlogo}
                                      alt=""
                                      className="object-cover w-full h-auto"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        detail.image
                                          ? detail.image
                                          : detail.emp_id == null
                                          ? detail.category_unverified_image
                                          : detail.category_verified_image
                                      }
                                      alt=""
                                      className="object-cover w-full h-auto"
                                    />
                                  )}
                                  {detail.is_premium == true && (
                                    <div className="px-2">
                                      <img
                                        src={RohLogo}
                                        className="w-auto h-7 mt-2"
                                      />
                                    </div>
                                  )}
                                  <p className="my-2 font-semibold text-left p-2">
                                    {detail.job_title ? detail.job_title : detail.position_label}
                                  </p>
                                </div>
                              </Link>

                              <Link to={`/job-details/${detail.id}`}>
                                <div className="p-2 text-left">
                                  <p className="mx-2 my-2 text-gray-500 text-left">
                                    {detail?.company_name}
                                  </p>
                                  <div className="flex items-top">
                                    <div className="mt-0">
                                      <GoLocation
                                        color="gray"
                                        size="1em"
                                        className="self-start mt-1"
                                      />
                                    </div>

                                    <p className="mx-1 my-0 text-gray-500 ">
                                      {detail.location_details.address}
                                    </p>
                                  </div>
                                  {salaryType?.[0] === "Fixed" && (
                                    <p className="my-2 font-bold text-main text-sm">
                                      {detail?.max_salary
                                        ? "$" +
                                          detail.max_salary.toLocaleString() +
                                          "  "
                                        : ""}
                                      {detail?.max_salary ? "/" : ""}
                                      {salarySubType?.[0]}
                                    </p>
                                  )}

                                  {salaryType?.[0] !== "Fixed" && (
                                    <p className="font-bold text-main">
                                      {detail?.min_salary ||
                                      detail?.max_salary ? (
                                        <>
                                          {detail?.min_salary
                                            ? "$" +
                                              parseFloat(detail?.min_salary).toFixed(2).replace(/\.00$/, '')
                                              +"/Hr"
                                            : ""}
                                          {detail?.min_salary &&
                                            detail?.max_salary &&
                                            "-"}
                                          {detail?.max_salary
                                            ? "$" +
                                              parseFloat(detail?.max_salary).toFixed(2).replace(/\.00$/, '')
                                              +"/Hr"
                                            : ""}
                                        </>
                                      ) : detail?.min_yearly_pay ||
                                        detail?.max_yearly_pay ? (
                                        <>
                                          {detail?.min_yearly_pay
                                            ? "$" +
                                              detail?.min_yearly_pay +
                                              "/Yr"
                                            : ""}
                                          {detail?.min_yearly_pay &&
                                            detail?.max_yearly_pay &&
                                            "-"}
                                          {detail?.max_yearly_pay
                                            ? "$" +
                                              detail?.max_yearly_pay +
                                              "/Yr"
                                            : ""}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  )}

                                  <button
                                    type="submit"
                                    className="w-full py-1 font-semibold rounded btn-main"
                                    onClick={() =>
                                      history.push(`/job-details/${detail.id}`)
                                    }
                                  >
                                    View Jobs
                                  </button>
                                  {<button
                                    onClick={(e) => window.location.href = (`/hireSearch/id=${detail.id}`)}
                                    className="bg-tooltip text-white rounded-md py-1 w-full  mt-2"
                                  >
                                    View Job Seekers
                                  </button>}
                                </div>
                              </Link>
                            </div>
                            // </Link>
                          );
                        }
                      }
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="job-modal-inner h-96 overflow-y-scroll overflow-x-hidden mt-5">
                {Object.keys(details?.sameLocationData?.modal_data)?.map(
                  (item) => {
                    const userId = localStorage.getItem("user_id");
                    const empID =
                      details?.sameLocationData?.modal_data[item][0]?.emp_id;
                    return (
                      <div className="flex flex-col m-3 mb-10">
                        <div
                          className={`mt-3 mb-5 custom_shadow jobTitleContainer flex flex-col rounded p-4 
                        ${
                          empID == userId && userId !== null
                            ? "bg-gold"
                            : "bg-main"
                        }
                        `}
                        >
                          {details?.sameLocationData?.modal_data[item][0]
                            ?.emp_id == null ? (
                            <Link
                              to={`/employers-details/${details?.sameLocationData?.modal_data[
                                item
                              ][0]?.company_name
                                ?.replaceAll(" ", "-")
                                ?.toLowerCase()}`}
                            >
                              <h2 className="text-white font-xl-semibold mb-2  text-center">
                                {item ? item : "Company Name"}
                              </h2>
                            </Link>
                          ) : (
                            <Link
                              to={`/employers/${details?.sameLocationData?.modal_data[item][0]?.emp_id}`}
                            >
                              <h2 className="text-white font-xl-semibold mb-2 text-center">
                                {item ? item : "Company Name"}
                              </h2>
                            </Link>
                          )}

                          {details?.sameLocationData?.modal_data[item][0]
                            ?.address ? (
                            <p className="flex justify-center text-white">
                              <span className="mt-1 md:mt-0">
                                <GoLocation
                                  color="fff"
                                  className="mr-1"
                                  size="1.2em"
                                />
                              </span>
                              <div>
                                {
                                  details?.sameLocationData?.modal_data[item][0]
                                    ?.address
                                }
                              </div>
                            </p>
                          ) : (
                            <p className="flex justify-start ">
                              <span className="mt-1 md:mt-0">
                                <GoLocation
                                  color="007fee"
                                  className="mr-1"
                                  size="1.2em"
                                />
                              </span>
                              {details?.sameLocationData?.modal_data[item][0]
                                ?.city
                                ? `, ${details?.sameLocationData?.modal_data[item][0]?.city}`
                                : ""}
                              <br />
                              {
                                details?.sameLocationData?.modal_data[item][0]
                                  ?.country
                              }
                              {details?.sameLocationData?.modal_data[item][0]
                                ?.zipcode
                                ? `, ${details?.sameLocationData?.modal_data[item][0]?.zipcode}`
                                : ""}
                            </p>
                          )}
                        </div>

                        <div className="grid grid-cols-1 gap-4 capitalize md:grid-cols-3">
                          {details?.sameLocationData?.modal_data[item]?.map(
                            (detail, index) => {
                              let salarySubType = Object.values(
                                detail.salary_subtype
                                  ? detail.salary_subtype
                                  : {}
                              );
                              let salaryType = Object.values(
                                detail.salary_type ? detail.salary_type : {}
                              );
                              return (
                                // <Link to={`/job-details/${i.id}`}>
                                <div
                                  key={index}
                                  className="rounded shadow-md profileCards flex flex-col justify-between"
                                >
                                  <Link to={`/job-details/${detail.id}`}>
                                    <div className="relative">
                                      {!detail.image &&
                                      !detail.category_unverified_image &&
                                      !detail.category_verified_image ? (
                                        <img
                                          src={defaultlogo}
                                          alt=""
                                          className="object-cover w-full h-auto"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            detail.image
                                              ? detail.image
                                              : detail.emp_id == null
                                              ? detail.category_unverified_image
                                              : detail.category_verified_image
                                          }
                                          alt=""
                                          className="object-cover w-full h-auto"
                                        />
                                      )}
                                      {detail.is_premium == true && (
                                        <div className="px-2">
                                          <img
                                            src={RohLogo}
                                            className="w-auto h-7 mt-2"
                                          />
                                        </div>
                                      )}
                                      <p className="my-2 font-semibold text-left p-2">
                                        {detail.position}
                                      </p>
                                    </div>
                                  </Link>

                                  <Link to={`/job-details/${detail.id}`}>
                                    <div className="p-2 text-left">
                                      <p className="mx-2 my-2 text-gray-500 text-left">
                                        {detail?.company_name}
                                      </p>
                                      <div className="flex items-top">
                                        <div className="mt-0">
                                          <GoLocation
                                            color="gray"
                                            size="1em"
                                            className="self-start mt-1"
                                          />
                                        </div>

                                        <p className="mx-1 my-0 text-gray-500 ">
                                          {detail.address}
                                        </p>
                                      </div>
                                      {salaryType?.[0] === "Fixed" && (
                                        <p className="my-2 font-bold text-main text-sm">
                                          {detail?.max_salary
                                            ? "$" +
                                              detail.max_salary.toLocaleString() +
                                              "  "
                                            : ""}
                                          {detail?.max_salary ? "/" : ""}
                                          {salarySubType?.[0]}
                                        </p>
                                      )}

                                      {salaryType?.[0] !== "Fixed" && (
                                        <p className="font-bold text-main">
                                          {detail?.min_salary ||
                                          detail?.max_salary ? (
                                            <>
                                              {detail?.min_salary
                                                ? "$" +
                                                  parseFloat(detail?.min_salary).toFixed(2).replace(/\.00$/, '')
                                                  +"/Hr"
                                                : ""}
                                              {detail?.min_salary &&
                                                detail?.max_salary &&
                                                "-"}
                                              {detail?.max_salary
                                                ? "$" +
                                                  parseFloat(detail?.min_salary).toFixed(2).replace(/\.00$/, '')
                                                  +"/Hr"
                                                : ""}
                                            </>
                                          ) : detail?.min_yearly_pay ||
                                            detail?.max_yearly_pay ? (
                                            <>
                                              {detail?.min_yearly_pay
                                                ? "$" +
                                                  detail?.min_yearly_pay +
                                                  "/Yr"
                                                : ""}
                                              {detail?.min_yearly_pay &&
                                                detail?.max_yearly_pay &&
                                                "-"}
                                              {detail?.max_yearly_pay
                                                ? "$" +
                                                  detail?.max_yearly_pay +
                                                  "/Yr"
                                                : ""}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      )}

                                      <button
                                        type="submit"
                                        className="w-full py-1 font-semibold rounded btn-main"
                                        onClick={() =>
                                          history.push(
                                            `/job-details/${detail.id}`
                                          )
                                        }
                                      >
                                        View {lookingToHire ? "Profile" : "Job"}
                                      </button>
                                    </div>
                                  </Link>
                                </div>
                                // </Link>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleJobs;
