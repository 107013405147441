import React, { useState, useCallback, memo, useEffect } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

function SkillSelect({
  skillList,
  skillArray,
  setSkillArray,
  skillSelectRef,
  setUpdateSkill,
}) {
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    console.log('children', children)

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    const removeSelected = (data) => {
      if (skillArray?.map((a) => a.skill_name).includes(children) && isCheck) {
        let temp = skillArray.filter((item) => item.skill_name !== data);
        var uniq = {};
        var arrFiltered = temp.filter(
          (obj) => !uniq[obj.skill_name] && (uniq[obj.skill_name] = true)
        );
        setSkillArray(arrFiltered);
      }
    };

    const [isCheck, setIsCheck] = useState(
      skillArray?.map((a) => a.skill_name).includes(children)
    );

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          checked={isCheck}
          className="mx-2"
          onChange={() => {
            removeSelected(children);
          }}
        />

        {children}
      </components.Option>
    );
  };
  const [options, setOptions] = useState([]);
  console.log('options', options)
  const SelectStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
    }),
    multiValue: (provided, state) => ({
      ...provided,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };
  // const handleCreate = useCallback(
  //   (inputValue) => {
  //     const newValue = { skill_name: inputValue };
  //     // setOptions((preOptions) => [...preOptions, newValue]);
  //     // selectBenefit(newValue);
  //     setSkillArray([...skillArray, newValue]);
  //     setUpdateSkill(true);
  //   },
  //   [options]
  // );

  const handleCreate = (inputValue) => {
    const newValue = { skill_name: inputValue };
    setSkillArray([...skillArray, newValue]);
    setUpdateSkill(true);
  }

console.log("skillArray", skillArray)

  const selectSkills = (e) => {
    setSkillArray([...skillArray, ...e]);
    setUpdateSkill(true);
  };

  useEffect(() => {
    setOptions(skillList ? skillList.data?.response_data : []);
  }, [skillList]);

  return (
    <div className="App">
      <CreatableSelect
        ref={skillSelectRef}
        styles={SelectStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        maxMenuHeight={205}
        getOptionLabel={(option) => option.skill_name}
        getOptionValue={(option) => option.id}
        options={
          skillList
            ? Object.values(
                skillList?.data?.response_data.reduce(
                  (acc, cur) => Object.assign(acc, { [cur.skill_name]: cur }),
                  {}
                )
              )
            : {}
        }
        isClearable
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
        // value={benefit}
        onChange={(e) => {
          selectSkills(e);
        }}
        onCreateOption={handleCreate}
        components={{
          Option: InputOption,
        }}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          skill_name: optionLabel,
        })}
      />
    </div>
  );
}

export default memo(SkillSelect);
