import {useState, useEffect } from "react";
import usePutData from "../../hooks/usePutData";
import { useParams } from 'react-router-dom';
// import { fields, checkFields } from '../../helpers/ResetPasswordSchema';

import { HiOutlineEye, HiOutlineEyeOff} from "react-icons/hi";


const ResetPassword = () => {
    const url = "users/password";
    const loader = <span className="text-white">Sending...</span>;

    const [formdata, setFormdata] = useState(null);
    const [errors, setErrors] = useState(null);
    const [commonMessage, setMessage] = useState(null);
    const [password,setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');

    const [revealPassword, togglePasswordView] = useState(false);
    // const [revealConfirmPassword, toggleConfirmPasswordView] = useState(false);
    const [otp,setOtp] = useState('');

    let params = useParams();
    let uid= params.uid;
    let token= params.token;

    const processForm = (e) => {
        e.preventDefault();
        setFormdata({uid,temp_token:token,password:password,otp});
    };

  const { data, error, loading} = usePutData(url, formdata);

  useEffect(() => {
    if (data) {
        setMessage({ message: data.message, class: 'success' });  
        setPassword('');
        setOtp('');
        console.log(`data`, data)
        setTimeout(() => {
          window.location.href="/login";
        }, 2000);
    }
  }, [data])

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: 'error' });
    }
    if(error?.message){
      setMessage({message: error.message, class: 'error' });
    }
  }, [error])


  return (
    <div className="minHeight relative flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg lg:w-8/12 xl:w-6/12">
        {commonMessage &&
          <p className={commonMessage.class}>{commonMessage.message}</p>
        }
        <h1 className="my-5 text-3xl font-semibold text-black">Reset Password</h1>

        <form onSubmit={(e) => { processForm(e) }}>
          <div className="relative mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="password"
            >
              New Password
            </label>
            <input
              type={revealPassword ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e)=>setPassword(e.target.value)}
            />
            <span className='form_error_message block mb-3'>{errors?.password}</span>
            <HiOutlineEye onClick={() => togglePasswordView(!revealPassword)} className={`password_eye ${revealPassword ? 'hidden' : ''}`} />
            <HiOutlineEyeOff onClick={() => togglePasswordView(!revealPassword)} className={`password_eye ${revealPassword ? '' : 'hidden'}`} />
          </div>

          {/* <div className="mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="confirmPassword"
            >
              Confirm Password
            </label>
            <input
              type={revealConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e)=>setConfirmPassword(e.target.value)}
            />
            <HiOutlineEye onClick={() => toggleConfirmPasswordView(!revealConfirmPassword)} className={`password_eye ${revealConfirmPassword ? 'hidden' : ''}`} />
            <HiOutlineEyeOff onClick={() => toggleConfirmPasswordView(!revealConfirmPassword)} className={`password_eye ${revealConfirmPassword ? '' : 'hidden'}`} />
          </div> */}

          <div className="mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="otp"
            >
              Verification Code
            </label>
            <input
              type="text"
              name="otp"
              id="otp"
              value={otp}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              onChange={(e)=>setOtp(e.target.value)}
            />
            <span className='form_error_message block mb-3'>{errors?.otp}</span>
          </div>

          <div className="mb-3">
            <button className="w-full btn btn-main">
              {loading ? loader : 'Reset'}
            </button>
          </div>

          {/* <div className="flex items-center justify-between">
            <div className="w-full h-0 mr-6 border-t border-gray-200"></div>
            <div className="text-sm text-gray-400">Or</div>
            <div className="w-full h-0 ml-6 border-t border-gray-200"></div>
          </div> */}

          {/* <div className="flex items-center justify-center my-10 mr-2">
            <div className="p-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="/images/google.png"
                className="w-8 h-auto"
                alt="Sign Up with Google"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="/images/apple.png"
                className="w-8 h-auto"
                alt="Sign Up with Apple"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="/images/facebook.png"
                className="w-8 h-auto"
                alt="Sign Up with Facebook"
              />
            </div>
          </div> */}

          <div className="my-5 text-center">
            <p className="text-sm text-gray-500">
              New to Ring of Hires?{" "}
              <a className="text-main" href="/signup">
                Create Account
              </a>{" "}
            </p>

            <a className="mt-5 text-sm text-main" href="/help-center">
              Help Center
            </a>

            <p className="mt-10 text-sm text-gray-500">
              By signing into account, you agree to Ring of Hires{" "}
              <a className="text-main" href="/terms-of-service">
                Terms of Service
              </a>{" "}
              and consent to our{" "}
              <a className="text-main" href="/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
