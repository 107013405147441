import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Sidebar from "./Sidebar";
function HelpCenter() {
  return (
    <div class="minHeight inner-wrapper relative overflow-hidden xl:w-4/6 lg:mx-auto my-10 p-6">
      <h1 class="lg:text-4xl font-semibold mb-12 text-3xl align-middle">
        Help Center -{" "}
        <span className="italic text-xl align-middle">Ring of Hires</span>
      </h1>
      <div class="block">
        <div class="lg:col-span-3 rounded-md border-2 border-gray-100 shadow-lg p-5">
          <h2 className="font-bold text-2xl pb-4">Account FAQ</h2>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  I'm having problems signing into my account. What should I do?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Please email{" "}
                  <a
                    href="mailto:support@ringofhires.com"
                    className="font-semibold"
                  >
                    support@ringofhires.com
                  </a>{" "}
                  with details of your issues with signing into your account.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  I forgot my username/password. How can I retrieve it?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Click link:{" "}
                  <a href="/forgot-password" className="font-semibold">
                    Forgot password?
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Who should I contact if I face any problems while using my
                  account?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Please email{" "}
                  <a
                    href="mailto:support@ringofhires.com"
                    className="font-semibold"
                  >
                    support@ringofhires.com
                  </a>{" "}
                  with details of your issues with your account.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <h3 className="font-bold text-2xl py-4">Search FAQ</h3>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  The filter is not being applied correctly with my search, what
                  can I do?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Please email{" "}
                  <a
                    href="mailto:faq@ringofhires.com"
                    className="font-semibold"
                  >
                    faq@ringofhires.com
                  </a>{" "}
                  with details of your search issues and/or screenshots to
                  assist in correcting the issues. This is currently the beta
                  version and we appreciate any input so that we can continue to
                  improve the experience.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  The contact information for the job listed is incomplete, how
                  can I apply?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  For any job posting, click apply and send your message to the
                  employer. We are actively confirming all emails to the job
                  postings. Your message will be sent to the employer when we
                  have confirmation of the correct email account for the
                  posting.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I report a job that is no longer available or
                  inaccurate?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  There is a Report tab on the job posting. Currently, that
                  functionality is not finished for the beta version, so for
                  now, you can email us at{" "}
                  <a
                    href="mailto:faq@ringofhires.com"
                    className="font-semibold"
                  >
                    faq@ringofhires.com
                  </a>{" "}
                  with any details of postings that are inaccurate or no longer
                  available.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        {/* <div class="sidebar-outer rounded-md border-2 border-gray-100 shadow-lg p-5">
          <Sidebar />
        </div> */}
      </div>
    </div>
  );
}

export default HelpCenter;
