import { useState, useEffect, useContext, createContext } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import useGetData from "../../../hooks/useGetData";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import JobClicks from "./JobClicks";
import { JobContext } from "./EmployerDashboard";
import moment from "moment";

function ActiveJobs({ clicks }) {
  const [
    jobIdC,
    setJobIdC,
    positionId,
    setPositionId,
    positionlabel,
    setPositionLabel,
  ] = useContext(JobContext);
  const user_id = localStorage.getItem("user_id");
  const emp_id = localStorage.getItem("user_id");
  const loader = (
    <span className="text-base p-5 block font-semibold bg-white border rounded-xl mt-5">
      Loading...
    </span>
  );
  const [url, setUrl] = useState(
    `employers/${user_id}/active-jobs/list?page_size=10000`
  );
  const { data: EmployerDetails, loading, error: jobsError } = useGetData(url);
  // console.log("EmployerDetails", EmployerDetails?.results[0].id);

  const [isCheck, setIsCheck] = useState([]);

  // Click for each jobs API call
  const [jobsClick, setJobsClick] = useState(
    `employers/${user_id}/unique-jobs-count/list`
  );
  const { data: EachJobClicks, error: clicksError } = useGetData(jobsClick);

  function highLight(e) {
    const { id, checked } = e?.target;
    setIsCheck([parseInt(id)]);
    // if (!checked) {
    //   setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
    // }
  }
  useEffect(() => {
    setJobIdC(isCheck[0] ?? 0);
  }, [isCheck]);

  //Merge two array of objects for Active jobs table
  const combinedDetails = EmployerDetails?.results.map((item) => {
    const obj = clicks?.result?.find((o) => o.id == item.id);
    if (obj) {
      return { ...item, ...obj };
    } else {
      return { ...item, clicks: 0 };
    }
  });

  // Filter starts here
  const [direction, setDirection] = useState();
  const [sortBy, setSortBy] = useState();
  const [sortedItems, setSortedItems] = useState();

  useEffect(() => {
    setSortedItems(combinedDetails);
  }, [EmployerDetails, clicks]);

  useEffect(() => {
    if (EmployerDetails) {
      setJobIdC(EmployerDetails?.results[0]?.id);
      setIsCheck([parseInt(EmployerDetails?.results[0]?.id)]);
      setPositionId([parseInt(EmployerDetails?.results[0]?.position_id ?? 0)]);
      setPositionLabel(EmployerDetails?.results[0]?.position_label);
    } else {
      setJobIdC(0);
      setIsCheck([]);
      setPositionId("");
      setPositionLabel("");
    }
  }, [EmployerDetails]);

  console.log("EmployerDetails", EmployerDetails);

  const sortTableData = (array, { sortBy, direction }) => {
    return array?.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return direction === "ascending" ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const handleClick = (event) => {
    const sortDir = direction === "descending" ? "ascending" : "descending";
    setDirection(sortDir);
    setSortBy(event.target.id);

    const sortConfig = { sortBy: event.target.id, direction: sortDir };
    setSortedItems(sortTableData(combinedDetails, sortConfig));
  };

  const SortButton = ({ direction, id, onClick, sortBy }) => {
    const arrows = { ascending: "↓", descending: "↑" };
    const arrow = sortBy === id ? arrows[direction] : "⇡⇣";

    return (
      <div id={id} onClick={onClick}>
        {arrow}
      </div>
    );
  };
  // Filter ends here

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-lg font-semibold text-black">Active Jobs</h1>
        {/* <div className="relative">
          <input
            className="border rounded-xl px-3 py-2 outline-none bg-white flex-1 w-32"
            placeholder="Sort by"
          />
          <MdOutlineKeyboardArrowDown
            className="mx-auto w-7 h-full absolute top-0 right-2"
            color="555"
          />
        </div> */}
      </div>
      {loading ? (
        loader
      ) : jobsError || clicksError ? (
        <div className="text-red-600 p-5 block font-semibold bg-white border rounded-xl mt-5">
          Something went wrong!
        </div>
      ) : sortedItems?.length > 0 ? (
        <div>
          <body class="flex items-center justify-center">
            <div class="container">
              <table class="w-full flex flex-row flex-nowrap rounded-lg sm:overflow-y-scroll sm:shadow-lg my-5 custom-table">
                <thead class="text-gray-500">
                  <tr class="hidden flex-col flex-nowrap sm:table-row rounded-xl bg-gray-200">
                    <th className="py-3 px-3 text-left md:w-4/12 text-sm">
                      <div className="inline-block mr-1 custom-dev">
                        Job Title{""}
                      </div>
                      <div className="inline-block text-lg cursor-pointer custom-arrow">
                        <SortButton
                          direction={direction}
                          id="position_label"
                          onClick={handleClick}
                          sortBy={sortBy}
                        />
                      </div>
                    </th>
                    <th className="py-3 px-2 text-left md:text-center lg:text-left md:w-2/12 text-sm custom-space">
                      <div className="inline-block mr-1 custom-dev">
                        Open Jobs{""}
                      </div>
                      <div className="inline-block text-lg cursor-pointer custom-arrow">
                        <SortButton
                          direction={direction}
                          id="no_of_hires"
                          onClick={handleClick}
                          sortBy={sortBy}
                        />
                      </div>
                    </th>
                    <th className="py-3 px-2 text-left md:text-center lg:text-left md:w-2/12 text-sm custom-space">
                      <div className="inline-block mr-1 custom-dev">Clicks</div>
                      <div className="inline-block text-lg cursor-pointer custom-arrow">
                        <SortButton
                          direction={direction}
                          id="clicks"
                          onClick={handleClick}
                          sortBy={sortBy}
                        />
                      </div>
                    </th>
                    <th className="py-3 px-2 text-left md:text-center lg:text-left md:w-2/12 text-sm custom-space">
                      <div className="inline-block mr-1 custom-dev">
                        Posted on{""}
                      </div>
                      <div className="inline-block text-lg cursor-pointer custom-arrow">
                        <SortButton
                          direction={direction}
                          id="created_at"
                          onClick={handleClick}
                          sortBy={sortBy}
                        />
                      </div>
                    </th>
                    <th className="py-3 px-2 text-left md:text-center lg:text-left md:w-2/12 text-sm custom-space">
                      <div className="inline-block mr-1 custom-dev">
                        Job Applied{""}
                      </div>
                      <div className="inline-block text-lg cursor-pointer custom-arrow">
                        <SortButton
                          direction={direction}
                          id="job_applications_count"
                          onClick={handleClick}
                          sortBy={sortBy}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="flex-1 sm:flex-none custom-height activeTable border-b">
                  {sortedItems?.map((singleRow, row) => (
                    <>
                      <label for={singleRow?.id} className="cursor-pointer">
                        <tr
                          className={`custom-row flex flex-col flex-nowrap sm:table-row mb-3 sm:mb-0 cursor-pointer bg-white border rounded-lg md:border-none ${
                            isCheck.includes(singleRow?.id)
                              ? "bg-main text-white"
                              : ""
                          }`}
                          key={row}
                          onClick={() => {
                            setPositionId(singleRow?.position_id);
                            setPositionLabel(singleRow?.position_label);
                          }}
                        >
                          <td class="border-grey-light border p-3 w-full md:w-4/12">
                            <div className="flex items-start gap-1 flex-wrap md:flex-nowrap">
                              <span
                                className={`flex-none w-4 h-4 rounded-full relative mt-0.5 lg:mt-1 ${
                                  isCheck.includes(singleRow?.id)
                                    ? "bg-white"
                                    : "bg-blue-400"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  id={singleRow?.id}
                                  name="radio1"
                                  value=""
                                  className="w-3 absolute m-auto inset-0 custom-checkbox"
                                  onChange={highLight}
                                  checked={isCheck.includes(singleRow?.id)}
                                />
                              </span>
                              <div className="inline-block md:hidden font-bold">
                                Job Title :
                              </div>{" "}
                              {singleRow?.job_title
                                ? singleRow?.job_title
                                : singleRow?.position_label}
                            </div>
                          </td>

                          <td class="border-grey-light border p-3 w-full md:w-2/12 hidden md:table-cell">
                            <div className="inline-block md:hidden font-bold">
                              No of Hires :
                            </div>{" "}
                            {singleRow?.no_of_hires}
                          </td>
                          <td class="border-grey-light border p-3 w-full md:w-2/12 hidden md:table-cell">
                            <div className="inline-block md:hidden font-bold">
                              Clicks :
                            </div>{" "}
                            {/* <JobClicks
                            jobId={singleRow?.id}
                            EachJobClicks={EachJobClicks?.result}
                          /> */}
                            {singleRow?.clicks}
                          </td>

                          {/* For mobile device */}
                          <div className="flex md:hidden">
                            <td class="border-grey-light border p-3 w-full md:w-2/12">
                              <div className="inline-block md:hidden font-bold">
                                No of Hires :
                              </div>{" "}
                              {singleRow?.no_of_hires}
                            </td>
                            <td class="border-grey-light border p-3 w-full md:w-1/12">
                              <div className="inline-block md:hidden font-bold">
                                Clicks :
                              </div>
                              {/* <JobClicks
                              jobId={singleRow?.id}
                              EachJobClicks={EachJobClicks?.result}
                            /> */}
                              {singleRow?.clicks}
                            </td>
                          </div>
                          {/* For mobile device */}

                          <td class="border-grey-light border p-3 w-full md:w-2/12">
                            <div className="inline-block md:hidden font-bold">
                              Posted on :
                            </div>{" "}
                            {moment(
                              singleRow?.created_at?.slice(0, 10),
                              "YYYY-MM-DD"
                            ).format("MM/DD/YYYY") ?? "N/A"}
                          </td>
                          <td class="border-grey-light border p-3 w-full md:w-2/12">
                            <div className="inline-block md:hidden font-bold">
                              Job Applied :
                            </div>{" "}
                            {singleRow?.job_applications_count}
                          </td>
                        </tr>
                      </label>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </body>
        </div>
      ) : (
        <div className="bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
          Nothing to display
        </div>
      )}
    </>
  );
}

export default ActiveJobs;
