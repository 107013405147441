export const timeData = [
  { label: "08:00AM", value: "08:00AM" },
  { label: "08:15AM", value: "08:15AM" },
  { label: "08:30AM", value: "08:30AM" },
  { label: "08:45AM", value: "08:45AM" },
  { label: "09:00AM", value: "09:00AM" },
  { label: "09:15AM", value: "09:15AM" },
  { label: "09:30AM", value: "09:30AM" },
  { label: "09:45AM", value: "09:45AM" },
  { label: "10:00AM", value: "10:00AM" },
  { label: "10:15AM", value: "10:15AM" },
  { label: "10:30AM", value: "10:30AM" },
  { label: "10:45AM", value: "10:45AM" },
  { label: "11:00AM", value: "11:00AM" },
  { label: "11:15AM", value: "11:15AM" },
  { label: "11:30AM", value: "11:30AM" },
  { label: "11:45AM", value: "11:45AM" },
  { label: "12:00PM", value: "12:00PM" },
  { label: "00:15PM", value: "00:15PM" },
  { label: "00:30PM", value: "00:30PM" },
  { label: "00:45PM", value: "00:45PM" },
  { label: "01:00PM", value: "01:00PM" },
  { label: "01:15PM", value: "01:15PM" },
  { label: "01:30PM", value: "01:30PM" },
  { label: "01:45PM", value: "01:45PM" },
  { label: "02:00PM", value: "02:00PM" },
  { label: "02:15PM", value: "02:15PM" },
  { label: "02:30PM", value: "02:30PM" },
  { label: "02:45PM", value: "02:45PM" },
  { label: "03:00PM", value: "03:00PM" },
  { label: "03:15PM", value: "03:15PM" },
  { label: "03:30PM", value: "03:30PM" },
  { label: "03:45PM", value: "03:45PM" },
  { label: "04:00PM", value: "04:00PM" },
  { label: "04:15PM", value: "04:15PM" },
  { label: "04:30PM", value: "04:30PM" },
  { label: "04:45PM", value: "04:45PM" },
  { label: "05:00PM", value: "05:00PM" },
  { label: "05:15PM", value: "05:15PM" },
  { label: "05:30PM", value: "05:30PM" },
  { label: "05:45PM", value: "05:45PM" },
  { label: "06:00PM", value: "06:00PM" },
];
