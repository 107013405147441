import React, { useState, useEffect } from "react";
import usePostData from "../../../hooks/usePostData";

function HelpForm() {
  const helpurl = "help";
  const loader = <span className="text-white">Sending...</span>;
  const fields = { name: "", email: "" };

  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };

  const { data: HelpData, error, loading } = usePostData(helpurl, formdata);

  const processForm = (e) => {
    e.preventDefault();
    // const errList = checkFields(values);
    // if (!errList) {
    //   setFormData(values);
    //   setErrors({ email: "" });
    // } else {
    //   setErrors(errList);
    // }
    setFormData(values);
  };

  useEffect(() => {
    if (HelpData) {
      setMessage({ message: HelpData?.message, class: "success" });
    }
  }, [HelpData]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  //Timeout for the message
  useEffect(() => {
    if (HelpData) {
      setTimeout(() => {
        setMessage({ message: HelpData?.message, class: "success hidden" });
      }, 3000);
      setValue({ name: "", email: "" });
    }
  }, [HelpData]);

  useEffect(() => {
    if (error?.non_field_errors) {
      setTimeout(() => {
        setMessage({ message: error.non_field_errors, class: "error hidden" });
      }, 3000);
    }
  }, [error]);

  return (
    <form
      onSubmit={(e) => {
        processForm(e);
      }}
    >
      <div className="flex flex-col gap-4 lg:flex-row mt-10">
        <div className="flex-auto w-full lg:w-5/12 ">
          <input
            type="text"
            id="name"
            name="name"
            value={values?.name}
            class="w-full rounded-lg border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
            placeholder="Your Name"
            onChange={(e) => updateValue(e)}
          />
          <span className="form_error_message block mt-1 ml-2">
            {errors?.name}
          </span>
        </div>

        <div className="flex-auto w-full lg:w-5/12 ">
          <input
            type="text"
            id="email"
            name="email"
            value={values?.email}
            class="w-full rounded-lg border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
            placeholder="Your Email"
            onChange={(e) => updateValue(e)}
          />
          <span className="form_error_message block mt-1 ml-2">
            {errors?.email}
          </span>
        </div>

        <button className="flex-none w-full h-14 lg:w-2/12 bg-main text-white text-center text-lg font-semibold rounded-lg mx-auto block hover:bg-blue-700 border">
          {loading ? loader : "Submit"}
        </button>
      </div>
      {commonMessage && (
        <p className={commonMessage.class}>{commonMessage.message}</p>
      )}
    </form>
  );
}

export default HelpForm;
