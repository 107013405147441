import React from "react";
import Sidebar from "./Sidebar";

function PrivacyPolicy() {
  return (
    <div class="inner-wrapper relative overflow-hidden xl:w-5/6 lg:mx-auto my-10 p-6">
      <h1 class="lg:text-4xl font-semibold mb-12 text-3xl">
        Privacy Policy -{" "}
        <span className="italic text-xl align-middle">Ring of Hires</span>
      </h1>
      <div class="block">
        <div class="lg:col-span-3 rounded-md border-2 border-gray-100 shadow-lg p-5">
          <p class="text-gray-400 mb-7">Effective: March 14, 2022</p>

          <div class="terms-sec">
            <h2 className="font-bold text-2xl pb-4">Mission Statement</h2>
            <p>
              Our mission at Ring of Hires is to provide a platform for job
              seekers to connect to employers in a seamless fashion
            </p>
          </div>
          <div class="terms-sec">
            <h3>Introduction</h3>
            <p>
              The Privacy Policy describes how we work to protect information
              and maintain trust for our users and customers. The trust of our
              users is of paramount importance Ring of Hires. This Privacy
              Policy details how we gather, use and disclose personal and
              unidentifiable data given to us when our platform is accessed.
            </p>
            <p>
              <strong>1.</strong>
              <strong class="underline pl-1"> Ring of Hires Entity.</strong> For
              the purposes of this Privacy Policy, “Ring of Hires”, “we” or “us”
              means: Ring of Hires, LLC. Ring of Hires is the controller of all
              Personal Data processed pursuant to this Privacy Policy. Ring of
              Hires offers hiring services to Employers where Ring of Hires acts
              as a data processor. We process the personal data from and about
              our users on the Employer’s behalf, subject to our Terms. Ring of
              Hires may also act as a data processor on behalf of its affiliates
              (e.g., for billing purposes).
            </p>
            <p>
              <strong>2.</strong>
              <strong class="underline pl-1"> Definitions.</strong> Personal
              data (“Personal Data”) refers to any information that relates to
              an identified or identifiable individual, and unidentifiable data
              (“Unidentifiable Data”) refers to any information that does not
              identify you as a specific individual. Please note that at all
              times Ring of Hires will adhere to the applicable statutory
              definition in determining what is and is not Personal Data for the
              purposes of this Privacy Policy. Where this Privacy Policy refers
              to “information” or “your information” this may include both
              Personal and Unidentifiable Data. Our services are intended to be
              made available for use only by individuals searching for
              employment openings (“Job Seekers”) or for employers (“Employers”)
              seeking new hires. Employment openings (“Job Listings”) may be
              listed by Employers or our services may also be made available to
              individuals and/or organizations seeking information related to
              hiring regarding employment openings (“Job Listings”), on their
              behalf or other’s behalf, including but not limited to agencies
              purchasing for multiple parties.
            </p>
            <p>
              <strong>3.</strong>
              <strong class="underline pl-1">
                {" "}
                The Scope of Ring of Hires’ Privacy Policy.
              </strong>{" "}
              This Privacy Policy covers only data that we collect through the
              Sites, and not any other data collection or processing, including,
              without limitation, any third-party operators of web pages to
              which the Sites link, and any information that we collect offline
              or through any websites, products, or services that do not display
              a direct link to this Privacy Policy. Ring of Hires may amend this
              Privacy Policy at any time. If you applied to a Job Listing,
              please consult the Job Listing for additional information
              applicable to you.
            </p>
            <p>
              <strong>4.</strong>
              <strong class="underline pl-1">
                {" "}
                Changes to the Privacy Policy.
              </strong>{" "}
              Ring of Hires will notify users via email and/or by posting a
              notice on the applicable Sites for 30 days prior to changes of the
              Privacy Policy or by other means as required by law.
            </p>
            <p>
              <strong>5.</strong>
              <strong class="underline pl-1">
                {" "}
                Ring of Hires’ Privacy FAQ.
              </strong>{" "}
              Please note this Privacy Policy describes, in a comprehensive
              manner, how Ring of Hires processes your data. For more
              information on Ring of Hires, CCPA, and privacy generally, please
              visit Ring of Hires’ Privacy FAQs.
            </p>
            <p>
              <strong>6.</strong>
              <strong class="underline pl-1">
                {" "}
                Information Collected.
              </strong>{" "}
              You provide different types of information in order for Ring of
              Hires to provide our Sites’ services, products and features to
              you.
            </p>
            <div class="pl-2">
              <p>
                <strong>a.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Information You Provide to Ring of Hires.
                </strong>{" "}
                We collect data from you when you create an account, request
                information, subscribe for a service, participate in a survey,
                post a rating or review, post a question or answer, upload or
                build a resume, complete Ring of Hires Assessment, update your
                job preferences, upload content, or otherwise actively send us
                data on our Sites. This may include, but is not limited to, your
                user name, password, first and last name, email address,
                telephone number (including mobile phone number), street
                address, gender, occupation, interests, achievements, career
                goals, professional certification or license numbers, messages
                you send to users, your desired work schedule or salary and
                other job preferences, and any other data included in your
                account, Ring of Hires Profile, or Your Resume, including but
                not limited to application materials, answers to screener
                questions submitted to Ring of Hires or to Employers through
                Ring of Hires, and answers you give to any questionnaires on our
                Site or sent to you. All of this information may be associated
                with your Ring of Hires account.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> You may provide your specific location
                  data when you provide it yourself on the Ring of Hires Apps
                  (as defined in the Ring of Hires Terms of Service
                  Introduction) to help you find more relevant Job Listings.
                  Ring of Hires seeks your consent at the time of such
                  provision, and such processing will only be performed where
                  consent is secured. Some location data the Ring of Hires Apps
                  collect is linked to your account (for example, “coarse”
                  location data collected by an iOS app). Other location data
                  the Ring of Hires Apps collect is not linked to your account
                  (for example, “precise” location data collected by an iOS app)
                  but is still used by Ring of Hires.
                </p>
                <p>
                  <strong>ii.</strong> If you create a Ring of Hires account,
                  Ring of Hires may also create and assign to your device an
                  identifier that is similar to an account number. We may
                  collect the name you have associated with your device, device
                  type, telephone number, country, and any other information you
                  choose to provide, such as user name, email address, or zip
                  code. For example, while using a mobile device to access Ring
                  of Hires, Ring of Hires may provide a Job Seeker with relevant
                  Job Listings that are located within the same zip code as
                  where this activity is taking place.
                </p>
                <p>
                  <strong>iii.</strong> In addition, as part of the standard and
                  necessary operation of the Sites, Ring of Hires may collect,
                  analyze, process, store, and record in real-time and analyze
                  any and all of your activity on, interaction with, and
                  communications on or through the Sites, including, but not
                  limited to, by collecting, analyzing, processing, storing, and
                  recording the areas of the Sites you visit (such as specific
                  URLs), whether and where you click, scroll, hover-over,
                  mouseover, or otherwise interact with or communicate with or
                  through our Sites, and when and for how long each such
                  activity occurred. This also may include Ring of Hires
                  collecting, analyzing, processing, storing, and recording
                  information from and about your computer or mobile device and
                  your actions taken on the Sites, including, but not limited
                  to, Your Resume, your searches, the titles of jobs you search,
                  click on or apply to, where those jobs are located, your
                  salary interest or experience, the general salary range or
                  experience level of the jobs you view (where indicated), the
                  Job Listings you apply to, the date, time and type of your
                  activity on the Sites such as how often you update Your Resume
                  or look for jobs, session activity, browser type, operating
                  system, type of device, mobile device advertising identifier,
                  MAC address, IP address, and any of your other behavior or
                  actions on, interaction with, or communications with or
                  through the Sites. The Site cannot work as Ring of Hires
                  intends it to work unless Ring of Hires undertakes such
                  activities. If you do not want your actions to be collected,
                  processed, analyzed, or recorded this way, do not use the
                  Site.
                </p>
                <p>
                  <strong>iv.</strong> For Employers, this may also include
                  actions taken with regard to a Job Listing or to Job Seeker’s
                  resume or application, such as pausing or closing a Job
                  Listing, opening, viewing or responding to a resume or
                  application, or making a decision with regard to a resume,
                  application or Job Listing.
                </p>
                <p>
                  <strong>v.</strong> Employers may be contacted to take
                  advantage of our Ring of Hires Hire service, in which case you
                  may provide Ring of Hires with additional information about
                  hiring needs. This allows Ring of Hires to determine whether
                  or not our Hire product is suitable for an Employer’s needs.
                  When Job Seekers apply for a job recommended to them by Ring
                  of Hires Hire, Ring of Hires facilitates certain aspects of
                  the recruitment process including, for example, negotiations
                  between the Job Seeker and the Employer, as applicable. As
                  such, we collect and process any information submitted to Ring
                  of Hires through this process, including but not limited to
                  any queries a Job Seeker may have about the job or interview,
                  information about progression through the recruitment process
                  and information relating to salary.
                </p>
                <p>
                  <strong>vi.</strong> If you use any automated phone screening
                  product, Ring of Hires will record and store your answers to
                  an Employer’s screening questions and will forward your
                  answers to the Employer. Where you wish to use such a product,
                  you will agree in advance to Ring of Hires listening to and
                  analyzing the recording in accordance with this Privacy
                  Policy.
                </p>
                <p>
                  <strong>vii.</strong> If you are an Employer, we may request
                  that you provide certain information in order to verify your
                  account. This information may include, but may not be limited
                  to, your Federal Employer Identification Number (EIN),
                  Business Registration information, or a utility bill, the name
                  and email address of an Employer representative. This
                  information will be used for internal purposes only, subject
                  to any request by law enforcement or a court order.
                </p>
                <p>
                  <strong>viii.</strong> In connection with chargeable services,
                  we will also collect payment information (e.g., the last four
                  digits of credit, debit, or other payment card numbers and
                  related verification information). Please note that Ring of
                  Hires only has access to the last four digits of credit,
                  debit, or other payment card numbers, and does not store full
                  card numbers or related card security codes.
                </p>
                <p>
                  <strong>ix.</strong> In certain markets users can, entirely at
                  their own choice, provide their demographic information
                  including age, race &amp; ethnicity, gender, disability,
                  LGBTQ+ Community Membership to Ring of Hires for specific
                  purpose(s) such as evaluating fairness in our offerings which
                  includes testing for unfair bias. Ring of Hires has a
                  commitment to fairness. The provision of this information is
                  entirely voluntary and will not be used in any determination
                  of your job application. The data provided will not be
                  displayed on Your Resume or your Ring of Hires Profile. Ring
                  of Hires may share aggregate data (data grouped together with
                  personal identifiers removed) to employers to show. The data
                  you provide is based on your consent at the time the
                  information is taken. If you choose to provide your
                  demographic information, you can complete as many of the data
                  fields as you like when presented with an input form (you can
                  choose ‘prefer not to say’ for any of the fields that you
                  don’t want to complete). You can easily request a copy of the
                  demographic data Ring of Hires and ask for this information to
                  be removed at any time.
                </p>
                <p>
                  <strong>x.</strong> Please note that some employers are
                  required by law to ask each job applicant to provide their
                  Demographic Information on a voluntary basis, and you may see
                  these questions after applying to jobs on Ring of Hires. This
                  is a separate process to Ring of Hires’.
                </p>
                <p>
                  <strong>xi.</strong> When you share information with Ring of
                  Hires, or update information previously provided to Ring of
                  Hires, including preferences you may set in your Ring of Hires
                  Profile such as by making Your Resume on Ring of Hires or
                  information shared in the “About Me” section of your Ring of
                  Hires Profile “public” or “private,” it may take us a few days
                  to reflect these changes on the Sites. From the time you make
                  these changes to the time we reflect them on our Sites, new
                  data may not appear and previously provided data may continue
                  to show. As a result, information you choose to share publicly
                  on our Site may continue to be visible in our Resume Search
                  product and elsewhere on our Sites to Employers and other
                  users.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>b.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Information Collected When You Apply for Jobs or Access Ring
                  of Hires from Some Third-Party Websites.
                </strong>{" "}
                You may also create an account or log in to your Ring of Hires
                account using third-party websites including, but not limited
                to, Facebook or Google. If you do not already have a Ring of
                Hires account but log in to Ring of Hires using your Facebook or
                Google account, this creates a Ring of Hires account using the
                same email address used in your Facebook or Google account. If
                you agree to provide this information to Ring of Hires,
                Facebook, Apple or Google will authenticate you and redirect you
                to Ring of Hires. Please note, when you log in to Ring of Hires
                using your Facebook, Apple or Google account, Facebook, Apple or
                Google will cookie you in order to authenticate you as a
                Facebook, Apple or Google user.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> By accessing Ring of Hires through your
                  Facebook, Apple or Google account, you understand that
                  Facebook or Google will share certain data detailed in the
                  above paragraph for the purposes of authentication to permit
                  you to access Ring of Hires in a secure manner. You may stop
                  this at any point from your Facebook or Google account. This
                  information will be considered Ring of Hires account
                  information for purposes of your use of Ring of Hires.
                </p>
                <p>
                  <strong>ii.</strong> You have the ability to disable the
                  connection between your Facebook or Google account and your
                  Ring of Hires account at any time by accessing your privacy
                  settings on your Facebook, Apple or Google account. Facebook,
                  Apple or Google may also ask for your permission to share
                  certain other details with Ring of Hires, including but not
                  limited to your name, profile picture, public profile
                  information, and email address. Once you give this permission,
                  the requested information will be shared with Ring of Hires.
                  This information will be used to provide services to you,
                  including populating your Ring of Hires Profile on Ring of
                  Hires. The shared information will remain associated with your
                  Ring of Hires Profile until you modify or delete it.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>c.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Any Other Personal Data Ring of Hires May Receive from Third
                  Parties.
                </strong>{" "}
                Any Personal Data that Ring of Hires may obtain from other
                third-party sources, including data from sponsored
                advertisements that we have on third-party sites, will be
                processed by Ring of Hires in accordance with this Privacy
                Policy and all applicable laws.
              </p>
              <p>
                <strong>d.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Storage of Your Information.
                </strong>{" "}
                Ring of Hires stores your Personal Data until it is no longer
                necessary to provide our services or until you ask us to delete
                your Personal Data. As one example, if an Employer deletes a job
                application in their candidate dashboard on Ring of Hires, Ring
                of Hires may delete information associated with that
                application. As another example, after an application has been
                delivered to an Employer, Ring of Hires may retain a copy of
                that application for a limited time and then delete the copy. We
                are also required to retain certain information in order to
                comply with local laws. Ring of Hires may also store the
                information detailed in this section 2 of the Privacy Policy on
                its equipment or the equipment of third parties that Ring of
                Hires has a relationship with. Such storage services will be
                governed by appropriate protections, as required by applicable
                rules.
              </p>
              <p>
                <strong>e.</strong>
                <strong class="underline pl-1">
                  {" "}
                  What Personal Data is Required.
                </strong>{" "}
                It will be indicated to you at the point of provision as to
                whether or not particular Personal Data provided by you is
                required in order to provide particular Ring of Hires services
                or features to you. Where Personal Data that is required is not
                provided by you then the particular feature or service may not
                be available to you.
              </p>
              <p>
                <strong>f.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Legal Basis on Which Ring of Hires Collects Your Personal
                  Data.
                </strong>{" "}
                Ring of Hires collects and processes your Personal Data on the
                basis of different legal grounds, depending on the nature of the
                Personal Data being provided and the type of processing
                involved. Some of the Personal Data processed by Ring of Hires
                is performed on the basis that it is necessary for the
                performance of our agreement with you, or in order to take steps
                at the request of the user prior to entering such an agreement.
                An example of this would be where Ring of Hires sends a Job
                Seeker’s application to an Employer.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> A second ground relied upon by Ring of
                  Hires for other types of processing of your Personal Data is
                  that it is necessary for the purposes of legitimate interests
                  pursued by Ring of Hires, A Job-Seeker or an Employer.
                  Examples of such legitimate interests will include where Ring
                  of Hires sends you marketing about our products and services,
                  where we believe you have a reasonable expectation that we
                  will perform a particular type of processing on your behalf,
                  where such processing is strictly necessary for fraud
                  detection and prevention, or where we share your Personal Data
                  with Ring of Hires affiliates as detailed in section 4 below.
                  Ring of Hires will only rely on such a ground where an
                  assessment has been performed balancing the interests and
                  rights involved and the necessity of the processing in order
                  to provide our services, products and features to you.
                </p>
                <p>
                  <strong>ii.</strong> A third ground relied upon for certain
                  types of processing is that it is necessary in order to allow
                  Ring of Hires to comply with a legal obligation. An example of
                  this would be where Ring of Hires is required to retain
                  business records for fixed periods of time in order to comply
                  with local legal requirements.
                </p>
                <p>
                  <strong>iii.</strong> Finally, in certain limited situations,
                  Ring of Hires relies on your consent in order to process your
                  Personal Data. Where Ring of Hires requires your consent, we
                  seek it at the time of provision, and the processing will only
                  be performed where consent is secured. For example, your
                  consent will be sought in certain instances where you initiate
                  a marketing message such as a Job Alert with Ring of Hires.
                  You can withdraw your consent, as detailed in section 10 of
                  this Privacy Policy where applicable, or by sending an email
                  to{" "}
                  <a
                    href="mailto:info@ringofhires.com"
                    className="text-main font-semibold underline"
                  >
                    info@ringofhires.com
                  </a>
                  .
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>g.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Where a Job Seeker Wants to Close a Job Seeker Account
                </strong>
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> When a Job Seeker closes an account, the
                  Job Seeker will no longer have access to any of the data we
                  make available under that account, including but not limited
                  to, search history, job search preferences, saved jobs, jobs
                  the Job Seeker has applied to, Job Alerts, resume, and any
                  other data associated with the account. If the Job Seeker uses
                  the same email address to set up a new account, this data from
                  a previous account will not become available to the Job Seeker
                  again. Please also note that closing your Job Seeker account
                  may result in you losing access to some of our products and
                  the ability to utilize the “log in with Ring of Hires” feature
                  found on certain third-party sites.
                </p>
                <p>
                  <strong>ii.</strong> Ring of Hires reserves the right to keep
                  any materials in a closed account as necessary to preserve and
                  protect its rights to the extent permitted by law (for
                  example, to preserve records of a dispute) or to comply with
                  its obligations under local law (for example, if requested to
                  retain by law enforcement). A closed account does not mean
                  that all of the data is expunged from the Ring of Hires
                  systems, although it will not be readily available under the
                  Job Seeker’s original account. When the Job Seeker presses the
                  close account button on the dashboard, Ring of Hires will
                  begin to process that instruction within 24 hours and will
                  have completed the operation within 40 days.
                </p>
                <p>
                  <strong>iii.</strong> If a Job Seeker wishes to access or
                  delete their Personal Data held by Ring of Hires, they should
                  follow the steps listed in section 10 of this Privacy Policy.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>h.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Where an Employer Wants to Disable an Employer Account.
                </strong>{" "}
                Employers may disable an account at any time by contacting Ring
                of Hires customer support. Note that Ring of Hires must preserve
                business records pertaining to that account to comply with its
                obligations under law. Alternatively, if an Employer agent
                wishes to access or delete their Personal Data held by Ring of
                Hires, they should follow the steps listed in section 10 of this
                Privacy Policy.
              </p>
              <div class="">
                <p>
                  <strong>i.</strong>{" "}
                  <strong class="underline pl-1">
                    Where a Job Seeker Wants to Delete a Job Application.
                  </strong>{" "}
                  If a Job Seeker wants to delete a job application performed on
                  the Sites, they are advised to contact the prospective
                  Employer directly to request deletion of their application.
                  For job applications which you, the Job Seeker submit through
                  the Sites (including the use of a Ring of Hires Apply button)
                  to an Employer, (with related materials submitted), please
                  note: 1) that your applications and materials may be
                  controlled by an Employer and 2) we may direct you to that
                  Employer for Personal Data deletion requests where that
                  Personal Data is held by that Employer.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>j.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Where the Employer Contact Details Provided to Ring of Hires
                  Are Incorrect.
                </strong>{" "}
                Ring of Hires depends on the Employer or the Employer’s agent to
                provide Ring of Hires with the correct destination for all
                applications. In the event that the electronic destination
                provided to Ring of Hires is incorrect and your application
                materials not be sent to the intended recipient of the
                application, Ring of Hires has no liability for such
                applications. We do verify emails with initial creation of
                accounts with either third party affiliates (who verify for
                their websites and pursuant of their own policies) or
                individually with a verification email. If the email is no
                longer going to the initially intended destination, Ring of
                Hires is not liable. If you do not wish to send your application
                materials through our platform, you should send them directly to
                the Employer.
              </p>
              <p>
                <strong>k.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Ring of Hires’ Email Addresses.
                </strong>{" "}
                When communicating through Ring of Hires’ message relay system,
                you will see an email address with an @ringofhires.com ending.
                You understand that you retain no ownership rights in these
                email addresses, which are specific to Employers’ Job Listings,
                but are owned by Ring of Hires. However, Ring of Hires’ relay
                system will show the name associated with your account or job
                application in the “To:” or “From:” field. In addition, the
                above shall apply to any email addresses added to a thread when
                communicating via Ring of Hires’ message relay system.
                Specifically, if you choose to add additional recipients to an
                ongoing email thread through Ring of Hires’ message relay
                system, the additional recipient(s) will also be assigned
                @ringofhires.com addresses as well. Please note that emails
                which include an email address ending @ringofhires.com will be
                stored on our systems. You should not send a message to an email
                address ending in @ringofhires.com if you do not wish for this
                to happen. Please also note that since these message proxy and
                relay services depend on the functionality of third-party
                providers, there may be technical delays on the part of those
                email service providers.
              </p>
              <p>
                <strong>l.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Minimum Age of our Users.
                </strong>{" "}
                The Sites are not for use by anyone under the age of 16.
                However, if local laws provide for an alternative minimum age
                for the provision of the Sites’ services to you, then that shall
                apply as the applicable minimum age. If you are under the age of
                18 or the age of majority in your jurisdiction, you must use the
                Sites under the supervision of your parent, legal guardian or
                responsible adult. We adopt a range of measures to try to ensure
                that we do not accept individuals who do not meet the minimum
                age and any other applicable age requirements.
              </p>
            </div>
            <p>
              <strong>7.</strong>
              <strong class="underline pl-1">
                {" "}
                Purposes, Uses and Disclosures of Information.
              </strong>{" "}
              We may use any information collected pursuant to this Privacy
              Policy, including Personal Data, for the purposes set forth in
              sections 4, 5 and 9 of this Privacy Policy, to the extent
              applicable. Ring of Hires’ associates Personal Data with your Ring
              of Hires Profile in order to perform the activities described in
              this Privacy Policy. Ring of Hires uses such information to help
              Job Seekers find jobs and to help Employers find candidates. In
              order to do this, we use your Personal Data to provide our
              services, products and features to you, to help Job Seekers find
              jobs and Employers find candidates, to measure and improve those
              services, products and features; and to protect our users and
              provide them with customer support.
            </p>
            <div class="pl-2">
              <p>
                <strong>a.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Creating an Account, Sending You Job Alerts and Other
                  Promotional Materials.{" "}
                </strong>
              </p>
              <p>
                If and to the extent permitted by applicable law, Ring of Hires
                may use the information you provide to us, such as your contact
                information and information about your activity on our Sites, to
                (i) create an account for you; (ii) send you Job Alerts; (iii)
                send you Invitations to additional roles, where you can apply,
                interview, or take additional action such as confirming your
                interest, and (iv) send you information about the Sites,
                including by displaying our promotional materials to you on
                third-party platforms. Ring of Hires may use third-party
                partners to perform these activities on Ring of Hires’ behalf.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> A Job Alert is an email that you sign up
                  for containing Job Listings in response to a search query you
                  have run. A company alert is one type of Job Alert which
                  contains Job Listings and other information you have requested
                  regarding a company. Job Alerts may also include jobs that are
                  recommended for you, Ring of Hires Targeted Ads, companies you
                  may be interested in following, or other actions you may want
                  to take on the Sites. The frequency of Job Alerts may be
                  adjusted by Ring of Hires on the Sites.
                </p>
                <p>
                  <strong>ii.</strong> An Invitation delivered by Ring of Hires
                  is a notice sent to you inviting you to take action on a Job
                  Listing that may be relevant to you, which you may receive via
                  email, notification, or push notification depending on your
                  preferences. Ring of Hires may invite you to apply, interview,
                  confirm your interest, or take other actions based on your
                  previous job search and application history on Ring of Hires,
                  and any information, including Personal Data, you upload to
                  the Site. Ring of Hires may also send Invitations on behalf of
                  Employers who determine your resume to be a relevant match for
                  their Job Listing. If you agree to share Your Resume with Ring
                  of Hires on affiliate Sites, Ring of Hires may send you an
                  Invitation. If Your Resume is public on Ring of Hires,
                  employers may send you an Invitation to apply, interview, or
                  confirm your interest, when they post relevant Job Listings.
                </p>
                <p>
                  <strong>iii.</strong> As part of how we display our
                  promotional materials to you, we may also use your information
                  to show you targeted advertisements, and other content that
                  has been customized for you, on third-party platforms, such as
                  other online, print, or televised media. Such advertisements
                  will only relate to the Sites services, products, and
                  features, all of which focus on helping Job Seekers find jobs
                  and Employers find candidates. If you wish to opt out of
                  receiving any marketing messages from the Sites, please see
                  section 10.3 of this Privacy Policy.
                </p>
                <p>
                  <strong>iv.</strong> In the employer account settings, we
                  offer users the ability to invite other users to link their
                  Ring of Hires employer accounts, and in doing so, assign
                  invited users’ specific roles that provide varying levels of
                  access to information, including candidate Personal Data,
                  contained in the user’s account who initiated the invite. If
                  you are an Employer and you invite other Ring of Hires users
                  to link their accounts to yours, you agree that such linked
                  users may have access to any and all information in your
                  account. Depending on the roles assigned to a particular
                  linked user, this may include, but not be limited to, resume
                  contacts, application materials and statuses, communications
                  with applicants, any action taken by a you or another user
                  linked to the account and when you took that action, and
                  billing information within the account.{" "}
                </p>
                <p>
                  <strong>v.</strong> If you accept another user’s invitation to
                  link your Employer account to their Employer account, you
                  acknowledge that any and all information about your activity
                  in your Employer account, including without limitation the
                  actions you take and when you take them, will be visible to
                  all linked users. Linked users will not be able to see your
                  activity, such as your job searches, applications or
                  preferences or your Ring of Hires Profile information, in your
                  Job Seeker account.{" "}
                </p>
                <p>
                  <strong>vi.</strong> You may visit your account settings at
                  any time to unlink your account. The administrator assigned to
                  an Employer account may also unlink any user from that
                  Employer account at any time, and may be notified if your
                  linked account is used to login from too many devices.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>b.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Giving You Relevant Search Results and Recommended Jobs.{" "}
                </strong>
                In order to optimize our services, products and features for
                you, we may use your previous search and browsing history (e.g.,
                the jobs you click on), or application materials and answers to
                screener questions, to identify you, determine relevant search
                results and reach out to you about jobs you may be interested
                in. We may utilize Ring of Hires Profile data to tailor your
                experience on the Sites and to display content according to your
                preferences. We may return different results for the same search
                query and have full discretion with respect to the Job Listings
                we present in response to any particular search. In the event
                that you have applied to a job that is being serviced by Ring of
                Hires services, Ring of Hires may invite you to consider
                additional roles, where you can apply, interview, confirm your
                interest, or take other actions related to a Job Listing.
              </p>
              <p>
                <strong>c.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Your Contact Information.{" "}
                </strong>
                When you give the Sites contact information (such as your name
                and email address) and other forms of Personal and
                Unidentifiable Data, you agree that we may utilize this
                information for the purposes outlined in sections 4, 5 and 9, to
                the extent applicable. In particular, you agree that we may, as
                part of our services to you, communicate with you, or initiate
                communication with you on behalf of a third-party, through your
                account or through other means such as email, telephone
                (including mobile phone), text message or postal mail. This
                includes communication through the use of contact information
                that you may provide to us or that we may otherwise obtain from
                third-party resources. Should you apply to a job that is
                supported by our Ring of Hires Hire product, a Hiring Liaison
                may use your contact information to conduct or coordinate
                interviews with you, and provide you with updates throughout the
                hiring process. We may also analyze our users’ contact
                information in the aggregate to better understand our users and
                improve our products and services.
              </p>
              <p>
                <strong>d.</strong>
                <strong class="underline pl-1">
                  Your Resume Information.
                </strong>{" "}
                By creating a public resume through the Site (“Ring of Hires
                Resume”) or uploading a file resume on the Site (collectively,
                “Your Resume” or “Job Seeker Resume”), you are requesting and
                authorizing Ring of Hires to make available Your Resume to third
                parties, such as Employers, that Ring of Hires believes may have
                an interest in Your Resume. If you do not want Ring of Hires to
                make available Your Resume to third parties or if you do not
                want Employers to contact you, set your Ring of Hires Resume and
                uploaded file resume to private, as detailed below.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> If you have a Ring of Hires account, an
                  Ring of Hires Resume, or an uploaded file resume, you agree
                  that Ring of Hires may associate Your Resume data with
                  information you provide on the Site in the “Profile” section
                  of your account (collectively, your “Ring of Hires Profile”).
                  This includes Your Resume, your scores on any Ring of Hires
                  Assessments you may have taken and any information in the
                  “About me” section, such as your desired job title, job types,
                  work schedule, whether you are ready to work, or pay.
                </p>
                <p>
                  <strong>ii.</strong> We reserve the right to charge third
                  parties a fee for accessing your information, including
                  Personal Data, as part of the Sites’ services. At your
                  direction, we may also send Your Resume to third parties if
                  you choose to apply for a Job Listing, RSVP for an interview,
                  or otherwise show interest in an Employer or Job Listing.
                  Information in your Ring of Hires Profile, except as otherwise
                  indicated on the Site, may be available to third parties, such
                  as Employers. We may also use information in your Ring of
                  Hires Profile to offer suggestions to you when you apply for
                  jobs on third-party websites in our mobile software. The
                  extent to which your information is made available to third
                  parties depends on the privacy settings you choose, in
                  accordance with this Privacy Policy. When a Job Seeker creates
                  a profile on Ring of Hires, their resume is set to public by
                  default, and the Job Seeker may change their resume privacy
                  settings at any time. This is detailed below.
                </p>
                <p>
                  <strong>iii.</strong> Private resumes are only made available
                  to those to whom the Job Seeker has initiated an application
                  or provided their resume. Where this occurs, you make Your
                  Resume, your name and other Personal Data that you place on
                  Your Resume available to third parties. Those third parties
                  may be interested in you as an employee, and may forward Your
                  Resume to other third parties.
                </p>
                <p>
                  <strong>iv.</strong> Public resumes may be available to anyone
                  accessing our Site. This includes users of Ring of Hires’
                  Resume Search product, Employers whose Company Page you may be
                  following, and anyone with access to the URL associated with
                  your public resume, such as search engines and other third
                  parties that may crawl our Site. We offer you this visibility
                  to help you find a job.{" "}
                </p>
                <p>
                  <strong>v.</strong> When you make your Ring of Hires Resume
                  public (or upload a file as Your Resume and set it to public)
                  on our Site, you authorize Ring of Hires to share the contents
                  of Your Resume, including your name and any other Personal
                  Data that you may include in it, publicly on the Site.
                </p>
                <p>
                  <strong>vi.</strong> Ring of Hires may also offer you the
                  ability to include additional information in your Ring of
                  Hires Profile about your desired job preferences, such as your
                  desired job schedule and salary, your willingness to relocate,
                  licenses, certifications, skills, and other information you
                  choose to include about your job search. We use this
                  information to help you find a job. Our Resume Search product
                  allows Employers to find great candidates by searching public
                  resumes. By posting a public resume, you authorize Ring of
                  Hires to make the entirety of Your Resume available to any
                  user who searches for you on our Sites, to associate your
                  desired job preferences with Your Resume and share this
                  information with Employers who search for resumes on our
                  Sites, to share Your Resume with Employers who post Job
                  Listings that may be a relevant match, and to allow employers
                  to see if you were recently active on the Sites, your
                  readiness to work, and your available contact methods. For
                  example, Employers using Ring of Hires’ Resume Search product
                  are able to search for candidates based upon the information
                  in Your Resume and any of your desired job preferences
                  associated with it. As another example, Employers may invite
                  Job Seekers with public resumes to take action on their Job
                  Listings when the resume is relevant to their hiring needs. If
                  you do not want Ring of Hires to share this information with
                  Employers, you can remove it from Your Resume and Ring of
                  Hires Profile, or change your Resume Privacy Settings to
                  private.
                </p>
                <p>
                  <strong>vii.</strong> If you upload a file as your resume and
                  set it to public, you authorize Ring of Hires to extract the
                  contents of this file and share them on our Site, including
                  within our Resume Search product.{" "}
                </p>
                <p>
                  <strong>viii.</strong> We may remove your name and contact
                  information from your public resume in certain instances, such
                  as for users of our Resume Search product without a
                  subscription. Employers who have subscribed to our Resume
                  Search product will be able to see your name and any other
                  contents of your public resume, except that we reserve the
                  right to remove your contact information (such as your email
                  and telephone number) until you express interest in an
                  Employer. In either case, Employers may still contact you
                  through our Site Messaging Service. You also authorize Ring of
                  Hires to share any file you upload as your resume and set to
                  public on our Site with Employers where you express interest
                  in them.
                </p>
                <p>
                  <strong>ix.</strong> Ring of Hires expressly prohibits all
                  scraping of its Site, although some third parties may use our
                  Site in violation of these terms to obtain your public resume
                  information.
                </p>
                <p>
                  <strong>x.</strong> Please note that Resume Search has not
                  launched in all markets. If you are located in a market where
                  Resume Search has not yet launched, setting your resume to
                  public may make it available to anyone who visits the
                  associated URL but it may not be searchable on our Site.
                  Resumes set to public in markets where Resume Search is not
                  yet available may become searchable upon our launch of Resume
                  Search in that market.
                </p>
                <p>
                  <strong>xi.</strong> Ring of Hires may also provide browser
                  extensions and other tools to help Employers better interact
                  with Job Seekers and their resume information, including tools
                  that allow Employers to download your resume information and
                  enhance and transfer it to third parties engaged to process
                  information on their behalf, such as applicant tracking
                  systems and other recruitment management tools. Employers are
                  solely responsible for complying with all applicable laws that
                  relate to their use of information obtained from Ring of Hires
                  and other sources, including any obligations Employers may
                  have to restrict how others interact with resume information
                  downloaded from our Site. Employers are also responsible for
                  the activities of any third-party service provider they engage
                  to facilitate their sourcing activities through the Ring of
                  Hires Resume Search Program, such as an Applicant Tracking
                  System.
                </p>
                <p>
                  <strong>xii.</strong> When you place Your Resume, Your
                  Address, Your Desired Pay/Payscale, Your Email, Your Phone
                  Number and all data entered on Ring of Hires, it will be set
                  to the public by default. All users who have created an
                  account will have access to this information. If you do not
                  wish to allow this to be available, then you will have to
                  delete your account as this basic information is how we guide
                  potential hires to employers. Ring of Hires does not dictate
                  how often third-party search engines will update their caches,
                  which may continue to contain your resume information.
                  Additionally, Employers who have saved your resume while it
                  was public may continue to have access to your resume even
                  after you have adjusted it to private.
                </p>
                <p>
                  <strong>xiii.</strong> Changes made to your resume privacy
                  settings may take a few days to complete, during which time a
                  resume set to public, for example, may remain visible to
                  Employers and other users searching on Ring of Hires.
                </p>
                <p>
                  <strong>xiv.</strong> If you do not want Ring of Hires to make
                  your Ring of Hires Profile available to third parties or if
                  you do not want Employers to contact you, set your Ring of
                  Hires Resume and uploaded file resume to “private” in your
                  resume privacy settings or remove your Resume. Your response
                  to a Ring of Hires Assessment will be associated with your
                  Ring of Hires Profile, but is only visible to the Employer who
                  requested the assessment from you or to other Employers if you
                  choose to share your Ring of Hires Assessment score on our
                  Site.
                </p>
                <p>
                  <strong>xv.</strong> We may also offer additional resume
                  review services to help Job Seekers build and improve their
                  resumes. For example, this can include helping you understand
                  how your resume may be parsed by applicant tracking system
                  software, feedback on ways to build a more effective resume,
                  or a personalized resume review video. In providing these
                  services, Ring of Hires may give you feedback, including
                  questions and comments on the contents of your resume, about
                  Your Resume or other materials you submit through the
                  services. We provide this feedback directly to you, and may
                  give you the ability to access it within your account. It is
                  not made available to Employers.{" "}
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>e.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Matching Job Seekers with Employers.
                </strong>{" "}
                You acknowledge that Ring of Hires may determine whether the
                words of Your Resume or any other individual’s resume match, or
                match the words of certain job descriptions or Resume search
                queries and vice versa. Ring of Hires may use this information
                and other information collected pursuant to this Privacy Policy,
                such as your answers to screener questions and recent activity
                on the Sites, in order to improve the Sites or any of our other
                products or services. As one example, Ring of Hires may display
                or otherwise make available potentially relevant job
                descriptions and resumes to Job Seekers and Employers and
                differentiate between resumes and Job Listings that match and
                those that do not. As another example, Employers may instruct
                Ring of Hires to help manage their candidate pool according to
                their requirements and preferences for the Job Listing, which
                may include sending updates to Job Seekers based on Employer
                activity, so that Job Seekers better understand the status of
                their candidacy, or giving Employers the ability to sort and
                arrange their candidates for future reference.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> Ring of Hires may also provide information
                  collected pursuant to this Privacy Policy, including Personal
                  Data, to Employers that may be interested in contacting you.
                  By applying to a job, answering screener questions, providing
                  your contact information to show interest in a Job Listing,
                  confirming your interest in a job invitation, using Ring of
                  Hires Hire, or by replying to a message from an Employer, such
                  as by expressing your interest in a particular Job Listing,
                  you agree to the disclosure of your information to that
                  Employer. Ring of Hires may also provide the results of any
                  Ring of Hires Assessments you complete to Employers, where you
                  agree to such provision at the time of taking such an
                  assessment. Ring of Hires may also use information provided to
                  Ring of Hires via Your Resume to pre-populate application
                  information for you and for similar purposes.
                </p>
                <p>
                  <strong>ii.</strong> If you have an account or a Ring of Hires
                  Resume, you agree that we may contact you or suggest you or
                  your public resume to Employers that might be interested in a
                  person who matches your activity on Ring of Hires.
                </p>
                <p>
                  <strong>iii.</strong> In addition, if you are located in the
                  United States and you set Your Resume to public, this means
                  that Ring of Hires may share all of the aforementioned
                  information about you with recruiters working for or on behalf
                  of Employers. If you do not wish to share such information,
                  you may delete your account at any time and not utilize Ring
                  of Hires’ services.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>f.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Optimizing Job Campaigns.
                </strong>{" "}
                When Job Seekers click on a Job Listing or apply for a job
                powered by Ring of Hires on our Sites or on a third-party
                website, including an Employer’s website or a website operated
                by an Employer’s applicant tracking system, Ring of Hires may
                collect certain information through this feature about Job
                Seekers and any actions taken while on the Sites, as detailed in
                Section 2 of this Privacy Policy. Ring of Hires uses this
                information to help us understand the effectiveness of our Sites
                and to ensure that Job Listings powered by Ring of Hires perform
                at the optimal level on our Sites and on other third-party
                websites.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>g.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Sending Messages on Ring of Hires.
                </strong>{" "}
                Ring of Hires provides a two-way message board that
                relays/records all information directly to emails of the Job
                Seekers and the Employers with multiple areas in the application
                allowing direct emailing to the Job Seekers or Employers. By
                utilizing any of the messaging, you acknowledge that you are
                asking Ring of Hires to send these messages on your behalf. This
                includes your resumes, cover letters, applications, messages,
                questionnaire answers, responses, offer letters and other
                materials. Ring of Hires may also separately inform the sender
                and recipient of a message through a Messaging Service whether
                the sender or recipient recently has been active on the Site.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> We process, monitor, review, store and
                  analyze such content, which may include Personal Data,
                  including via automated means, for data analysis, quality
                  control, enforcement of the Sites’ rules and other Ring of
                  Hires policies, content moderation, and to improve the Sites
                  or any other Ring of Hires product or service. Examples of
                  such improvements would be improving the open rate of messages
                  by optimizing their delivery, optimizing our search results,
                  improving Job Listings, permitting applicants to manage their
                  applications after transmitting them to Ring of Hires,
                  providing Job Seekers with the status of their application
                  when instructed to by Employers, ensuring you are provided
                  with personally relevant product features and communications,
                  and preventing fraud and spam. Some of this activity is
                  performed on Ring of Hires’ behalf by service providers, as
                  described in the section of this Privacy Policy titled
                  ‘Transfers of Information to Service Providers’. As a result
                  of this activity, or due to technical malfunction, in certain
                  circumstances such content may be delayed or may not be
                  delivered to the intended recipient. Ring of Hires may (but is
                  not obligated to) notify you in such an event. By using a
                  Relay Service or otherwise providing User Content through the
                  Site, you agree to this activity and acknowledge that this
                  activity is necessary for maintaining the quality of the Sites
                  and for providing the Relay Service.{" "}
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>h.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Sending You Reminders About Employer Appointments.
                </strong>{" "}
                Ring of Hires may offer you the opportunity to receive a message
                or phone call from Ring of Hires when an Employer: has scheduled
                an interview or event with you, seeks to contact you based on
                your public resume, or has responded to your application via
                Ring of Hires’ email relay. In such an event you will enter your
                phone number on the Ring of Hires consent form. By entering your
                phone number you are representing and confirming it is your
                number and you have the right to accept messages and phone calls
                at the number. You also consent to receive messages and phone
                calls at said number, which will be sent to you by Ring of
                Hires’ third-party service provider. To unsubscribe to text
                messages, reply to the text with STOP or contact {""}
                <a
                  href="mailto:info@ringofhires.com"
                  className="text-main font-semibold underline"
                >
                  info@ringofhires.com
                </a>
                . To unsubscribe to phone calls you should follow the
                instructions provided to you during the phone call. Replying
                STOP in response to an Ring of Hires SMS alert or unsubscribing
                during a phone call will unsubscribe you from all Ring of Hires
                SMS and call alerts except those related to Text2Apply and
                Hiring Events. In order to unsubscribe from those products, you
                must respond STOP within the specific text flows of those
                products.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>i.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Facilitating telephone relay system between Job Seekers and
                  Employers.
                </strong>{" "}
                Where applicable, Ring of Hires may offer Job Seekers and
                Employers the option to use a telephone relay system to
                facilitate contact for the purpose of submitting applications,
                conducting job interviews or other communication related to a
                Job Listing. Such a system uses masked or proxy telephone
                numbers to protect users, and is only available to users who
                select it on the Site. No recording of conversations or messages
                is performed through this relay system, although data relating
                to whether a call was answered and call duration may be
                gathered. Such functionality is provided to Ring of Hires by
                third-party service providers.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>j.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Facilitating text messaging relay service between Job Seekers
                  and Employer.{" "}
                </strong>{" "}
                Ring of Hires may offer Job Seekers and Employers the ability to
                utilize an autodialed text messaging relay service to facilitate
                contact for the purpose of submitting applications and for other
                communications related to Job Listings. To use this service, a
                Job Seeker must text an Employer’s preset keyword to a telephone
                number or short code that we make available on our Sites or in
                advertising materials (such as a sign or flyer at an Employer’s
                place of business). By texting the preset keyword to the
                telephone number or short code, you are consenting to receive
                text messages about the Employer’s Job Listings and as part of
                the application process, and acknowledge Ring of Hires may use
                your Personal Data to submit your application to the Employer
                and otherwise in accordance with this Privacy Policy. To
                unsubscribe at any time from such services, reply STOP by text
                message to the telephone number or short code provided or
                contact Ring of Hires customer support.{" "}
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>k.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Preventing Fraud and Spam.{" "}
                </strong>
                Ring of Hires may also use information collected pursuant to
                this Privacy Policy to help diagnose problems with our platform
                and our products and services, to prevent potentially fraudulent
                or illegal activities, and to protect individuals from other
                activities that may be detrimental to you or others. Ring of
                Hires may investigate and disclose information from or about you
                or your use of Ring of Hires if we have a good faith belief that
                such investigation or disclosure is reasonably necessary to
                comply with legal process (including subpoenas, search warrants,
                court orders) and law enforcement instructions and orders; is
                helpful to prevent, investigate, or identify possible wrongdoing
                in connection with the use of the Sites; or may protect our
                rights, reputation, property, safety, or that of the public; or
                as necessary to meet national security requirements. For
                example, Ring of Hires is subject to the investigatory and
                enforcement powers of the Federal Trade Commission in the United
                States, the Data Protection Commission of Ireland, and many
                other regulatory bodies. We may use a variety of methods to
                detect and address anomalous activity and screen content to
                prevent abuse such as spam or fraud. However, such detection
                methods are not perfect and false positives may occur. These
                efforts may, on occasion, result in a temporary or permanent
                suspension or termination of some functions for some users. Ring
                of Hires also reserves the right to drop messages from our relay
                system that we determine do not adhere to the Sites’ rules or
                any of the terms of our terms of service. Ring of Hires further
                reserves the right to conduct investigations to determine
                whether you are perpetrating a scam, spamming Ring of Hires or
                its users, or otherwise conducting fraudulent activity on the
                Sites by various means. Ring of Hires also reserves the right to
                turn over any information gathered via such investigations to
                the police or other third parties if we have a good faith belief
                that you are using the Sites in violation of this Privacy Policy
                or the applicable terms of service. Ring of Hires reserves the
                right to verify and confirm the identity of any party associated
                with an email relay. This includes but is not limited to
                verifying the identity of the sender of an email with the
                Employer agents associated with that email address on the
                relevant Job Listing.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>l.</strong>
                <strong class="underline pl-1"> Facilitating Payment. </strong>
                If you provide credit, debit or other payment card and related
                details to us in order to make a payment, we will use such
                information only for the purpose of processing the payment
                (e.g., the last four digits of credit card numbers and related
                verification information). Any credit, debit, or other payment
                card and related billing and payment information that you
                provide to us may be shared by us with companies who work on our
                behalf, such as payment processors and/or credit agencies,
                solely for the purposes of checking credit, effecting payment
                and servicing your account.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>m.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Automated Processing of Your Personal Data.
                </strong>{" "}
                In order to optimize the performance of the Sites, we perform
                automated processing of Personal Data evaluating certain
                specific aspects relating to a user. In particular we use
                automated processing to analyze or predict a user’s personal
                preferences or interests. Where it occurs, such processing is
                carried out if it is necessary for the performance of our
                agreement with our users, to allow us to provide our services to
                Job Seekers and Employers, including analyzing applications and
                resumes on behalf of Employers, or in other cases on the basis
                of a user’s consent.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> For example, in certain cases such
                  processing allows us to match Job Seekers with Employers
                  effectively, to provide Job Seekers with Job Alerts,
                  recommended jobs, and invitations to take action on jobs that
                  the Job Seeker is likely to be more interested in, to send
                  particular Job Seeker applications to an Employer, to display
                  search results, to send application status notifications on
                  behalf of Employers, or to perform certain spam and fraud
                  detection activities to protect our users. Such processing is
                  subject to the safeguards detailed in this Privacy Policy to
                  protect the privacy of our users. You may contact {""}
                  <a
                    href="mailto:info@ringofhires.com"
                    className="text-main font-semibold underline"
                  >
                    info@ringofhires.com
                  </a>{" "}
                  to contest any decision generated by said automated
                  processing.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>n.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Sharing your Information with Our Affiliates.
                </strong>{" "}
                Our core mission is to help Job Seekers find great jobs, and
                Employers find great candidates, which is a mission we share
                with each of our affiliates. In order to support that mission,
                we share certain information with our affiliates. Such
                information sharing is governed by this Privacy Policy. We also
                developed the Privacy Center as a way of educating our users
                about our affiliates and how we share data with them to provide
                the strongest product and service offerings to our users.{" "}
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> Information shared by you with Ring of
                  Hires may be shared with an affiliated entity to provide you
                  with services, to improve the Sites and optimize functionality
                  on the Sites, and other purposes as described in this Policy.
                  In particular, such sharing strengthens our ability to help
                  Job Seekers find jobs and Employers find great candidates. The
                  services provided to you and the functionality of the Sites
                  could not be provided without such transfers. If you do not
                  wish your information to be transferred in this way you should
                  not use the Sites.
                </p>
                <p>
                  <strong>ii.</strong> For the purposes of this Policy,
                  “affiliates” means any entity that directly or indirectly,
                  controls, is controlled by, or is under common control of or
                  with a Ring of Hires entity, now or in the future. “Control”
                  for these purposes means having a majority of shares or the
                  right and ability to direct management.{" "}
                </p>
                <p>
                  <strong>iii.</strong> Data sharing with affiliates provides
                  Job Seekers with more personalized and improved content,
                  performance, features and services. For example, Ring of Hires
                  and its affiliates use your previous search and browsing
                  history (e.g., the jobs you click on), IP address, or Your
                  Resume or Ring of Hires Profile information, to determine
                  relevant search results, send Job Alerts and Invitations, and
                  display and moderate career insights, such as company reviews,
                  Job Listing salary data, interview tips and more. Such sharing
                  is also performed to facilitate improved data analysis,
                  testing, research, and service improvement. This sharing also
                  allows Employers to have better insight into the performance
                  of their Job Listings. For example, we may share data related
                  to user clicks on their Job Listings which in turn allows
                  Employers to understand the extent to which they are
                  attracting candidates. By providing Employers with the
                  information they need to better utilize our platform, they can
                  provide stronger Job Listings.
                </p>
                <p>
                  <strong>iv.</strong> Such data sharing allows Job Seekers to
                  access an even broader database of Job Listings and Employers,
                  and for Employers to access more Job Seekers. For example,
                  this allows Ring of Hires to recommend jobs or Employers on
                  our Sites or on websites of our affiliates. It also
                  facilitates applications and the display of Job Listings from
                  the Sites to affiliates’ websites and vice versa.
                </p>
                <p>
                  <strong>v.</strong> For example, Ring of Hires and its
                  affiliates recommend a Job Seeker to an Employer, and a Job
                  Listing and/or Invitation to a Job Seeker. This applies to Job
                  Seekers, Employers and Job Listings on Ring of Hires and any
                  of its affiliates. Ring of Hires and its affiliates also share
                  Employer data (such as account information and behavioral
                  data) to provide a better experience to those Employers. Ring
                  of Hires may process billing information on behalf of
                  affiliates. If you are using a chargeable service, Ring of
                  Hires and its affiliates may share billing information solely
                  for the purposes of checking credit, affecting payment, and
                  servicing your account.
                </p>
                <p>
                  <strong>vi.</strong> Such data sharing allows Ring of Hires
                  and our affiliates to process your Personal Data for the
                  purpose of improving user security and internal operations,
                  troubleshooting, and detecting and preventing fraud and spam.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>o.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Transfers of Information to Employers.
                </strong>{" "}
                Ring of Hires sends Job Seeker Personal Data to Employers on the
                instruction of Job Seekers. Such transfers are performed in the
                course of the job search, application and interview process.
                This is further described in sections 2 and 4 of this Privacy
                Policy. Such Personal Data will be processed in accordance with
                these terms and those of the relevant Employer. Job Seekers that
                apply to jobs through Ring of Hires that are outside their own
                country may involve sending their Personal Data to countries
                with lower levels of data protection. Employer account holders
                agree to comply with all their responsibilities under applicable
                data protection rules with respect to the collection, processing
                and storage of Personal Data, as well as providing adequate
                protection of all data subject rights provided for under all
                applicable data protection rules.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>p.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Transfer of Information to Job Seekers.
                </strong>{" "}
                Ring of Hires sends certain Employer-related information to Job
                Seekers. This includes publishing Job Ads, Company Pages, and
                salary information on the Sites and publishing Job Listings on
                third-party websites. Ring of Hires may also provide information
                to Job Seekers about an Employer’s activity and responsiveness
                on the Sites in order to help Job Seekers evaluate employment
                opportunities. This may include the Employer representative’s
                name, the Employer’s city/state location, whether the Employer
                has taken or has chosen not to take an action with regard to the
                Job Seeker’s resume or application such as opening, viewing,
                responding to or making a decision in regard to it, whether the
                Employer has engaged with other Job Seekers or viewed other Job
                Seekers’ resumes, and whether the Employer has taken an action
                in regard to a Job Listing such as pausing or closing it. As an
                Employer, when you use the Sites (for example by posting a Job
                Listing or contacting a Job Seeker through the Ring of Hires
                Resume Program) you agree that Ring of Hires may provide this
                information to Job Seekers.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>q.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Transfer of Information to Service Providers.
                </strong>{" "}
                We may use third parties to perform services in connection with
                our operations, to improve the Sites and our services, products,
                and features, to protect our users and better understand their
                needs, and to optimize the services and the users’ experiences.
                By using our Sites, you acknowledge and agree to Ring of Hires’
                use of such third-party service providers, including, but not
                limited to, for the purpose of collecting, processing,
                analyzing, and recording your activity on, interaction with, and
                communications with or through our Sites, as further described
                in section 2 of this Privacy Policy.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> These service providers may have access to
                  Personal Data in the course of providing these services, which
                  may involve transfers of such Personal Data to other
                  countries. Such transfers are made for the purposes set forth
                  in sections 4, 5 and 9 of this Privacy Policy.
                </p>
                <p>
                  <strong>ii.</strong> These third parties may provide data
                  points including how much time you spend on specific pages,
                  which links you choose to click, and data that helps determine
                  user preferences. This information enables us to build and
                  maintain our services with user feedback. These third parties
                  may use cookies and other technologies to collect data on your
                  behavior and devices such as the device type, browser
                  information, and preferred language.
                </p>
                <p>
                  <strong>iii.</strong> Ring of Hires may transfer your Personal
                  Data in limited situations to certain third-party vendors that
                  assist Ring of Hires with protecting our users and maintaining
                  the quality of our services, products and features provided to
                  those users. An example would be vendors Ring of Hires uses
                  for fraud detection purposes.{" "}
                </p>
                <p>
                  <strong>iv.</strong> Ring of Hires may also share any credit,
                  debit, or other payment card and related billing and payment
                  information that you provide to Ring of Hires with companies
                  who work on Ring of Hires’ behalf, such as payment processors
                  and/or credit agencies, solely for the purposes of checking
                  credit, effecting payment to Ring of Hires and servicing your
                  account.{" "}
                </p>
                <p>
                  <strong>v.</strong> Please also be aware that we may also use
                  third-party cloud service providers that provide hosting, data
                  storage and other services pursuant to standard terms and
                  conditions that may be non-negotiable. These service providers
                  have informed us or the general public that they apply
                  security measures they consider adequate for the protection of
                  information within their system, or they have a general
                  reputation for applying such measures. However, we will not be
                  liable (to the fullest extent permitted by law) for any
                  damages that may result from the misuse of any information,
                  including Personal Data, by these companies.
                </p>
                <p>
                  <strong>vi.</strong> We may also share certain professional
                  certification or license numbers that you provide to Ring of
                  Hires with the issuing entity or organization in order to
                  verify the certification or license is valid. We only perform
                  this verification at your request when, for example, you add
                  certain certifications or licenses to your Ring of Hires
                  Profile.
                </p>
              </div>
            </div>
            <div class="pl-2">
              <p>
                <strong>r.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Transfers of Publicly Available Information.{" "}
                </strong>
                When users post content on the Sites in a manner that is
                intended to make that content publicly available and searchable
                by individuals, Ring of Hires reserves the right to aggregate
                and share that information with third parties. For example, if
                an Employer posts a job on the Sites, Ring of Hires may share
                that Job Listing or information contained within that Job
                Listing with third parties, at Ring of Hires’ discretion and
                subject to agreement with such third parties. Ring of Hires also
                reserves the right to share information aggregated from public
                sources in this capacity.
              </p>
            </div>
            <div class="pl-2">
              <p>
                <strong>s.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Third-Party Sites You Link to from Ring of Hires.
                </strong>{" "}
                You may also use certain third-party websites or services that
                you link to from the Sites. In such cases, all information you
                provide to a third-party is provided to that third-party and not
                to Ring of Hires, and is subject to the third-party’s privacy
                policy and terms of service. Please see section 8 of this
                Privacy Policy titled Links to Third-Party Websites for more
                information.
              </p>
            </div>
            <p>
              <strong>8.</strong>
              <strong class="underline pl-1">
                {" "}
                Additional Uses and Disclosures of Unidentifiable Data.
              </strong>{" "}
              This policy also applies to our use of Unidentifiable Data. We may
              use and disclose Unidentifiable Data for any purpose, except where
              we are required to do otherwise under applicable law. If we
              combine any Unidentifiable Data with Personal Data, then we will
              only use and disclose such combined information.{" "}
            </p>
            <p>
              <strong>9.</strong>
              <strong class="underline pl-1"> Security.</strong> Ring of Hires
              seeks to use reasonable security measures to help protect against
              the loss, misuse and alteration of the Personal Data under Ring of
              Hires’ control. No method of transmission over the Internet, or
              method of electronic storage, is 100% secure, however. In
              addition, please note that emails, messages sent via your web
              browser, and other similar means of communication with other
              users, are not encrypted. Therefore, while we strive to protect
              your information, we cannot guarantee its security.{" "}
            </p>
            <p>
              <strong>10.</strong>
              <strong class="underline pl-1"> Cookies.</strong> “Cookies” are
              small pieces of information that are stored by your browser on
              your computer’s hard drive. Please see our Cookie Policy for
              further information regarding our use of Cookies. N. We do not
              respond to the browser “Do Not Track” (DNT) signal if enabled by
              the user in their web browser. When we set or read cookies on
              non-affiliated sites that have integrated our job search and
              display features, we do so, for example, to provide a personalized
              job search experience to users on those sites. We do not share any
              information about your use of our Sites with those third parties.
              When third parties that we have integrated into the Sites (as
              described in the Ring of Hires Cookie Policy) set or read their
              own third-party cookies, they may or may not respond to the DNT
              signal.
            </p>
            <p>
              <strong>11.</strong>
              <strong class="underline pl-1">
                {" "}
                Links to Third-Party Sites.
              </strong>{" "}
              The Sites may make available links to other third-party websites.
              When you click on such links, you may leave our Sites. Your web
              browser may also disclose to the third-party website the URL of
              the Sites page you clicked from, and this URL may include search
              terms and filters you used on the Sites. The Sites may also make
              available Company Pages, which may contain information provided by
              third-party Employers. We are not responsible for the information
              collection, use, disclosure or other privacy practices of any
              third-party.
            </p>
            <p>
              <strong>12.</strong>
              <strong class="underline pl-1">
                {" "}
                Acquisitions and Other Reorganizations.
              </strong>{" "}
              Information collected pursuant to this Privacy Policy, including
              Personal Data, may be disclosed to one or more third parties in
              connection with any change of ownership or control in Ring of
              Hires’ business (whether by merger, sale, or otherwise), or any
              other reorganization or joint venture, or assignment, transfer or
              other disposition of all or any portion of our business, assets or
              stock (including in connection with any bankruptcy or similar
              proceeding), and any such information may be used by such
              third-party in accordance with this Privacy Policy. Equally,
              information collected pursuant to this Privacy Policy, including
              Personal Data, may be disclosed to one or more third parties in
              connection with any acquisition or merger carried out by Ring of
              Hires of such third parties.
            </p>
            <p>
              <strong>13.</strong>
              <strong class="underline pl-1">
                {" "}
                Exercising Rights with Respect to Personal Data.
              </strong>{" "}
              As a user of these Sites, you may exercise the rights explained
              below. Please note, we may attempt to verify your identity through
              the email address or telephone number associated with your use of
              our Sites prior to fulfilling any such request and reserve the
              right to deny a request where we are unable to satisfactorily
              complete this process. If you authorize someone to make a request
              on your behalf, we may also deny your request if we are unable to
              verify with you that the individual making the request is
              authorized to act on your behalf.
            </p>
            <p>
              <strong>14.</strong>
              <strong class="underline pl-1">
                {" "}
                The Right to Rectify Your Personal Data.
              </strong>{" "}
              Users of these Sites are able to edit and update most of their
              Personal Data held by each Site on the Site in question, and if
              you have any queries or complaints, we encourage you to contact
              us. Alternatively, if you do not wish to do this you may make a
              formal request to have Ring of Hires rectify any inaccurate
              Personal Data on your behalf by sending an email to{" "}
              <a
                href="mailto:info@ringofhires.com"
                className="text-main font-semibold underline"
              >
                info@ringofhires.com
              </a>{" "}
              from the email address associated with your account.
            </p>
            <div class="pl-2">
              <p>
                <strong>a.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Right to Object to or Restrict the Processing of Your
                  Personal Data.{" "}
                </strong>
                If you have any queries or complaints about how we process your
                Personal Data we encourage you to contact us. Alternatively, if
                you do not wish to do this you may make a formal request to
                object to, or restrict, processing of your Personal Data by each
                Site. For example, you have the right to object to the
                legitimate interest processing of your Personal Data by each
                Site for marketing purposes. In order to do so, you can submit a
                request to{" "}
                <a
                  href="mailto:info@ringofhires.com"
                  className="text-main font-semibold underline"
                >
                  info@ringofhires.com
                </a>
              </p>
              <p>
                <strong>b.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Opting Out of Marketing Messages and Withdrawing Consent.{" "}
                </strong>
                If you no longer want to receive marketing materials from us
                based upon your Personal Data, there are a number of options
                available to you. If you wish to opt out of a particular
                marketing email from any Site, you may do so by clicking
                “unsubscribe” (or an equivalent action) where applicable. Please
                note that this will only opt you out of that particular
                marketing message. If you wish to adjust all of your job alerts,
                you can click on “edit this job alert” (or an equivalent action)
                to adjust your subscriptions. You can also access and adjust
                your subscriptions (for job alerts, recommended jobs, and other
                notifications) from your account dashboard (where applicable),
                or contact customer support do so. In addition, you may opt out
                of all marketing messages by sending an email to {""}
                <a
                  href="mailto:info@ringofhires.com"
                  className="text-main font-semibold underline"
                >
                  info@ringofhires.com
                </a>
                . We will comply with your request as soon as reasonably
                practicable, and in accordance with applicable laws. Finally,
                please note that we may still send other messages to you, such
                as administrative messages.{" "}
              </p>
              <p>
                <strong>c.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Rights of Access to and Portability of Your Personal Data.
                </strong>{" "}
                Users of these Sites are able to access most of their Personal
                Data provided to each Site on the Site in question, and if you
                have any queries or complaints, we encourage you to contacts us.
                Alternatively, if you do not wish to do this you may make a
                formal request to access your Personal Data held by Ring of
                Hires. In order to do so, please email{" "}
                <a
                  href="mailto:info@ringofhires.com"
                  className="text-main font-semibold underline"
                >
                  info@ringofhires.com
                </a>
                . Each specific Site will provide your Personal Data to you in a
                portable format. If you have interacted with the Site in
                question using more than one email address, please submit a
                subsequent request with the additional email addresses so that
                we may provide this personal data as well.
              </p>
              <p>
                <strong>d.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Right to Delete Your Personal Data.{" "}
                </strong>
                Users of these Sites are able to edit and update most of their
                Personal Data held by each Site on the Site, and if you have any
                queries or complaints, we encourage you to contact us.
                Alternatively, if you do not wish to do this, you may make a
                formal request to delete your Personal Data provided to each
                Site. In order to do so, please email{" "}
                <a
                  href="mailto:info@ringofhires.com"
                  className="text-main font-semibold underline"
                >
                  info@ringofhires.com
                </a>
                . Once your request has been confirmed, each specific Site will
                initiate the necessary steps to fulfill your request, including
                the deletion of any applicable Site account. Where the deletion
                request relates to Personal Data that you previously requested
                be made public on each Site (e.g., a Job Seeker posting a public
                resume), we will make reasonable efforts to inform any other
                controllers of this request. If you have interacted with the
                Site in question using more than one email address, please
                submit a subsequent request with the additional email addresses
                so that we may delete this personal data as well.
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> By requesting the deletion of your
                  Personal Data, you will no longer be able to use the same
                  email address on the Site in question. This may include, but
                  is not limited to, your ability to create an account, receive
                  job alerts, or apply to job postings using this email address.
                  If you simply wish to close your account, please click the
                  “close my account” button in your account page. If you take
                  any action on or otherwise interact with our Sites after
                  submitting a deletion request, such as for example by using
                  Ring of Hires Apply or Easy Apply to apply to a job listing
                  that is hosted by the Site associated with your deletion
                  request (including job listings hosted by that Site on
                  affiliated Sites), you may not receive certain communications
                  associated with those actions from the Site affiliated with
                  your deletion request or from other users of that Site.
                  Additionally, by making a personal data deletion request, you
                  acknowledge that you will be removed from consideration for
                  all active sweepstakes, contests, prize draws, and other
                  promotions. Deleting your Personal Data held by Ring of Hires
                  does not affect messages we process on behalf of others in
                  connection with their use of Ring of Hires’ message Relay
                  Service and they may continue to have access to all messages
                  sent to or received from you.
                </p>
                <p>
                  <strong>ii.</strong> If you are a Job Seeker and want to
                  delete a job application performed from the Site in question,
                  please contact the prospective Employer directly to request
                  deletion of your application. If you are a Job Seeker who
                  submitted a job application to work at Ring of Hires, please
                  refer to the privacy policy you received when you applied, or
                  reach out to your recruiter, for additional instructions on
                  how to exercise your rights.
                </p>
              </div>
            </div>
            <p>
              <strong>15.</strong>
              <strong class="underline pl-1"> Withdrawing Consent.</strong>{" "}
              There are certain activities where we ask for your consent to use
              your Personal Data. You have the right to withdraw this consent at
              any time. Where you are providing location information via a
              mobile app, you may turn off your location services on your device
              at any time. For other services that rely on your consent, you can
              withdraw your consent via the workflow for that given service.
              Please note that any processing that we have carried out before
              the withdrawal of your consent remains lawful. Ring of Hires may
              provide an Employer with code for a Conversion Tracker to place on
              its website that pings the Job Seeker and tracks whenever a Job
              Seeker has applied to a job on that Employer’s website. This
              Conversion Tracker will attribute an application to Ring of Hires
              any time the Job Seeker started their search on Ring of Hires,
              despite any intervening activity, and a conversion count will be
              displayed on the Employer dashboard. Data may be shared from the
              Employer to Ring of Hires for the purposes of displaying unique
              applications. The Conversion Tracker is simply a counter that
              notifies Ring of Hires that a Job Seeker has applied, and also has
              the ability to count these conversions across devices if a Job
              Seeker is logged-in to the Employers website. If you would like to
              remove yourself from Ring of Hires’ Job Seeker web beacon, you
              email us at{" "}
              <a
                href="mailto:info@ringofhires.com"
                className="text-main font-semibold underline"
              >
                info@ringofhires.com
              </a>
            </p>
            <p>
              <strong>16.</strong>
              <strong class="underline pl-1"> Some Limitations.</strong> Such
              rights of rectification, objection, restriction, access,
              portability and deletion are subject to certain limitations, as
              provided for by applicable laws. Individual requests will be
              completed within the time allotted by relevant regulations, which
              starts to run from the point of us confirming your request. Where
              permitted, please note there may be a charge for subsequent
              requests from the same individual, which will be determined by
              Ring of Hires and only imposed to the extent permitted by
              applicable law.
            </p>
            <p>
              <strong>17.</strong>
              <strong class="underline pl-1">
                {" "}
                General Inquiries and Complaints
              </strong>
            </p>
            <div class="pl-2">
              <p>
                <strong>a.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Contacting Ring of Hires.{" "}
                </strong>
                You may contact Ring of Hires, LLC at 802 11th St W, Bradenton,
                FL 34205, USA.
              </p>
              <p>
                <strong>b.</strong>
                <strong class="underline pl-1"> Complaints.</strong> If you
                believe your data protection rights have been infringed by Ring
                of Hires, you have the right to complain to the appropriate data
                protection supervisory authority.
              </p>
              <p>
                <strong>c.</strong>
                <strong class="underline pl-1">
                  {" "}
                  Your California Privacy Rights.
                </strong>{" "}
                By exercising the rights afforded in this section of our Privacy
                Policy, you are declaring that you are a California resident.
                All users may make a verified request to Ring of Hires regarding
                their Personal Data. This process is detailed in Section 10 of
                our Privacy Policy, which also describes the designated address
                information for making requests to Ring of Hires regarding your
                Personal Data.
              </p>
              <p>
                <strong>d.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Right to Know Your Personal Data.{" "}
                </strong>
                You have the right to request that we disclose the Personal Data
                we collect, use, disclose and “sell” (see Section 13.2) about
                you. For more information on how to exercise this right, please
                see Section 10 of our Privacy Policy.
              </p>
              <p>
                <strong>e.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Right to Opt-out of the Sale of Your Personal Data.
                </strong>{" "}
                To learn more about Ring of Hires’ approach to California’s “Do
                not sell my personal information” requirement, why it applies to
                your use of our Sites, and how you can exercise this right,
                please visit our Opt out page. There, we explain how California
                has defined “sales” under the California Consumer Protection Act
                (the “CCPA”) and how certain transfers of Personal Data that are
                integral to the products and services we offer to our users may
                be captured by this definition.{" "}
              </p>
              <div class="pl-3">
                <p>
                  <strong>i.</strong> Under California’s “Shine the Light” law
                  (Civil Code section 1798.83), California residents have
                  certain rights regarding the disclosure of their Personal Data
                  to third parties for their own direct marketing purposes. Ring
                  of Hires’ policy is not to disclose your Personal Data to
                  third parties for the third parties’ direct marketing purposes
                  if you have exercised your option to prevent that information
                  from being disclosed to third parties for those purposes. If
                  you wish to not have your personal information shared for
                  those purposes, you may request that we delete your Personal
                  Data by following the steps outlined in Section 10 of this
                  Privacy Policy.
                </p>
              </div>
              <p>
                <strong>f.</strong>
                <strong class="underline pl-1">
                  {" "}
                  The Right to Non-Discrimination for Exercising Your Privacy
                  Rights.{" "}
                </strong>
                We will not discriminate against you for exercising any of your
                rights under the CCPA.
              </p>
            </div>
            <h4>Cookie Policy</h4>
            <p>
              This Cookie Policy explains how Ring of Hires ("
              <strong>Company</strong>," "<strong>we</strong>," "
              <strong>us</strong>," and "<strong>our</strong>") uses cookies and
              similar technologies to recognize you when you visit our website
              at <a href="http://ringofhires.com">http://www.ringofhires.com</a>{" "}
              ("<strong>Website</strong>"). It explains what these technologies
              are and why we use them, as well as your rights to control our use
              of them.
            </p>
            <p>
              In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.
            </p>

            <p>
              <strong className="text-lg mb-2 block">What are cookies?</strong>
              <p>
                Cookies are small data files that are placed on your computer or
                mobile device when you visit a website. Cookies are widely used
                by website owners in order to make their websites work, or to
                work more efficiently, as well as to provide reporting
                information.
              </p>
              <p>
                Cookies set by the website owner (in this case, Ring of Hires)
                are called "first-party cookies." Cookies set by parties other
                than the website owner are called "third-party cookies."
                Third-party cookies enable third-party features or functionality
                to be provided on or through the website (e.g., advertising,
                interactive content, and analytics). The parties that set these
                third-party cookies can recognize your computer both when it
                visits the website in question and also when it visits certain
                other websites.
              </p>
            </p>

            <p>
              <strong className="text-lg mb-2 block">
                Why do we use cookies?
              </strong>
              <p>
                We use first- and third-party cookies for several reasons. Some
                cookies are required for technical reasons in order for our
                Website to operate, and we refer to these as "essential" or
                "strictly necessary" cookies. Other cookies also enable us to
                track and target the interests of our users to enhance the
                experience on our Online Properties. Third parties serve cookies
                through our Website for advertising, analytics, and other
                purposes. This is described in more detail below.
              </p>
            </p>
            <p>
              <strong className="text-lg mb-2 block">
                How can I control cookies?
              </strong>
              <p>
                You have the right to decide whether to accept or reject
                cookies. You can exercise your cookie rights by setting your
                preferences in the Cookie Consent Manager. The Cookie Consent
                Manager allows you to select which categories of cookies you
                accept or reject. Essential cookies cannot be rejected as they
                are strictly necessary to provide you with services.
              </p>
              <p>
                The Cookie Consent Manager can be found in the notification
                banner and on our website. If you choose to reject cookies, you
                may still use our website though your access to some
                functionality and areas of our website may be restricted. You
                may also set or amend your web browser controls to accept or
                refuse cookies.
              </p>
              <p>
                The specific types of first- and third-party cookies served
                through our Website and the purposes they perform are described
                in the table below (please note that the specific cookies served
                may vary depending on the specific Online Properties you visit):
              </p>
            </p>
            <p>
              <strong className="text-lg mb-2 block underline">
                Analytics and customization cookies:
              </strong>
              <p>
                These cookies collect information that is used either in
                aggregate form to help us understand how our Website is being
                used or how effective our marketing campaigns are, or to help us
                customize our Website for you.
              </p>
            </p>
            <div className="border p-8 mb-6">
              Name : _gid
              <br />
              Purpose : Keeps an entry of unique ID which is then used to come
              up with statistical data on website usage by visitors. It is a
              HTTP cookie type and expires after a browsing session.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Google Analytics{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 1 day
            </div>
            <div className="border p-8 mb-6">
              Name : _ga
              <br />
              Purpose : Records a particular ID used to come up with data about
              website usage by the user
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Google Analytics{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country :United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 1 year 11 months 29 days
            </div>
            <div className="border p-8 mb-6">
              Name : _gat#
              <br />
              Purpose : Enables Google Analytics regulate the rate of
              requesting. It is a HTTP cookie type that lasts for a session.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Google Analytics{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 1 minute
            </div>

            <p>
              <strong className="text-lg mb-2 block underline">
                Advertising cookies:
              </strong>{" "}
              <p>
                These cookies are used to make advertising messages more
                relevant to you. They perform functions like preventing the same
                ad from continuously reappearing, ensuring that ads are properly
                displayed for advertisers, and in some cases selecting
                advertisements that are based on your interests.
              </p>
            </p>
            <div className="border p-8 mb-6">
              Name : sa-user-id-v2
              <br />
              Purpose : Used by Stackadapt to save unique visitor ID to monitor
              how they use the site and show them relevant ads. Persistent
              cookie that is saved for 5 years.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Stackadapt{" "}
              <a
                href="https://www.stackadapt.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 11 months 30 days
              <br />
            </div>
            <div className="border p-8 mb-6">
              Name : google_experiment_mod
              <br />
              Purpose : Used by Google AdSense for experimenting with
              advertisement efficiency across websites using their services.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Google{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : html_local_storage
              <br />
              Expires in : persistent
            </div>
            <div className="border p-8 mb-6">
              Name : sa-user-id-v2
              <br />
              Purpose : Used by Stackadapt to save unique visitor ID to monitor
              how they use the site and show them relevant ads. Persistent
              cookie that is saved for 5 years.
              <br />
              Provider : qvdt3feo.com
              <br />
              Service : Stackadapt{" "}
              <a
                href="https://www.stackadapt.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : server_cookie
              <br />
              Expires in : 11 months 30 days
              <br />
            </div>
            <div className="border p-8 mb-6">
              Name : ga-audiences
              <br />
              Purpose : Used by Google AdWords to re-engage visitors that are
              likely to convert to customers based on the visitor's online
              behaviour across websites
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : AdWords{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : pixel_tracker
              <br />
              Expires in : session
            </div>
            <div className="border p-8 mb-6">
              Name : sa-user-id
              <br />
              Purpose : Used by Stackadapt to save unique visitor ID to monitor
              how they use the site and show them relevant ads. Persistent
              cookie that is saved for 5 years.
              <br />
              Provider : qvdt3feo.com
              <br />
              Service : Stackadapt{" "}
              <a
                href="https://www.stackadapt.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 11 months 30 days
            </div>
            <div className="border p-8 mb-6">
              Name : test_cookie
              <br />
              Purpose : A session cookie used to check if the user’s browser
              supports cookies.
              <br />
              Provider : www.doubleclick.net
              <br />
              Service : DoubleClick{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : server_cookie
              <br />
              Expires in : 15 minutes
            </div>
            <div className="border p-8 mb-6">
              Name : sa-user-id
              <br />
              Purpose : Used by Stackadapt to save unique visitor ID to monitor
              how they use the site and show them relevant ads. Persistent
              cookie that is saved for 5 years.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Stackadapt{" "}
              <a
                href="https://www.stackadapt.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 11 months 30 days
            </div>
            <div className="border p-8 mb-6">
              Name : __gads
              <br />
              Purpose : Set by Google Ad Manager on a site to help with
              measuring how a user interacts with the ads on that domain and
              preventing the same ads from being shown to the user too many
              times.
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : Google AD Manager{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-main underline"
              >
                View Service Privacy Policy
              </a>
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 1 year 24 days
            </div>
            <p>
              <strong className="text-lg mb-2 block underline">
                Unclassified cookies:
              </strong>
              <p>
                These are cookies that have not yet been categorized. We are in
                the process of classifying these cookies with the help of their
                providers.
              </p>
            </p>
            <div className="border p-8 mb-6">
              Name : __gpi
              <br />
              Purpose : __________
              <br />
              Provider : www.ringofhires.com
              <br />
              Service : __________
              <br />
              Country : United States
              <br />
              Type : http_cookie
              <br />
              Expires in : 1 year 24 days
            </div>
            <p>
              <strong className="text-lg mb-2 block">
                How can I control cookies on my browser?
              </strong>
              <p>
                As the means by which you can refuse cookies through your web
                browser controls vary from browser to browser, you should visit
                your browser's help menu for more information. The following is
                information about how to manage cookies on the most popular
                browsers:
              </p>
            </p>

            <ul className="pl-10 list-disc mb-4">
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                  className="text-main underline"
                >
                  Chrome
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  className="text-main underline"
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                  className="text-main underline"
                >
                  Firefox
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                  className="text-main underline"
                >
                  Safari
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  className="text-main underline"
                >
                  Edge
                </a>
              </li>
              <li>
                <a
                  href="https://help.opera.com/en/latest/web-preferences/"
                  className="text-main underline"
                >
                  Opera
                </a>
              </li>
            </ul>

            <p>
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit:
            </p>

            <ul className="pl-10 list-disc mb-4">
              <li>
                <a
                  href="http://www.aboutads.info/choices/"
                  className="text-main underline"
                >
                  Digital Advertising Alliance
                </a>
              </li>
              <li>
                <a
                  href="https://youradchoices.ca/"
                  className="text-main underline"
                >
                  Digital Advertising Alliance of Canada
                </a>
              </li>
              <li>
                <a
                  href="http://www.youronlinechoices.com/"
                  className="text-main underline"
                >
                  European Interactive Digital Advertising Alliance
                </a>
              </li>
            </ul>

            <p>
              <strong className="text-lg mb-2 block">
                What about other tracking technologies, like web beacons?
              </strong>
              <p>
                Cookies are not the only way to recognize or track visitors to a
                website. We may use other, similar technologies from time to
                time, like web beacons (sometimes called "tracking pixels" or
                "clear gifs"). These are tiny graphics files that contain a
                unique identifier that enables us to recognize when someone has
                visited our Website or opened an email including them. This
                allows us, for example, to monitor the traffic patterns of users
                from one page within a website to another, to deliver or
                communicate with cookies, to understand whether you have come to
                the website from an online advertisement displayed on a
                third-party website, to improve site performance, and to measure
                the success of email marketing campaigns. In many instances,
                these technologies are reliant on cookies to function properly,
                and so declining cookies will impair their functioning.
              </p>
            </p>

            <p>
              <strong className="text-lg mb-2 block">Do you use Flash cookies or Local Shared Objects?</strong>
              <p>
                Websites may also use so-called "Flash Cookies" (also known as
                Local Shared Objects or "LSOs") to, among other things, collect
                and store information about your use of our services, fraud
                prevention, and for other site operations.
              </p>
              <p>
                If you do not want Flash Cookies stored on your computer, you
                can adjust the settings of your Flash player to block Flash
                Cookies storage using the tools contained in the{" "}
                <a
                  href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                  className="text-main underline"
                >
                  Website Storage Settings Panel
                </a>
                . You can also control Flash Cookies by going to the{" "}
                <a
                  href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                  className="text-main underline"
                >
                  Global Storage Settings Panel
                </a>{" "}
                and following the instructions (which may include instructions
                that explain, for example, how to delete existing Flash Cookies
                (referred to "information" on the Macromedia site), how to
                prevent Flash LSOs from being placed on your computer without
                your being asked, and (for Flash Player 8 and later) how to
                block Flash Cookies that are not being delivered by the operator
                of the page you are on at the time).
              </p>
              <p>
                Please note that setting the Flash Player to restrict or limit
                acceptance of Flash Cookies may reduce or impede the
                functionality of some Flash applications, including,
                potentially, Flash applications used in connection with our
                services or online content.
              </p>
            </p>

            <p>
              <strong className="text-lg mb-2 block">Do you serve targeted advertising?</strong>
              <p>
                Third parties may serve cookies on your computer or mobile
                device to serve advertising through our Website. These companies
                may use information about your visits to this and other websites
                in order to provide relevant advertisements about goods and
                services that you may be interested in. They may also employ
                technology that is used to measure the effectiveness of
                advertisements. They can accomplish this by using cookies or web
                beacons to collect information about your visits to this and
                other sites in order to provide relevant advertisements about
                goods and services of potential interest to you. The information
                collected through this process does not enable us or them to
                identify your name, contact details, or other details that
                directly identify you unless you choose to provide these.
              </p>
            </p>

            <p>
              <strong className="text-lg mb-2 block">How often will you update this Cookie Policy?</strong>
              <p>
                We may update this Cookie Policy from time to time in order to
                reflect, for example, changes to the cookies we use or for other
                operational, legal, or regulatory reasons. Please therefore
                revisit this Cookie Policy regularly to stay informed about our
                use of cookies and related technologies.
              </p>
              <p>
                The date at the top of this Cookie Policy indicates when it was
                last updated.
              </p>
            </p>

            <p>
              <strong className="text-lg mb-2 block">Where can I get further information?</strong>
              <p>
                If you have any questions about our use of cookies or other
                technologies, please email us at contact@ringofhires.com or by
                post to:
              </p>
              <p>
                Ring of Hires
                <br />
                802 11th St W 
                Bradenton, FL 34205
                <br />
                United States
                <br />
                Phone: <a href="tel:941-216-5957" className="text-main font-semibold underline">(941) 216-5957</a>
              </p>
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions about our privacy policy, email us at{" "}
              <a
                href="mailto:info@ringofhires.com"
                className="text-main font-semibold underline"
              >
                info@ringofhires.com
              </a>
            </p>
          </div>
        </div>

        {/* <div class="sidebar-outer rounded-md border-2 border-gray-100 shadow-lg p-5">
          <Sidebar />
        </div> */}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
