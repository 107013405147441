import { useState, useContext, useEffect, useRef } from "react";
import {
  LookingToHireContext,
  MapContext,
  UserContext,
  ChatContext,
} from "../../App";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

const TopNav = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDropdownMoblie, setIsDropdownMoblie] = useState(false);
  // const urls = window.location.href.split("/")[3];
  // console.log("urls", urls);

  const logOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const { username } = useContext(UserContext);
  const [lookingToHire, setLookingToHire] = useContext(LookingToHireContext);
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
  ] = useContext(MapContext);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const { userRole } = useContext(UserContext);
  const [
    connectedUser,
    setConnectedUser,
    userList,
    setUserList,
    messageNotification,
    setMessageNotification,
  ] = useContext(ChatContext);

  //location check to remove job seeker and looking for hires button
  const location = useLocation();

  /* Close the dropdown on outside click */
  function popupHide() {
    setIsDropdown(false);
    // console.log('outside click');
  }
  useEffect(() => {
    if (isDropdown) {
      window.addEventListener("click", popupHide);

      // unmound the eventlistner
      return () => {
        window.removeEventListener("click", popupHide);
      };
    }
  }, [isDropdown]);

  /* Close the mobile menu on outside click */
  function toggleHide() {
    setToggleMenu(true);
    console.log("outside click");
  }

  useEffect(() => {
    if (!toggleMenu) {
      window.addEventListener("click", toggleHide);

      // unmound the eventlistner
      return () => {
        window.removeEventListener("click", toggleHide);
      };
    }
  }, [toggleMenu]);

  const dropdownRef = useRef();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        isDropdownMoblie &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsDropdownMoblie(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [isDropdownMoblie]);

  useEffect(() => {
    if (!toggleMenu && !isDropdown) {
      setToggleMenu(false);
    }
  });

  return (
    <div className="relative flex flex-col">
      <div className="relative z-50 flex items-center justify-between bg-white container-wrapper md:py-3 lg:py-0">
        <div className="flex items-center">
          <a href="/" className="px-2 py-4">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="relative bottom-2 w-36 md:w-1/4"
              title="ROH Logo"
              loading="lazy"
              width="100%"
              height="auto"
            />
          </a>
        </div>
        {/* {location.pathname === "/" && (
          <div className="items-center justify-center hidden xl:flex">
            <button
              href="#"
              className={`bg-white btn-base ${
                lookingToHire ? "" : "bg-main text-white"
              }`}
              onClick={() => {
                setLookingToHire(false);
                setMapData([]);
                setSameLocationData([]);
                setGroupData([]);
                setShowModal(false);
              }}
            >
              Job Seekers
            </button>
            <button
              href="#"
              className={`ml-2 bg-white btn-base ${
                lookingToHire ? "bg-main text-white" : ""
              }`}
              onClick={() => {
                setLookingToHire(true);
                setMapData([]);
                setSameLocationData([]);
                setGroupData([]);
                setShowModal(false);
              }}
            >
              Looking to Hire
            </button>
          </div>
        )} */}
        <div className="items-center justify-end hidden whitespace-pre lg:flex">
          <div className="md:mr-3 lg:mr-6 xl:mr-12 hidden lg:block">
            {location.pathname.split("/").includes("profile") &&
              userRole === "employer" && (
                <Link to="/hireSearch">
                  <h1 className="py-1 my-2 font-semibold rounded top-button btn btn-sub px-6 w-full">
                    Search Candidates
                  </h1>
                </Link>
              )}

            {location.pathname.split("/").includes("profile") &&
              userRole === "employee" && (
                <Link to="/jobSearch">
                  <h1 className="py-1 my-2 font-semibold rounded top-button btn btn-sub px-6 w-full">
                    Search Jobs
                  </h1>
                </Link>
              )}
          </div>

          <Link to="/">
            <h2
              className={`md:mr-3 lg:mr-6 xl:mr-12 cursor-pointer ${
                location.pathname === "/"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Home
            </h2>
          </Link>
          <Link to="/plans-and-pricing">
            <h2
              className={`md:mr-3 lg:mr-6 xl:mr-12 ${
                location.pathname === "/plans-and-pricing"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Plans & Pricing
            </h2>
          </Link>

          {/* <a
            href="/profile/mailbox"
            className={`mr-3 flex items-center ${
              location.pathname === "/profile/mailbox"
                ? "active font-semibold text-red-600"
                : ""
            }`}
          >
            {messageNotification && (
              <div className="w-3 h-3 bg-main rounded-full mr-1"></div>
            )}
            Mailbox
          </a> */}
          <Link to="/contact-us">
            <h2
              className={`md:mr-3 lg:mr-6 xl:mr-12 ${
                location.pathname === "/contact-us"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Contact
            </h2>
          </Link>
          {username && (
            <>
              <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 border-none rounded-md shadow-sm hover:bg-gray-50 focus:outline-none "
                    id="menu-button"
                    onClick={() => {
                      setIsDropdown(!isDropdown);
                    }}
                  >
                    {username.split("@")[0]}
                    {/* Heroicon name: solid/chevron-down */}
                    <svg
                      className="w-5 h-5 ml-2 -mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div
                  className={`absolute left-0 w-56 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                    isDropdown ? "block" : "hidden"
                  }`}
                >
                  <div className="p-1" role="none">
                    {userRole === "employee" && (
                      <Link
                        onClick={() => {
                          setIsDropdown(false);
                        }}
                        to="/profile/employees/job-applications"
                        className="block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white"
                      >
                        Profile
                      </Link>
                    )}
                    {userRole === "employer" && (
                      <Link
                        onClick={() => {
                          setIsDropdown(false);
                        }}
                        to="/profile/joblist"
                        className="block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white"
                      >
                        Profile
                      </Link>
                    )}

                    <Link
                      onClick={() => {
                        logOut();
                      }}
                      to="/"
                      className="block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-sub hover:text-white"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-0"
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          {!username && (
            <>
              <Link to="/login">
                <h2 className="bg-white shadow-md btn-base hover:text-white hover:bg-main ">
                  Sign In
                </h2>
              </Link>
              <Link to="/signup">
                <h2 className="top-button mx-2 btn btn-sub">Create Profile</h2>
              </Link>
              <Link to="/login">
                <h2 className="top-button btn btn-sub">Post Job</h2>
              </Link>
            </>
          )}

          {userRole === "employer" && (
            <Link to="/profile/job-posting">
              <h3 className="top-button mx-2 btn btn-sub">Post Job</h3>
            </Link>
          )}
          {userRole === "employer" && (
            <Link to="/profile/employer-dashboard">
              <h3 className="top-button mx-2 btn btn-sub">Dashboard</h3>
            </Link>
          )}
          {userRole === "employee" && (
            <Link to="/profile/employee-dashboard">
              <h3 className="top-button mx-2 btn btn-sub">Dashboard</h3>
            </Link>
          )}
        </div>
        <div className="flex items-center justify-end lg:hidden">
          {!username && (
            <>
              <Link to="/login" className="top-button btn btn-sub mr-4">
                Sign In
              </Link>
            </>
          )}
          {username && (
            <>
              <div
                className="relative inline-block text-left"
                ref={dropdownRef}
              >
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 border-none rounded-md shadow-sm hover:bg-gray-50 focus:outline-none "
                    id="menu-button"
                    onClick={() => {
                      setIsDropdownMoblie(!isDropdownMoblie);
                      if (!toggleMenu) {
                        setToggleMenu(true);
                      }
                    }}
                  >
                    {username.split("@")[0]}
                    {/* Heroicon name: solid/chevron-down */}
                    <svg
                      className="w-5 h-5 ml-2 -mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div
                  className={`absolute w-48 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                    isDropdownMoblie ? "block" : "hidden"
                  }`}
                  style={{ right: "-24px" }}
                >
                  <div className="p-1" role="none">
                    {userRole === "employer" && (
                      <>
                        <Link
                          to="/profile/employer-dashboard"
                          className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                            location.pathname === "/profile/employer-dashboard"
                              ? "active font-semibold text-red-600"
                              : ""
                          }`}
                          onClick={() => {
                            setIsDropdownMoblie(false);
                          }}
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/profile/joblist"
                          className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                            location.pathname === "/profile/joblist"
                              ? "active font-semibold text-red-600"
                              : ""
                          }`}
                          onClick={() => {
                            setIsDropdownMoblie(false);
                          }}
                        >
                          Job Posts
                        </Link>

                        <Link
                          to="/profile/job-posting"
                          className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                            location.pathname === "/profile/job-posting"
                              ? "active font-semibold text-red-600"
                              : ""
                          }`}
                          onClick={() => {
                            setIsDropdownMoblie(false);
                          }}
                        >
                          Create Job Post
                        </Link>
                      </>
                    )}
                    {userRole === "employee" && (
                      <>
                        <Link
                          to="/profile/employee-dashboard"
                          className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                            location.pathname === "/profile/employee-dashboard"
                              ? "active font-semibold text-red-600"
                              : ""
                          }`}
                          onClick={() => {
                            setIsDropdownMoblie(false);
                          }}
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/profile/employees/job-applications"
                          className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                            location.pathname ===
                            "/profile/employees/job-applications"
                              ? "active font-semibold text-red-600"
                              : ""
                          }`}
                          onClick={() => {
                            setIsDropdownMoblie(false);
                          }}
                        >
                          Jobs
                        </Link>
                      </>
                    )}
                    <Link
                      to="/profile/my-plan"
                      className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                        location.pathname === "/profile/my-plan"
                          ? "active font-semibold text-red-600"
                          : ""
                      }`}
                      onClick={() => {
                        setIsDropdownMoblie(false);
                      }}
                    >
                      My Plan
                    </Link>
                    <Link
                      to="/profile/mailbox"
                      className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                        location.pathname === "/profile/mailbox"
                          ? "active font-semibold text-red-600"
                          : ""
                      }`}
                      onClick={() => {
                        setIsDropdownMoblie(false);
                      }}
                    >
                      Mailbox
                    </Link>
                    {userRole === "employer" && (
                      <Link
                        to="/profile/availability"
                        className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                          location.pathname === "/profile/availability"
                            ? "active font-semibold text-red-600"
                            : ""
                        }`}
                        onClick={() => {
                          setIsDropdownMoblie(false);
                        }}
                      >
                        Availability
                      </Link>
                    )}

                    <Link
                      to="/profile/edit"
                      className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                        location.pathname === "/profile/edit"
                          ? "active font-semibold text-red-600"
                          : ""
                      }`}
                      onClick={() => {
                        setIsDropdownMoblie(false);
                      }}
                    >
                      Edit Profile
                    </Link>
                    <Link
                      to="/profile/change-password"
                      className={`block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-main hover:text-white ${
                        location.pathname === "/profile/change-password"
                          ? "active font-semibold text-red-600"
                          : ""
                      }`}
                      onClick={() => {
                        setIsDropdownMoblie(false);
                      }}
                    >
                      Change Password
                    </Link>
                    <Link
                      onClick={() => {
                        logOut();
                      }}
                      to="/"
                      className="block px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-sub hover:text-white"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-0"
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          <button
            className="mobile-menu-button focus:outline-none"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <svg
              className="w-6 h-6 text-gray-500 hover:text-main"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>

      {/* mobile nav */}
      <div
        className={`relative z-10 transition-all duration-300 w-full transform  ${
          toggleMenu ? "-translate-y-96 hidden" : "translate-y-0 block"
        } bg-white mobile-menu lg:hidden text-center`}
      >
        <ul className="absolute w-full bg-white shadow-md rounded-b-md">
          <li className="active">
            <Link
              onClick={() => {
                setToggleMenu(!toggleMenu);
              }}
              to="/"
              className={`block px-2 py-4 text-sm focus:bg-main focus:text-white ${
                location.pathname === "/"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setToggleMenu(!toggleMenu);
              }}
              to="/plans-and-pricing"
              className={`block px-2 py-4 text-sm focus:bg-main focus:text-white ${
                location.pathname === "/plans-and-pricing"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Plans & Pricing
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setToggleMenu(!toggleMenu);
              }}
              to="/contact-us"
              className={`block px-2 py-4 text-sm focus:bg-main focus:text-white ${
                location.pathname === "/contact-us"
                  ? "active font-semibold text-red-600"
                  : ""
              } url-nav`}
            >
              Contact
            </Link>
          </li>

          {!username && (
            <>
              <li>
                <Link
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                  to="/login"
                  className="block px-2 py-4 text-sm focus:bg-main focus:text-white"
                >
                  Sign In
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                  to="/signup"
                  className="block px-4 py-4 m-2 text-sm rounded btn-sub focus:text-white"
                >
                  Create Profile
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                  to="/login"
                  className="block px-4 py-4 m-2 text-sm rounded btn-sub focus:text-white"
                >
                  Post Job
                </Link>
              </li>
            </>
          )}
          {userRole === "employer" && (
            <>
              <li>
                <Link
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                  to="/profile/job-posting"
                  className="block px-4 py-4 m-2 text-sm rounded btn-sub focus:text-white"
                >
                  Post Job
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                  to="/hireSearch"
                  className="block px-4 py-4 m-2 text-sm rounded btn-sub focus:text-white"
                >
                  Search Candidates
                </Link>
              </li>
            </>
          )}

          {userRole === "employee" && (
            <Link
              onClick={() => {
                setToggleMenu(!toggleMenu);
              }}
              to="/jobSearch"
              className="block px-4 py-4 m-2 text-sm rounded btn-sub focus:text-white"
            >
              Search Jobs
            </Link>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopNav;
