import React, { useContext } from "react";
import { useState, useMemo, useEffect } from "react";
import useToken from "../../hooks/useToken";
import axios from "axios";
import usePatch from "../../hooks/usePatch";
import { MdModeEditOutline, MdCalendarToday } from "react-icons/md";
import { AiOutlineDelete, AiOutlineClockCircle } from "react-icons/ai";
import moment from "moment";
import usePostData from "../../hooks/usePostData";
import TimeAgo from "react-timeago";
import SlotsPopup from "./SlotsPopup";
import { RefreshContext } from "../../App";

export default function AllSlots({
  allSlots,
  employeeId,
  employerId,
  jobId,
  next,
  from,
  to_user,
  setRefresh,
}) {
  const user_id = localStorage.getItem("user_id");
  const [rejectSlot, setRejectSlot] = useState(false);
  const [rejectType, setRejectType] = useState(null);

  const { defaultOptions } = useToken("application/json");
  const [bookingSlotId, bookedByMe] = useMemo(() => {
    let temp1 = [];
    let temp2 = false;
    if (allSlots) {
      temp1.push(allSlots.booking1);
      temp1.push(allSlots.booking2);
      temp1.push(allSlots.booking3);
    }
    if (allSlots?.from_user?.id == employeeId) temp2 = true;
    return [temp1, temp2];
  }, [allSlots]);

  const jobApplicationAcceptedOrRejected = useMemo(() => {
    let temp = null;
    if (bookingSlotId) {
      bookingSlotId.map((e) => {
        if (e.booking_status === "accepted") {
          temp = "accepted";
        } else if (e.booking_status === "rejected") {
          temp = "rejected";
        }
      });
    }
    return temp;
  }, [bookingSlotId]);

  const rejectAllSlots = () => {
    if (bookingSlotId?.length >= 3) {
      bookingSlotId.map((e) => {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}availability/booking/${e.id}`,
            { booking_status: "rejected" },
            defaultOptions
          )
          .then(() => {
            setRefresh();
          });
      });
    }
  };

  let offset = new Date().getTimezoneOffset();
  const centralTimestamp = new Date(allSlots?.created_at);
  centralTimestamp.setMinutes(centralTimestamp.getMinutes() - offset);

  return (
    <>
      {rejectSlot && (
        <RejectModal
          rejectSlot={rejectSlot}
          setRejectSlot={setRejectSlot}
          allData={allSlots}
          jobid={jobId}
          functionReject={rejectAllSlots}
          rejectType={rejectType}
          to_user={to_user}
          setRefresh={setRefresh}
        />
      )}
      <div className="flex flex-row">
        {!jobApplicationAcceptedOrRejected ? (
          <div className="flex item-center">
            <div className="flex flex-col">
              <h1>
                {jobApplicationAcceptedOrRejected ?? "Interview Initiated"}
              </h1>
              {bookingSlotId.map((e) => {
                return (
                  <>
                    {e && (
                      <IndividualBookedSlot
                        key={e}
                        bookingSlots={e}
                        displayButton={!bookedByMe}
                        jobid={jobId}
                        setRefresh={setRefresh}
                      />
                    )}
                  </>
                );
              })}
            </div>
            {!(
              bookingSlotId.includes("accepted") ||
              bookingSlotId.includes("rejected")
            ) &&
              !bookedByMe && (
                <div className="w-full mt-6 flex justify-center items-center">
                  <button
                    className="bg-red-500 rounded-sm px-4 h-8  text-white "
                    onClick={() => {
                      setRejectSlot(true);
                      setRejectType("rejectAll");
                    }}
                  >
                    {"Reject all"}
                  </button>
                </div>
              )}
          </div>
        ) : (
          <>
            {
              <div className="flex flex-col w-full ">
                <div className="capitalize relative flex justify-between w-full">
                  <div> Interview slot {jobApplicationAcceptedOrRejected}</div>
                  <div className="text-gray-600">
                    (
                    <spam className="text-blue-500 capitalize">
                      {allSlots.from_user.first_name}
                    </spam>
                    ){" "}
                    <TimeAgo
                      date={centralTimestamp}
                      formatter={(value, unit, suffix) => {
                        if (unit === "second") {
                          return "just now";
                        } else {
                          return (
                            value +
                            " " +
                            unit +
                            (value > 1 ? "s" : "") +
                            " " +
                            suffix
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  {bookingSlotId.map((e) => {
                    if (
                      e.booking_status === "accepted" ||
                      e.booking_status === "rejected"
                    )
                      return (
                        <div className="flex flex-row">
                          <IndividualBookedSlot
                            key={e}
                            bookingSlots={e}
                            displayButton={false}
                          />
                        </div>
                      );
                  })}

                  {!next?.application_action &&
                    allSlots.from_user.id != user_id && (
                      <button
                        className="bg-red-400 rounded-sm px-3 mx-5 py-1 text-white"
                        onClick={() => {
                          setRejectSlot(true);
                          setRejectType("Reschedule");
                        }}
                      >
                        Reschedule
                      </button>
                    )}
                </div>
              </div>
            }
          </>
        )}
      </div>
    </>
  );
}

export function IndividualBookedSlot({
  bookingSlots,
  allData,
  jobid,
  setRefreshLog,
  history,
  displayButton,
  handleDisable = () => {},
  disable,
  setRefresh,
}) {
  const [acceptPatchData, setAcceptPatchData] = useState(null);
  const [acceptPatchUrl, setAcceptPatchUrl] = useState(null);

  const { refreshWraper, setRefreshWraper } = useContext(RefreshContext);

  // const { data: acceptData, error: err } = usePostData(
  //   `availability/applicationlog`,
  //   formData
  // );
  // const emp_id = useMemo(() => {
  //   let temp = [];
  //   if (allData) {
  //     allData.map((e) => {
  //       if (e.application_action === "slot_booked") {
  //         temp.push(e.from_user);
  //       }
  //     });
  //   }
  //   return temp;
  // }, [bookingSlots]);

  const { data } = usePatch(acceptPatchUrl, acceptPatchData);

  useEffect(() => {
    if (data) {
      setRefresh();
      setRefreshWraper();
      // history.go(0);
    }
  }, [data]);

  const AcceptSlot = (data) => {
    setAcceptPatchData({ booking_status: "accepted" });
    setAcceptPatchUrl(`availability/booking/${data.id}`);
  };
  return (
    <div>
      <div className="text-sm text-gray-500 flex flex-col sm:flex-row mx-7 py-0">
        {bookingSlots && (
          <>
            <div className="flex flex-row my-1">
              <div className="flex flex-row  py-2">
                <div className="flex w-36">
                  <MdCalendarToday
                    className=" text-blue-500 my-1"
                    // size="1.2em"
                  />
                  <p className="mx-2 w-50">
                    {bookingSlots?.event_date
                      ? moment(bookingSlots?.event_date).format("MMMM Do YYYY")
                      : ""}
                  </p>
                </div>
                <div className="flex w-32">
                  <AiOutlineClockCircle
                    className=" text-blue-500 my-1"
                    // size="1.2em"
                  />
                  <p className="mx-2">
                    {bookingSlots?.from_hour
                      ? moment(bookingSlots?.from_hour, "HH mm ss").format("LT")
                      : ""}
                  </p>
                </div>
              </div>

              {displayButton && (
                <button
                  disabled={disable}
                  className=" text-white bg-blue-500  hover:bg-blue-700 rounded-sm p-1 px-4"
                  onClick={(e) => {
                    if (jobid) {
                      AcceptSlot(bookingSlots);
                    }
                    handleDisable();
                    // e.target.setAttribute("disabled", true);
                  }}
                >
                  {"Accept"}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function RejectModal({
  rejectSlot,
  setRejectSlot,
  allData,
  jobid,
  setRefreshLog,
  functionReject,
  rejectType,
  to_user,
  setRefresh,
}) {
  const [formData, setFormData] = useState();

  const { data: rejectData, error: err } = usePostData(
    `availability/applicationlog`,
    formData
  );
  useEffect(() => {
    if (rejectData) {
      setRefresh();
      setRejectSlot(false);
    }
  }, [rejectData]);

  return (
    <>
      <div className="fixed z-10  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => {
                setRejectSlot(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to{" "}
                {rejectType == "rejectAll" ? "Reject all slots" : "Reschedule"}?
              </h3>
              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={() => {
                  if (rejectType == "rejectAll") {
                    functionReject();
                    setRejectSlot(false);
                  } else if (rejectType == "Reschedule") {
                    setFormData({
                      job: jobid,
                      to_user: to_user,
                      application_action: "booking_cancelled",
                      application: null,
                    });
                  }
                }}
              >
                Yes, I'm sure
              </button>
              <button
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={() => {
                  setRejectSlot(false);
                }}
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
