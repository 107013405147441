import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import usePostData from "../../hooks/usePostData";
import { useHistory } from "react-router-dom";

const SparkMembership = ({
  planData,
  isActivateSpark,
  setIsActivateSpark,
  sparkFun,
  setPricingUrl
}) => {
  // Spark membership Upgrade
  let token = localStorage.getItem("accessToken");
  const sparkUrl = "spark-member";
  const [sparkData, setSparkData] = useState(null);
  const [upgraded, setupgraded] = useState(false);
  const { data: upgrateToSpark } = usePostData(sparkUrl, sparkData);
  const upgradeMe = () => {
    setSparkData({ Authorization: token });
  };
  let history = useHistory();

  useEffect(() => {
    if (upgrateToSpark) {
      sparkFun();
      // setPricingUrl(null);
      // setPricingUrl(`plan-details`);
      // setTimeout(() => {
      //   window.location.href="/profile/my-plan";
      // }, 1000 );
    }
  }, [upgrateToSpark]);

  const redirectHandle = () => {
    if (localStorage.getItem("redirectToJobSh")) {
      history.goBack();
      localStorage.removeItem("redirectToJobSh");
    } else {
      window.location.href = "/profile/my-plan";
    }

    if (localStorage.getItem("redirected_jobpost")) {
      history.goBack();
      localStorage.removeItem("redirected_jobpost");
    }
  };

  return (
    <div>
      {isActivateSpark ? (
        <>
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
              {/*content*/}
              <div className="relative flex flex-col w-5/6 mx-auto md:w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-2xl">
                    Spark Membership
                  </h3>
                  {/* <p className="text-red-600 font-base-semibold">1/3</p> */}
                  <button
                    className="flex items-center justify-center font-semibold text-gray-500 w-auto"
                    onClick={() => {
                      setIsActivateSpark(false);
                    }}
                  >
                    <AiOutlineClose className="text-gray-600" size="1.2em" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto px-6 pt-6">
                  <div className="flex w-full text-gray-500 md:items-center details font_small_light normal-case">
                    <p>Are you sure you want to activate Spark Membership?</p>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b border-blueGray-200">
                  <div className="flex space-x-2">
                    <button
                      className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-sub"
                      onClick={() => {
                        setIsActivateSpark(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                      onClick={() => {
                        upgradeMe();
                        setIsActivateSpark(false);
                        setTimeout(() => {
                          redirectHandle();
                        }, 1000);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SparkMembership;
