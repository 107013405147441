const SingleMessage = ({ message }) => {
  //let newMessage = message.split("|-|");
  let messageType = "chat";

  messageType = message.message_type;

  let messageData = null;

  if (message.meta_data) {
    let data = message.meta_data.replace(/'/g, '"');
    messageData = JSON.parse(data);
  }

  if (messageType == "applied") {
    return (
      <>
        {messageData?.message}{" "}
        <b>
          <a target="_blank" href={messageData?.resume_url} download>
            {" "}
            Download Resume{" "}
          </a>
        </b>{" "}
        ||{" "}
        <b>
          <a target="_blank" href={"/job-details/" + messageData?.job_id}>
            {" "}
            Visit Job{" "}
          </a>
        </b>{" "}
      </>
    );
  } else if (messageType == "hired") {
    return (
      <>
        {messageData?.subject} || {messageData?.message}{" "}
        {messageData?.document_urls ? (
          <b>
            <a target="_blank" href={messageData?.document_urls[0]} download>
              {" "}
              Download Offer Letter{" "}
            </a>
          </b>
        ) : (
          ""
        )}{" "}
      </>
    );
  } else {
    return <>{message.text}</>;
  }
};

export default SingleMessage;
