const fields = {
  contact_name: "",
  email_address: "",
  mobile_number: "",
  description: "",
  type: "",
};

const checkFields = (data) => {
  const errors = {};
  // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (let key in fields) {
    if (!data[key]) {
      errors[key] = "This field may not be blank.";
    }
  }

  // if(data.mobile_number !=='' && data.mobile_number.length<8) errors.mobile_number = 'Please enter a valid phone number'

  // if (data.email_address !== '') {
  //     if (!emailRegex.test(data.email_address)) {
  //       errors.email_address = 'Invalid email format'
  //     }
  // }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
