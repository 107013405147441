import { useContext, useState, useEffect } from "react";
import usePutData from "../../hooks/usePutData";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ForgotPassword = () => {
  const url = "users/password";
  const loader = <span className="text-white">Sending...</span>;

  const [formdata, setFormData] = useState(null);

  const [forgotemail, setForgotEmail] = useState("");
  const [forgotphone, setForgotPhone] = useState("");
  const [countrycode, setCountryCode] = useState("");

  const [show, setShow] = useState("email");

  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  const [isValid, setIsValid] = useState("");
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const history = useHistory();

  const updatePhone = (value, country) => {
    setForgotPhone(value);
    setCountryCode(country.countryCode);
  };

  const processForm = (e) => {
    e.preventDefault();

    if (show == "email") {
      setFormData({ email: forgotemail });
    }

    if (show == "phone") {
      setFormData({ phone: forgotphone });
    }

    // if (forgotemail == '') {
    //   setMessage({ message: 'Please fill either email or phone!', class: 'error' });
    // } else {
    //   if (forgotemail !== '') {
    //     if (!emailRegex.test(forgotemail)) {
    //       setIsValid('Invalid email format');
    //     } else {
    //       setIsValid('');
    //       setFormData(forgotemail);
    //     }
    //   } else {
    //     setFormData(forgotphone);
    //     console.log(`Typed phone number is here......`, forgotphone);
    //   }
    // }
  };

  const { data, error, loading } = usePutData(url, formdata);

  useEffect(() => {
    if (data) {
      // setValue(fields);
      setMessage({ message: data.message, class: "success" });
      setTimeout(() => {
        history.push(
          `/reset-password/${data.data.uid}/${data.data.temp_token}`
        );
      }, 3000);
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  return (
    <div className="minHeight relative flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg lg:w-8/12 xl:w-6/12">
        {commonMessage && (
          <p className={commonMessage.class}>{commonMessage.message}</p>
        )}
        <h1 className="my-5 text-3xl font-semibold text-black">
          Forgot Password
        </h1>

        <div className="flex items-center py-2 mb-5 justify-items-start">
          <div
            className={`cursor-pointer px-3 py-1  rounded-md ${
              show == "email" ? "bg-main text-white" : "bg-gray-200"
            }`}
            onClick={() => setShow("email")}
          >
            email
          </div>
          <div
            className={`ml-3 cursor-pointer px-3 py-1 rounded-md ${
              show == "phone" ? "bg-main text-white" : "bg-gray-200"
            }`}
            onClick={() => setShow("phone")}
          >
            phone
          </div>
        </div>

        <form
          onSubmit={(e) => {
            processForm(e);
          }}
        >
          {show === "email" && (
            <div className="mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={forgotemail.email}
                autoComplete="off"
                className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                placeholder="example@gmail.com"
                onChange={(e) => setForgotEmail(e.target.value)}
              />
              <span className="form_error_message">
                {errors?.message ? errors?.message : errors?.email}
              </span>
              {isValid && <p className="form_error_message">{isValid}</p>}
            </div>
          )}

          {show === "phone" && (
            <div className="mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="phone"
              >
                Phone no
              </label>
              <PhoneInput
                country={'us'}
                inputClass="max-h-12 p-0  mb-4"
                containerClass="h-12 p-0"
                //country={values.country_code}
                value={forgotphone}
                onChange={(phone, country) => updatePhone(phone, country)}
              />
              <span className="form_error_message">
                {errors?.message ? errors?.message : errors?.phone}
              </span>
            </div>
          )}

          <div className="mb-3">
            <button className="w-full btn btn-main">
              {loading ? loader : "Send"}
            </button>
          </div>

          {/* <div className="flex items-center justify-between">
            <div className="w-full h-0 mr-6 border-t border-gray-200"></div>
            <div className="text-sm text-gray-400">Or</div>
            <div className="w-full h-0 ml-6 border-t border-gray-200"></div>
          </div> */}

          {/* <div className="flex items-center justify-center my-10 mr-2">
            <div className="p-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/google.png"
                className="w-8 h-auto"
                alt="Sign Up with Google"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/apple.png"
                className="w-8 h-auto"
                alt="Sign Up with Apple"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/facebook.png"
                className="w-8 h-auto"
                alt="Sign Up with Facebook"
              />
            </div>
          </div> */}

          <div className="my-5 text-center">
            <p className="text-sm text-gray-500">
              New to Ring of Hires?{" "}
              <a className="text-main" href="/signup">
                Create Account
              </a>{" "}
            </p>

            <a className="mt-5 text-sm text-main" href="/help-center">
              Help Center
            </a>

            <p className="mt-10 text-sm text-gray-500">
              By signing into account, you agree to Ring of Hires{" "}
              <a className="text-main" href="/terms-of-service">
                Terms of Service
              </a>{" "}
              and consent to our{" "}
              <a className="text-main" href="/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
