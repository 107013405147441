import { useState, useContext, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import PaymentFaq from "./PaymentFaq";
import useGetData from "../../../hooks/useGetData";
import { UserContext } from "../../../App";
import { useLocation } from "react-router-dom";
import StepOne from "../../../images/step-one.png";
import StepTwo from "../../../images/step-two.png";
import StepThree from "../../../images/step-three.png";
import PaymentLock from "../../../images/payment-lock.png";
import StripePayment from "./StripePayment";
import usePostData from "../../../hooks/usePostData";
import SparkMembership from "../../modals/SparkMembership";
import Unsubscribe from "../../modals/PricingAndPlan/Unsubscribe";

function MyPlan() {
  const current_plan = localStorage.getItem("plan_value");
  console.log('current_plan', current_plan);
  const [show, setShow] = useState(null);
  console.log("show", show);
  const [upgraded, setupgraded] = useState(false);
  const [NewPlanActivate, setNewPlanActivate] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  console.log("activePlan", activePlan);
  const [gotoSearch, setgotoSearch] = useState(false);
  const [trial, setTrial] = useState("");
  const [planSwitched, setPlanSwitched] = useState(false);
  const [clear, setClear] = useState("");

  const [pricingUrl, setPricingUrl] = useState(`plan-details`);
  const { data: PricingData } = useGetData(pricingUrl);
  console.log("PricingData", PricingData);

  const location = useLocation();
  const [planData, setPlanData] = useState(null);
  console.log("plandata..", planData);

  const { userRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isActivateSpark, setIsActivateSpark] = useState(false);
  const [unSubscribe, setUnSubscribe] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isPlanChanged, setIsPlanChanged] = useState(false);
  const user_role = localStorage.getItem("user_role");

  // pricing plan filtering based on the user type
  const pricingSortData = PricingData?.data;
  const employee_ids = ["employee"];
  const employer_ids = ["employer"];

  //Filter only the employee objects from the pricing array
  const employeeSortedData = pricingSortData?.filter((i) =>
    employee_ids.includes(i.applicable_user_type_name)
  );

  //Filter only the employer objects from the pricing array
  const employerSortedData = pricingSortData?.filter((i) =>
    employer_ids.includes(i.applicable_user_type_name)
  );

  //Convert the number of billing periods in months value to "12.34" format
  function monthlyTotal(price) {
    const em1 = price.toFixed(2);
    return em1;
  }

  // Check the trial period of the particular user
  const { data: trialStatus } = useGetData("flag-check");
  const isTrialed = trialStatus?.data?.is_trialed;
  const trialEnds = trialStatus?.data?.trial_ends;

  // Function to check the trial details
  const checkTrial = () => {
    if (trialEnds > 0) {
      if (isTrialed == true) {
        setTrial("Trial Ends in ${trialEnds} days");
      }
    }
  };

  useEffect(() => {
    if (PricingData) {
      setLoading(false);
      setShow(PricingData?.data[0]?.logged_users_plan);
      setActivePlan(PricingData?.data[0]?.logged_users_plan);
    } else {
      setLoading(true);
    }
  }, [PricingData]);

  // Spark membership Upgrade
  let token = localStorage.getItem("accessToken");
  const sparkUrl = "spark-member";
  const [sparkData, setSparkData] = useState(null);
  const { data: upgrateToSpark } = usePostData(sparkUrl, sparkData);
  const upgradeMe = () => {
    setSparkData({ Authorization: token });
  };

  //Function that triggered from parent to update the state
  const sparkFun = () => {
    setupgraded(true);
  };

  //Redirected to search after the plan upgrade.

  const ActivateNewPlan = () => {
    setNewPlanActivate(true);
  };

  const cancelSubscription = () => {
    setIsSubscription(true);
  };

  useEffect(() => {
    if(activePlan === null) {
      localStorage.setItem("plan_value", 0);
    }
  }, [activePlan])
  

  // Get the trial end date from the login
  let trial_ends = localStorage.getItem("trial_ends");
  console.log("trial_ends", trial_ends);

  //Redirect to dashboard page once the plan has been upgraded
  const newpath = localStorage.getItem("from-dashboard-path");

  if (newpath != null && (activePlan == 2 || activePlan == 3)) {
    setTimeout(() => {
      window.location.href = newpath;
      localStorage.removeItem("from-dashboard-path");
    }, 1000);
  }

  //Redirected to Claim job once the new plan has been activated
  const callback = () => {
    setIsPlanChanged(true);
    localStorage.removeItem("clickedPlan");

    
      if(availability_path) {
        window.location.href= availability_path;
        localStorage.removeItem("availability-path");
      }
  };

  // Set the redirect to the corresponding pages where we clicked from
  const availability_path = localStorage.getItem("availability-path");
  const emp_dashboard_path = localStorage.getItem("emp-dashboard-path");
  const job_post_path = localStorage.getItem("job-post-path");
  const hire_search_path = localStorage.getItem("hire-search-path");
  useEffect(() => {
    if(isPlanChanged) {
      if(availability_path) {
        window.location.href= availability_path;
        localStorage.removeItem("availability-path");
      }

      if(emp_dashboard_path) {
        window.location.href= emp_dashboard_path;
        localStorage.removeItem("emp-dashboard-path");
      }

      if(job_post_path) {
        window.location.href= job_post_path;
        localStorage.removeItem("job-post-path");
      }

      if(hire_search_path) {
        window.location.href= hire_search_path;
        localStorage.removeItem("hire-search-path");
      }

      localStorage.setItem("plan_value", activePlan);
    }
  }, [isPlanChanged])
  

  useEffect(() => {
    if(activePlan) 
      localStorage.setItem("plan_value", activePlan);
    }, [activePlan])
  

  const claimpath = localStorage.getItem("from-claimjob-path");
  if (claimpath != null && isPlanChanged) {
    setTimeout(() => {
      window.location.href = claimpath;
      setIsPlanChanged(false);
      localStorage.removeItem("from-claimjob-path");
      localStorage.setItem("success_redirect", true);
    }, 1000);
  }

  const clickedPlan = localStorage.getItem("clickedPlan");
  console.log("clickedPlan", clickedPlan);

  useEffect(() => {
    if (clickedPlan) {
      setClear(clickedPlan);
      setShow(clickedPlan);
      // setPlanData();
    }
  }, [clickedPlan, show]);

  useEffect(() => {
    if (planSwitched) {
      localStorage.removeItem("clickedPlan");
      setClear("");
    }
  }, [planSwitched]);
  console.log("show", show);

  useEffect(() => {
    if (clickedPlan) {
      if (user_role === "employer" && employerSortedData) {
        const selectedItemOne = employerSortedData?.filter(
          (i) => clickedPlan == i.name
        );
        setPlanData(selectedItemOne[0]);
      }
      if (user_role === "employee" && employeeSortedData) {
        const selectedItemTwo = employeeSortedData?.filter(
          (j) => clickedPlan == j.name
        );
        setPlanData(selectedItemTwo[0]);
      }
    }
  }, [clickedPlan, employeeSortedData, employerSortedData]);

  return (
    <div className="w-full space-x-0 space-y-2 lg:flex lg:space-x-2 lg:space-y-0">
      <div className="p-5 bg-white rounded-md shadow-lg w-full lg:w-5/12 2xl:w-3/12 space-y-5">
        {!loading ? (
          <div className="flex flex-col space-y-5">
            {userRole === "employee"
              ? employeeSortedData?.map((single_employee_item, index) => (
                  <div className="single-card" key={index}>
                    <div
                      className={`cursor-pointer p-7 rounded-lg shadow-lg 
                        ${
                          clickedPlan &&
                          single_employee_item.name == clickedPlan
                            ? "bg-main text-white"
                            : show == single_employee_item.name && !clickedPlan
                            ? "bg-main text-white"
                            : "bg-white text-black"
                        }`}
                      onClick={() => {
                        setShow(
                          single_employee_item.name == 1
                            ? 1
                            : single_employee_item.name == 2
                            ? 2
                            : 3
                        );
                        setPlanData(single_employee_item);
                        setPlanSwitched(true);
                      }}
                    >
                      <h4 className="text-xl font-bold mb-2">
                        {single_employee_item.plan_name} Membership
                      </h4>
                      <p
                        className={`font-semibold capitalize 
                        ${
                          clickedPlan &&
                          single_employee_item.name == clickedPlan
                            ? "text-white"
                            : show == single_employee_item.name && !clickedPlan
                            ? "text-white"
                            : "text-gray-600"
                        }`}
                      >
                        {single_employee_item.tag_line}
                      </p>

                      <div
                        className={`text-3xl font-bold py-5 
                        ${
                          clickedPlan &&
                          single_employee_item.name == clickedPlan
                            ? "text-white"
                            : show == single_employee_item.name && !clickedPlan
                            ? "text-white"
                            : "text-red-500"
                        }`}
                      >
                        ${single_employee_item.monthly_price}
                      </div>
                      <div className="">
                        {clickedPlan &&
                        single_employee_item.name == clickedPlan ? (
                          <button className="rounded-lg w-full text-lg font-semibold p-3 border border-red-500 bg-red-500 text-white hover:bg-white hover:text-main hover:border-transparent">
                            Selected
                          </button>
                        ) : (
                          <button
                            class={`rounded-lg w-full text-lg font-semibold p-3  ${
                              show == single_employee_item.name && !clickedPlan
                                ? "border border-red-500 bg-red-500 text-white hover:bg-white hover:text-main hover:border-transparent"
                                : "border border-main bg-transparent text-main hover:bg-main hover:text-white"
                            }`}
                          >
                            {single_employee_item.name == activePlan
                              ? "Current Plan"
                              : show == single_employee_item.name
                              ? "Selected"
                              : "Upgrade"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : employerSortedData?.map((single_employer_item, index) => (
                  <div className="single-card" key={index}>
                    <div
                      className={`cursor-pointer p-7 rounded-lg shadow-lg ${
                        clickedPlan && single_employer_item.name == clickedPlan
                          ? "bg-main text-white"
                          : show == single_employer_item.name && !clickedPlan
                          ? "bg-main text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => {
                        setShow(
                          single_employer_item.name == 1
                            ? 1
                            : single_employer_item.name == 2
                            ? 2
                            : 3
                        );
                        setPlanData(single_employer_item);
                        setPlanSwitched(true);
                      }}
                    >
                      <h4 className="text-xl font-bold mb-2">
                        {single_employer_item.plan_name} Membership
                      </h4>
                      <p
                        className={`font-semibold capitalize 
                        ${
                          clickedPlan &&
                          single_employer_item.name == clickedPlan
                            ? "text-white"
                            : show == single_employer_item.name && !clickedPlan
                            ? "text-white"
                            : "text-gray-600"
                        }`}
                      >
                        {single_employer_item.tag_line}
                      </p>

                      <div
                        className={`text-3xl font-bold py-5 
                        ${
                          clickedPlan &&
                          single_employer_item.name == clickedPlan
                            ? "text-white"
                            : show == single_employer_item.name && !clickedPlan
                            ? "text-white"
                            : "text-red-500"
                        }`}
                      >
                        ${single_employer_item.monthly_price}
                      </div>
                      <div className="">
                        {clickedPlan &&
                        single_employer_item.name == clickedPlan ? (
                          <button className="rounded-lg w-full text-lg font-semibold p-3 border border-red-500 bg-red-500 text-white hover:bg-white hover:text-main hover:border-transparent">
                            Selected
                          </button>
                        ) : (
                          <button
                            class={`rounded-lg w-full text-lg font-semibold p-3  ${
                              show == single_employer_item.name
                                ? "border border-red-500 bg-red-500 text-white hover:bg-white hover:text-main hover:border-transparent"
                                : "border border-main bg-transparent text-main hover:bg-main hover:text-white"
                            }`}
                          >
                            {single_employer_item.name == activePlan
                              ? "Current Plan"
                              : show == single_employer_item.name
                              ? "Selected"
                              : "Upgrade"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        ) : (
          <h5 className="p-3 text-black text-center">Loading...</h5>
        )}
      </div>

      <div className="p-5 bg-white rounded-md shadow-lg w-full lg:w-7/12 2xl:w-9/12">
        {/* <img src={StepOne} className="w-8 h-auto" />
            <h2 className="font-bold mt-2 mb-4 text-2xl">
              Confirm Your Billing
            </h2> */}
        {/* <h2 className="font-bold my-5 text-2xl">Confirm Your Billing</h2> */}
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}

        <h2 className="font-bold my-5 text-2xl">My Plan</h2>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}

        <SparkMembership
          isActivateSpark={isActivateSpark}
          setIsActivateSpark={setIsActivateSpark}
          sparkFun={sparkFun}
          setPricingUrl={setPricingUrl}
        />
        <Unsubscribe
          unSubscribe={unSubscribe}
          setUnSubscribe={setUnSubscribe}
          cancelSubscription={cancelSubscription}
        />

        {show == 1 &&
          (userRole === "employee"
            ? employeeSortedData?.map(
                (single_employee_item, index) =>
                  single_employee_item.plan_name === "Spark" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employee_item.plan_name} Membership
                            </span>{" "}
                            "{single_employee_item.tag_line}"
                          </p>
                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employee_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>
                          <hr className="mt-3" />
                          {single_employee_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employee_item.trial_period > 1
                                    ? single_employee_item.trial_period +
                                      " Days"
                                    : single_employee_item.trial_period +
                                      " Day"}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employee_item.monthly_price} Monthly
                                After{" "}
                                {single_employee_item.trial_period > 1
                                  ? single_employee_item.trial_period + " Days"
                                  : single_employee_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employee_item.billing_period_in_months >
                                1
                                  ? single_employee_item.billing_period_in_months +
                                    " Month"
                                  : single_employee_item.billing_period_in_months +
                                    "Month"}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employee_item.monthly_price *
                                    single_employee_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <hr className="mt-3" />

                        {single_employee_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <span className="font-semibold">
                                {single_employee_item.trial_period} Days Free
                                Trial, Cancel Anytime
                              </span>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in {trialEnds} days
                            </span>
                          </div>
                        )}
                      </div>
                      {single_employee_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              )
            : employerSortedData?.map(
                (single_employer_item, index) =>
                  single_employer_item.plan_name === "Spark" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employer_item.plan_name} Membership
                            </span>{" "}
                            "{single_employer_item.tag_line}"
                          </p>
                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employer_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>
                          <hr className="mt-3" />
                          {single_employer_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employer_item.trial_period > 1
                                    ? single_employer_item.trial_period +
                                      " Days "
                                    : single_employer_item.trial_period +
                                      " Day "}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employer_item.monthly_price} Monthly
                                After{" "}
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employer_item.billing_period_in_months >
                                1
                                  ? single_employer_item.billing_period_in_months +
                                    " Months "
                                  : single_employer_item.billing_period_in_months +
                                    " Month "}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employer_item.monthly_price *
                                    single_employer_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>

                        <hr className="mt-3" />

                        {single_employer_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <span className="font-semibold">
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period +
                                    " Day "}{" "}
                                Free Trial, Cancel Anytime
                              </span>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in{" "}
                              {trialEnds > 1
                                ? trialEnds + " days "
                                : trialEnds + " day "}
                            </span>
                          </div>
                        )}
                      </div>
                      {single_employer_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              ))}

        {show == 2 &&
          (userRole === "employee"
            ? employeeSortedData?.map(
                (single_employee_item, index) =>
                  single_employee_item.plan_name === "Torch" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employee_item.plan_name} Membership
                            </span>{" "}
                            {single_employee_item.billing_period_in_months >
                              1 && (
                              <>
                                (
                                <span className="text-main font-semibold">
                                  ${single_employee_item.monthly_price} /month
                                </span>{" "}
                                for{" "}
                                <span className="text-main font-semibold">
                                  {
                                    single_employee_item.billing_period_in_months
                                  }
                                </span>{" "}
                                months [billed one time as{" "}
                                <span className="text-main font-semibold">
                                  $
                                  {monthlyTotal(
                                    single_employee_item.monthly_price *
                                      single_employee_item.billing_period_in_months
                                  )}
                                </span>
                                ])
                              </>
                            )}{" "}
                            "{single_employee_item.tag_line}"
                          </p>

                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employee_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>

                          <hr className="mt-3" />

                          {single_employee_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employee_item.trial_period > 1
                                    ? single_employee_item.trial_period +
                                      " Days "
                                    : single_employee_item.trial_period +
                                      " Day "}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employee_item.monthly_price} Monthly
                                After{" "}
                                {single_employee_item.trial_period > 1
                                  ? single_employee_item.trial_period + " Days "
                                  : single_employee_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employee_item.billing_period_in_months >
                                1
                                  ? single_employee_item.billing_period_in_months +
                                    " Months"
                                  : single_employee_item.billing_period_in_months +
                                    " Month "}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employee_item.monthly_price *
                                    single_employee_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <hr className="mt-3" />

                        {single_employee_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <span className="font-semibold">
                                {single_employee_item.trial_period > 1
                                  ? single_employee_item.trial_period + " Days "
                                  : single_employee_item.trial_period +
                                    " Day "}{" "}
                                Free Trial, Cancel Anytime
                              </span>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in{" "}
                              {trialEnds > 1
                                ? trialEnds + " days "
                                : trialEnds + " day "}
                            </span>
                          </div>
                        )}
                      </div>

                      {single_employee_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              )
            : employerSortedData?.map(
                (single_employer_item, index) =>
                  single_employer_item.plan_name === "Torch" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employer_item.plan_name} Membership
                            </span>{" "}
                            {single_employer_item.billing_period_in_months >
                              1 && (
                              <>
                                (
                                <span className="text-main font-semibold">
                                  ${single_employer_item.monthly_price} /month
                                </span>{" "}
                                for{" "}
                                <span className="text-main font-semibold">
                                  {
                                    single_employer_item.billing_period_in_months
                                  }
                                </span>{" "}
                                months [billed one time as{" "}
                                <span className="text-main font-semibold">
                                  $
                                  {monthlyTotal(
                                    single_employer_item.monthly_price *
                                      single_employer_item.billing_period_in_months
                                  )}
                                </span>
                                ])
                              </>
                            )}{" "}
                            "{single_employer_item.tag_line}"
                          </p>
                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employer_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>

                          <hr className="mt-3" />
                          {single_employer_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employer_item.trial_period > 1
                                    ? single_employer_item.trial_period +
                                      " Days "
                                    : single_employer_item.trial_period +
                                      " Day "}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employer_item.monthly_price} Monthly
                                After{" "}
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employer_item.billing_period_in_months >
                                1
                                  ? single_employer_item.billing_period_in_months +
                                    " Months"
                                  : single_employer_item.billing_period_in_months +
                                    " Month "}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employer_item.monthly_price *
                                    single_employer_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <hr className="mt-3" />

                        {single_employer_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <p className="font-semibold">
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period +
                                    " Day "}{" "}
                                Free Trial, Cancel Anytime
                              </p>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in{" "}
                              {trialEnds > 1
                                ? trialEnds + " days "
                                : trialEnds + " day "}
                            </span>
                          </div>
                        )}
                      </div>

                      {single_employer_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              ))}

        {show == 3 &&
          (userRole === "employee"
            ? employeeSortedData?.map(
                (single_employee_item, index) =>
                  single_employee_item.plan_name === "Bonfire" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employee_item.plan_name} Membership
                            </span>{" "}
                            {single_employee_item.billing_period_in_months >
                              1 && (
                              <>
                                (
                                <span className="text-main font-semibold">
                                  ${single_employee_item.monthly_price} /month
                                </span>{" "}
                                for{" "}
                                <span className="text-main font-semibold">
                                  {
                                    single_employee_item.billing_period_in_months
                                  }
                                </span>{" "}
                                months [billed one time as{" "}
                                <span className="text-main font-semibold">
                                  $
                                  {monthlyTotal(
                                    single_employee_item.monthly_price *
                                      single_employee_item.billing_period_in_months
                                  )}
                                </span>
                                ])
                              </>
                            )}{" "}
                            "{single_employee_item.tag_line}"
                          </p>
                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employee_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>

                          <hr className="mt-3" />
                          {single_employee_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employee_item.trial_period > 1
                                    ? single_employee_item.trial_period +
                                      " Days "
                                    : single_employee_item.trial_period +
                                      " Day "}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employee_item.monthly_price} Monthly
                                After{" "}
                                {single_employee_item.trial_period > 1
                                  ? single_employee_item.trial_period + " Days "
                                  : single_employee_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employee_item.billing_period_in_months >
                                1
                                  ? single_employee_item.billing_period_in_months +
                                    " Months"
                                  : single_employee_item.billing_period_in_months +
                                    " Month "}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employee_item.monthly_price *
                                    single_employee_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <hr className="mt-3" />

                        {single_employee_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <span className="font-semibold">
                                {single_employee_item.trial_period > 1
                                  ? single_employee_item.trial_period + " Days "
                                  : single_employee_item.trial_period +
                                    " Day "}{" "}
                                Free Trial, Cancel Anytime
                              </span>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in{" "}
                              {trialEnds > 1
                                ? trialEnds + " days "
                                : trialEnds + " day "}
                            </span>
                          </div>
                        )}
                      </div>

                      {single_employee_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              )
            : employerSortedData?.map(
                (single_employer_item, index) =>
                  single_employer_item.plan_name === "Bonfire" && (
                    <>
                      <div
                        className=" bg-white rounded-md shadow-lg my-6"
                        key={index}
                      >
                        <div className="px-7 pt-7">
                          <p>
                            <span className="font-semibold">
                              {single_employer_item.plan_name} Membership
                            </span>{" "}
                            {single_employer_item.billing_period_in_months >
                              1 && (
                              <>
                                (
                                <span className="text-main font-semibold">
                                  ${single_employer_item.monthly_price} /month
                                </span>{" "}
                                for{" "}
                                <span className="text-main font-semibold">
                                  {
                                    single_employer_item.billing_period_in_months
                                  }
                                </span>{" "}
                                months [billed one time as{" "}
                                <span className="text-main font-semibold">
                                  $
                                  {monthlyTotal(
                                    single_employer_item.monthly_price *
                                      single_employer_item.billing_period_in_months
                                  )}
                                </span>
                                ])
                              </>
                            )}{" "}
                            "{single_employer_item.tag_line}"
                          </p>
                          <hr className="mt-3" />

                          <ul className="space-y-2 my-5">
                            {single_employer_item.description.map(
                              (single_feature, index) => (
                                <li className="flex" key={index}>
                                  <div>
                                    <BsCheck2Circle
                                      className="mr-3 mt-1 text-main stroke-1"
                                      size="1.2em"
                                    />
                                  </div>
                                  {single_feature.description}
                                </li>
                              )
                            )}
                          </ul>

                          <hr className="mt-3" />
                          {single_employer_item.trial_period > 0 &&
                          isTrialed == false ? (
                            <div>
                              <div className="flex justify-between my-5 text-main font-bold text-xl">
                                <p>
                                  Total{" "}
                                  {single_employer_item.trial_period > 1
                                    ? single_employer_item.trial_period +
                                      " Days "
                                    : single_employer_item.trial_period +
                                      " Day "}
                                  Charge
                                </p>
                                <p>$0.00</p>
                              </div>
                              <p className="font-semibold text-red-500">
                                (${single_employer_item.monthly_price} Monthly
                                After{" "}
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period + " Day "}
                                Trial)
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between my-5 text-main font-bold text-xl">
                              <p>
                                Total{" "}
                                {single_employer_item.billing_period_in_months >
                                1
                                  ? single_employer_item.billing_period_in_months +
                                    " Months"
                                  : single_employer_item.billing_period_in_months +
                                    " Month "}
                                Charge
                              </p>
                              <p>
                                $
                                {monthlyTotal(
                                  single_employer_item.monthly_price *
                                    single_employer_item.billing_period_in_months
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <hr className="mt-3" />

                        {single_employer_item.trial_period > 0 &&
                          isTrialed == false && (
                            <div className="p-7">
                              <span className="font-semibold">
                                {single_employer_item.trial_period > 1
                                  ? single_employer_item.trial_period + " Days "
                                  : single_employer_item.trial_period +
                                    " Day "}{" "}
                                Free Trial, Cancel Anytime
                              </span>
                            </div>
                          )}
                        {isTrialed == true && trialEnds > 0 && (
                          <div className="p-7">
                            <span className="font-semibold">
                              Trial Ends in{" "}
                              {trialEnds > 1
                                ? trialEnds + " days "
                                : trialEnds + " day "}
                            </span>
                          </div>
                        )}
                      </div>

                      {single_employer_item.name == activePlan &&
                        (isSubscription ? (
                          <div className="text-main font-bold">
                            Plan unsubscribed successfully!
                          </div>
                        ) : (
                          <button
                            onClick={() => setUnSubscribe(true)}
                            className="rounded-lg text-lg font-semibold px-9 py-4  border border-red-500 bg-red-500 text-white hover:bg-main hover:text-white hover:border-transparent"
                          >
                            Cancel Subscription
                          </button>
                        ))}
                    </>
                  )
              ))}

        <div>
          {planData == null ||
            (planData?.name != activePlan && (
              <>
                <div className="mt-10">
                  {/* <img src={StepTwo} className="w-8 h-auto" />
                    <h2 className="font-bold mt-2 mb-4 text-2xl">
                      Review Your Order
                    </h2> */}
                  {/* <p>
                      Nulla Lorem mollit cupidatat irure. Laborum magna nulla
                      duis cillum dolor.
                    </p> */}
                  {/* <div className=" bg-white rounded-md shadow-lg my-6">
                      <ul className="space-y-3 p-7">
                        <li className="flex">
                          <span className="w-3 h-3 bg-main rounded-full mr-3 mt-1.5 flex-none"></span>
                          Erat pellentesque adipiscing commodo elit at imperdiet
                          dui.
                        </li>
                        <li className="flex">
                          <span className="w-3 h-3 bg-main rounded-full mr-3 mt-1.5 flex-none"></span>
                          Erat pellentesque adipiscing commodo elit at imperdiet
                          dui.
                        </li>
                        <li className="flex">
                          <div className="w-3 h-3 bg-main rounded-full mr-3 mt-1.5 flex-none"></div>
                          <p>
                            Duis ac augue ut lectus congue luctus. Vivamus eu
                            lacus vestibulum, luctus ante dignissim, interdum
                            orci. Donec in ullamcorper lacus, molestie accumsan
                            tortor.
                          </p>
                        </li>
                      </ul>
                      <hr className="mt-3" />
                      <p className="px-7 py-5">
                        By placing this order you agree to our{" "}
                        <a className="text-main font-semibold" href="#">
                          Terms of Service
                        </a>
                        . To ensure continued service, we'll store and update
                        (e.g., upon expiration) your payment method. Learn about{" "}
                        <a className="text-main font-semibold" href="#">
                          How to Cancel
                        </a>{" "}
                        and our{" "}
                        <a className="text-main font-semibold" href="#">
                          Refund Policy
                        </a>
                        . Prices may change.
                      </p>
                    </div> */}
                </div>
                <div className="mt-10">
                  {/* <img src={StepThree} className="w-8 h-auto" /> */}
                  <h2 className="font-bold mt-2 mb-4 text-2xl">Payment</h2>
                  {/* <p>
                      Nulla Lorem mollit cupidatat irure. Laborum magna nulla
                      duis cillum dolor.
                    </p> */}
                  <hr className="my-3 " />
                  <div className=" bg-white my-6">
                    <div>
                      {/* <button class="bg-main text-white text-lg md:text-xl font-semibold p-5 border border-main rounded-lg w-full md:w-8/12 xl:w-6/12 2xl:w-4/12 block text-center mx-auto">
                    Confirm Payment
                  </button> */}
                      <StripePayment
                        planData={planData}
                        setPricingUrl={setPricingUrl}
                        setgotoSearch={setgotoSearch}
                        callback={callback}
                      />
                      <div className="flex items-center mt-6 justify-center">
                        <img src={PaymentLock} className="w-5 h-auto mr-3" />
                        <p className="text-main font-semibold">
                          Secure Checkout
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>

        {/* <div className="bg-white rounded-md shadow-lg mt-10 mb-5 pricing-faq">
          <PaymentFaq />
        </div> */}
      </div>
    </div>
  );
}

export default MyPlan;
