import useGetData from "../../hooks/useGetData";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

function AccessProfile() {
  //Get the token_id from the redirected url
  const userToken = new URLSearchParams(document.location.search);
  const token = userToken.get("token_id");

  const url = `user/access-profile?token_id=${token}`;
  const [errors, setErrors] = useState(null);
  const { data, error, loading } = useGetData(url);

  useEffect(() => {
    if (data) {
      localStorage.setItem("accessToken", data.data.token);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("user_id", data.data.user_id);
      localStorage.setItem("user_role", data.data.user_role);
      localStorage.setItem("latitude", data.data["latitude "]);
      localStorage.setItem("longitude", data.data["longitude "]);
      localStorage.setItem("plan_value", data.data.plan_value);

      window.location.href = "/profile/edit";
    }
  }, [data]);

  useEffect(() => {
    if (error?.non_field_error) {
      setErrors(error?.non_field_error);
    }
  }, [error, errors]);

  return (
    <div className="minHeight mb-5 container-wrapper">
      {loading && (
        <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
      )}
      {error && <p className="py-5 text-red-500">{errors}</p>}
    </div>
  );
}

export default AccessProfile;
