import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import usePostData from "../../hooks/usePostData";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fields, checkFields } from "../../helpers/SignUpSchema";

import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

const SignUp = () => {
  const history = useHistory();

  const url = "users";
  const loader = <span className="text-white">Sending...</span>;

  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  const [revealConfirmPassword, toggleConfirmPasswordView] = useState(false);
  const [revealPassword, togglePasswordView] = useState(false);

  console.log(values.user_role, "values");
  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };

  const updatePhone = (value, country) => {
    localStorage.setItem("country", country.countryCode);
    setValue({ ...values, phone: value });
    console.log(`country`, country.dialCode);
  };

  const processForm = (e) => {
    console.log(values)
    e.preventDefault();
    const errList = checkFields(values);
    if (!errList) {
      setFormData(values);
    } else {
      setErrors(errList);
    }
  };

  const { data, error, loading } = usePostData(url, formdata);

  useEffect(() => {
    if (data) {
      setValue(fields);
      setMessage({ message: data.message, class: "success" });
      setTimeout(() => {
        history.push(
          `/activate-account/${data.data.uid}/${data.data.temp_token}`
        );
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  console.log(errors, "aaa");
  return (
    <div className="minHeight relative flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg  lg:w-8/12 xl:w-6/12">
        {commonMessage && (
          <p className={commonMessage.class}>{commonMessage.message}</p>
        )}

        <div className="text-center block mb-9">
          <h1 className="my-5 text-3xl font-semibold text-black text-center">
            Create Account
          </h1>
          <div className="mb-5 md:mb-0 custom-signup">
            <button
              name="user_role"
              value="employee"
              onClick={(e) => updateValue(e)}
              className={`btn ${
                values.user_role === "employee" ? "btn-main" : ""
              } mb-3 md:mb-0`}
            >
              {" "}
              Job Seekers
            </button>
            <button
              name="user_role"
              value="employer"
              onClick={(e) => updateValue(e)}
              className={`btn ${
                values.user_role === "employer" ? "btn-main" : ""
              } ml-3`}
            >
              {" "}
              Employer
            </button>
          </div>
        </div>

        {values.user_role && (
          <form>
            {values.user_role === "employer" && (
              <div className="mb-3">
                <label
                  className="block mb-2 text-sm font-semibold text-gray-500"
                  htmlFor="email"
                >
                  Company Name
                </label>
                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={values.company_name}
                  autoComplete="off"
                  className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                  placeholder="Ring Of Hires"
                  onChange={(e) => updateValue(e)}
                />
                <span className="form_error_message">
                  {errors?.company_name}
                </span>
              </div>
            )}
            <div className="mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={values.email}
                autoComplete="off"
                className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                placeholder="example@gmail.com"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">{errors?.email}</span>
            </div>

            <div className="mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="phone"
              >
                Phone no
              </label>
              <PhoneInput
                country={"us"}
                inputClass="max-h-11 p-0 m-0"
                containerClass="h-11 p-0"
                //country={values.country_code}
                value={values.phone}
                onChange={(phone, country) => updatePhone(phone, country)}
              />
              <span className="form_error_message">{errors?.phone}</span>
            </div>

            <div className="relative mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type={revealPassword ? "text" : "password"}
                name="password"
                id="password"
                value={values.password}
                autoComplete="off"
                className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                placeholder="********"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">{errors?.password}</span>
              <HiOutlineEye
                onClick={() => togglePasswordView(!revealPassword)}
                className={`password_eye ${revealPassword ? "hidden" : ""}`}
              />
              <HiOutlineEyeOff
                onClick={() => togglePasswordView(!revealPassword)}
                className={`password_eye ${revealPassword ? "" : "hidden"}`}
              />
            </div>

            <div className="relative mb-3">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="confirm_password"
              >
                Confirm Password
              </label>
              <input
                type={revealConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                value={values.confirmPassword}
                autoComplete="off"
                className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                placeholder="********"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">
                {errors?.confirmPassword}
              </span>
              <HiOutlineEye
                onClick={() =>
                  toggleConfirmPasswordView(!revealConfirmPassword)
                }
                className={`password_eye ${
                  revealConfirmPassword ? "hidden" : ""
                }`}
              />
              <HiOutlineEyeOff
                onClick={() =>
                  toggleConfirmPasswordView(!revealConfirmPassword)
                }
                className={`password_eye ${
                  revealConfirmPassword ? "" : "hidden"
                }`}
              />
            </div>

            <div className="mb-3">
              <button
                disabled={loading ? true : false}
                onClick={(e) => processForm(e)}
                className="w-full btn btn-main"
              >
                {loading ? loader : "Create Account"}
              </button>
            </div>

            {/* <div className="flex items-center justify-between">
            <div className="w-full h-0 mr-6 border-t border-gray-200"></div>
            <div className="text-sm text-gray-400">Or</div>
            <div className="w-full h-0 ml-6 border-t border-gray-200"></div>
          </div> */}

            {/* <div className="flex items-center justify-center my-10 mr-2">
            <div className="p-2 rounded-md shadow cursor-pointer  h-15 w-15 hover:shadow-lg">
              <img
                src="images/google.png"
                className="w-8 h-auto"
                alt="Sign Up with Google"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer  h-15 w-15 hover:shadow-lg">
              <img
                src="images/apple.png"
                className="w-8 h-auto"
                alt="Sign Up with Apple"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer  h-15 w-15 hover:shadow-lg">
              <img
                src="images/facebook.png"
                className="w-8 h-auto"
                alt="Sign Up with Facebook"
              />
            </div>
          </div> */}
          </form>
        )}
        <div className="my-5 text-center">
          <p className="text-sm text-gray-500">
            Already have an account?{" "}
            <a className="text-main" href="/login">
              Sign In
            </a>{" "}
          </p>

          <a className="mt-5 text-sm text-main" href="/help-center">
            Help Center
          </a>

          <p className="mt-10 text-sm text-gray-500">
            By signing into account, you agree to Ring of Hires{" "}
            <a className="text-main" href="/terms-of-service">
              Terms of Service
            </a>{" "}
            and consent to our{" "}
            <a className="text-main" href="/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
