import { useContext, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import useGetData from "../../hooks/useGetData";
import { LookingToHireContext, MapContext } from "../../App";
import {
  AiOutlineSearch,
  AiOutlineEnvironment,
  AiOutlineClose,
} from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Select, { components } from "react-select";
import Highlighter from "react-highlight-words";
import { IconContext } from "react-icons";
import locationIcon from "../../images/Home/locationIcon.svg";
import Map from "../elements/Map";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import NewsLetterPopup from "../modals/NewsLetterPopup";
import Cookies from "universal-cookie";
import HomeJobAlert from "../modals/HomeJobAlert";
import NewHome from "./Home/NewHome";

const Home = () => {
  const [lookingToHire, setLookingToHire] = useContext(LookingToHireContext);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showJobAlertModal, setShowJobAlertModal] = useState(false);
  const [currentLoc, setCurrentLoc] = useState(false);
  const [pos, setPos] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
    circles,
    setCircles,
    clusters,
    setClusters,
    employerJobs,
    setEmployerJobs,
    allemployerJobs,
    setAllEmployerJobs,
  ] = useContext(MapContext);
  const [markerFlag] = useState(false);
  const [position, setPosition] = useState({
    name: null,
    id: null,
  });
  const [positionData, setPositionData] = useState([{}]);
  const [positionAll, setPositionAll] = useState([{}]);
  const [filteredData, setFilteredData] = useState([]);
  const [showAllList, setShowAllList] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [positionClear, setPositionClear] = useState(tempPostion !== null);
  const [locationClear, setLocationClear] = useState(false);
  const [autoZoomFalse, setAutoZoomFalse] = useState(true);
  const cookies = new Cookies();
  let cookieLoc = cookies.get("locationHistory");
  const [locationHistory, setLocationHistory] = useState(
    cookieLoc ? cookieLoc : []
  );

  let cookiePos = cookies.get("positionHistory");
  const [positionHistory, setPositionHistory] = useState(
    cookiePos ? cookiePos : []
  );
  const { Option } = components;

  const history = useHistory();

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const url_category = params.get("category");
  const url_address = params.get("address");
  useEffect(() => {
    if (tempCoord) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: tempCoord.lat,
        lng: tempCoord.lan,
      }));
    } else {
      setMarkerLocation({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }

    if (homeCoordinates?.position) {
      setPosition(homeCoordinates.position);
    } else if (url_address) {
      setAddress(url_address);
      handleSelect(url_address);
      setLocationClear(true);
    } else {
      setAddress("");
    }
    if (url_category) {
      setPositionClear(true);
      setTempPosition({ id: 1, name: url_category });
    }
  }, []);

  const handleGeolocationMarker = (lat, lng) => {
    if (lat == null && lng == null) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        // lat: lat,
        // lng: lng,
        lat: marker.lat,
        lng: marker.lng,
      }));
    } else {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: lat,
        lng: lng,
        // lat: marker.lat,
        // lng: marker.lng,
      }));
    }
  };
  // useEffect(() => {
  //    console.log("render");
  //   handleGeolocationMarker();
  // }, []);

  const { data: positionList } = useGetData("category-list");

  //Check whether the particular user already downloaded the newsletter pdf or not
  const user_id = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const { data: downloadStatus } = useGetData("flag-check");
  const isdownloaded = downloadStatus?.data?.is_downloaded;

  //Check whether the particular user submitted the job alert popup or not
  const isJobAlertSubmitted = downloadStatus?.data?.new_jobs_email_pop_up_flag;

  //remove the token passing for the public user in header
  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  //Cookie function declaration
  const getCookie = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
    null;

  //Newsletter popup for loggedin users
  useEffect(() => {
    setShowPopup(false);
    const isPopupShow = getCookie("isPopupShow");
    if (!isPopupShow && isdownloaded == false) {
      setTimeout(() => {
        setShowPopup(true);
      }, 3000);
    }
  }, [isdownloaded]);

  //Newsletter popup for non-loggedin users
  useEffect(() => {
    if (!user_id) {
      const isPopupShow = getCookie("isPopupShow");

      if (!isPopupShow) {
        setTimeout(() => {
          setShowPopup(true);
        }, 3000);
      }
    }
  }, []);

  //Job Alert popup for logged in users
  useEffect(() => {
    setShowJobAlertModal(false);
    const isJobAlertShow = getCookie("isJobAlertShow");

    if (!isJobAlertShow && isJobAlertSubmitted == false) {
      setTimeout(() => {
        setShowJobAlertModal(true);
      }, 3000);
    }
  }, [isJobAlertSubmitted]);

  //Job Alert popup for non logged in users
  useEffect(() => {
    if (!user_id) {
      const isJobAlertShow = getCookie("isJobAlertShow");

      if (!isJobAlertShow) {
        setTimeout(() => {
          setShowJobAlertModal(true);
        }, 3000);
      }
    }
  }, []);

  useEffect(() => {
    setPositionData(positionList?.data);
  }, [positionList]);

  //Sorted the position array of object in ascending order
  const sortedPositionData = positionData?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
  // Onclick function to show the position dropdown list
  const showPositionList = () => {
    setShowAllList(true);
  };

  /* Close the position dropdown on outside click */
  // function positionHide() {
  //   setHideDropdown(true);
  //   console.log("outside click");
  // }
  const positionListRef = useRef();
  const selectPositionInputRef = useRef();
  const selectAddressInputRef = useRef();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        showAllList &&
        positionListRef.current &&
        !positionListRef.current.contains(e.target)
      ) {
        setShowAllList(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [showAllList]);

  const handleChange = (address) => {
    setAddress(address);
    setLocationClear(true);
  };

  const handlePosition = (e) => {
    const val = e.target.value;
    setPos(val);
    if (pos !== "") {
      setShowAllList(false);
    }
    if (val !== "") {
      const filteredPositions = positionData.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });

      setFilteredData(filteredPositions);
    } else {
      setFilteredData([]);
    }

    setPosition({ ...position, name: val });
  };
  const clubArray = (arr) => {
    return arr.reduce((acc, val, ind) => {
      const index = acc.findIndex((el) => el.user_id === val.user_id);
      if (index !== -1) {
        const key = Object.keys(val)[1];
        acc[index][key] = val[key];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    let json_str = JSON.stringify(locationHistory);
    cookies.set("locationHistory", json_str);
  }, [locationHistory]);

  const handleClickLoc = (address) => {
    setAutoZoomFalse(true);
    setAddress(address);
    setLocationClear(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };
  const removeLocationHistory = (address) => {
    setLocationHistory(locationHistory.filter((item) => item !== address));
  };
  const handleSelect = (address) => {
    setAutoZoomFalse(true);
    if (!locationHistory.includes(address) && locationHistory.length < 5) {
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (
      !locationHistory.includes(address) &&
      locationHistory.length === 5
    ) {
      locationHistory.pop();
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (locationHistory.includes(address)) {
      const index = locationHistory.indexOf(address);
      if (index > -1) {
        locationHistory.splice(index, 1);
      }
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    }
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
    setTempCoord({
      lat: location.lat,
      lan: location.lng,
    });
  };

  let apiTimeout;

  useEffect(() => {
    setLoading(true);
  }, [homeCoordinates, tempPostion]);

  const getJobData = async (coordinates) => {
    let token = localStorage.getItem("accessToken");
    clearTimeout(apiTimeout);

    setHomeCoordinates((prev) => ({
      ...prev,
      coordinates: coordinates.coordinates,
    }));
    coordinates.position_name = tempPostion?.name;

    if (lookingToHire) {
      apiTimeout = setTimeout(() => {
        axios
          .post(
            `${process.env.REACT_APP_SEARCH_URL}/users`,
            coordinates
            // {
            //   headers: headerData,
            // }
          )
          .then((res) => {
            let allEmp = [...res.data];
            if (res.data && user_id && userRole === "employer") {
              axios
                .get(
                  `${process.env.REACT_APP_SEARCH_URL}/jobs/nearest-jobs/${user_id}`,
                  {
                    headers: headerData,
                  }
                )
                .then((res) => {
                  if (res.data) {
                    let hires = clubArray(res.data);

                    hires.sort((a, b) => b.is_premium - a.is_premium);
                    const common = hires.filter((c) =>
                      allEmp.some((s) => s.user_id === c.user_id)
                    );
                    const rest = [...common, ...allEmp].reduce(
                      (c, n) =>
                        c.find((el) => el.user_id == n.user_id) ? c : [...c, n],
                      []
                    );

                    let tempmapData = rest.map(
                      ({ longtitude: lng, latitude: lat, ...rest }) => ({
                        lng,
                        lat,
                        ...rest,
                      })
                    );
                    setMapData(tempmapData);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            } else {
              // setCommonJobList(allEmp)
              let tempmapData = allEmp.map(
                ({ longtitude: lng, latitude: lat, ...rest }) => ({
                  lng,
                  lat,
                  ...rest,
                })
              );
              setMapData(tempmapData);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });
        // users Jobs
        if (userRole === "employer")
          axios
            .get(
              `${process.env.REACT_APP_API_URL}employers/${user_id}/jobs`,
              coordinates,
              {
                headers: headerData,
              }
            )
            .then((res) => {
              let jobs = [...res.data.data];
              jobs = jobs.map(({ location_details, ...rest }) => ({
                lng: location_details.longtitude,
                lat: location_details.latitude,
                location_details,
                ...rest,
              }));
              setEmployerJobs(jobs);
              setAllEmployerJobs(jobs);
            })
            .catch((err) => {
              console.log("err ", err);
            });
      }, 500);
    } else {
      if (zoomlvl == 4) {
        coordinates.step = 2.5;
      } else if (zoomlvl == 5) {
        coordinates.step = 1.5;
      } else if (zoomlvl == 6) {
        coordinates.step = 0.8;
      } else if (zoomlvl == 7) {
        coordinates.step = 0.4;
      }

      // if(zoomlvl <= 6 && !lookingToHire) coordinates.step = step;

      if (zoomlvl > 7) {
        axios
          .post(
            `${process.env.REACT_APP_SEARCH_URL}/alljobs/count`,
            coordinates,
            {
              headers: headerData,
            }
          )
          .then((res) => {
            if (res?.data?.total_count > 1000) {
              coordinates.step = zoomlvl < 10 ? 0.2 : 0.1;
            }
            apiTimeout =
              res?.data?.total_count > 1000
                ? setTimeout(() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          setSameLocationData([]);
                          setGroupData([...res.data.data]);
                          setMapData([]);
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500)
                : setTimeout(() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          let jobs = [...res.data];

                          // //Add data with same locations in arr
                          // let arr = [];

                          // for (let i = 0; i < jobs.length; i++) {
                          //   for (let j = i + 1; j < jobs.length; j++) {
                          //     if (
                          //       jobs[i].latitude === jobs[j].latitude &&
                          //       jobs[i].longtitude === jobs[j].longtitude
                          //     ) {
                          //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
                          //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
                          //     }
                          //   }
                          // }

                          // //seperate same locations into different arrays
                          // let obj = {};
                          // let jobData = res.data;
                          // for (let i = 0; i < jobData.length; i++) {
                          //   obj[i] = [];
                          //   for (let j = i + 1; j < jobData.length; j++) {
                          //     if (
                          //       jobData[i].latitude === jobData[j].latitude &&
                          //       jobData[i].longtitude === jobData[j].longtitude
                          //     ) {
                          //       if (!obj[i].includes(jobData[i])) {
                          //         obj[i].push(jobData[i]);
                          //       }

                          //       obj[i].push(jobData[j]);
                          //       jobData.splice(j, 1);
                          //       j = j - 1;
                          //     }
                          //   }
                          // }

                          // // deleting empty arrays in object
                          // Object.keys(obj).forEach((i) => {
                          //   if (obj[i].length === 0) {
                          //     delete obj[i];
                          //   }
                          // });

                          // //group same location data by company_name
                          // Object.keys(obj).forEach((i) => {
                          //   let key = "company_name";

                          //   obj[i].modal_data = obj[i].reduce((hash, obj) => {
                          //     if (obj[key] === undefined) return hash;
                          //     return Object.assign(hash, {
                          //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
                          //     });
                          //   }, {});
                          // });

                          // //Remove same location data from other datas
                          // const filteredJobData = jobs.filter(
                          //   (i) => !arr.find((f) => f.id === i.id)
                          // );

                          //sorting premium jobs
                          jobs.sort((a, b) => b.is_premium - a.is_premium);

                          // setSameLocationData(obj);

                          // for clustering replacing lng for longitude and lat for latitude
                          jobs = jobs.map(
                            ({ longtitude: lng, latitude: lat, ...rest }) => ({
                              lng,
                              lat,
                              ...rest,
                            })
                          );
                          setMapData(jobs);
                          setGroupData([]);
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500);
          });
      } else {
        apiTimeout = setTimeout(() => {
          axios
            .post(
              `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
              coordinates,
              {
                headers: headerData,
              }
            )
            .then((res) => {
              if (res.data) {
                setSameLocationData([]);
                setGroupData([...res.data.data]);
                setMapData([]);
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        }, 500);
      }
    }
  };

  const initRef = useRef(true);
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      getJobData(homeCoordinates);
    }
  }, [lookingToHire, tempPostion]);

  const Indicator = () => {
    return (
      <div className="rounded w-10 h-10 right-0 blue_box bg-main top-1 p-1 siftright">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (pos === "") {
              setShowAllList((old) => !old);
            }
          }}
        >
          <MdOutlineKeyboardArrowDown
            className="mx-auto w-7 h-full"
            color="fff"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    let json_str = JSON.stringify(positionHistory);
    cookies.set("positionHistory", json_str);
  }, [positionHistory]);

  const updatePositionSelectValue = (value) => {
    setAutoZoomFalse(true);
    if (value) {
      let filtered = positionData.filter((ele) => {
        return ele.name != value.name;
      });
      setPositionData(filtered);
    }
    if (value) {
      if (
        !positionHistory.some((ele) => ele.name === value.name) &&
        positionHistory.length !== 3
      ) {
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (
        !positionHistory.some((ele) => ele.name === value.name) &&
        positionHistory.length === 3
      ) {
        let temp = positionHistory.pop();
        if (!positionData.some((ele) => ele.name === temp.name)) {
          setPositionData((oldArray) => [temp, ...oldArray]);
        }
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (positionHistory.some((ele) => ele.name === value.name)) {
        let filtered = positionHistory.filter((ele) => {
          return ele.name != value.name;
        });
        setPositionHistory(filtered);
        setPositionHistory((oldArray) => [value, ...oldArray]);
      }
    }
    setTempPosition(value);
    if (value) setPositionClear(true);
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? "grey" : "red",
    }),
    menu: (provided, state) => ({
      ...provided,
      // textColor: state.isFocused ? 'grey' : 'red',
      // backgroundColor:"red"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      color: positionHistory.some((ele) => ele.name === state.data.name)
        ? "#047cec"
        : "black",
      backgroundColor: state.isFocused ? "#deebff" : "#fff",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lan: position.coords.longitude,
        });
        if (
          !localStorage.getItem("longitude") ||
          !localStorage.getItem("latitude")
        ) {
          localStorage.setItem("longitude", position.coords.longitude);
          localStorage.setItem("latitude", position.coords.latitude);
        }
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  const handleClickCurrentLoc = () => {
    setAutoZoomFalse(true);
    if (currentLocation) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: currentLocation.lat,
        lng: currentLocation.lan,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lan}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          setCurrentLoc(false);
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          // handleSelect(route?.formatted_address)
          setAddress("Current Location");
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const currentLocModalref = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        currentLoc &&
        currentLocModalref.current &&
        !currentLocModalref.current.contains(e.target)
      ) {
        setCurrentLoc(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [currentLoc]);
  const openCurrentLoc = (e) => {
    if (e.target.localName !== "img") {
      setCurrentLoc(true);
    }
  };

  function formatOptionLabel({ name }, { inputValue }) {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={name}
        highlightClassName="text-red-500  border-red-500 bg-transparent"
      />
    );
  }

  const customFilter = (option, searchText) => {
    const getSearctextPos = (string) => {
      if (!positionHistory.some((ele) => ele.name === string.name)) {
        return string?.name?.toLowerCase().indexOf(searchText.toLowerCase());
      }
    };
    let temp = positionAll?.sort((a, b) => {
      return getSearctextPos(a) - getSearctextPos(b);
    });
    setPositionAll(temp);
    if (
      option?.data?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data?.alias.includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (positionData) {
      setPositionAll([
        ...positionHistory,
        ...positionData.filter(
          (x) => !positionHistory.filter((y) => y.name === x.name).length
        ),
      ]);
    }
  }, [positionData, positionHistory]);

  return (
    <>
      {/* {lookingToHire ? ( */}
        <NewHome />
      {/* ) : (
        <div className="block text-lg">Employee</div>
      )} */}
    </>
  );
};

export default Home;
