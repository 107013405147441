import { useEffect, useState } from "react";
import Pointer from "../../../images/Dashboard/Pointer.png";
import Employee from "../../../images/Dashboard/Employee.png";
import Case from "../../../images/Dashboard/Case.png";
import Tick from "../../../images/Dashboard/Tick.png";
import useGetData from "../../../hooks/useGetData";
import { AiOutlineFieldTime } from "react-icons/ai";

function Overview({ setClickData }) {
  const user_id = localStorage.getItem("user_id");
  const [isSelected, setIsSelected] = useState("alltime");
  const loader = <span className="text-base">Loading...</span>;

  // API for total jobs posted & active jobs
  const [urlActiveJobs, setUrlActiveJobs] = useState("");
  const { data: ActiveJobsData, loading: activeJobsLoading } =
    useGetData(urlActiveJobs);

  // API for total application count
  const [urlJobsCount, setUrlJobsCount] = useState();
  const { data: JobsCountsData, loading: JobsCountLoading } =
    useGetData(urlJobsCount);

  // API for total clicks count
  const [jobsClick, setJobsClick] = useState();
  const { data: EachJobClicks, loading: JobsClickLoading } =
    useGetData(jobsClick);

  useEffect(() => {
    setClickData(EachJobClicks);
  }, [EachJobClicks]);

  //Default All Time selection starts here
  const allTime = () => {
    setIsSelected("alltime");
  };

  //For the initial load
  useEffect(() => {
    allTime();
  }, []);
  //Default All Time selection ends here

  //Button functions
  const dayFilter = () => {
    setIsSelected("today");
  };

  const weekFilter = () => {
    setIsSelected("7");
  };

  const monthFilter = () => {
    setIsSelected("30");
  };

  const yearFilter = () => {
    setIsSelected("365");
  };

  useEffect(() => {
    if (isSelected === "alltime") {
      setUrlActiveJobs(`employers/${user_id}/active-jobs/count`);
      setUrlJobsCount(`employers/${user_id}/job-application/count`);
      setJobsClick(`employers/${user_id}/unique-jobs-count/list`);
    } else if (isSelected === "today") {
      setUrlActiveJobs(`employers/${user_id}/active-jobs/count?day=today`);
      setUrlJobsCount(`employers/${user_id}/job-application/count?day=today`);
      setJobsClick(
        `employers/${user_id}/unique-jobs-count/list?start_date=1&end_date=today`
      );
    } else if (isSelected === "7") {
      setUrlActiveJobs(`employers/${user_id}/active-jobs/count?week=7`);
      setUrlJobsCount(`employers/${user_id}/job-application/count?week=7`);
      setJobsClick(
        `employers/${user_id}/unique-jobs-count/list?start_date=7&end_date=today`
      );
    } else if (isSelected === "30") {
      setUrlActiveJobs(`employers/${user_id}/active-jobs/count?month=30`);
      setUrlJobsCount(`employers/${user_id}/job-application/count?month=30`);
      setJobsClick(
        `employers/${user_id}/unique-jobs-count/list?start_date=30&end_date=today`
      );
    } else {
      setUrlActiveJobs(`employers/${user_id}/active-jobs/count?year=365`);
      setUrlJobsCount(`employers/${user_id}/job-application/count?year=365`);
      setJobsClick(
        `employers/${user_id}/unique-jobs-count/list?start_date=365&end_date=today`
      );
    }
  }, [isSelected]);

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-6">
        <h1 className="text-xl font-semibold text-black mb-8 lg:mb-0 text-left">
          Overview
        </h1>
        <div className="lg:border rounded-xl lg:p-2 flex gap-2 md:gap-2 flex-wrap md:flex-nowrap justify-center md:justify-start">
          <button
            onClick={() => dayFilter()}
            className={`p-3 btn mb-3 md:mb-0 ${
              isSelected === "today" ? "btn-main" : ""
            }`}
          >
            Day
          </button>
          <button
            onClick={() => weekFilter()}
            className={`p-3 btn mb-3 md:mb-0 ${
              isSelected === "7" ? "btn-main" : ""
            }`}
          >
            Week
          </button>
          <button
            onClick={() => monthFilter()}
            className={`p-3 btn mb-3 md:mb-0 ${
              isSelected === "30" ? "btn-main" : ""
            }`}
          >
            Month
          </button>
          <button
            onClick={() => yearFilter()}
            className={`p-3 btn mb-3 md:mb-0 ${
              isSelected === "365" ? "btn-main" : ""
            }`}
          >
            Year
          </button>
          <button
            onClick={() => allTime()}
            className={`p-3 btn mb-3 md:mb-0 ${
              isSelected === "alltime" ? "btn-main" : ""
            }`}
          >
            All Time
          </button>
        </div>
      </div>

      <div className="flex gap-3 md:gap-2 xl:gap-5 flex-col md:flex-row md:flex-wrap xl:flex-nowrap">
        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-blue-50 rounded-full relative">
              <img
                className="w-5 h-auto absolute m-auto inset-0"
                src={Pointer}
              />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Total Clicks</h2>
              <span className="text-black text-2xl font-semibold">
                {JobsClickLoading ? loader : EachJobClicks?.total_clicks_count}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-blue-50 rounded-full relative">
              <img
                className="w-5 h-auto absolute m-auto inset-0"
                src={Employee}
              />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Total Applied Candidates</h2>
              <span className="text-black text-2xl font-semibold">
                {JobsCountLoading
                  ? loader
                  : JobsCountsData?.data?.total_application_count}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-blue-50 rounded-full relative">
              <img className="w-5 h-auto absolute m-auto inset-0" src={Case} />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Total Jobs Posted</h2>
              <span className="text-black text-2xl font-semibold">
                {activeJobsLoading ? loader : ActiveJobsData?.total_jobs_posted}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-blue-50 rounded-full relative">
              <img className="w-5 h-auto absolute m-auto inset-0" src={Tick} />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Active Jobs</h2>
              <span className="text-black text-2xl font-semibold">
                {activeJobsLoading ? loader : ActiveJobsData?.active_jobs}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
