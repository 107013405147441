import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineClose,
} from "react-icons/ai";

function ProfileErrorModal({ showModal, setShowModal }) {
  return (
    <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
      <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
        {/*content*/}
        <div className="relative flex flex-col w-5/6 md:w-full mx-auto bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
            <h3 className="text-xl font-semibold md:text-2xl">
              Error in Applying Job
            </h3>
            <AiOutlineClose
              className="cursor-pointer h-5 w-5 hover:text-gray-600 text-black"
              onClick={() => {
                setShowModal(false)
              }}
            />
          </div>
          {/*body*/}
          <div className="relative flex-auto p-6">
            <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
              <div className="space-y-6">
                <h1>Please complete profile to apply for a Job</h1>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
            {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
            {/* <div className="flex space-x-2"> */}
              <Link to={"/profile/edit"}>
                <button className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main">
                  Complete Profile
                </button>
              </Link>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileErrorModal;
