const fields = {
  email: "",
  password: "",
  confirmPassword: "",
  user_role: null,
  phone: "",
};

const checkFields = (data) => {
  const role = data.user_role;
  const errors = {};
  for (let key in fields) {
    if (!data[key]) {
      errors[key] = "This field is required";
    }
  }

  if (role === "employer")
    if (data.company_name?.trim().length <= 0 || !data.company_name) {
      errors.company_name = "This field is required";
    }

  if (data.phone.length < 8) errors.phone = "Please enter a valid phone number";

  // Frond end password validation
  const emailRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (data.password) {
    if (!emailRegex.test(data.password)) {
      errors.password =
        "Password must be minimum 8 characters, alphanumeric with at least one uppercase, one lowercase and one special character.";
    }
  }

  if (emailRegex.test(data.password)) {
    if (data.password && data.confirmPassword) {
      if (data.password !== data.confirmPassword)
        errors.confirmPassword = "Password and confirm password should match";
    }
  }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
