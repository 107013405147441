import TopNav from "./components/elements/TopNav";
import Home from "./components/pages/Home";
import Footer from "./components/elements/Footer";
import PageNotFound from "./components/pages/PageNotFound";
import SignUp from "./components/pages/SignUp";
import Login from "./components/pages/Login";
import JobSearch from "./components/pages/JobSearch";
import HelpCenter from "./components/pages/HelpCenter";
import ContactUs from "./components/pages/ContactUs";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfService from "./components/pages/TermsOfService";
import ActivateUser from "./components/pages/ActivateUser";
import JobDetails from "./components/pages/JobDetails";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import ProfileWrapper from "./components/pages/profile/Wrapper";
import PlansAndPricing from "./components/pages/payment//PlansAndPricing";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

import { createContext, useState, useEffect } from "react";
import JobPosting from "./components/pages/JobPosting";
import EmployeeDetails from "./components/pages/EmployeeDetails";
import HireSearch from "./components/pages/HireSearch";
import CandidateList from "./components/pages/CandidateList";
import AppliedJoblist from "./components/pages/AppliedJoblist";

import useGetData from "./hooks/useGetData";
import useWebSocket, { ReadyState } from "react-use-websocket";
import UserSetPassword from "./components/pages/UserSetPassword";
import UnsubscribeNewsletter from "./components/pages/UnsubscribeNewsletter";
import AboutUs from "./components/pages/AboutUs";
import PartnerWithUs from "./components/pages/PartnerWithUs";
import EmployerDetails from "./components/pages/EmployerDetails";
import NullJobIdEmployerDetails from "./components/pages/NullJobIdEmployerDetails";
import AccessProfile from "./components/pages/AccessProfile";
import { Helmet } from "react-helmet";

// for managing user state globally
const initialUserState = null;

//reducer function for managing user state globally
//@param {object} action : {type:'',data:{}}
//@return {object} updated state
const reducer = (action, state) => {
  switch (action.type) {
    case "create":
      localStorage.setItem("user", action.data);
      return action.data;
    case "update":
      let user = localStorage.getItem("user");
      user = { ...user, ...action.data };
      localStorage.setItem("user", user);
      return user;
    case "delete":
      localStorage.removeItem("user");
      return initialUserState;
    default:
      return state;
  }
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const AuthenticatedRouteHire = ({ component: Component, ...rest }) => {
  localStorage.setItem("path", rest.location.pathname);
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("accessToken") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

//context for managing user state
export const UserContext = createContext();
export const LookingToHireContext = createContext();
export const MapContext = createContext();
// export const SameLocationContext = createContext();
// export const MarkerContext = createContext();
// export const FilterContext = createContext();
// export const AddressContext = createContext();
// export const CoordinateContext = createContext();
export const ChatContext = createContext();
export const RefreshContext = createContext();

function App() {
  // for managing user state globally
  const accessToken = localStorage.getItem("accessToken");
  const username = localStorage.getItem("username");
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");
  const [address, setAddress] = useState("");
  const [zoomlvl, setZoomlvl] = useState(12);
  const [tempCoord, setTempCoord] = useState(null);
  const [lookingToHire, setLookingToHire] = useState(
    userRole === "employer" ? true : false
  );
  const [mapData, setMapData] = useState([]);
  const [employerJobs, setEmployerJobs] = useState([]);
  const [allemployerJobs, setAllEmployerJobs] = useState([]);
  const [sameLocationData, setSameLocationData] = useState([]);
  const [homeCoordinates, setHomeCoordinates] = useState({});
  const [marker, setMarkerLocation] = useState({
    lat: latitude && latitude !== "null" ? parseFloat(latitude) : "",
    lng: longitude && longitude !== "null" ? parseFloat(longitude) : "",
  });
  const [tempPostion, setTempPosition] = useState(null);
  const [filterValues, setFilterValues] = useState({
    position: null,
    min: 1,
    max: 250,
    jobType: [],
    radius: 0,
    salary: false,
    salary_type: "Hourly",
  });
  const [groupData, setGroupData] = useState([]);
  const [step, setStep] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [circles, setCircles] = useState();
  const [clusters, setClusters] = useState([]);
  const [paymentId, setPaymentId] = useState(null);

  const [refreshWraper, setRefresh] = useState(false);

  /// chat

  const { data: chatUserList } = useGetData("chat/users", "chat");

  const [messageNotification, setMessageNotification] = useState(false);
  const [userList, setuserList] = useState(null);
  const [connectedUser, setConnectedUser] = useState(null);
  const [socketUrl, setSocketUrl] = useState(
    `${process.env.REACT_APP_CHAT_SOCKET}list-users/?my_token=${accessToken}`
  );
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendJsonMessage, sendMessage, lastMessage, readyState } =
    useWebSocket(socketUrl);

  const [title, setTitle] = useState("Home | Ring of Hires");
  let slug = useParams();
  const [jobTitle, setJobTitle] = useState("Job Details");

  useEffect(() => {
    if (chatUserList) {
      setuserList(chatUserList.threads);
    }
  }, [chatUserList]);

  useEffect(() => {
    if (lastMessage?.data) {
      const message = JSON.parse(lastMessage.data);
      //console.log(message);
      //console.log(userList);

      if (message.sender_id != connectedUser?.user_id) {
        //new user message, insert to user list
        const findUser = userList?.find(
          (user) => user.user_id == message.sender_id
        );
        if (!findUser) {
          const newUserData = {
            user_id: message.sender_id,
            latest_message: message.message,
            latest_message_sender: message.sender_name,
            user_name: message.sender_name,
            user_image: message.send_pic,
            unread: true,
          };
          setuserList([{ ...newUserData }, ...userList]);
        } else {
          // update user list unread flag and latest_message
          let newList = userList.filter(
            (user) => user.user_id != message.sender_id
          );
          setuserList([
            { ...findUser, latest_message: message.message, unread: true },
            ...newList,
          ]);
        }

        // if in message page
        if (!connectedUser) {
          setMessageNotification(true);
        }

        //chatting with other user in chat page itself
        console.log("message from another  user");
        console.log(userList, message);
      } else {
        const userIndex = userList.findIndex(
          (user) => user.user_id == connectedUser.user_id
        );
        let newList = [...userList];
        if (userIndex > -1) {
          newList[userIndex]["latest_message"] = message.message;
          setuserList(newList);
        }
      }
    }
  }, [lastMessage]);

  const toTitleCase = (str) =>
    str.replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );

  useEffect(() => {
    let url =
      window.location.pathname.endsWith("/") && window.location.pathname !== "/"
        ? window.location.pathname.slice(0, -1)
        : window.location.pathname;
    let temp = url.split("/");

    if (temp.length === 2) {
      if (temp[1] === "") {
        setTitle("Home | Ring of Hires");
      } else {
        setTitle(`${toTitleCase(temp[1].replace(/-/g, " "))} | Ring of Hires`);
      }
    } else if (temp.length === 3) {
      if (temp[1] === "profile") {
        setTitle(`${toTitleCase(temp[2].replace(/-/g, " "))} | Ring of Hires`);
      } else if (temp[1] === "jobsearch" || temp[1] === "jobSearch") {
        setTitle(`${toTitleCase(temp[2].replace(/-/g, " "))} | Ring of Hires`);
      } else if (temp[1] === "job-details") {
        setTitle(`${jobTitle ? jobTitle : "Job Details"} | Ring of Hires`);
      } else {
        setTitle(`${toTitleCase(temp[1].replace(/-/g, " "))} | Ring of Hires`);
      }
    } else if (temp.length === 4) {
      if (temp[2] === "employees" && temp[3] === "job-applications") {
        setTitle(`Jobs | Ring of Hires`);
      }
    } else {
      setTitle(`${toTitleCase(temp[1].replace(/-/g, " "))} | Ring of Hires`);
    }
  }, [window.location.pathname, slug, jobTitle]);

  ///////

  return (
    <UserContext.Provider
      value={{ accessToken, username, userId, userRole, paymentId }}
    >
      <ChatContext.Provider
        value={[
          connectedUser,
          setConnectedUser,
          userList,
          setuserList,
          messageNotification,
          setMessageNotification,
        ]}
      >
        <LookingToHireContext.Provider
          value={[lookingToHire, setLookingToHire]}
        >
          <MapContext.Provider
            value={[
              mapData,
              setMapData,
              sameLocationData,
              setSameLocationData,
              marker,
              setMarkerLocation,
              address,
              setAddress,
              filterValues,
              setFilterValues,
              homeCoordinates,
              setHomeCoordinates,
              zoomlvl,
              setZoomlvl,
              tempCoord,
              setTempCoord,
              tempPostion,
              setTempPosition,
              groupData,
              setGroupData,
              step,
              setStep,
              showModal,
              setShowModal,

              circles,
              setCircles,
              clusters,
              setClusters,
              employerJobs,
              setEmployerJobs,
              allemployerJobs,
              setAllEmployerJobs,
            ]}
          >
            <RefreshContext.Provider
              value={{
                refreshWraper,
                setRefreshWraper: () => setRefresh((prev) => !prev),
              }}
            >
              {/* <SameLocationContext.Provider
                value={[sameLocationData, setSameLocationData]}
              >
                <MarkerContext.Provider value={[marker, setMarkerLocation]}>
                  <AddressContext.Provider value={[address, setAddress]}>
                    <FilterContext.Provider
                      value={[filterValues, setFilterValues]}
                    >
                      <CoordinateContext.Provider
                        value={[homeCoordinates, setHomeCoordinates]}
                      > */}
              <Helmet>
                        <title>{title}</title>
                    
              </Helmet>
              <TopNav />

              <main className="relative w-full h-auto">
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/ad1">
                    <Home />
                  </Route>
                  <Route path="/ad2">
                    <Home />
                  </Route>
                  <Route path="/ad3">
                    <Home />
                  </Route>
                  <Route path="/twu">
                    <Home />
                  </Route>
                  <Route path="/jobs">
                    <Home />
                  </Route>

                  <LoginRoute path="/signup" component={SignUp} />
                  <LoginRoute path="/login" component={Login} />
                  <LoginRoute
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <AuthenticatedRoute
                    path="/profile"
                    component={ProfileWrapper}
                  />

                  <Route path="/jobsearch/:optional?">
                    <JobSearch />
                  </Route>

                  <Route path="/hiresearch/:optional?">
                    <HireSearch />
                  </Route>

                  <Route path="/hiresearch">
                    <HireSearch />
                  </Route>
                  <LoginRoute
                    path="/reset-password/:uid/:token"
                    component={ResetPassword}
                  />
                  <Route path="/set-password/:uid/:token/:user_id">
                    <UserSetPassword />
                  </Route>
                  <Route path="/job">
                    <JobDetails />
                  </Route>
                  <Route path="/help-center">
                    <HelpCenter />
                  </Route>
                  <Route path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route path="/privacy-policy">
                    <PrivacyPolicy />
                  </Route>
                  <Route path="/terms-of-service">
                    <TermsOfService />
                  </Route>
                  <Route path={`/job-details/:jobid`}>
                    <JobDetails setJobTitle={setJobTitle} />
                  </Route>
                  <Route path={`/applications/:jobid`}>
                    <CandidateList />
                  </Route>
                  <AuthenticatedRouteHire
                    path="/employees/:emp_id"
                    component={EmployeeDetails}
                  />
                  <Route path={`/employers/:emp_id`}>
                    <EmployerDetails />
                  </Route>
                  <Route path={`/employers-details/:company_name`}>
                    <NullJobIdEmployerDetails />
                  </Route>

                  <Route path="/activate-account/:uid/:token">
                    <ActivateUser />
                  </Route>
                  <Route path="/404">
                    <PageNotFound />
                  </Route>
                  <Route path="/plans-and-pricing">
                    <PlansAndPricing />
                  </Route>
                  <Route path="/unsubscribedailycandidates/:user_role/:uid/:token/:email_template">
                    <UnsubscribeNewsletter />
                  </Route>
                  <Route path="/about-us">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/partner-with-us">
                    <PartnerWithUs />
                  </Route>
                  <Route path="/user/access-profile">
                    <AccessProfile />
                  </Route>
                  <Redirect to="/404" />
                </Switch>
              </main>

              <Footer />
              {/* </CoordinateContext.Provider>
                    </FilterContext.Provider>
                  </AddressContext.Provider>
                </MarkerContext.Provider>
              </SameLocationContext.Provider> */}
            </RefreshContext.Provider>
          </MapContext.Provider>
        </LookingToHireContext.Provider>
      </ChatContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
