import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { FaWindowClose } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import checkCircle from "../../../images/JobApply/check-circle.png";
import usePostData from "../../../hooks/usePostData";

function ContactModal({
  showModal,
  setShowModal,
  empDetails,
  position,
  phone,
  contactRefresh,
}) {
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const { emp_id } = useParams();

  const [modalNo, setModalNo] = useState(1);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState(null);
  const [message, setMessage] = useState("");
  const url = `chat/${emp_id}`;

  const { data, error, loading } = usePostData(
    url,
    formdata,
    "application/json",
    "chat"
  );

  const validate = async () => {
    let isvalid = true;
    let error = {};
    if (!message) {
      isvalid = false;
      error["message"] = "Please provide a message";
    }
    setErrors({ ...error });
    return isvalid;
  };

  const updateMessage = (e) => {
    setMessage(e.target.value);
  };

  const checkIsLoggedIn = () => {
    if (!user_id) {
      // localStorage.setItem("job_id", jobid);
      window.location.href = "/login";
      return;
    }
  };

  useEffect(() => {
    if (data?.messages === "created") {
      setModalNo(4);

      setTimeout(() => {
        setShowModal(false);
        contactRefresh(true);
      }, 2000);
    }
  }, [data]);

  const submitMessage = () => {
    const msgForm = new FormData();
    msgForm.append("message", message);
    setFormData(msgForm);
  };

  const closeModal = () => {
    setShowModal(false);
    setErrors("");
  }

  return (
    <div>
      {showModal === true && checkIsLoggedIn()}
      {user_id && showModal ? (
        user_role === "employer" ? (
          <>
            {modalNo === 1 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-4xl mx-auto my-6">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Send Message to{" "}
                        {empDetails?.first_name
                          ? `${empDetails?.first_name} ${empDetails.last_name}`
                          : "User Name"}{" "}
                      </h3>
                      <p className="text-red-600 font-base-semibold">1/1</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <div className="gap-2 md:flex">
                            <img
                              className="rounded-md"
                              src={
                                empDetails?.image
                                  ? empDetails.image
                                  : "/images/employee-default.png"
                              }
                              alt=""
                              width="100px"
                            />
                            <div className="space-y-2">
                              <h2 className="text-black font-xl-semibold">
                                {empDetails?.first_name
                                  ? `${empDetails?.first_name} ${empDetails?.last_name}`
                                  : "User Name"}
                              </h2>
                              <p>{position && position}</p>
                              <div className="flex-col md:flex md:flex-row md:gap-3 items-start flex-wrap">
                                <p
                                  // style={{ flexBasis: "30%" }}
                                  className="flex text-sm"
                                >
                                  <span className="mt-1 md:mt-0">
                                    <GoLocation
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {empDetails.location}
                                </p>

                                <p className="flex items-center m-0 text-sm">
                                  <span>
                                    <HiOutlinePhone
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {phone}
                                </p>

                                <p className="flex items-center m-0 text-sm">
                                  <span>
                                    <HiOutlineMail
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {empDetails.email}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="block w-full gap-3 md:flex">
                            <div className="w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="message"
                              >
                                Message
                              </label>
                              <textarea
                                type="text"
                                name="message"
                                rows={10}
                                id="message"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateMessage(e);
                                }}
                                value={message}
                                className="text-sm form_input"
                                placeholder="Enter your message here..."
                              />
                              <span className="form_error_message">
                                {errors?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            closeModal();
                            // setShowModal(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={async () => {
                            if (await validate()) {
                              submitMessage();
                            }
                          }}
                        >
                          Send Mail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 4 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full border-0 rounded-lg outline-none bg-main custom_shadow focus:outline-none">
                    <div className="flex flex-col items-center justify-center gap-4 p-6 border-t border-solid rounded-b border-blueGray-200">
                      <img src={checkCircle} alt="" width="" />
                      <h2 className="text-3xl font-semibold text-center text-white">
                        Successfully Sent!
                      </h2>
                      <p className="w-10/12 text-xl text-center text-white ">
                        Your message has been sent to the candidate
                      </p>
                      <div className="flex space-x-2">
                        {/* <button
                        className="flex items-center justify-center w-full mt-3 bg-white text-main md:w-auto md:mt-0 btn btn-base"
                        onClick={() => {
                          setModalNo(2);
                        }}
                      >
                        ok
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-xl">
                    Only Employers can contact this Candidate
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="ml-4 text-red-600 font-base-semibold "
                  >
                    <FaWindowClose size="1.4em" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default ContactModal;
