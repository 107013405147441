import { useContext } from "react";
import { useState, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import RohLogo from "../../../images/ROH-logo.png";
import { UserContext } from "../../../App";

import useGetData from "../../../hooks/useGetData";
import { Link, useLocation } from "react-router-dom";

function HomePricing() {
  const { username } = useContext(UserContext);
  const [select, setSelect] = useState(null);
  console.log("select", select);
  const location = useLocation();

  const customLogin = () => {
    localStorage.setItem("path", window.location.href);
    window.location.href = "/login";
  };

  const GotoMyPlan = (value) => {
    window.location.href = "/profile/my-plan";
    console.log("plan......", value);
    setSelect(value);
    localStorage.setItem("clickedPlan", value);
  };

  const GotoPricing = () => {
    window.location.href = "/plans-and-pricing";
  }

  const [pricingUrl, setPricingUrl] = useState(`plan-details`);
  const { data: PricingData } = useGetData(pricingUrl);
  const [choosePlan, SetChoosePlan] = useState("employer");
  const [loading, setLoading] = useState(true);
  const { userRole } = useContext(UserContext);

  useEffect(() => {
    if (PricingData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [PricingData]);

  //   useEffect(() => {
  //     if (userRole === "employee") {
  //       SetChoosePlan("employee");
  //     } else if (userRole === "employer") {
  //       SetChoosePlan("employer");
  //     } else {
  //       SetChoosePlan("employer");
  //     }
  //   }, []);

  // pricing plan filtering based on the user type
  const pricingSortData = PricingData?.data;
  const employee_ids = ["employee"];
  const employer_ids = ["employer"];

  //Filter only the employee objects from the pricing array
  const employeeSortedData = pricingSortData?.filter((i) =>
    employee_ids.includes(i.applicable_user_type_name)
  );

  //Convert the number of billing periods in months value to "12.34" format
  function monthlyTotal(price) {
    const em1 = price.toFixed(2);
    return em1;
  }

  //Filter only the employer objects from the pricing array
  const employerSortedData = pricingSortData?.filter((i) =>
    employer_ids.includes(i.applicable_user_type_name)
  );

  const newSorted = employerSortedData?.sort((a, b) => {
    return a.name - b.name;
  });

  console.log("newSorted", newSorted);

  // Check the trial period of the particular user
  const { data: trialStatus } = useGetData("flag-check");
  const isTrialed = trialStatus?.data?.is_trialed;

  return (
    <>
      <div className="container-wrapper bg-main py-10">
        {location.pathname === "/plans-and-pricing" && (
          <div className="text-white text-center mb-12">
            <h1 className="lg:text-4xl font-semibold mb-5 text-3xl">
              Plans & Pricing -{" "}
              <span className="italic text-xl align-middle">Ring of Hires</span>
            </h1>
            {/* <p className="font-medium">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
          </p> */}
          </div>
        )}

        {!loading ? (
          <div className="flex flex-col md:flex-row space-y-5 md:space-x-2 lg:space-x-5 md:space-y-0 justify-center">
            {choosePlan === "employer" &&
              newSorted?.map((single_employer_item, index) => (
                <div
                  className={`pricing-card ${
                    single_employer_item.plan_name == "Torch"
                      ? "bg-white"
                      : "bg-blue-100"
                  } px-10 pt-10 pb-5 md:px-5 md:pt-5 lg:px-10 lg:pt-10 rounded-lg flex-initial w-full md:w-4/12 lg:w-4/12 xl:w-3/12 flex flex-col justify-between`}
                  key={single_employer_item}
                >
                  <div className="pricing-content">
                    <div className="pricing-head flex justify-between">
                      <h2 className="text-2xl md:text-xl lg:text-2xl font-bold text-black mb-2">
                        {single_employer_item.plan_name} Membership
                      </h2>
                      <div className="flex space-between">
                        {single_employer_item.name != 1 && (
                          <img src={RohLogo} className="h-10 md:h-8 lg:h-10 w-7 mx-2" />
                        )}
                        {single_employer_item.plan_name == "Bonfire" && (
                          <img src={RohLogo} className="h-10 md:h-8 lg:h-10 w-7 mx-2" />
                        )}
                      </div>
                    </div>
                    <p className="font-semibold text-gray-600 md:h-14 xl:h-10 2xl:h-6 capitalize">
                      {single_employer_item.tag_line}
                    </p>
                    <div className="text-4xl text-red-500 font-bold py-5">
                      ${single_employer_item.monthly_price}{" "}
                      {single_employer_item.monthly_price !== "0.00" ? (
                        <sup>
                          <span className="text-sm text-gray-600">
                            {" "}
                            / Month
                          </span>
                        </sup>
                      ) : (
                        ""
                      )}
                    </div>

                    {single_employer_item.billing_period_in_months > 1 && (
                      <p>
                        <span className="text-main font-semibold">
                          ${single_employer_item.monthly_price}
                        </span>
                        /month for{" "}
                        {single_employer_item.billing_period_in_months} months.
                        <br />
                        Billed one time as{" "}
                        <span className="text-main font-semibold">
                          $
                          {monthlyTotal(
                            single_employer_item.monthly_price *
                              single_employer_item.billing_period_in_months
                          )}
                        </span>
                      </p>
                    )}

                    <hr className="mt-2" />

                    <div className="privilege-list my-5">
                      <ul className="space-y-2">
                        {single_employer_item?.description.map(
                          (single_feature, index) => (
                            <li className="flex items-top" key={single_feature}>
                              <div>
                                <BsCheck2Circle
                                  className="mr-3 mt-1 text-main stroke-1"
                                  size="1.2em"
                                />
                              </div>
                              {single_feature.description}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  {!username ? (
                    single_employer_item.trial_period > 0 ? (
                      <div className="get-started-butt">
                        <button
                          class={`text-xl font-semibold  p-4 rounded-lg w-full block text-center
                          ${
                            single_employer_item.plan_name == "Torch"
                              ? "bg-main text-white"
                              : "bg-transparent hover:bg-main text-main hover:text-white border-main border hover:border-transparent"
                          }`}
                          onClick={customLogin}
                        >
                          Start Free Trial
                        </button>
                      </div>
                    ) : (
                      <div className="get-started-butt">
                        <button
                          class="bg-transparent hover:bg-main text-main text-xl font-semibold hover:text-white p-4 border border-main hover:border-transparent rounded-lg w-full block text-center"
                          onClick={customLogin}
                        >
                          Get Started
                        </button>
                      </div>
                    )
                  ) : (
                    <>
                     {userRole === "employer" && (
                      <div className="get-started-butt">
                        <button
                          class={`bg-transparent text-xl font-semibold hover:text-white p-4 border hover:border-transparent rounded-lg w-full block text-center ${
                            userRole === "employer" &&
                            single_employer_item.name ==
                              single_employer_item.logged_users_plan
                              ? "bg-red-500 border-red-500 text-white"
                              : "border-main text-main hover:bg-main hover:text-white"
                          }`}
                          onClick={() => GotoMyPlan(single_employer_item.name)}
                        >
                          {userRole === "employer" &&
                          single_employer_item.name ==
                            single_employer_item.logged_users_plan
                            ? "Current Plan"
                            : single_employer_item.trial_period > 0 &&
                              isTrialed == false
                            ? "Start Free Trial"
                            : "Get Started"}
                        </button>
                      </div>
                     )}
                    {userRole === "employee" && (
                      <div className="get-started-butt">
                      <button
                        class={`bg-transparent text-xl font-semibold hover:text-white p-4 border rounded-lg w-full block text-center border-main text-main hover:bg-main hover:text-white `}
                        onClick={() => GotoPricing()}
                      >
                      Get Started
                      </button>
                    </div>
                    )}
                    </>
                  )}
                </div>
              ))}
          </div>
        ) : (
          <h5 className="p-3 text-white text-center">Loading...</h5>
        )}
      </div>

      {/* <div class="container-wrapper py-16 pricing-faq">
        <PaymentFaq />
      </div> */}
    </>
  );
}

export default HomePricing;
