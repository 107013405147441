import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  Link,
  useLocation,
  Redirect,
} from "react-router-dom";
import ChangePassword from "./ChangePassword";
import EditEmployee from "./EditEmployee";
import EditEmployer from "./EditEmployer";
import EmployerJobList from "./EmployerJobList";

import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import JobPosting from "../JobPosting";
import Notifications from "../Notifications";
import MailBox from "../MailBox";
import AppliedJoblist from "../AppliedJoblist";
import MyPlan from "../payment/MyPlan";
import { ChatContext } from "../../../App";
import EmployerDashboard from "../EmployerDashboard/EmployerDashboard";
import EmployeeDashboard from "../EmployeeDashboard/EmployeeDashboard";
import Availability from "../EmployerDashboard/Availability";
import InterViewAvailability from "../EmployeeDashboard/InterViewAvailability";
import RecentInterview from "../../elements/RecentInterview";

const ProfileWrapper = () => {
  const { userRole } = useContext(UserContext);
  const { url, path } = useRouteMatch();
  const { ...data } = useContext(ChatContext);
  const [smsNotification, setSmsNotification] = useState(false);
  const { username } = useContext(UserContext);

  console.log("smsNotification :>> ", smsNotification);
  useEffect(() => {
    setSmsNotification(false);
    data[2]?.map((ChatMember) => {
      setSmsNotification((prev) => prev || ChatMember.unread);
    });
  }, [data[2]]);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  const location = useLocation();

  const EmployerRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        userRole === "employer" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/edit/profile",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  const EmployeeRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        userRole === "employee" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/edit/profile",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  //
  useEffect(() => {
    if (!username) {
      localStorage.clear();
      window.location.href = "/login";
    }
  }, [username]);

  return (
    <>
      <div className="minHeight bg-gray-100">
        <div
          className={`justify-around py-10 bg-gray-100 md:flex ${
            location.pathname === "/profile/employer-dashboard" ||
            location.pathname === "/profile/employee-dashboard"
              ? "dashboard-wrapper"
              : "container-wrapper"
          }`}
        >
          <div
            className={`hidden mr-2 bg-white rounded-lg shadow-lg md:flex flex-col md:w-3/12 lg:w-2/12 h-auto ${
              location.pathname === "/profile/change-password"
                ? "changePasssidebarHgt"
                : ""
            } `}
          >
            <div className="flex-col w-full md:flex-row">
              <nav className="flex-grow px-4 pb-4 my-10 md:block md:pb-0 md:overflow-y-auto">
                {/* <Link className={`profile_menu_item ${location.pathname === "/profile/notifications" ? "active text-red-600" : ""}`} to={`${url}/notifications`}>
                Notifications
              </Link> */}
                {userRole === "employee" && (
                  <>
                    <Link
                      className={`profile_menu_item ${
                        location.pathname === "/profile/employee-dashboard"
                          ? "active text-red-600"
                          : ""
                      }`}
                      to={`${url}/employee-dashboard`}
                    >
                      Dashboard
                    </Link>
                    <Link
                      className={`profile_menu_item ${
                        location.pathname ===
                        "/profile/employees/job-applications"
                          ? "active text-red-600"
                          : ""
                      }`}
                      to={`${url}/employees/job-applications`}
                    >
                      Jobs
                    </Link>
                  </>
                )}
                {userRole === "employer" && (
                  <>
                    <Link
                      className={`profile_menu_item ${
                        location.pathname === "/profile/employer-dashboard"
                          ? "active text-red-600"
                          : ""
                      }`}
                      to={`${url}/employer-dashboard`}
                    >
                      Dashboard
                    </Link>
                    <Link
                      className={`profile_menu_item ${
                        location.pathname === "/profile/joblist"
                          ? "active text-red-600"
                          : ""
                      }`}
                      to={`${url}/joblist`}
                    >
                      Job Posts
                    </Link>
                    <Link
                      className={`profile_menu_item ${
                        location.pathname === "/profile/job-posting"
                          ? "active text-red-600"
                          : ""
                      }`}
                      to={`${url}/job-posting`}
                    >
                      Create Job Post
                    </Link>
                  </>
                )}
                {userRole === "employer" && (
                  <Link
                    className={`profile_menu_item ${
                      location.pathname === "/profile/my-plan"
                        ? "active text-red-600"
                        : ""
                    }`}
                    to={`${url}/my-plan`}
                  >
                    My Plan
                  </Link>
                )}

                <Link
                  className={`flex relative ${
                    smsNotification && "notification"
                  } mt-2 rounded-lg hover:bg-gray-200`}
                  to={`${url}/mailbox`}
                >
                  <div
                    className={`profile_menu_item1 flex relative px-2 pr-2 md:pr-0 md:px-2 py-2 lg:px-4 text-sm font-semibold bg-transparent  ${
                      location.pathname === "/profile/mailbox"
                        ? "active text-red-600"
                        : ""
                    }`}
                  >
                    Mailbox
                  </div>
                </Link>
                {userRole === "employer" && (
                  <Link
                    className={`flex relative mt-2 rounded-lg hover:bg-gray-200`}
                    to={`${url}/availability`}
                  >
                    <div
                      className={`profile_menu_item1 flex relative px-2 pr-2 md:pr-0 md:px-2 py-2 lg:px-4 text-sm font-semibold bg-transparent  ${
                        location.pathname === "/profile/availability"
                          ? "active text-red-600"
                          : ""
                      }`}
                    >
                      Availability
                    </div>
                  </Link>
                )}
                <Link
                  className={`profile_menu_item ${
                    location.pathname === "/profile/edit"
                      ? "active text-red-600"
                      : ""
                  }`}
                  to={`${url}/edit`}
                >
                  Edit Profile
                </Link>
                <Link
                  className={`profile_menu_item ${
                    location.pathname === "/profile/change-password"
                      ? "active text-red-600"
                      : ""
                  }`}
                  to={`${url}/change-password`}
                >
                  Change Password
                </Link>
              </nav>
            </div>
            <div className="pl-6 lg:pl-8 pr-4">
              <RecentInterview showInvites={userRole === "employer"} />
            </div>
          </div>

          {/* <div>
          <nav className="flex-grow px-4 my-2 md:hidden">
            <Link className="profile_menu_item" to={`${url}/edit`}>
              Edit Profile
            </Link>
            <Link className="profile_menu_item" to={`${url}/change-password`}>
              Change Password
            </Link>
          </nav>
        </div> */}

          <div className="w-full md:w-9/12 lg:w-10/12">
            {userRole === "employee" && (
              // <EmployeeRoute path={`${path}/edit`} component={EditEmployee} />
              <Route path={`${path}/edit`}>
                <EditEmployee />
              </Route>
            )}
            {userRole === "employer" && (
              <Route path={`${path}/edit`}>
                <EditEmployer />
              </Route>
            )}

            {/* <EmployeeRoute
            path={`${path}/employees/job-applications`}
            component={AppliedJoblist}
          />forref */}

            <Route path={`${path}/employees/job-applications`}>
              {userRole === "employee" ? (
                <AppliedJoblist />
              ) : (
                <Redirect to="/404" />
              )}
            </Route>

            <Route path={`${path}/joblist`}>
              {userRole === "employer" ? (
                <EmployerJobList />
              ) : (
                <Redirect to="/404" />
              )}
            </Route>

            <Route path={`${path}/job-edit/:jobslug`}>
              {userRole === "employer" ? (
                <JobPosting />
              ) : (
                <Redirect to="/404" />
              )}
            </Route>

            <Route path={`${path}/job-posting`}>
              {userRole === "employer" ? (
                <JobPosting />
              ) : (
                <Redirect to="/404" />
              )}
            </Route>

            <Route path={`${path}/change-password`}>
              <ChangePassword />
            </Route>
            {/* <Route path={`${path}/notifications`}>
            <Notifications />
          </Route> */}
            <Route path={`${path}/mailbox`}>
              <MailBox />
            </Route>
            <Route path={`${path}/availability`}>
              {userRole === "employer" ? (
                <Availability />
              ) : (
                // <InterViewAvailability />
                <Redirect to="/404" />
              )}
            </Route>
            <Route path={`${path}/my-plan`}>
              <MyPlan />
            </Route>
            <Route path={`${path}/employer-dashboard`}>
              <EmployerDashboard />
            </Route>
            <Route path={`${path}/employee-dashboard`}>
              <EmployeeDashboard />
            </Route>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileWrapper;
