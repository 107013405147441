import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import usePostData from "../../hooks/usePostData";
import useGetData from "../../hooks/useGetData";
import { useEffect } from "react";

export default function TimeList({
  currentSlots,
  date,
  selectedSlot,
  setSelectedSlot,
  duration,
  to_user,
  job_id,
}) {
  const user_id = localStorage.getItem("user_id");

  const [formData, setFromData] = useState(null);

  const [alreadySentData, setAlreadySentData] = useState(null);

  const url = `availability/bookedslots/${user_id}`;

  const { data: booked, error: err } = usePostData(url, formData);

  useEffect(() => {
    setFromData({
      to_user: to_user.toString(),
      event_date: date.toString(),
    });
  }, [date]);

  const { data: alreadySent } = useGetData(
    `availability/unused-user-timeslot`,
    "main",
    date
  );

  const { data: aplicationLog } = useGetData(
    `availability/applicationlog/${job_id}/${to_user}`
  );
  console.log(alreadySent?.data, "data");

  function extractBookingData(obj) {
    return [obj.booking1, obj.booking2, obj.booking3].filter(Boolean);
  }
  function extractAcceptedData(obj) {
    return [obj];
  }

  function findObjectsByDate(dataArray, dateToFind) {
    return dataArray?.filter((obj) => obj.event_date === dateToFind);
  }

  const bookingData = useCallback((eventDateToFilter, rowData, booked) => {
    const extractedDataArray = rowData?.data.reduce((accumulator, obj) => {
      const bookingData = booked
        ? extractAcceptedData(obj)
        : extractBookingData(obj);
      return accumulator.concat(bookingData);
    }, []);

    return findObjectsByDate(extractedDataArray, eventDateToFilter);
  }, []);

  function isTimeInsideSlots(timeslots, time) {
    const timeToCheck = time + ":00";
    for (const slot of timeslots) {
      const fromTime = slot.from_hour.slice(0, 5);
      const toTime = slot.to_hour.slice(0, 5);
      if (timeToCheck >= fromTime && timeToCheck <= toTime) {
        return true;
      }
    }
    return false;
  }

  const timeSlots = useMemo(() => {
    const timeSeries = [];
    currentSlots?.map((ele) => {
      const start = moment(ele.from_hour, "HH:mm:ss");
      timeSeries.push(ele.from_hour.slice(0, -3));
      const end = moment(ele.to_hour, "HH:mm:ss").subtract(1, "hours");
      while (start.isSameOrBefore(end)) {
        timeSeries.push(start.add(Number(duration), "m").format("HH:mm"));
      }
    });
    return timeSeries.sort(
      (a, b) => moment(a, "h:mm").unix() - moment(b, "h:mm").unix()
    );
  }, [currentSlots, duration]);

  useEffect(() => {
    let tempdata = findObjectsByDate(alreadySent?.data, date);
    setAlreadySentData(tempdata);
  }, [alreadySent, date]);

  return (
    <>
      {currentSlots?.length > 0 ? (
        <div className="flex flex-col overflow-y-scroll h-72 ">
          {timeSlots.map((ele, index) => {
            let alreadyAcepted = bookingData(date, booked, true)
              ? isTimeInsideSlots(bookingData(date, booked, true), ele)
              : false;

            let overlap = bookingData(date, aplicationLog, false)
              ? isTimeInsideSlots(bookingData(date, aplicationLog, false), ele)
              : false;

            let alreadySentOverlap = alreadySentData
              ? isTimeInsideSlots(alreadySentData, ele)
              : false;
            const current =
              moment(ele, "HH:mm").format("h:mm A") === selectedSlot;
            return (
              <button
                key={index}
                disabled={overlap || alreadyAcepted}
                className={`border-2 py-4 px-4 my-2 text-center font-semibold mx-2 rounded-md 
                ${
                  overlap
                    ? "bg-gray-500 border-gray-500 text-white"
                    : current
                    ? "bg-blue-500 text-white cursor-pointer"
                    : alreadyAcepted
                    ? "bg-blue-300 border-blue-300 text-white"
                    : alreadySentOverlap
                    ? "hover:bg-blue-500 hover:text-white text-gray-300"
                    : "hover:bg-blue-500 hover:text-white text-blue-500"
                } ${!current && !overlap && "border-blue-300 text-white"}
                `}
                onClick={(e) => {
                  setSelectedSlot(e.target.textContent);
                }}
              >
                {moment(ele, "HH:mm").format("h:mm A")}
              </button>
            );
          })}
        </div>
      ) : (
        <h1 className="flex justify-center text-center font-semibold ">
          No slots available
        </h1>
      )}
    </>
  );
}
