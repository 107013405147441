import { useState, useEffect } from "react";
import PointerRed from "../../../images/Dashboard/PointerRed.png";
import Invite from "../../../images/Dashboard/Invite.png";
import Case from "../../../images/Dashboard/Case.png";
import Tick from "../../../images/Dashboard/Tick.png";
import Cross from "../../../images/Dashboard/Cross.png";
import useGetData from "../../../hooks/useGetData";

function EmployeeOverview() {
  const user_id = localStorage.getItem("user_id");
  const loader = <span className="text-base">Loading...</span>;

  //Applied job count API call
  const [appliedJobCount, setAppliedJobCount] = useState(
    `employees/${user_id}/job-application-count/dashboard`
  );
  const { data: AppliedJobCountData, loading: AppliedJobLoading } =
    useGetData(appliedJobCount);

  // Invite received API call
  const [inviteCount, setInviteJobCount] = useState(
    `employees/${user_id}/send-invite-count/dashboard`
  );
  const { data: InviteCountData, loading: InviteCountLoading } =
    useGetData(inviteCount);

  // Accepted API call
  const [acceptedCount, setAcceptedCount] = useState(
    `availability/application-status-count?application_status=accepted`
  );
  const { data: AcceptedCountData, loading: AcceptedCountLoading } =
    useGetData(acceptedCount);

  // Rejected API call
  const [rejectedCount, setRejectedCount] = useState(
    `availability/application-status-count?application_status=rejected`
  );
  const { data: RejectedCountData, loading: RejectedCountLoading } =
    useGetData(rejectedCount);

  return (
    <>
      <h1 className="text-xl font-semibold text-black mb-8 lg:mb-0 text-left">
        Overview
      </h1>

      <div className="flex gap-3 md:gap-2 xl:gap-5 flex-col md:flex-row md:flex-wrap xl:flex-nowrap mt-5">
        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-blue-100 rounded-full relative">
              <img className="w-5 h-auto absolute m-auto inset-0" src={Case} />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Applied Jobs</h2>
              <span className="text-black text-2xl font-semibold">
                {AppliedJobLoading
                  ? loader
                  : AppliedJobCountData?.data?.applied_job_count}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-green-100 rounded-full relative">
              <img className="w-5 h-auto absolute m-auto inset-0" src={Tick} />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Accepted Applications</h2>
              <span className="text-black text-2xl font-semibold">
                {AcceptedCountLoading ? loader : AcceptedCountData?.data?.count}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-red-100 rounded-full relative">
              <img className="w-5 h-auto absolute m-auto inset-0" src={Cross} />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Rejected Applications</h2>
              <span className="text-black text-2xl font-semibold">
                {RejectedCountLoading ? loader : RejectedCountData?.data?.count}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-red-50 rounded-full relative">
              <img
                className="w-5 h-auto absolute m-auto inset-0"
                src={PointerRed}
              />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Total Clicks</h2>
              <span className="text-black text-2xl font-semibold">
                500
              </span>
            </div>
          </div>
        </div> */}

        <div className="border border-slate-200 bg-white rounded-xl p-5 flex-auto w-full md:w-48 lg:w-2/5 xl:w-64">
          <div className="flex gap-5">
            <span className="flex-none w-12 h-12 bg-yellow-100 rounded-full relative">
              <img
                className="w-5 h-auto absolute m-auto inset-0"
                src={Invite}
              />
            </span>
            <div className="text-gray-400">
              <h2 className="text-sm mb-2">Invite Received</h2>
              <span className="text-black text-2xl font-semibold">
                {InviteCountLoading
                  ? loader
                  : InviteCountData?.data?.send_invite_count}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeOverview;
