import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdModeEditOutline, MdCalendarToday } from "react-icons/md";
import { AiOutlineDelete, AiOutlineClockCircle } from "react-icons/ai";
import { IndividualBookedSlot } from "./AllSlots";

export default function SlotsPopup({
  showSlots,
  setShowSlots,
  bookingSlots,
  jobId,
  empId,
  hireRefresh,
}) {
  let currentUser = localStorage.getItem("user_id");
  console.log(currentUser, "aa");

  const [bookingSlotId, bookedByMe] = useMemo(() => {
    let temp1 = [];
    let temp2 = false;
    if (bookingSlots) {
      temp1.push(bookingSlots?.booking1);
      temp1.push(bookingSlots?.booking2);
      temp1.push(bookingSlots?.booking3);
    }
    if (bookingSlots?.from_user?.id == currentUser) temp2 = true;
    return [temp1, temp2];
  }, [bookingSlots]);

  const popUpRef = useRef();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        showSlots &&
        popUpRef.current &&
        !popUpRef.current.contains(e.target)
      ) {
        setShowSlots(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [showSlots]);

  const [disableButton, setDisableButton] = useState(false);

  return (
    <div
      ref={popUpRef}
      className="fixed z-10  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={() => setShowSlots(false)}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Select Slots
            </h3>
            <div className="text-sm text-gray-500 flex flex-col sm:flex-row  py-0">
              <div className="flex flex-col">
                {bookingSlotId.map((e, index) => {
                  return (
                    <>
                      {e && (
                        <IndividualBookedSlot
                          key={index}
                          bookingSlots={e}
                          displayButton={!bookedByMe}
                          jobid={jobId}
                          handleDisable={() => setDisableButton(true)}
                          disable={disableButton}
                          setRefresh={() => {
                            setShowSlots(false);
                            hireRefresh((prev) => !prev);
                          }}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
