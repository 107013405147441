import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { FaWindowClose } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import checkCircle from "../../../images/JobApply/check-circle.png";
import useGetData from "../../../hooks/useGetData";
import usePostData from "../../../hooks/usePostData";

const HireModal = ({
  showModal,
  setShowModal,
  empDetails,
  position,
  phone,
  hireRefresh,
  jobId,
}) => {
  // localStorage
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const user_mail = localStorage.getItem("username");
  const { emp_id } = useParams();

  // states
  const [message, setMessage] = useState("");
  const [modalNo, setModalNo] = useState(1);
  const [doc, setDoc] = useState([]);
  const [formdata, setFormData] = useState(null);
  const [errors, setErrors] = useState({});
  const [doubleClick, setDoubleClick] = useState(false);
  const url = `employers/${user_id}/hired-candidates`;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMesssage, setErrorMessage] = useState("Something went wrong!");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [values, setValues] = useState({
    employee_id: emp_id ?? empDetails.user,
    job: query.get("job_id") ?? jobId,
    userMail: user_mail,
    email: empDetails.email,
    subject: "",
    message: "",
    // documents: [],
    phone: empDetails?.phone,
  });

  const { data, error, loading } = usePostData(
    url,
    formdata,
    "multipart/form-data"
  );

  useEffect(() => {
    if (data) {
      setModalNo(4);
      setDoc([]);
      setTimeout(() => {
        setShowModal(false);
        hireRefresh();
      }, 2000);
    } else if (error) {
      console.log(error, "apple");
      if (error?.message == "candidate already hired") {
        setErrorMessage("This candidate is already been hired");
      }
      setModalNo(6);
    }
  }, [data, error]);

  //Hire Candidate
  const hireCandidate = () => {
    // if (!empDetails.is_applied) {
    const temp_form = new FormData();
    for (var key in values) {
      temp_form.append(key, values[key]);
    }
    setFormData(temp_form);
    setDoubleClick(true);
    // applyRefresh(true);
    // }
  };

  //field validation
  const validate = async () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isvalid = true;
    let error = {};
    if (!values.email) {
      isvalid = false;
      error["email"] = "Please provide your candidate email";
    } else if (!emailRegex.test(values.email)) {
      isvalid = false;
      error["email"] = "Please provide a valid candidate email";
    }

    if (!values.userMail) {
      isvalid = false;
      error["userMail"] = "Please provide your email";
    } else if (!emailRegex.test(values.userMail)) {
      isvalid = false;
      error["userMail"] = "Please provide a valid email";
    }
    if (!values.subject) {
      isvalid = false;
      error["subject"] = "Please provide a subject";
    }
    if (!values.message) {
      isvalid = false;
      error["message"] = "Please provide a message";
    }
    setErrors({ ...error });
    return isvalid;
  };

  const updateValues = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (doc.length > 0) {
      const docFileArr = doc.reduce((acc, item) => {
        return [...acc, item.file];
      }, []);

      setValues((prev) => ({
        ...prev,
        documents: docFileArr[0],
      }));
    }
  }, [doc]);

  const updateResume = (e) => {
    let allowedExtension = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    let type = e.target.files[0]?.type;
    // console.log(e.target.files[0]);
    if (allowedExtension.indexOf(type) > -1) {
      if (e.target.files[0] && e.target.files[0].size < 15e6) {
        if (doc.length <= 4) {
          if (doc.some((ele) => ele["id"] === e?.target?.files[0]?.name)) {
            setMessage("Similar File");
            setTimeout(() => {
              setMessage("");
            }, 5000);
          } else {
            setDoc((prev) => [
              ...prev,
              {
                id: e.target.files[0].name,
                file: e.target.files[0],
                path: URL.createObjectURL(e.target.files[0]),
              },
            ]);
          }
        } else {
          setMessage("Maximum file limit is 5");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      } else {
        setMessage("File should be less than 2 MB");
      }
    } else {
      setMessage("Invalid Format");
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }

    if (
      type === "application/pdf" ||
      type === "application/msword" ||
      type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const removeResume = (id) => {
    const filteredDoc = doc.filter((item, index) => index !== id);
    setDoc(filteredDoc);
    console.log(filteredDoc);

    if (id === 0) {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  const checkIsLoggedIn = () => {
    if (!user_id) {
      // localStorage.setItem("job_id", jobid);
      window.location.href = "/login";
      return;
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setErrors("");
  };

  return (
    <div>
      {showModal === true && checkIsLoggedIn()}
      {user_id && showModal ? (
        user_role === "employer" ? (
          <>
            {modalNo === 1 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div
                  style={{ height: "95vh" }}
                  className="relative w-auto max-w-3xl mx-auto my-6 md:flex md:items-center"
                >
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Hire{" "}
                        {empDetails?.first_name
                          ? `${empDetails?.first_name} ${empDetails.last_name}`
                          : "User Name"}{" "}
                      </h3>
                      <p className="text-red-600 font-base-semibold">1/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <p className="">Contact Info</p>
                          <div className="gap-2 md:flex">
                            <img
                              className="rounded-md"
                              src={
                                empDetails?.image
                                  ? empDetails.image
                                  : "/images/employee-default.png"
                              }
                              alt=""
                              width="100px"
                            />
                            <div className="space-y-2">
                              <h2 className="text-black font-xl-semibold">
                                {empDetails?.first_name
                                  ? `${empDetails?.first_name} ${empDetails?.last_name}`
                                  : "User Name"}
                              </h2>
                              <p>{position && position}</p>
                              <div className="flex-col md:flex md:flex-row md:gap-2 flex-wrap">
                                {empDetails.location && (
                                  <p className="flex text-sm">
                                    <span className="mt-1 md:mt-0">
                                      <GoLocation
                                        color="007fee"
                                        className="mr-1"
                                        size="1.2em"
                                      />
                                    </span>
                                    {empDetails.location}
                                  </p>
                                )}

                                <p className="flex text-sm items-center m-0">
                                  <span>
                                    <HiOutlinePhone
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {phone}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="block w-full gap-3 md:flex">
                            <div className="flex flex-col w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="candidateMail"
                              >
                                Candidate Email Address
                              </label>
                              <input
                                type="text"
                                name="candidateMail"
                                id="candidateMail"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateValues(e);
                                }}
                                value={values?.email}
                                className="text-sm form_input"
                                placeholder="Enter your email"
                              />
                              <span className="form_error_message">
                                {errors?.email}
                              </span>
                            </div>
                            <div className="w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="email"
                              >
                                Your Email Address
                              </label>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateValues(e);
                                }}
                                value={values?.userMail}
                                className="text-sm form_input"
                                placeholder="Enter your email"
                              />
                              <span className="form_error_message">
                                {errors?.userMail}
                              </span>
                            </div>
                          </div>
                          <div className="block w-full gap-3 md:flex">
                            <div className="w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="subject"
                              >
                                Subject
                              </label>
                              <input
                                type="text"
                                name="subject"
                                id="subject"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateValues(e);
                                }}
                                value={values?.subject}
                                className="text-sm form_input"
                                placeholder="Enter the subject..."
                              />
                              <span className="form_error_message">
                                {errors?.subject}
                              </span>
                            </div>
                          </div>

                          <div className="block w-full gap-3 md:flex">
                            <div className="w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="message"
                              >
                                Message
                              </label>
                              <textarea
                                type="text"
                                name="message"
                                rows={10}
                                id="message"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateValues(e);
                                }}
                                value={values?.message}
                                className="text-sm form_input"
                                placeholder="Enter your message here..."
                              />
                              <span className="form_error_message">
                                {errors?.message}
                              </span>
                            </div>
                          </div>
                          <p className="text-sm">
                            Submitting this application won’t change your Ring
                            of Hires profile. Application powered by Ring of
                            Hires |
                            <a href="/help-center" target="_blank">
                              <span className="ml-1 text-main">
                                Help Center
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            // setShowModal(false);
                            setModalNo(5);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={async () => {
                            if (await validate()) {
                              setModalNo(2);
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 2 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 hire-modal-width md:flex md:items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between px-6 py-5 md:px-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Hire{" "}
                        {empDetails?.first_name
                          ? `${empDetails?.first_name} ${empDetails.last_name}`
                          : "User Name"}{" "}
                      </h3>
                      <p className="text-red-600 font-base-semibold">2/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <div className="space-y-2">
                            <p className="font-normal">Attach documents </p>
                          </div>
                          <div className="space-y-6">
                            {doc.length > 0 &&
                              doc.map((item, index) => {
                                return (
                                  <div className="block">
                                    <div className="inline-flex flex-col sm:flex-row h-auto border-2 border-gray-300 rounded-md md:h-20 relative">
                                      <div className="flex-none items-center justify-center bg-red-500 border-gray-300 rounded-md md:rounded-r-none">
                                        <button className="font-bold text-white flex px-1 py-3 w-full md:w-12 md:h-full h-10 justify-center items-center">
                                          PDF
                                        </button>
                                      </div>
                                      <div className="flex-auto px-5 my-auto">
                                        <h2 className="text-black font-lg-semibold break-all">
                                          {item?.file?.name}
                                        </h2>
                                      </div>
                                      <div
                                        onClick={() => {
                                          removeResume(index);
                                        }}
                                        className="absolute w-5 h-5 cursor-pointer -right-6"
                                      >
                                        <FaWindowClose
                                          size="1.5em"
                                          color="red"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            <div className="">
                              <label className="flex flex-col items-center justify-center w-44 h-3 px-4 py-6 tracking-wide uppercase bg-white border border-gray-300 rounded-lg cursor-pointer text-blue hover:border-main hover:text-main">
                                <span className="text-sm normal-case text-center text-black hover:text-main">
                                  Upload
                                </span>
                                <input
                                  type="file"
                                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  className="hidden"
                                  name="resume"
                                  onChange={updateResume}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </label>
                              <p className="mt-2 text-gray-400 text-xs font-normal">
                                PDF, DOC, DOCX (Up to 15MB)
                              </p>
                              <p className="text-red-500 font-medium">
                                {message}
                              </p>
                              {/* {buttonDisabled && (
                                <p className="text-red-500 font-medium mt-3">
                                  Please upload a file
                                </p>
                              )} */}
                            </div>

                            <p className="text-sm">
                              Submitting this application won’t change your Ring
                              of Hires profile. Application powered by Ring of
                              Hires |
                              <a href="/help-center" target="_blank">
                                <span className="ml-1 text-main">
                                  Help Center
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setModalNo(1);
                          }}
                        >
                          Back
                        </button>
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setModalNo(3);
                          }}
                          // disabled={buttonDisabled ? true : false}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 3 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div
                  style={{ height: "95vh" }}
                  className="relative w-auto max-w-3xl mx-auto my-6 md:flex md:items-center"
                >
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg outline-none custom_shadow focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Hire{" "}
                        {empDetails?.first_name
                          ? `${empDetails?.first_name} ${empDetails.last_name}`
                          : "User Name"}{" "}
                      </h3>
                      <p className="text-red-600 font-base-semibold">3/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <p className="text-black">Review your message</p>
                              {/* <button className="text-black font-base-bold">
                              Edit
                            </button> */}
                            </div>
                          </div>
                          <div className="gap-2 md:flex">
                            <img
                              className="rounded-md self-start"
                              src={
                                empDetails.image
                                  ? empDetails?.image
                                  : "/images/employee-default.png"
                              }
                              alt=""
                              width="100px"
                            />
                            <div className="space-y-2">
                              <h2 className="text-black font-xl-semibold">
                                {empDetails?.first_name
                                  ? `${empDetails?.first_name} ${empDetails.last_name}`
                                  : "User Name"}
                              </h2>
                              <p>{position && position}</p>
                              <p className="flex flex-col items-start gap-2 text-sm space-x-4 md:flex-row flex-wrap">
                                <p className="flex text-sm">
                                  <span className="mt-1 md:mt-0">
                                    <GoLocation
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {empDetails?.location}
                                </p>
                                <p
                                  style={{ margin: "0" }}
                                  className="flex text-sm"
                                >
                                  <span>
                                    <HiOutlinePhone
                                      color="007fee"
                                      className="mr-1"
                                      size="1.2em"
                                    />
                                  </span>
                                  {phone}
                                </p>
                              </p>
                              <p className="flex text-sm">
                                <span>
                                  <HiOutlineMail
                                    color="007fee"
                                    className="mr-1"
                                    size="1.2em"
                                  />
                                </span>
                                {empDetails?.email}
                              </p>
                            </div>
                          </div>
                          <h3 className="text-xl font-bold md:text-2xl text-black">
                            {values.subject}
                          </h3>
                          <div
                            style={{ height: "fit-content" }}
                            className="max-h-80 overflow-y-auto"
                          >
                            <p className="font-medium">{values.message}</p>
                          </div>

                          {/* {line} */}
                          <div className="w-full bg-gray-200 h-0.5"></div>
                          <p className="text-sm">
                            Submitting this application won’t change your Ring
                            of Hires profile. Application powered by Ring of
                            Hires |
                            <a href="/help-center" target="_blank">
                              <span className="ml-1 text-main">
                                Help Center
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        {!loading && (
                          <>
                            <button
                              className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                              onClick={() => {
                                setModalNo(2);
                              }}
                            >
                              Back
                            </button>
                            <button
                              disabled={doubleClick}
                              onClick={hireCandidate}
                              className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                            >
                              Hire
                            </button>
                          </>
                        )}
                        {loading && <>Sending...</>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 4 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full border-0 rounded-lg outline-none bg-main custom_shadow focus:outline-none">
                    <div className="flex flex-col items-center justify-center gap-4 p-6 border-t border-solid rounded-b border-blueGray-200">
                      <img src={checkCircle} alt="" width="" />
                      <h2 className="text-3xl font-semibold text-center text-white">
                        Successfully Sent!
                      </h2>
                      <p className="w-10/12 text-xl text-center text-white ">
                        Your message has been sent to the candidate's email
                        address.
                      </p>
                      <div className="flex space-x-2">
                        {/* <button
                        className="flex items-center justify-center w-full mt-3 bg-white text-main md:w-auto md:mt-0 btn btn-base"
                        onClick={() => {
                          setModalNo(2);
                        }}
                      >
                        ok
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 5 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Discard hiring?
                      </h3>
                      {/* <button className="relative -right-11 -top-6">
                        <FaWindowClose size="1.4em" />
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <h1>
                            Your changes will not be saved if you close this
                            hiring process prior to submission.
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setModalNo(1);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            closeModal();
                            setModalNo(1);
                          }}
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 6 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Error in hiring Candidate
                      </h3>
                      {/* <button className="relative -right-11 -top-6">
                        <FaWindowClose size="1.4em" />
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <h1>{errorMesssage}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-xl">
                    Only Employers can hire this Candidate
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="ml-4 text-red-600 font-base-semibold "
                  >
                    <FaWindowClose size="1.4em" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default HireModal;
