/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo, useContext } from "react";
import useGetData from "../../../hooks/useGetData";
import { Link, useHistory } from "react-router-dom";
import InterViewAvailability from "../EmployeeDashboard/InterViewAvailability";
import usePostData from "../../../hooks/usePostData";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import { JobContext } from "./EmployerDashboard";
import HireModal from "../../modals/EmployeeDetails/HireModal";
import moment from "moment";
import SlotsPopup from "../../elements/SlotsPopup";
import { useRef } from "react";
import { RefreshContext } from "../../../App";

function HireLists({ updateRecent, setUpdateRecent }) {
  const status = [
    { applied: "Application Submitted" },
    { application_accepted: "Application Accepted" },
    { application_rejected: "Application Rejected" },
    { slot_sent: "Interview Initiated" },
    { slot_booked: "Interview Scheduled" },
    { booking_cancelled: "Interview Slot Rejected" },
    { hired: "Hired" },
    { rejected: "Application Rejected" },
    { null: "Not Available" },
    { invited: "invited" },
  ];
  const [jobIdC, setJobIdC] = useContext(JobContext);

  const history = useHistory();
  const [openBooking, setOpenBooking] = useState(false);
  const [rejectSlot, setRejectSlot] = useState(false);
  const [empId, setEmpId] = useState();
  const [jobId, setJobId] = useState();
  const [currentLog, setCurrentLog] = useState();
  // const [refresh, setRefresh] = useState(false);
  const [rejectType, setRejectType] = useState("");
  const [hireModal, setHireModal] = useState(false);
  const [employeeUrl, setEmployeeUrl] = useState();
  const [position, setPosition] = useState("");
  const [showSlots, setShowSlots] = useState(false);
  const [disableButton, setDisablebutton] = useState(false);

  const { data: employeeData } = useGetData(employeeUrl);

  const user_id = localStorage.getItem("user_id");
  const loader = <span className="text-base font-semibold">Loading...</span>;
  const [jobApplication, setJobApplication] = useState(``);

  useEffect(() => {
    if (jobIdC) {
      setJobApplication(`job-posts/${jobIdC}/applied-employees?page_size=1000`);
      setJobId(jobIdC);
    }
  }, [jobIdC]);

  useEffect(() => {
    setDisablebutton(rejectSlot || showSlots || hireModal || openBooking);
  }, [rejectSlot, showSlots, hireModal, openBooking]);

  const { data: JobApplicationData, loading: JobApplicationsLoading } =
    useGetData(jobApplication, "main", updateRecent);

  return (
    <div className="relative">
      <h1 className="text-lg mt-10 font-semibold text-black mb-4 text-left">
        Candidate Tracking
      </h1>
      <div className="max-h-96 overflow-y-auto">
        {JobApplicationsLoading ? (
          loader
        ) : JobApplicationData?.results?.length > 0 ? (
          JobApplicationData?.results?.map((singleApplication, index) => (
            <button
              key={index}
              className="w-full"
              onClick={(e) => {
                if (e.target.id !== "action_key")
                  history.push(
                    `/employees/${singleApplication.emp_id}?job_id=${jobId}`
                  );
              }}
            >
              <div className="flex flex-col lg:flex-row justify-between bg-white rounded-lg p-5 mb-4 w-full gap-2 lg:gap-0">
                <div className="text-base flex flex-col flex-1">
                  <span className="text-gray-400 mb-1">Candidate Name</span>
                  <span className="capitalize">
                    {singleApplication?.first_name +
                      " " +
                      singleApplication?.last_name}
                  </span>
                </div>
                <div className="text-base flex flex-col flex-1">
                  <span className="text-gray-400 mb-1">Job Post</span>

                  {singleApplication?.job_title ?? singleApplication?.position}
                </div>
                <div className="text-base flex flex-col flex-1">
                  <span className="text-gray-400 mb-1">Date</span>
                  {moment(
                    singleApplication?.created_at?.slice(0, 10),
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY") ?? "N/A"}
                </div>
                <div className="text-base flex flex-col flex-1">
                  <span className="text-gray-400 mb-1">Status</span>
                  {singleApplication?.application_log?.application_action ==
                  "applied" ? (
                    <div className="text-yellow-300 capitalize font-medium">
                      {status[0].applied}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "application_accepted" ? (
                    <div className="text-green-300 capitalize font-medium">
                      {status[1].application_accepted}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "application_rejected" ? (
                    <div className="text-red-600 capitalize font-medium">
                      {status[2].application_rejected}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "slot_booked" ? (
                    singleApplication?.application_log?.booking1
                      ?.booking_status == "sent" &&
                    singleApplication?.application_log?.booking2
                      ?.booking_status == "sent" &&
                    singleApplication?.application_log?.booking3
                      ?.booking_status == "sent" ? (
                      <div className="text-indigo-300 capitalize font-medium">
                        {status[3].slot_sent}
                      </div>
                    ) : singleApplication?.application_log?.booking1
                        ?.booking_status == "accepted" ||
                      singleApplication?.application_log?.booking2
                        ?.booking_status == "accepted" ||
                      singleApplication?.application_log?.booking3
                        ?.booking_status == "accepted" ? (
                      <div className="text-blue-600 capitalize font-medium">
                        {status[4].slot_booked}
                      </div>
                    ) : (
                      <div className="text-red-600 capitalize font-medium">
                        {status[5].booking_cancelled}
                      </div>
                    )
                  ) : singleApplication?.application_log?.application_action ==
                    "booking_cancelled" ? (
                    <div className="text-red-600 capitalize font-medium">
                      {status[5].booking_cancelled}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "hired" ? (
                    <div className="text-green-500 capitalize font-medium">
                      {status[6].hired}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "rejected" ? (
                    <div className="text-red-600 capitalize font-medium">
                      {status[7].rejected}
                    </div>
                  ) : singleApplication?.application_log?.application_action ==
                    "invited" ? (
                    <div className="text-green-600 capitalize font-medium">
                      {status[9].invited}
                    </div>
                  ) : (
                    <div className="text-gray-400 capitalize font-medium">
                      {status[8].null}
                    </div>
                  )}
                </div>
                <div className="text-base flex flex-center lg:flex-start justify-center lg:w-24 w-full">
                  <div className="w-24">
                    <div class="single-chart">
                      <svg
                        viewBox="0 0 36 36"
                        className={`circular-chart 
                  ${
                    singleApplication?.application_log?.application_action ==
                    "applied"
                      ? "yellow"
                      : singleApplication?.application_log
                          ?.application_action == "application_accepted"
                      ? "light-green"
                      : singleApplication?.application_log
                          ?.application_action == "application_rejected"
                      ? "red"
                      : singleApplication?.application_log
                          ?.application_action == "slot_booked"
                      ? singleApplication?.application_log?.booking1
                          ?.booking_status == "sent" &&
                        singleApplication?.application_log?.booking2
                          ?.booking_status == "sent" &&
                        singleApplication?.application_log?.booking3
                          ?.booking_status == "sent"
                        ? "light-blue"
                        : singleApplication?.application_log?.booking1
                            ?.booking_status == "accepted" ||
                          singleApplication?.application_log?.booking2
                            ?.booking_status == "accepted" ||
                          singleApplication?.application_log?.booking3
                            ?.booking_status == "accepted"
                        ? "blue"
                        : "red"
                      : singleApplication?.application_log
                          ?.application_action == "booking_cancelled"
                      ? "red"
                      : singleApplication?.application_log
                          ?.application_action == "hired"
                      ? "green"
                      : singleApplication?.application_log
                          ?.application_action == "rejected"
                      ? "red"
                      : singleApplication?.application_log
                          ?.application_action == "invited"
                      ? "green"
                      : "gray"
                  } `}
                      >
                        <path
                          class="circle-bg"
                          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                          class="circle"
                          strokeDasharray={`${
                            singleApplication?.application_log
                              ?.application_action == "applied"
                              ? "10"
                              : singleApplication?.application_log
                                  ?.application_action == "application_accepted"
                              ? "20"
                              : singleApplication?.application_log
                                  ?.application_action == "application_rejected"
                              ? "100"
                              : singleApplication?.application_log
                                  ?.application_action == "slot_booked"
                              ? singleApplication?.application_log?.booking1
                                  ?.booking_status == "sent" &&
                                singleApplication?.application_log?.booking2
                                  ?.booking_status == "sent" &&
                                singleApplication?.application_log?.booking3
                                  ?.booking_status == "sent"
                                ? "30"
                                : "50"
                              : singleApplication?.application_log
                                  ?.application_action == "booking_cancelled"
                              ? "30"
                              : singleApplication?.application_log
                                  ?.application_action == "hired"
                              ? "100"
                              : singleApplication?.application_log
                                  ?.application_action == "rejected"
                              ? "100"
                              : singleApplication?.application_log
                                  ?.application_action == "invited"
                              ? "5"
                              : "0"
                          } , 100`}
                          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" class="percentage">
                          {singleApplication?.application_log
                            ?.application_action == "applied"
                            ? "10%"
                            : singleApplication?.application_log
                                ?.application_action == "application_accepted"
                            ? "20%"
                            : singleApplication?.application_log
                                ?.application_action == "application_rejected"
                            ? "100%"
                            : singleApplication?.application_log
                                ?.application_action == "slot_booked"
                            ? singleApplication?.application_log?.booking1
                                ?.booking_status == "sent" &&
                              singleApplication?.application_log?.booking2
                                ?.booking_status == "sent" &&
                              singleApplication?.application_log?.booking3
                                ?.booking_status == "sent"
                              ? "30%"
                              : "50%"
                            : singleApplication?.application_log
                                ?.application_action == "booking_cancelled"
                            ? "30%"
                            : singleApplication?.application_log
                                ?.application_action == "hired"
                            ? "100%"
                            : singleApplication?.application_log
                                ?.application_action == "rejected"
                            ? "100%"
                            : singleApplication?.application_log
                                ?.application_action == "invited"
                            ? "5%"
                            : "N/A"}
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="text-base flex flex-col flex-1">
                  <div className="text-base flex flex-col flex-1  ">
                    {/* <span className="text-gray-400 mb-1">Action Button</span> */}
                    {singleApplication?.application_log?.application_action ==
                    "applied" ? (
                      <div className="text-yellow-300 capitalize font-medium">
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded btn-main w-full mb-2"
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setRejectSlot(true);
                            setRejectType("application_accepted");
                            setCurrentLog(singleApplication.application_log);
                          }}
                        >
                          Accept Application
                        </button>
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded btn-main w-full"
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setRejectSlot(true);
                            setRejectType("application_rejected");
                            setCurrentLog(singleApplication.application_log);
                          }}
                        >
                          Reject Application
                        </button>
                      </div>
                    ) : singleApplication?.application_log
                        ?.application_action == "application_accepted" ? (
                      <div className="text-green-300 capitalize font-medium">
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded btn-main w-full mt-2"
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setOpenBooking(true);
                          }}
                        >
                          Schedule Interview
                        </button>
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded w-full relative my-1 bg-red-500 text-white"
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setRejectSlot(true);
                            setRejectType("rejected");
                            setCurrentLog(singleApplication.application_log);
                          }}
                        >
                          Cancel Application
                        </button>
                      </div>
                    ) : singleApplication?.application_log
                        ?.application_action == "application_rejected" ? (
                      <div className="text-red-600 capitalize font-medium"></div>
                    ) : singleApplication?.application_log
                        ?.application_action == "slot_booked" ? (
                      singleApplication?.application_log?.booking1
                        ?.booking_status == "sent" &&
                      singleApplication?.application_log?.booking2
                        ?.booking_status == "sent" &&
                      singleApplication?.application_log?.booking3
                        ?.booking_status == "sent" ? (
                        <div className="text-indigo-300 capitalize font-medium">
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded btn-main w-full mb-2"
                            id={"action_key"}
                            onClick={() => {
                              setShowSlots(true);
                              if (singleApplication) {
                                setCurrentLog(
                                  singleApplication.application_log
                                );
                                setEmpId(Number(singleApplication.emp_id));
                              }
                            }}
                          >
                            View Slots
                          </button>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded btn-main w-full"
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("rejectAll");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Reject Interview
                          </button>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded w-full relative my-1 bg-red-500 text-white"
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("rejected");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Cancel Application
                          </button>
                        </div>
                      ) : singleApplication?.application_log?.booking1
                          ?.booking_status == "accepted" ||
                        singleApplication?.application_log?.booking2
                          ?.booking_status == "accepted" ||
                        singleApplication?.application_log?.booking3
                          ?.booking_status == "accepted" ? (
                        <div className="text-blue-600 capitalize font-medium">
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded btn-main w-full relative"
                            id={"action_key"}
                            onClick={() => {
                              setPosition(singleApplication.position);
                              setEmployeeUrl(
                                `employees/${singleApplication.emp_id}/public`
                              );
                              setHireModal(true);
                            }}
                          >
                            Hire Now
                          </button>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded btn-main w-full relative mt-1"
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("reschedule");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Reschedule
                          </button>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded w-full relative my-1 bg-red-500 text-white"
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("rejected");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Cancel Application
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded btn-main w-full mt-4 "
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("reschedule");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Reschedule
                          </button>
                          <button
                            disabled={disableButton}
                            className="p-1 font-semibold rounded w-full relative my-1 bg-red-500 text-white"
                            id={"action_key"}
                            onClick={() => {
                              setEmpId(Number(singleApplication.emp_id));
                              setRejectSlot(true);
                              setRejectType("rejected");
                              setCurrentLog(singleApplication.application_log);
                            }}
                          >
                            Cancel Application
                          </button>
                        </div>
                      )
                    ) : singleApplication?.application_log
                        ?.application_action == "booking_cancelled" ? (
                      <>
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded btn-main w-full mt-4 "
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setOpenBooking(true);
                          }}
                        >
                          Schedule Interview
                        </button>
                        <button
                          disabled={disableButton}
                          className="p-1 font-semibold rounded w-full relative my-1 bg-red-500 text-white"
                          id={"action_key"}
                          onClick={() => {
                            setEmpId(Number(singleApplication.emp_id));
                            setRejectSlot(true);
                            setRejectType("rejected");
                            setCurrentLog(singleApplication.application_log);
                          }}
                        >
                          Cancel Application
                        </button>
                      </>
                    ) : singleApplication?.application_log
                        ?.application_action == "hired" ? (
                      <div className="text-green-500 capitalize font-medium"></div>
                    ) : singleApplication?.application_log
                        ?.application_action == "rejected" ? (
                      <div className="text-red-600 capitalize font-medium"></div>
                    ) : (
                      <div className="text-gray-400 capitalize font-medium"></div>
                    )}
                  </div>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
            Nothing to display
          </div>
        )}
      </div>

      {rejectSlot && (
        <RejectModal
          allData={currentLog}
          rejectSlot={rejectSlot}
          setRejectSlot={setRejectSlot}
          jobid={jobId}
          to_user={empId}
          setRefresh={() => {
            setUpdateRecent((prev) => !prev);
          }}
          rejectType={rejectType}
        />
      )}
      {openBooking && (
        <div className="fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white">
          <InterViewAvailability
            emp_id={user_id}
            job_id={jobId}
            refresh={true}
            setRefresh={() => {
              setUpdateRecent((prev) => !prev);
            }}
            to_user={empId}
            onClose={() => setOpenBooking(false)}
            showCloseButton={true}
          />
        </div>
      )}
      {employeeData && position && (
        <HireModal
          showModal={hireModal}
          setShowModal={setHireModal}
          empDetails={employeeData?.data}
          position={position}
          hireRefresh={() => setUpdateRecent((prev) => !prev)}
          jobId={jobId}
        />
      )}
      {/*  */}
      {showSlots && (
        <SlotsPopup
          showSlots={showSlots}
          setShowSlots={setShowSlots}
          bookingSlots={currentLog}
          jobId={jobId}
          empId={empId}
          hireRefresh={setUpdateRecent}
        />
      )}
    </div>
  );
}

export default HireLists;

function RejectModal({
  allData,
  rejectSlot,
  setRejectSlot,
  jobid,
  to_user,
  setRefresh,
  rejectType,
}) {
  const { defaultOptions } = useToken("application/json");
  const { refreshWraper, setRefreshWraper } = useContext(RefreshContext);
  const [formData, setFormData] = useState();

  const { data: rejectData, error: err } = usePostData(
    `availability/applicationlog`,
    formData
  );
  useEffect(() => {
    if (rejectData) {
      setRefresh();
      setRejectSlot(false);
      setRefreshWraper();
    }
    if (
      rejectData &&
      (rejectType == "reschedule" || rejectType == "rejected")
    ) {
      setRefreshWraper();
    }
  }, [rejectData]);

  const bookingSlotId = useMemo(() => {
    let temp = [];
    if (allData) {
      temp.push(allData.booking1);
      temp.push(allData.booking2);
      temp.push(allData.booking3);
    }
    return temp;
  }, [allData]);

  const rejectAllSlots = () => {
    if (bookingSlotId?.length >= 3) {
      bookingSlotId.map((e) => {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}availability/booking/${e.id}`,
            { booking_status: "rejected" },
            defaultOptions
          )
          .then(() => {
            setRefresh();
            setRefreshWraper();
          });
      });
    }
  };

  const popUpRef = useRef();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        rejectSlot &&
        popUpRef.current &&
        !popUpRef.current.contains(e.target)
      ) {
        setRejectSlot(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [rejectSlot]);

  return (
    <div ref={popUpRef}>
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => {
                setRejectSlot(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to{" "}
                {rejectType == "rejectAll"
                  ? "Reject all slots"
                  : rejectType == "reschedule"
                  ? "Reschedule"
                  : rejectType == "application_rejected"
                  ? "Reject Application"
                  : rejectType == "application_accepted"
                  ? "Accept Application"
                  : rejectType == "rejected"
                  ? "Cancel the Application"
                  : "Accept Application"}
              </h3>

              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={(e) => {
                  if (rejectType === "rejectAll") {
                    rejectAllSlots();
                    setRejectSlot(false);
                  } else {
                    setFormData({
                      job: jobid,
                      to_user: to_user,
                      application_action:
                        rejectType === "reschedule"
                          ? "booking_cancelled"
                          : rejectType,
                      application: null,
                    });
                  }
                  e.target.setAttribute("disabled", true);
                }}
              >
                Yes, I'm sure
              </button>
              <button
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={() => {
                  setRejectSlot(false);
                }}
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
