import React, { useState, useEffect } from "react";
import Select from "react-select";
import { timeData } from "../../helpers/TimeData";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import moment from "moment";
import usePostData from "../../hooks/usePostData";
import usePatch from "../../hooks/usePatch";
import axios from "axios";

const customStylesTime = {
  container: (provided) => ({
    ...provided,
    // width: 140,
  }),
  control: (base) => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: -15,
  }),
};

const EditGroup = ({
  item,
  formatTime,
  index,
  setRefresh,
  setNotification,
  check = false,
}) => {
  const [id, setId] = useState(null);
  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }
  const convertTime12to24 = (time12h) =>
    moment(time12h, "hh:mmA").format("HH:mm:ss");
  const [currentTimeSlot, setCurrentTimeSlot] = useState(item);
  const [patchTrue, setPatchTrue] = useState(false);
  const [deleteTimeslotId, setDeleteTimeslotId] = useState(null);

  useEffect(() => {
    if (deleteTimeslotId) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}availability/${deleteTimeslotId}`,
          {
            headers: headerData,
          }
        )
        .then((res) => {
          setRefresh();
          setNotification({ status: true, data: "Slot deleted" });
        });
    }
  }, [deleteTimeslotId]);

  useEffect(() => {
    if (check && currentTimeSlot.from_hour && currentTimeSlot.to_hour) {
      let formData = {
        weekday: index,
        from_hour: currentTimeSlot.from_hour,
        to_hour: currentTimeSlot.to_hour,
        duration: "30",
      };
      console.log("xx0p", formData);

      axios
        .post(`${process.env.REACT_APP_API_URL}availability/`, formData, {
          headers: headerData,
        })
        .then((res) => {
          setNotification({ status: true, data: "Slot updated" });
          // if (res?.status === 201) {
          //   setId(res?.data?.data?.id);
          //   setCurrentTimeSlot((prev) => ({
          //     ...prev,
          //     id: res?.data?.data?.id,
          //   }));
          // }
        });
    }
  }, [currentTimeSlot]);

  useEffect(() => {
    if (patchTrue && currentTimeSlot.from_hour && currentTimeSlot.to_hour) {
      let formData = {
        weekday: index,
        from_hour: currentTimeSlot.from_hour,
        to_hour: currentTimeSlot.to_hour,
        duration: "30",
      };
      if (!id && !currentTimeSlot.id) {
        axios
          .post(`${process.env.REACT_APP_API_URL}availability/`, formData, {
            headers: headerData,
          })
          .then((res) => {
            setNotification({ status: true, data: "Slot updated" });
            if (res?.status === 201) {
              setId(res?.data?.data?.id);
              // setDeleteTimeslotId(res?.data?.data?.id);
              setCurrentTimeSlot((prev) => ({
                ...prev,
                id: res?.data?.data?.id,
              }));
              // setRefresh((prev) => !prev);
            }
          });
      } else {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}availability/${
              id ? id : currentTimeSlot.id
            }`,
            formData,
            {
              headers: headerData,
            }
          )
          .then((res) => {
            setNotification({ status: true, data: "Slot updated" });
            if (res?.status === "OK") {
              setPatchTrue(false);
              // setRefresh((prev) => !prev);
            }
          });
      }
    }
  }, [currentTimeSlot]);

  useEffect(() => {
    if (!currentTimeSlot) {
      // setCurrentTimeSlot((prev) => ({
      //   ...prev,
      //   from_hour: convertTime12to24(),
      // }));
    }
  }, [currentTimeSlot]);

  return (
    <>
      <div className="flex flex-row mx-4 gap-2 my-2">
        <Select
          className="react-select-container text-left custom-selectbox rounded-md w-24 sm:w-28"
          styles={customStylesTime}
          options={timeData}
          name="position"
          id="position"
          value={
            currentTimeSlot?.from_hour
              ? {
                  label: formatTime(currentTimeSlot.from_hour),
                  value: formatTime(currentTimeSlot.from_hour),
                }
              : null
          }
          onChange={(value) => {
            setPatchTrue(true);
            setCurrentTimeSlot((prev) => ({
              ...prev,
              from_hour: convertTime12to24(value.value),
            }));
          }}
          placeholder="From"
          // filterOption={customFilter}
          components={{
            IndicatorSeparator: () => null,
          }}
        />

        <Select
          className="react-select-container text-left custom-selectbox rounded-md w-24 sm:w-28"
          styles={customStylesTime}
          options={timeData}
          name="position"
          id="position"
          value={
            currentTimeSlot?.to_hour
              ? {
                  label: formatTime(currentTimeSlot.to_hour),
                  value: formatTime(currentTimeSlot.to_hour),
                }
              : null
          }
          onChange={(value) => {
            setPatchTrue(true);
            setCurrentTimeSlot((prev) => ({
              ...prev,
              to_hour: convertTime12to24(value.value),
            }));
          }}
          placeholder="To"
          // filterOption={customFilter}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
        <MdDelete
          className="m-auto w-7 h-full "
          color="000"
          onClick={() => {
            setDeleteTimeslotId(currentTimeSlot.id);
          }}
        />
      </div>
    </>
  );
};

export default function AvailabilityWeek({
  index,
  clock,
  day,
  data,
  setRefresh,
  setNotification,
}) {
  const [maxTimeSlots, setMaxTimeSlot] = useState(0);
  const [ischecked, setIschecked] = useState(data.length > 0);
  const [formtimeData, setFormtimeData] = useState({
    weekday: index,
    from_hour: "",
    to_hour: "",
    duration: "30",
  });

  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  const [modifiedData, setModifiedData] = useState(data);
  const [hideDeletedSlots, setHideDeletedSlots] = useState(false);
  const [postData, setPostData] = useState(null);
  const [inputList, setInputList] = useState([]);
  const onAddBtnClick = (event) => {
    setInputList(
      inputList.concat(
        <EditGroup
          item={null}
          index={index}
          formatTime={formatTime}
          formtimeData={formtimeData}
          setFormtimeData={setFormtimeData}
          setRefresh={setRefresh}
          setNotification={setNotification}
        />
      )
    );
  };
  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }

  useEffect(() => {
    setMaxTimeSlot(data.length);
  }, [data]);

  const {
    data: newtimeAdded,
    error,
    message,
  } = usePostData("availability/", postData);
  useEffect(() => {
    if (newtimeAdded && newtimeAdded?.status === "CREATED") {
      setRefresh();
    }
  }, [newtimeAdded]);

  const addTwoSlots = async () => {
    try {
      // First POST request
      const response1 = await axios.post(
        `${process.env.REACT_APP_API_URL}availability/`,
        {
          weekday: index,
          from_hour: "08:00:00",
          to_hour: "12:00:00",
          duration: "30",
        },
        {
          headers: headerData,
        }
      );

      // Check the response of the first request
      if (response1) {
        // Second POST request
        const response2 = await axios.post(
          `${process.env.REACT_APP_API_URL}availability/`,
          {
            weekday: index,
            from_hour: "13:00:00",
            to_hour: "17:00:00",
            duration: "30",
          },
          {
            headers: headerData,
          }
        );

        // Check the response of the second request
        if (response2) {
          setRefresh();
          setNotification({ status: true, data: "Slots Created" });
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the requests
      console.error("Error:", error);
    }
  };

  const removeAllSlots = () => {
    // console.log(data, "click off");
    let ids = data.map((e) => e.id);
    console.log(ids, "click off");
    if (ids.length > 0) {
      const deletePromises = ids.map((id) => {
        return axios.delete(
          `${process.env.REACT_APP_API_URL}availability/${id}`,
          {
            headers: headerData,
          }
        );
      });

      Promise.all(deletePromises)
        .then((responses) => {
          responses.forEach((res) => {
            console.error(res, "delete");
          });
          setRefresh();
          setNotification({ status: true, data: "Slots deleted" });
          setHideDeletedSlots(true);
        })
        .catch((error) => {
          console.error("Error deleting slots:", error);
        });
    }
  };

  return (
    <div className="relative">
      <div className="flex flex-col mobile:flex-row  relative ">
        <div className="flex w-10">
          <MdAddCircleOutline
            onClick={() => {
              onAddBtnClick();
            }}
            key={day}
            className="my-5 w-8 h-8 absolute right-0"
            color="000"
          />
          <input
            type="checkbox"
            className="my-2 mobile:my-5 h-5 w-5"
            checked={ischecked}
            onChange={() => {
              setIschecked((prev) => !prev);
              if (!ischecked) {
                addTwoSlots();
              }
              if (ischecked) {
                removeAllSlots();
              }
            }}
          />
        </div>

        <h1 className="flex text-left py-0 mobile:py-4 font-semibold w-24 lg:mx-10">
          {day}
        </h1>
        <div className="flex flex-col">
          {data.map((item) => (
            <>
              <EditGroup
                key={item}
                item={item}
                index={index}
                formatTime={formatTime}
                setRefresh={setRefresh}
                setNotification={setNotification}
              />
            </>
          ))}
          {inputList}
        </div>
      </div>
      <div className="line"></div>
    </div>
  );
}
