import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from "react-accessible-accordion";
  import "react-accessible-accordion/dist/fancy-example.css";
  
function PaymentFaq() {
  return (
    <div className="lg:col-span-3 rounded-md border-2 border-gray-100 shadow-lg p-7">
      <h2 className="text-2xl font-bold">Questions?</h2>
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              I'm having problems signing into my account. What should I do?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Please email{" "}
              <a
                href="mailto:support@ringofhires.com"
                className="font-semibold"
              >
                support@ringofhires.com
              </a>{" "}
              with details of your issues with signing into your account.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              I forgot my username/password. How can I retrieve it?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Click link:{" "}
              <a href="/forgot-password" className="font-semibold">
                Forgot password?
              </a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Who should I contact if I face any problems while using my
              account?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Please email{" "}
              <a
                href="mailto:support@ringofhires.com"
                className="font-semibold"
              >
                support@ringofhires.com
              </a>{" "}
              with details of your issues with your account.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default PaymentFaq;
