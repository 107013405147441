import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import useGetData from "../../../hooks/useGetData";
import usePostData from "../../../hooks/usePostData";
import Select from "react-select";
import AvailabilityWeek from "../../elements/AvailabilityWeek";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import {
  MdVideocam,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdKeyboardArrowRight,
  MdDelete,
} from "react-icons/md";
import Calendar from "react-calendar";
import TimeList from "../../elements/TimeList";
import axios from "axios";
import usePatch from "../../../hooks/usePatch";
import { RefreshContext } from "../../../App";

export default function InterViewAvailability({
  emp_id,
  job_id,
  refresh,
  setRefresh,
  to_user,
  onClose,
  showCloseButton,
  customTime = false,
  customId = null,
}) {
  const [date, setDate] = useState(new Date());
  const [calendarDisplay, setCalendarDisplay] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [formData, setFromData] = useState(null);
  const [updateCustomSlot, setupdateCustomSlot] = useState(null);
  const [bookedNotification, setBookedNotification] = useState(false);
  const [alreadyBooked, setAlreadyBooked] = useState(null);
  const [selectedtimeSlots, SetSelectedTimeSlots] = useState([]);
  const [duration, setDuration] = useState(30);
  const [disAbleSaveButtom, setDisAbleSaveButtom] = useState(false);
  const [notification, setNotification] = useState(false);
  const [lessData, setLessData] = useState(false);
  const history = useHistory();
  const { refreshWraper, setRefreshWraper } = useContext(RefreshContext);

  let token = localStorage.getItem("accessToken");
  let user_role = localStorage.getItem("user_role");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  useEffect(() => {
    if (bookInterviewSlot) {
      setTimeout(() => {
        setBookedNotification(false);
        setDisAbleSaveButtom(false);
        setAlreadyBooked(null);
      }, 5000);
    }
  }, [bookedNotification]);

  const AvailabilityUrl = `availability/public/${emp_id}`;
  // const postAvailabilityUrl = "";
  const { data: AvailabilityData } = useGetData(AvailabilityUrl);
  const [bookingUrl, setBookingUrl] = useState("availability/booking");

  const { data, error, message, code } = usePostData(bookingUrl, formData);

  const { data: updatedSlot, error: updatedSlotError } = usePatch(
    bookingUrl,
    updateCustomSlot
  );

  useEffect(() => {
    if (updatedSlot?.code == 200) {
      setBookedNotification(true);
      setRefreshWraper();
      setTimeout(() => {
        onClose();
        setRefresh();
      }, 3000);
    }
  }, [updatedSlot, updatedSlotError]);

  useEffect(() => {
    if (data?.code == 201) {
      setBookedNotification(true);
      setAlreadyBooked(null);
      setRefreshWraper();
      setTimeout(() => {
        onClose();
        setRefresh();
      }, 3000);
    } else if (error && code == 403) {
      setBookedNotification(true);
      setAlreadyBooked(error);
    }
  }, [data, error]);

  const currentSlots = useMemo(
    () =>
      AvailabilityData?.data.filter(
        (ele) => ele.weekday === moment(date).day() + 1
      ),
    [date, AvailabilityData]
  );
  function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  useEffect(() => {
    if (selectedSlot) {
      let newObject = {
        event_date: moment(date).format("YYYY-MM-DD"),
        from_hour: moment(selectedSlot, "h:mm A").format("HH:mm:ss"),
        to_hour: moment(selectedSlot, "h:mm A")
          .add(Number(duration), "m")
          .format("HH:mm:ss"),
      };
      if (
        !customTime &&
        selectedtimeSlots.length < 3 &&
        selectedSlot &&
        date &&
        !selectedtimeSlots.some(
          (obj) => JSON.stringify(obj) === JSON.stringify(newObject)
        )
      ) {
        let newArr = [...selectedtimeSlots, newObject];
        SetSelectedTimeSlots(newArr);
      } else if (customTime) {
        let newArr = [newObject];
        SetSelectedTimeSlots(newArr);
      } else if (
        selectedtimeSlots.some(
          (obj) => JSON.stringify(obj) === JSON.stringify(newObject)
        )
      ) {
        setNotification(true);
      } else {
        console.log("pick somthing else");
      }
    }
  }, [selectedSlot]);

  useEffect(() => {
    setTimeout(() => {
      if (notification) {
        setLessData(false);
        setNotification(false);
      }
      if (lessData) {
        setLessData(false);
        setNotification(false);
      }
    }, 3000);
  }, [notification, lessData]);

  const bookInterviewSlot = () => {
    if (!customTime && selectedtimeSlots?.length === 3) {
      setBookingUrl("availability/booking");
      setFromData({
        to_user: to_user,
        job: job_id,
        timings: JSON.stringify(selectedtimeSlots),
      });
      setDisAbleSaveButtom(true);
    } else if (customTime && customId && selectedtimeSlots?.length === 1) {
      setBookingUrl(
        `availability/update-accepted-interview-slot/?id=${customId}`
      );
      setupdateCustomSlot({
        event_date: selectedtimeSlots[0].event_date,
        from_hour: selectedtimeSlots[0].from_hour,
        to_hour: selectedtimeSlots[0].to_hour,
      });
      setDisAbleSaveButtom(true);
    } else {
      setLessData(true);
    }
    // setRefresh((prev) => !prev);
  };
  useEffect(() => {
    setSelectedSlot(null);
  }, [date]);

  const ActiveWeek = useMemo(() => {
    let temp = [];

    AvailabilityData?.data.map((e) => {
      temp.push(e.weekday - 1);
    });

    return temp;
  }, [AvailabilityData]);

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (date < moment().subtract(1, "day")) {
      return true;
    }
    if (!ActiveWeek.includes(date.getDay())) {
      return true;
    }
  };
  const InterViewAvailabilityRef = useRef(null);
  // if (!AvailabilityData) return <></>;

  useEffect(() => {
    scrollToBooking();
  }, []);

  const scrollToBooking = () => {
    if (InterViewAvailabilityRef)
      InterViewAvailabilityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  };

  return (
    <div className="relative" ref={InterViewAvailabilityRef}>
      {AvailabilityData && AvailabilityData?.data.length <= 0 ? (
        <div className="w-screen inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none bg-transparent">
          <div className="relative w-auto max-w-3xl mx-auto flex items-center">
            {/*content*/}
            <div className="relative flex flex-col w-5/6 md:w-full mx-auto border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                <h3 className="text-xl font-semibold md:text-2xl">
                  Interview Scheduling
                </h3>
                <AiOutlineClose
                  className="cursor-pointer h-5 w-5 hover:text-gray-600 text-black"
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
              {/*body*/}
              <div className="relative flex-auto p-4">
                <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                  <div className="space-y-6">
                    {user_role == "employer" ? (
                      <h1>
                        Please provide your availability before scheduling the
                        interview !
                      </h1>
                    ) : (
                      <h1>
                        Employer didn't provide any availability, check back
                        later !
                      </h1>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                {user_role == "employer" && (
                  <Link to={"/profile/availability"}>
                    <button
                      onClick={() => {
                        localStorage.setItem("redirection_availability", true);
                      }}
                      className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                    >
                      Add Availability
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full p-2 jobDescription custom_shadow font-base-light overflow-y-auto md:overflow-y-hidden max-h-screen">
          <div className="flex flex-col mb-6 mx-6 relative px-2">
            <div className="flex flex-row relative mx-4">
              <h1 className="text-xl font-semibold text-black mb-2 mt-5 lg:mb-0 text-left">
                Availability
              </h1>
              <AiOutlineClose
                className="cursor-pointer h-5 w-5 hover:text-gray-600 text-black absolute top-5 right-0"
                onClick={() => {
                  onClose();
                }}
              />
            </div>

            <div className="flex flex-col mx-4">
              <div>
                {!customTime && (
                  <h1 className="text-gray-700 pb-2">
                    Select three time slots of Interview
                  </h1>
                )}
                <div className="flex flex-row justify-between ">
                  <div className="flex flex-row justify-right">
                    <FaCalendarAlt
                      onClick={() => setCalendarDisplay(!calendarDisplay)}
                      className="text-white bg-blue-700 py-2 w-10 h-10 rounded-md "
                      size="2em"
                    />

                    <h1 className="text-center p-2 font-bold">
                      {moment(date).format("DD MMMM YYYY")}
                    </h1>

                    <MdKeyboardArrowLeft
                      onClick={() => {
                        let temp = moment(date).subtract(1, "d");
                        setDate(temp);
                      }}
                      className="text-black bg-gray-200 rounded-full h-10 w-10 p-2 mx-1 hover:bg-gray-400"
                      size="1.5em"
                    />
                    <MdKeyboardArrowRight
                      onClick={() => {
                        let temp = moment(date).add(1, "d");
                        setDate(temp);
                      }}
                      className="text-black bg-gray-200 rounded-full h-10 w-10 p-2 mx-1 hover:bg-gray-400"
                      size="1.5em"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Select Duration of the Interview
                </label>
                <select
                  value={duration}
                  className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option value="15">15 Min</option>
                  <option value="30">30 Min</option>
                  <option value="45">45 Min</option>
                  <option value="60">1 Hour</option>
                </select>
              </div>
            </div>

            <div className="relative h-auto py-2 flex flex-col md:flex-row">
              <Calendar
                onChange={setDate}
                value={new Date(date)}
                tileDisabled={tileDisabled}
              />
              <div className="my-4 mx-2 w-full md:w-1/2">
                <TimeList
                  currentSlots={currentSlots}
                  date={moment(date).format("YYYY-MM-DD")}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  duration={duration}
                  to_user={to_user}
                  job_id={job_id}
                />
              </div>
            </div>

            {selectedtimeSlots.length > 0 && (
              <div className="mx-4">
                <h1 className="font-semibold">
                  Selected Time Slots{" "}
                  {lessData && (
                    <span className="text-sm text-red-500">
                      &#40;please select three time slots&#41;
                    </span>
                  )}
                  {notification && (
                    <span className="text-sm text-red-500">
                      &#40;please select a different time slot&#41;
                    </span>
                  )}
                </h1>
                {selectedtimeSlots.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className=" border-2 py-4 px-4 my-2 text-center font-semibold mx-2 rounded-md relative"
                    >
                      {moment(ele.event_date).format("MMM DD")} -{" "}
                      {moment(ele.from_hour, "HH:mm:ss").format("h:mm a")}
                      <MdDelete
                        onClick={() => {
                          let temp = selectedtimeSlots?.filter(
                            (e) => e !== ele
                          );
                          SetSelectedTimeSlots(temp);
                        }}
                        className="absolute text-black top-0 bottom-0 mx-auto my-4 right-6"
                        size="1.5em"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div className="line"></div>
            <div className="pt-6 flex flex-row justify-between">
              <div></div>
              <button
                className="w-48 py-4 font-semibold rounded btn-main hover:bg-blue-700"
                onClick={bookInterviewSlot}
                disabled={disAbleSaveButtom}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {bookedNotification && (
        <RejectModal alreadyBooked={alreadyBooked} customTime={customTime} />
      )}
    </div>
  );
}

function RejectModal({ alreadyBooked, customTime }) {
  return (
    <>
      <div className="fixed mr-4 w-3/4 z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-2 rounded-lg px-4 md:px-24 bg-white shadow dark:bg-gray-700 overflow-hidden">
        <div className="md:h-auto">
          <div className="">
            <div className="p-2 text-center relative ">
              {alreadyBooked ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="mx-auto w-16 h-16"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="red"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              ) : (
                <svg
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="mx-auto w-14 h-14"
                  fill="none"
                >
                  <path
                    fill="blue"
                    d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"
                  />
                </svg>
              )}
              <h3 className="mb-2 text-gray-900 dark:text-gray-400 text-3xl font-bold">
                {alreadyBooked ? "Error!" : "Congratulations!"}
              </h3>
              <h3 className="mb-0 w-full text-lg font-normal text-gray-500 dark:text-gray-400">
                {alreadyBooked
                  ? "These slots are already selected before. Please select different slots"
                  : customTime
                  ? "You have Updated the current slot"
                  : "You have scheduled three slots"}
                .
              </h3>
              <div className="my-2">
                {alreadyBooked &&
                  alreadyBooked.map((ele, index) => {
                    return (
                      <div
                        key={index}
                        className=" border-2  border-red-500 py-1 px-4 my-2 text-center font-semibold mx-2 rounded-md relative"
                      >
                        {moment(ele.event_date).format("MMM DD")} -{" "}
                        {moment(ele.from_hour, "HH:mm:ss").format("h:mm a")}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
