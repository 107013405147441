import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useHistory, useLocation, Link, useRouteMatch } from "react-router-dom";
import { MapContext } from "../../App";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import defaultlogo from "../../images/JobDetails/hospital-default.jpg";
import { ImLocation2 } from "react-icons/im";
import MultipleJobs from "../modals/Job/MultipleJobs";
import loadingRoh from "../../images/gif/loader.gif";

import supercluster from "points-cluster";

import { AccordionItemState } from "react-accessible-accordion";

const CenterPin = () => (
  <div className="pin z-0">
    <ImLocation2 size={20} color="crimson" />
  </div>
);

const map_options = {
  scrollwheel: true,
  // gestureHandling: "greedy",
};

const Map = ({
  zoomLevel,
  lookingToHire,
  handleMapAddress,
  geolocationMarker,
  geolocationError,
  markerFlag,
  updateListing,
  filters,
  page,
  refresh,
  getJobData,
  showZoom = true,
  loading,
  showRadius,
  usersJobId = null,
  autoZoomFalse = true,
  setAutoZoomFalse,
}) => {
  const [render, setRender] = useState(true);
  const [currentLatitiude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [mapOptions, setMapOptions] = useState({
    scrollwheel: true,
    minZoom: 4,
  });
  const [coordinates, setCoordinates] = useState({});
  const [ownJobPopUp, setOwnJobPopUp] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const { path } = useRouteMatch();
  console.log("path..", path);

  const circleRef = useRef(false);
  const hoverRef = useRef(false);
  const href = window.location.href;
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
    circles,
    setCircles,
    clusters,
    setClusters,
    employerJobs,
    setEmployerJobs,
  ] = useContext(MapContext);

  const userId = localStorage.getItem("user_id");

  const [jobClusters, setJobClusters] = useState([]);

  const [zoom, setZoom] = useState(zoomlvl);
  const [mapAddress, setMapAddress] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [showHireCard, setShowHireCard] = useState(false);
  const [showSameCard, setShowSameCard] = useState(false);
  const [hirePopUpTop, setHirePopUpTop] = useState(true);
  const mapRef = useRef();
  const [headYloction, setHeadYloction] = useState(288);

  //check queries in url
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [details, setDetails] = useState({
    lat: 0,
    lng: 0,
    price: "",
    post: "",
    hospital: "",
    location: "",
    img: "",
    id: "",
    sameLocationData: [],
  });

  const [multJobs, setMultJobs] = useState({
    lat: 0,
    lng: 0,
    price: "",
    post: "",
    hospital: "",
    location: "",
    img: "",
    id: "",
    sameLocationData: [],
  });
  const location = {
    address: "",
    lat: 27.964157,
    lng: -82.452606,
  };
  const [mapsOptions, setMapsOptions] = useState({
    center: marker.lat ? marker : location,
    zoom: zoomlvl,
  });

  const history = useHistory();

  const handleCardShow = (
    e,
    lat,
    lng,
    maxHr,
    minHr,
    maxYr,
    minYr,
    post,
    hospital,
    location,
    img,
    id,
    salary_type,
    salary_subtype,
    emp_id,
    category_unverified_image,
    category_verified_image
  ) => {
    e.stopPropagation();
    setShowCard(true);
    setOwnJobPopUp(false);
    setDetails({
      lat: lat,
      lng: lng,
      max_salary: maxHr,
      min_salary: minHr,
      max_yearly_pay: maxYr,
      min_yearly_pay: minYr,
      post: post,
      hospital: hospital,
      location: location,
      img: img,
      id: id,
      salary_type: salary_type,
      salary_subtype: salary_subtype,
      emp_id,
      category_unverified_image,
      category_verified_image,
    });
  };

  //check for location query in url and set marker to that location
  useEffect(() => {
    if (query.get("location")) {
      geocodeByAddress(query.get("location"))
        .then((results) => {
          return getLatLng(results[0]);
        })
        .then((latLng) => getSelectedLocation(latLng))
        .catch((error) =>
          setMarkerLocation((currentData) => ({
            ...currentData,
            lat: 40.73061,
            lng: -73.935242,
          }))
        );
    }
  }, []);

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  const handleViewJob = (e, id) => {
    e.stopPropagation();
    if (id) {
      history.push(`/job-details/${id}`);
    } else {
      history.push(`/job-details/${details.id}`);
    }
  };

  const redirectToHireSearch = (e, id) => {
    window.location.href = `/hireSearch/id=${id}`;
  };

  const DisplayCard = () => {
    console.log("details...", details);
    let salarySubType = details?.salary_subtype
      ? Object.values(details?.salary_subtype)
      : "";
    let salaryType = details?.salary_type
      ? Object.values(details?.salary_type)
      : "";
    return (
      <div
        onMouseEnter={() => {
          hoverRef.current = true;
        }}
        onMouseLeave={() => {
          hoverRef.current = false;
          setShowCard(false);
        }}
        className={`z-10 flex flex-row bg-white w-64 h-auto rounded-sm absolute 
        -left-24
        ${hirePopUpTop ? "bottom-12" : "bottom-[-100px]"}
        `}
      >
        {!details.img &&
        !details.category_unverified_image &&
        !details.category_verified_image ? (
          <img src={defaultlogo} className="w-2/5" />
        ) : (
          <img
            src={
              details.img
                ? details.img
                : details.emp_id == null
                ? details.category_unverified_image
                : details.category_verified_image
            }
            className="w-2/5"
          />
        )}

        <div className="bg-white flex flex-col gap-1 p-2 flex-1 items-start">
          <h6 className="font-bold">{details.post}</h6>
          <span className="text-gray-700">{details.hospital}</span>
          <div className="flex flex-row text-gray-700 relative">
            <span className="absolute">
              <ImLocation2 size={16} />
            </span>

            <p className="text-left ml-4">{details.location}</p>
          </div>

          <h5 className="text-main font-bold text-xs text-left">
            {salaryType[0] === "Fixed" && (
              <p className="font-bold text-main">
                {details.max_salary
                  ? "$" + details.max_salary.toLocaleString() + " "
                  : ""}
                {details.max_salary ? "/" : ""} {salarySubType[0]}
              </p>
            )}
            {salaryType[0] !== "Fixed" && (
              <p className="font-bold text-main">
                {details?.min_salary || details?.max_salary ? (
                  <>
                    {details?.min_salary
                      ? "$" + details?.min_salary.toLocaleString() + "/Hr"
                      : ""}
                    {details?.min_salary && details?.max_salary && "-"}
                    {details?.max_salary
                      ? "$" + details?.max_salary.toLocaleString() + "/Hr"
                      : ""}
                  </>
                ) : details?.min_yearly_pay || details?.max_yearly_pay ? (
                  <>
                    {details?.min_yearly_pay
                      ? "$" + details?.min_yearly_pay.toLocaleString() + "/Yr"
                      : ""}
                    {details?.min_yearly_pay && details?.max_yearly_pay && "-"}
                    {details?.max_yearly_pay
                      ? "$" + details?.max_yearly_pay.toLocaleString() + "/Yr"
                      : ""}
                  </>
                ) : (
                  ""
                )}
              </p>
            )}
          </h5>

          <button
            onClick={(e) => handleViewJob(e)}
            className="btn-main rounded-md py-1 w-full mt-auto"
          >
            View job
          </button>

          {userId && details?.emp_id !== null && userId == details?.emp_id && (
            <button
              onClick={(e) => redirectToHireSearch(e, details.id)}
              className="bg-tooltip text-white rounded-md py-1 w-full mt-auto"
            >
              View Job Seekers
            </button>
          )}
        </div>
      </div>
    );
  };
  const DisplayOwnJobsCard = () => {
    return (
      <div
        onMouseEnter={() => {
          hoverRef.current = true;
          setMapOptions({ ...mapOptions, scrollwheel: false });
        }}
        onMouseLeave={() => {
          hoverRef.current = false;
          setOwnJobPopUp(false);
          setMapOptions({ ...mapOptions, scrollwheel: true });
        }}
        className={`z-10 flex flex-col gap-4 w-64 h-36 bg-gray-100 overflow-y-scroll rounded-sm absolute 
        ${hirePopUpTop ? "bottom-12" : "bottom-[-100px]"}
        -left-24`}
      >
        {details?.sameLocationData?.map((item) => {
          let salarySubType = item?.salary_subtype
            ? Object.values(item?.salary_subtype)
            : null;
          let salaryType = item?.salary_type
            ? Object.values(item?.salary_type)
            : null;

          return (
            <div className="z-10 flex flex-row bg-white w-60 h-auto rounded-sm">
              {!item.image &&
              !item.category_unverified_image &&
              !item.category_verified_image ? (
                <img src={defaultlogo} className="w-2/5" />
              ) : (
                <img
                  src={
                    item.image
                      ? item.image
                      : item.emp_id == null
                      ? item.category_unverified_image
                      : item.category_verified_image
                  }
                  className="w-2/5"
                />
              )}

              <div className="bg-white flex flex-col gap-1 p-2 flex-1 items-start">
                <h6 className="font-bold">
                  {item.job_title ? item.job_title : item.position_label}
                </h6>
                <span className="text-gray-700">{item.hospital}</span>
                <div className="flex flex-row text-gray-700 text-left">
                  <span className="absolute">
                    <ImLocation2 size={16} />
                  </span>
                  <p className="text-left ml-4">
                    {item?.location_details?.address}
                  </p>
                </div>
                <h5 className="text-main font-bold text-xs text-left">
                  {salaryType?.[0] === "Fixed" && (
                    <p className="my-2 font-bold text-main">
                      {item?.max_salary
                        ? "$" + item.max_salary.toLocaleString() + "  "
                        : ""}
                      {item?.max_salary ? "/" : ""}
                      {salarySubType?.[0]}
                    </p>
                  )}
                  {salaryType?.[0] !== "Fixed" && (
                    <p className="font-bold text-main">
                      {item?.min_salary || item?.max_salary ? (
                        <>
                          {item?.min_salary
                            ? "$" + item?.min_salary.toLocaleString() + "/Hr"
                            : ""}
                          {item?.min_salary && item?.max_salary && "-"}
                          {item?.max_salary
                            ? "$" + item?.max_salary + "/Hr"
                            : ""}
                        </>
                      ) : item?.min_yearly_pay || item?.max_yearly_pay ? (
                        <>
                          {item?.min_yearly_pay
                            ? "$" +
                              item?.min_yearly_pay.toLocaleString() +
                              "/Yr"
                            : ""}
                          {item?.min_yearly_pay && item?.max_yearly_pay && "-"}
                          {item?.max_yearly_pay
                            ? "$" +
                              item?.max_yearly_pay.toLocaleString() +
                              "/Yr"
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </h5>
                <button
                  onClick={(e) => handleViewJob(e, item.id)}
                  className="btn-main rounded-md py-1 w-full"
                >
                  View job
                </button>

                {userId && item?.emp_id !== null && userId == item?.emp_id && (
                  <button
                    onClick={(e) => redirectToHireSearch(e, item.id)}
                    className="bg-tooltip text-white rounded-md py-1 w-full mt-auto"
                  >
                    View Job Seekers
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const handleHireCardShow = (e, lat, lng, price, name, post, img, id) => {
    e.stopPropagation();
    setShowHireCard(true);
    setDetails({
      lat: lat,
      lng: lng,
      price: price,
      name: name,
      post: post,
      img: img,
      id: id,
    });
  };

  const handleViewProfile = (e, id) => {
    e.stopPropagation();
    if (id) {
      history.push(`/employees/${id}`);
    } else {
      history.push(`/employees/${details.id}`);
    }
  };

  let randomStr = "XXXXX XXXX";
  const DisplayHireCard = () => {
    console.log(details, "img......");
    return (
      <div
        onMouseEnter={() => (hoverRef.current = true)}
        onMouseLeave={() => {
          hoverRef.current = false;
          setShowHireCard(false);
        }}
        className={`z-10 flex flex-row bg-white w-64 h-auto rounded-sm absolute  -left-24
        ${hirePopUpTop ? "bottom-12" : "bottom-[-100px]"}
        `}
      >
        <div className={`flex flex-row w-full`}>
          {details.img === null ? (
            <img src={defaultlogo} className={`w-2/5 blurimage`} />
          ) : (
            <img
              src={details.img ? details.img : "/images/employee-default.png"}
              className={`w-2/5 ${!details.name && "blurimage"}`}
            />
          )}

          <div className="bg-white flex flex-col gap-1 p-2 flex-1 items-start">
            <h6 className={`font-bold ${!details.name && "blurtext"}`}>
              {details.name ? details.name : randomStr}
            </h6>
            <span className="text-gray-700">{details.post}</span>
            <h5 className="text-main font-bold text-xs">
              {details.price && `$${details.price}/Hr`}
            </h5>
            {!details.name ? (
              <button
                onClick={() => history.push("/profile/my-plan")}
                className="text-white rounded-md py-1 w-full mt-auto bg-tooltip"
              >
                Upgrade
              </button>
            ) : (
              <button
                onClick={(e) => handleViewProfile(e)}
                className="btn-main rounded-md py-1 w-full mt-auto"
              >
                View Profile
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleSameCardShow = (e, lat, lng, data) => {
    e.stopPropagation();
    setDetails({
      lat: lat,
      lng: lng,
      sameLocationData: data,
    });
    setShowSameCard(true);
  };

  const DisplaySameLocationCard = () => {
    return (
      <div
        onMouseEnter={() => {
          hoverRef.current = true;
          setMapOptions({ ...mapOptions, scrollwheel: false });
        }}
        onMouseLeave={() => {
          hoverRef.current = false;
          setShowSameCard(false);
          setMapOptions({ ...mapOptions, scrollwheel: true });
        }}
        className={`z-10 flex flex-col gap-4 w-64 h-36 bg-gray-100 overflow-y-scroll rounded-sm absolute 
        ${hirePopUpTop ? "bottom-12" : "bottom-[-100px]"}
        -left-24`}
      >
        {details?.sameLocationData?.map((item) => {
          let salarySubType = item?.salary_subtype
            ? Object.values(item?.salary_subtype)
            : null;
          let salaryType = item?.salary_type
            ? Object.values(item?.salary_type)
            : null;

          if (!lookingToHire) {
            return (
              <div className="z-10 flex flex-row bg-white w-60 h-auto rounded-sm">
                {!item.image &&
                !item.category_unverified_image &&
                !item.category_verified_image ? (
                  <img src={defaultlogo} className="w-2/5" />
                ) : (
                  <img
                    src={
                      item.image
                        ? item.image
                        : item.emp_id == null
                        ? item.category_unverified_image
                        : item.category_verified_image
                    }
                    className="w-2/5"
                  />
                )}

                <div className="bg-white flex flex-col gap-1 p-2 flex-1 items-start">
                  <h6 className="font-bold">{item.position}</h6>
                  <span className="text-gray-700">{item.hospital}</span>
                  <div className="flex flex-row text-gray-700 text-left">
                    <span className="absolute">
                      <ImLocation2 size={16} />
                    </span>
                    <p className="text-left ml-4">{item.address}</p>
                  </div>
                  <h5 className="text-main font-bold text-xs text-left">
                    {salaryType?.[0] === "Fixed" && (
                      <p className="my-2 font-bold text-main">
                        {item?.max_salary
                          ? "$" + item.max_salary.toLocaleString() + "  "
                          : ""}
                        {item?.max_salary ? "/" : ""}
                        {salarySubType?.[0]}
                      </p>
                    )}
                    {salaryType?.[0] !== "Fixed" && (
                      <p className="font-bold text-main">
                        {item?.min_salary || item?.max_salary ? (
                          <>
                            {item?.min_salary
                              ? "$" + item?.min_salary.toLocaleString() + "/Hr"
                              : ""}
                            {item?.min_salary && item?.max_salary && "-"}
                            {item?.max_salary
                              ? "$" + item?.max_salary + "/Hr"
                              : ""}
                          </>
                        ) : item?.min_yearly_pay || item?.max_yearly_pay ? (
                          <>
                            {item?.min_yearly_pay
                              ? "$" +
                                item?.min_yearly_pay.toLocaleString() +
                                "/Yr"
                              : ""}
                            {item?.min_yearly_pay &&
                              item?.max_yearly_pay &&
                              "-"}
                            {item?.max_yearly_pay
                              ? "$" +
                                item?.max_yearly_pay.toLocaleString() +
                                "/Yr"
                              : ""}
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    )}
                  </h5>
                  <button
                    onClick={(e) => handleViewJob(e, item.id)}
                    className="btn-main rounded-md py-1 w-full"
                  >
                    View job
                  </button>
                </div>
              </div>
            );
          } else {
            return (
              <div className="z-10 flex flex-row bg-white w-60 h-auto rounded-sm">
                <div className={`flex flex-row`}>
                  <img
                    src={
                      item.image ? item.image : "/images/employee-default.png"
                    }
                    className={`w-2/5 ${!item.name && "blurimage"}`}
                  />
                  <div className="bg-white flex flex-col gap-1 p-2 flex-1 items-start">
                    <h6 className={`font-bold ${!item.name && "blurtext"}`}>
                      {item.name ? item.name : randomStr}
                    </h6>
                    <span className="text-gray-700">{item.position}</span>
                    <h5 className="text-main font-bold text-xs">
                      {item.desired_pay && `$${item.desired_pay}/Hr`}
                    </h5>
                    {item.name ? (
                      <button
                        onClick={(e) => handleViewProfile(e, item.user_id)}
                        className="btn-main rounded-md py-1 w-full mt-auto"
                      >
                        View Profile
                      </button>
                    ) : (
                      <button
                        onClick={() => history.push("/profile/my-plan")}
                        className="text-white rounded-md py-1 w-full mt-auto bg-tooltip"
                      >
                        Upgrade
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const LocationPin = ({
    lat,
    lng,
    maxHr,
    minHr,
    maxYr,
    minYr,
    post,
    hospital,
    location,
    img,
    id,
    salary_type = { null: null },
    salary_subtype = { null: null },
    emp_id,
    category_unverified_image,
    category_verified_image,
  }) => {
    // let salarySubType = Object.values(salary_subtype);
    const userId = localStorage.getItem("user_id");
    let salaryType = salary_type ? Object.values(salary_type) : {};
    const hirePinRef = useRef();
    return (
      <Link to={`/job-details/${id}`}>
        <div
          ref={hirePinRef}
          onMouseEnter={(e) => {
            setHirePopUpTop(
              hirePinRef?.current.getBoundingClientRect().top - 230 >
                headYloction
            );
            handleCardShow(
              e,
              lat,
              lng,
              maxHr,
              minHr,
              maxYr,
              minYr,
              post,
              hospital,
              location,
              img,
              id,
              salary_type,
              salary_subtype,
              emp_id,
              category_unverified_image,
              category_verified_image
            );
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              if (!hoverRef.current) {
                setShowCard(false);
              }
            }, 100);
          }}
          className="relative group flex flex-col items-center ml-2"
        >
          {/* <Icon icon={locationIcon} className="pin-icon" /> */}
          {/* <AiFillEnvironment /> */}
          {/* individuals jobs*/}
          <span
            style={{
              boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
            }}
            className={`rounded-lg  text-white absolute bottom-1 group-hover:bg-tooltip
            ${userId == emp_id && emp_id !== null ? " bg-gold" : " bg-main"}
            ${
              zoom >= 10
                ? "text-xs sm:text-base p-1 sm:p-2 font-semibold"
                : zoom >= 8
                ? "text-xs sm:text-sm p-1 sm:p-2 font-normal"
                : "text-xs p-1 font-normal"
            }`}
          >
            {salaryType[0] === "Fixed" &&
              (maxHr ? (
                `$${maxHr.toLocaleString()}`
              ) : maxYr ? (
                `$${maxYr.toLocaleString()}/Yr`
              ) : (
                <ImLocation2 size={zoom >= 8 ? 20 : 18} color="white" />
              ))}
            {salaryType[0] !== "Fixed" &&
              (maxHr ? (
                `$${maxHr}/Hr`
              ) : maxYr && maxYr != 0 ? (
                `$${maxYr}/Yr`
              ) : (
                <ImLocation2 size={zoom >= 8 ? 20 : 18} color="white" />
              ))}
          </span>
          <div
            className={`absolute w-0 h-0 border-t-8 group-hover:border-tooltip
            ${
              userId == emp_id && emp_id !== null
                ? "border-gold"
                : "border-main "
            }
            `}
            style={{
              bottom: "-3px",
              borderLeft: `${
                !maxHr && !maxYr
                  ? zoom >= 8
                    ? "10px solid transparent"
                    : "8px solid transparent"
                  : "13px solid transparent"
              }`,
              borderRight: `${
                !maxHr && !maxYr
                  ? "10px solid transparent"
                  : "13px solid transparent"
              }`,
              // borderTop: "15px solid #007FEE",
              // top: "1.9rem",
              // left: "1.5rem",
            }}
          ></div>
        </div>
      </Link>
    );
  };

  const LocationPinOwnJobs = ({ lat, lng, data, modal_data }) => {
    console.log(modal_data, "ccc...");
    const userId = localStorage.getItem("user_id");
    const empId =
      Object.keys(modal_data).length !== 0
        ? Object.values(modal_data)[0][0]?.emp_id
        : null;
    const no_of_org = Object.keys(modal_data).length;
    const hirePinRef = useRef();
    return (
      <div
        ref={hirePinRef}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setDetails({
            lat: lat,
            lng: lng,
            sameLocationData: data,
          });
          setOwnJobPopUp(true);
          setHirePopUpTop(
            hirePinRef?.current.getBoundingClientRect().top - 250 > headYloction
          );
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            if (!hoverRef.current) {
              setOwnJobPopUp(false);
            }
          }, 100);
        }}
        onClick={(e) => {
          e.stopPropagation();
          setMultJobs({
            lat: lat,
            lng: lng,
            sameLocationData: {
              modal_data: data,
            },
          });
          setTimeout(() => {
            setShowModal(!showModal);
          }, 100);
        }}
        className="relative group flex flex-col items-center ml-2"
      >
        {/* <Icon icon={locationIcon} className="pin-icon" /> */}
        {/* <AiFillEnvironment /> */}
        {/* bluck */}
        <span
          style={{
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          }}
          className={`rounded-lg group-hover:bg-tooltip  text-white absolute bottom-1 whitespace-nowrap 
          ${
            no_of_org == 1 && userId == empId && userId !== null
              ? "bg-gold"
              : "bg-main"
          }
          ${
            zoom >= 10
              ? "text-xs sm:text-base p-1 sm:p-2 font-semibold"
              : zoom >= 8
              ? "text-xs sm:text-sm p-1 sm:p-2 font-normal"
              : "text-xs p-1 font-normal"
          }`}
        >
          {data.length} Jobs
        </span>
        <div
          className={`absolute w-0 h-0 border-t-8 border-main group-hover:border-tooltip
          ${
            no_of_org == 1 && userId == empId && userId !== null
              ? "border-gold"
              : "border-main"
          }
          `}
          style={{
            bottom: "-3px",
            borderLeft: "13px solid transparent",
            borderRight: "13px solid transparent",
            // borderTop: "15px solid #007FEE",
            // top: "1.9rem",
            // left: "1.5rem",
          }}
        ></div>
      </div>
    );
  };

  const HireLocationPin = ({ lat, lng, price, name, post, img, id }) => {
    const userId = localStorage.getItem("user_id");
    const hirePinRef = useRef();

    return (
      <Link to={`/employees/${id}`}>
        <div
          ref={hirePinRef}
          onMouseEnter={(e) => {
            handleHireCardShow(e, lat, lng, price, name, post, img, id);
            setHirePopUpTop(
              hirePinRef?.current.getBoundingClientRect().top - 230 >
                headYloction
            );
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              if (!hoverRef.current) {
                setShowHireCard(false);
              }
            }, 100);
          }}
          className="relative group flex flex-col items-center ml-2"
        >
          {/* <Icon icon={locationIcon} className="pin-icon" /> */}
          {/* <AiFillEnvironment /> */}
          {/* indevudual empluyee */}
          <span
            style={{
              boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
            }}
            className={`rounded-lg text-white absolute bottom-1 group-hover:bg-tooltip
            ${userId == id ? " bg-gold" : " bg-main"}
            ${
              zoom >= 10
                ? "text-xs sm:text-base p-1 sm:p-2 font-semibold"
                : zoom >= 8
                ? "text-xs sm:text-sm p-1 sm:p-2 font-normal"
                : "text-xs p-1 font-normal"
            }`}
          >
            {price ? (
              `$${price}/Hr`
            ) : (
              <ImLocation2 size={zoom >= 8 ? 20 : 18} color="white" />
            )}
          </span>
          <div
            className={`absolute w-0 h-0 border-t-8 group-hover:border-tooltip
            ${userId == id ? "border-gold" : "border-main "}
            `}
            style={{
              bottom: "-3px",
              borderLeft: `${
                !price
                  ? zoom >= 8
                    ? "10px solid transparent"
                    : "8px solid transparent"
                  : "13px solid transparent"
              }`,
              borderRight: `${
                !price ? "10px solid transparent" : "13px solid transparent"
              }`,
              // borderTop: "15px solid #007FEE",
              // top: "1.9rem",
              // left: "1.5rem",
            }}
          ></div>
        </div>
      </Link>
    );
  };

  const SameLocationPin = ({ lat, lng, data, modal_data }) => {
    const userId = localStorage.getItem("user_id");

    const empId =
      Object.keys(modal_data).length !== 0
        ? Object.values(modal_data)[0][0]?.emp_id
        : null;
    const no_of_org = Object.keys(modal_data).length;
    const hirePinRef = useRef();
    return (
      <div
        ref={hirePinRef}
        onMouseEnter={(e) => {
          handleSameCardShow(e, lat, lng, data);
          setHirePopUpTop(
            hirePinRef?.current.getBoundingClientRect().top - 250 > headYloction
          );
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            if (!hoverRef.current) {
              setShowSameCard(false);
            }
          }, 100);
        }}
        onClick={(e) => {
          e.stopPropagation();
          setMultJobs({
            lat: lat,
            lng: lng,
            sameLocationData: {
              modal_data: lookingToHire ? data : modal_data,
            },
          });
          setTimeout(() => {
            setShowModal(!showModal);
          }, 100);
        }}
        className="relative group flex flex-col items-center ml-2"
      >
        {/* <Icon icon={locationIcon} className="pin-icon" /> */}
        {/* <AiFillEnvironment /> */}
        {/* bluck */}
        <span
          style={{
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          }}
          className={`rounded-lg group-hover:bg-tooltip  text-white absolute bottom-1 whitespace-nowrap 
          ${
            no_of_org == 1 && userId == empId && userId !== null
              ? "bg-gold"
              : "bg-main"
          }
          ${
            zoom >= 10
              ? "text-xs sm:text-base p-1 sm:p-2 font-semibold"
              : zoom >= 8
              ? "text-xs sm:text-sm p-1 sm:p-2 font-normal"
              : "text-xs p-1 font-normal"
          }`}
        >
          {data.length} {data[0]?.user_id || data[0]?.name ? "Hires" : "Jobs"}
        </span>
        <div
          className={`absolute w-0 h-0 border-t-8 border-main group-hover:border-tooltip
          ${
            no_of_org == 1 && userId == empId && userId !== null
              ? "border-gold"
              : "border-main"
          }
          `}
          style={{
            bottom: "-3px",
            borderLeft: "13px solid transparent",
            borderRight: "13px solid transparent",
            // borderTop: "15px solid #007FEE",
            // top: "1.9rem",
            // left: "1.5rem",
          }}
        ></div>
      </div>
    );
  };
  const GroupSameLocationPin = ({ lat, lng, count }) => {
    return (
      <div
        className="relative group flex flex-col items-center ml-2"
        onClick={() => {
          zoom >= 11 ? setZoom(zoom + 2) : setZoom(11);
          setMarkerLocation({ lat, lng });
        }}
      >
        {/* <Icon icon={locationIcon} className="pin-icon" /> */}
        {/* <AiFillEnvironment /> */}
        {/* bluck */}
        <span
          style={{
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          }}
          className={`rounded-lg group-hover:bg-tooltip bg-main text-white absolute bottom-1 whitespace-nowrap ${
            zoom >= 10
              ? "text-xs sm:text-base p-1 sm:p-2 font-semibold"
              : zoom >= 8
              ? "text-xs sm:text-sm p-1 sm:p-2 font-normal"
              : "text-xs p-1 font-normal"
          }`}
        >
          {count}
        </span>
      </div>
    );
  };

  useEffect(() => {
    // if (markerFlag) {
    if (!marker.lat || !marker.lng) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            geolocationMarker(
              position.coords.latitude,
              position.coords.longitude
            );
            setCurrentLatitude(position.coords.latitude);
            setCurrentLongitude(position.coords.longitude);
            handleGeolocation(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          function (error) {
            axios
              .post(
                `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_MAP_KEY}`
              )
              .then((res) => {
                geolocationMarker(res.data.location.lat, res.data.location.lng);
                setCurrentLatitude(res.data.location.lat);
                setCurrentLongitude(res.data.location.lng);
                handleGeolocation(res.data.location.lat, res.data.location.lng);
              })
              .catch((err) => {
                console.log(err);
                // geolocationError(error.message);
              });
          }
        );
      } else {
        console.log("Not Available");
      }
    } else {
      // setCurrentLatitude(marker.lat);
      // setCurrentLongitude(marker.lng);
    }
    // }
  }, []);

  const handleGeolocation = (lat, lng) => {
    if (lat && lng) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
            lat ? lat : currentLatitiude
          },${lng ? lng : currentLongitude}&key=${
            process.env.REACT_APP_MAP_KEY
          }`
        )
        .then((res) => {
          const data = res.data.results;

          const code = data.find((item) => item.types.includes("plus_code"));
          const route = data.find((item) => item.types.includes("route"));
          const country = data.find((item) => item.types.includes("country"));
          const postcode = data.find((item) =>
            item.types.includes("postal_code")
          );
          const state = data.find((item) =>
            item.types.includes("administrative_area_level_1")
          );
          const city = data.find((item) =>
            item.types.includes("administrative_area_level_2")
          );

          let addressVal = "";
          if (code.address_components.length > 1) {
            const data = code.address_components.filter((item) => {
              return (
                item.types.includes("locality") ||
                item.types.includes("administrative_area_level_2") ||
                item.types.includes("administrative_area_level_1")
              );
            });
            addressVal = data.reverse().reduce((str, item) => {
              return item.long_name + " " + str;
            }, "");
            setMapAddress(addressVal);
          } else if (route) {
            addressVal = route.formatted_address;
            setMapAddress(addressVal);
          } else {
            addressVal =
              city.address_components[0].long_name +
              " " +
              state.address_components[0].long_name;
            setMapAddress(addressVal);
          }
        })
        .catch((err) => {
          setMapAddress("");
        });
    } else {
      setMapAddress("Location not available");
    }
  };

  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds();

    // locationData.forEach((place) => {
    //   bounds.extend(new maps.LatLng(place.lat, place.lng));
    // });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  function drawCircle(point, radius, dir, maps) {
    var d2r = Math.PI / 180; // degrees to radians
    var r2d = 180 / Math.PI; // radians to degrees
    var earthsradius = 3963; // 3963 is the radius of the earth in miles

    var points = 32;

    // find the raidus in lat/lon
    var rlat = (radius / earthsradius) * r2d;
    var rlng = rlat / Math.cos(point.lat() * d2r);

    var extp = new Array();
    if (dir == 1) {
      var start = 0;
      var end = points + 1; // one extra here makes sure we connect the path
    } else {
      var start = points + 1;
      var end = 0;
    }
    var ex;
    var ey;
    for (var i = start; dir == 1 ? i < end : i > end; i = i + dir) {
      var theta = Math.PI * (i / (points / 2));
      ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
      ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)
      extp.push(new maps.LatLng(ex, ey));
    }
    return extp;
  }
  const initRef = useRef(true);
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      console.log("showRadius");
      drawPolygon(marker.lat, marker.lng);
    }
  }, [refresh, showRadius]);

  // useEffect(() => {
  //   if(circleRef.current){

  //   }

  // },[mapData])

  const drawPolygon = async (lat, lng) => {
    let ne = map.getBounds().getNorthEast();
    let sw = map.getBounds().getSouthWest();
    const coordinates = {
      coordinates: {
        sw: {
          latitude: sw.lat(),
          longitude: sw.lng(),
        },
        ne: {
          latitude: ne.lat(),
          longitude: ne.lng(),
        },
      },
    };

    if (!showRadius && circles) {
      circles.setMap(null);
    } else {
      if (!render) {
        setRender(true);
      }

      if (circles) {
        circles.setMap(null);
      }
      const circle = new maps.Polygon({
        map: map,
        paths: [
          drawCircle(
            new maps.LatLng(parseFloat(lat), parseFloat(lng)),
            filters ? filters.radius : 10,
            1,
            maps
          ),
        ],
        strokeColor: "#007FEE",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#007FEE",
        fillOpacity: 0.1,
      });
      setCircles(circle);
      circleRef.current = true;

      const center = {
        lat: lat,
        lng: lng,
      };
    }
  };

  // useEffect(() => {
  //   const latitude = localStorage.getItem("latitude");
  //   const longitude = localStorage.getItem("longitude");

  //   setMarkerLocation({
  //     lat: parseFloat(latitude),
  //     lng: parseFloat(longitude),
  //   });
  // }, []);

  //executed on load of google maps
  const apiIsLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    setRender(false);

    if (!query.get("location")) {
      let ne = map.getBounds().getNorthEast();
      let sw = map.getBounds().getSouthWest();

      const coordinates = {
        coordinates: {
          sw: {
            latitude: sw.lat(),
            longitude: sw.lng(),
          },
          ne: {
            latitude: ne.lat(),
            longitude: ne.lng(),
          },
        },
      };
      setCoordinates(coordinates);
      //for clustering nw se required as below
      setMapsOptions({
        ...mapsOptions,
        bounds: {
          nw: {
            lat: ne.lat(),
            lng: sw.lng(),
          },
          se: {
            lat: sw.lat(),
            lng: ne.lng(),
          },
        },
        center: marker.lat ? marker : location,
      });
    }

    const circle = new maps.Polygon({
      map: map,
      paths: [
        drawCircle(
          new maps.LatLng(-77.57175342304159, -167.68846526679147),
          filters ? filters.radius : 10,
          1,
          maps
        ),
      ],
      strokeColor: "#007FEE",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#007FEE",
      fillOpacity: 0.1,
    });

    setCircles(circle);

    // const circle = new maps.Circle({
    //   strokeColor: "#007FEE",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#FF0000",
    //   fillOpacity: 0.3,
    //   map: map,
    //   center: { lat: currentLatitiude, lng: currentLongitude },
    //   radius: filters ? filters.radius * 1609.34 : 16093.4,
    // });

    // let ne = map.getBounds().getNorthEast();
    // let sw = map.getBounds().getSouthWest();
    // const bounds = getMapBounds(map, maps);
    // map.fitBounds(bounds);
    // // Bind the resize listener
    // bindResizeListener(map, maps, bounds);
  };

  // const [saveFormData, setSaveFormData] = useState({
  //   coordinates: {
  //     sw: {
  //       latitiude: 11.157267330562478,
  //       longtitude: 75.87636867335608,
  //     },
  //     ne: {
  //       latitiude: 11.430983191222015,
  //       longtitude: 76.15102687648108,
  //     },
  //   },
  // });
  // const { data: savedJobsPostData } = usePostData(`api/jobs`, saveFormData);
  //for creating cluster
  useEffect(() => {
    console.log("clusters2", clusters);
  }, [clusters]);
  const getClusters = (props) => {
    //mapdata is the data
    const clusters = supercluster(mapData, {
      minZoom: 4,
      maxZoom: 22,
      radius: 40,
    });
    return clusters(mapsOptions);
  };
  const getClustersJob = (props) => {
    const clusters = supercluster(employerJobs, {
      minZoom: 4,
      maxZoom: 22,
      radius: 40,
    });
    return clusters(mapsOptions);
  };

  const createCluster = (props) => {
    setClusters(
      mapsOptions.bounds
        ? getClusters(props).map(({ wx, wy, numPoints, points }) => {
            //group same location data by company_name

            let key = "company_name";

            let modal_data = points.reduce((hash, obj) => {
              if (obj[key] === undefined) return hash;
              return Object.assign(hash, {
                [obj[key]]: (hash[obj[key]] || []).concat(obj),
              });
            }, {});

            return {
              lat: wy,
              lng: wx,
              numPoints,
              id: `${numPoints}_${points[0].id}`,
              points,
              modal_data,
            };
          })
        : []
    );
    setJobClusters(
      mapsOptions.bounds
        ? getClustersJob(props).map(({ wx, wy, numPoints, points }) => {
            //group same location data by company_name

            let key = "company_name";

            let modal_data = points.reduce((hash, obj) => {
              if (obj[key] === undefined) return hash;
              return Object.assign(hash, {
                [obj[key]]: (hash[obj[key]] || []).concat(obj),
              });
            }, {});

            return {
              lat: wy,
              lng: wx,
              numPoints,
              id: `${numPoints}_${points[0].id}`,
              points,
              modal_data,
            };
          })
        : []
    );
  };

  const initRef1 = useRef(true);
  useEffect(() => {
    if (initRef1.current == true) {
      initRef1.current = false;
    } else {
      console.log("mapsOptions", mapsOptions);
      if (map) {
        let ne = map.getBounds().getNorthEast();
        let sw = map.getBounds().getSouthWest();
        const coordinates = {
          coordinates: {
            sw: {
              latitude: sw.lat(),
              longitude: sw.lng(),
            },
            ne: {
              latitude: ne.lat(),
              longitude: ne.lng(),
            },
          },
        };
        getJobData(coordinates);
      }
    }
  }, [mapsOptions]);

  useEffect(() => {
    createCluster({ ...mapsOptions });
  }, [mapData, employerJobs]);

  //when map changes
  const handleChangeMap = ({ center, zoom, bounds }) => {
    if (showCard) {
      setShowCard(false);
    }
    if (showHireCard) {
      setShowHireCard(false);
    }
    if (!circleRef.current) {
      if (map) {
        // if (circles) {
        //   circles.setMap(null);
        //   setCircles();
        // }
        let ne = map.getBounds().getNorthEast();
        let sw = map.getBounds().getSouthWest();
        const coordinates = {
          coordinates: {
            sw: {
              latitude: sw.lat(),
              longitude: sw.lng(),
            },
            ne: {
              latitude: ne.lat(),
              longitude: ne.lng(),
            },
          },
        };
        console.log("bounds", bounds);
        setCoordinates(coordinates);
        setMapsOptions({
          ...mapsOptions,
          bounds: {
            nw: {
              lat: ne.lat(),
              lng: sw.lng(),
            },
            se: {
              lat: sw.lat(),
              lng: ne.lng(),
            },
          },
          zoom,
        });
      }
    } else {
      circleRef.current = false;
    }
  };

  useEffect(() => {
    setHeadYloction(mapRef?.current?.getBoundingClientRect().y);
  }, [mapRef?.current?.getBoundingClientRect().y]);

  useEffect(() => {
    if (autoZoomFalse) {
      setTimeout(() => {
        if (mapData.length <= 0 && groupData.length <= 0) {
          setZoom(zoom - 1);
        }
      }, 1000);
    }
  }, [mapData, groupData]);

  return (
    <>
      {/* <h2 className="map-h2">Come Visit Us At Our Campus</h2> */}
      <div className={`google-map relative custom-map`} ref={mapRef}>
        {loading && (
          <img
            src={loadingRoh}
            className="absolute z-10 bg-transparent m-auto left-0 right-0 top-0 bottom-0"
            alt="loading..."
          />
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDdKq2J2Dt60dlI0_PdKAk4UMV3-wBpTw0" }}
          defaultCenter={location}
          fullscreenControl={false}
          options={mapOptions}
          center={marker.lat ? marker : location}
          onChange={handleChangeMap}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          defaultZoom={zoomLevel}
          zoom={zoom}
          onZoomAnimationEnd={() => {
            setZoom(map?.getZoom());
            setZoomlvl(map?.getZoom());
          }}
          onClick={({ x, y, lat, lng }) => {
            markerFlag && drawPolygon(lat, lng);

            markerFlag &&
              setMarkerLocation((currentData) => ({
                ...currentData,
                lat: lat,
                lng: lng,
              }));
            markerFlag && setShowCard(false);
          }}
          onDragEnd={(mapProps) => {
            setAutoZoomFalse(false);
            setTempCoord({
              lat: mapProps.center.lat(),
              lan: mapProps.center.lng(),
            });
          }}
        >
          {Array.isArray(clusters) &&
            clusters.length > 0 &&
            clusters?.map((item) => {
              if (item.numPoints === 1) {
                // singles
                if (maps && maps.geometry && circles && render && showRadius) {
                  const resultColor = maps.geometry.poly.containsLocation(
                    { lat: item.lat, lng: item.lng },
                    circles
                  );
                  if (resultColor) {
                    if (!lookingToHire) {
                      const maxYearlyPay = item?.points?.[0]?.max_yearly_pay
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      const minYearlyPay = item?.points?.[0]?.min_yearly_pay
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                      //radius
                      return (
                        <LocationPin
                          lat={item.lat}
                          lng={item.lng}
                          maxHr={item?.points?.[0]?.max_salary}
                          minHr={item?.points?.[0]?.min_salary}
                          maxYr={maxYearlyPay}
                          minYr={minYearlyPay}
                          post={item.points?.[0]?.position}
                          hospital={item?.points?.[0]?.hospital}
                          location={item?.points?.[0]?.address}
                          img={item?.points?.[0]?.image}
                          salary_type={item?.points?.[0]?.salary_type}
                          salary_subtype={item?.points?.[0]?.salary_subtype}
                          // text={address}
                          id={item?.points?.[0]?.id}
                          emp_id={item?.points?.[0]?.emp_id}
                          category_verified_image={
                            item?.points?.[0]?.category_verified_image
                          }
                          category_unverified_image={
                            item?.points?.[0]?.category_unverified_image
                          }
                        />
                      );
                    } else {
                      //radius
                      return (
                        <HireLocationPin
                          lat={item.lat}
                          lng={item.lng}
                          price={item?.points?.[0]?.desired_pay}
                          name={item?.points?.[0]?.name}
                          post={item?.points?.[0]?.position}
                          img={item?.points?.[0]?.image}
                          id={item?.points?.[0]?.user_id}
                        />
                      );
                    }
                  }
                } else {
                  if (!lookingToHire) {
                    const maxYearlyPay = item?.points?.max_yearly_pay
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    const minYearlyPay = item?.points?.min_yearly_pay
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                    //single jobs
                    console.log(item, "item");
                    return (
                      <LocationPin
                        lat={item.lat}
                        lng={item.lng}
                        maxHr={item?.points?.[0]?.max_salary}
                        minHr={item?.points?.[0]?.min_salary}
                        maxYr={maxYearlyPay}
                        minYr={minYearlyPay}
                        post={item.points?.[0]?.position}
                        hospital={item?.points?.[0]?.hospital}
                        location={item?.points?.[0]?.address}
                        img={item?.points?.[0]?.image}
                        salary_type={item?.points?.[0]?.salary_type}
                        salary_subtype={item?.points?.[0]?.salary_subtype}
                        // text={address}
                        id={item?.points?.[0]?.id}
                        emp_id={item?.points?.[0]?.emp_id}
                        category_verified_image={
                          item?.points?.[0]?.category_verified_image
                        }
                        category_unverified_image={
                          item?.points?.[0]?.category_unverified_image
                        }
                      />
                    );
                  } else {
                    // single hire
                    return (
                      <HireLocationPin
                        lat={item.lat}
                        lng={item.lng}
                        price={item?.points?.[0]?.desired_pay}
                        name={item?.points?.[0]?.name}
                        post={item?.points?.[0]?.position}
                        img={item?.points?.[0]?.image}
                        id={item?.points?.[0]?.user_id}
                      />
                    );
                  }
                }
              } else {
                if (maps && maps.geometry && circles && render && showRadius) {
                  const resultColor = maps.geometry.poly.containsLocation(
                    { lat: item?.lat, lng: item?.lng },
                    circles
                  );
                  if (resultColor) {
                    return (
                      <SameLocationPin
                        lat={item?.lat}
                        lng={item?.lng}
                        data={item?.points}
                        modal_data={item?.modal_data}
                      />
                    );
                  }
                } else {
                  //multiple hires+ multiple jobs
                  return (
                    <SameLocationPin
                      lat={item?.lat}
                      lng={item?.lng}
                      data={item?.points}
                      modal_data={item?.modal_data}
                    />
                  );
                }
              }
            })}

          {/* job of the emplyee */}
          {Array.isArray(jobClusters) &&
            jobClusters.length > 0 &&
            lookingToHire &&
            jobClusters?.map((item) => {
              if (item.numPoints === 1) {
                // singles
                if (maps && maps.geometry && circles && render && showRadius) {
                  const resultColor = maps.geometry.poly.containsLocation(
                    { lat: item.lat, lng: item.lng },
                    circles
                  );
                  if (resultColor) {
                    if (lookingToHire) {
                      const maxYearlyPay = item?.points?.[0]?.max_yearly_pay
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      const minYearlyPay = item?.points?.[0]?.min_yearly_pay
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                      //radius
                      return (
                        <LocationPin
                          lat={item.lat}
                          lng={item.lng}
                          maxHr={item?.points?.[0]?.max_salary}
                          minHr={item?.points?.[0]?.min_salary}
                          maxYr={maxYearlyPay}
                          minYr={minYearlyPay}
                          post={
                            item.points?.[0]?.job_title
                              ? item.points?.[0]?.job_title
                              : item.points?.[0]?.position_label
                          }
                          hospital={item?.points?.[0]?.hospital}
                          location={
                            item?.points?.[0]?.location_details?.address
                          }
                          img={item?.points?.[0]?.image}
                          salary_type={item?.points?.[0]?.salary_type}
                          salary_subtype={item?.points?.[0]?.salary_subtype}
                          // text={address}
                          id={item?.points?.[0]?.id}
                          emp_id={item?.points?.[0]?.emp_id}
                          category_verified_image={
                            item?.points?.[0]?.category_verified_image
                          }
                          category_unverified_image={
                            item?.points?.[0]?.category_unverified_image
                          }
                        />
                      );
                    }
                  }
                } else {
                  if (lookingToHire) {
                    const maxYearlyPay = item?.points?.max_yearly_pay
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    const minYearlyPay = item?.points?.min_yearly_pay
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    //single jobs
                    return (
                      <LocationPin
                        lat={item.lat}
                        lng={item.lng}
                        maxHr={item?.points?.[0]?.max_salary}
                        minHr={item?.points?.[0]?.min_salary}
                        maxYr={maxYearlyPay}
                        minYr={minYearlyPay}
                        post={
                          item.points?.[0]?.job_title
                            ? item.points?.[0]?.job_title
                            : item.points?.[0]?.position_label
                        }
                        hospital={item?.points?.[0]?.hospital}
                        location={item?.points?.[0]?.location_details?.address}
                        img={item?.points?.[0]?.image}
                        salary_type={item?.points?.[0]?.salary_type}
                        salary_subtype={item?.points?.[0]?.salary_subtype}
                        // text={address}
                        id={item?.points?.[0]?.id}
                        emp_id={item?.points?.[0]?.emp_id}
                        category_verified_image={
                          item?.points?.[0]?.category_verified_image
                        }
                        category_unverified_image={
                          item?.points?.[0]?.category_unverified_image
                        }
                      />
                    );
                  }
                }
              } else {
                return (
                  <LocationPinOwnJobs
                    lat={item?.lat}
                    lng={item?.lng}
                    data={item?.points}
                    modal_data={item?.modal_data}
                  />
                );
              }
            })}

          {/* {Object.values(sameLocationData).map((item) => {
            if (maps && maps.geometry && circles && render && showRadius) {
              const resultColor = maps.geometry.poly.containsLocation(
                { lat: item[0]?.latitude, lng: item[0]?.longtitude },
                circles
              );
              if (resultColor) {
                return (
                  <SameLocationPin
                    lat={item[0]?.latitude}
                    lng={item[0]?.longtitude}
                    data={item}
                  />
                );
              }
            } else {
              return (
                <SameLocationPin
                  lat={item[0]?.latitude}
                  lng={item[0]?.longtitude}
                  data={item}
                />
              );
            }
          })} */}
          {Array.isArray(groupData) &&
            groupData.length > 0 &&
            groupData?.map((item) => {
              if (maps && maps.geometry && circles && render && showRadius) {
                const resultColor = maps.geometry.poly.containsLocation(
                  { lat: item?.latitude, lng: item?.longtitude },
                  circles
                );
                if (resultColor) {
                  return (
                    <GroupSameLocationPin
                      lat={item?.latitude}
                      lng={item?.longtitude}
                      count={item?.count}
                    />
                  );
                }
              } else {
                return (
                  <GroupSameLocationPin
                    lat={item?.latitude}
                    lng={item?.longtitude}
                    count={item?.count}
                  />
                );
              }
            })}

          {ownJobPopUp && (
            <DisplayOwnJobsCard lat={details.lat} lng={details.lng} />
          )}
          {showCard && <DisplayCard lat={details.lat} lng={details.lng} />}
          {showHireCard && (
            <DisplayHireCard lat={details.lat} lng={details.lng} />
          )}
          {showSameCard && (
            <DisplaySameLocationCard lat={details.lat} lng={details.lng} />
          )}
          <CenterPin
            lat={
              marker.lat
                ? marker.lat
                : currentLatitiude
                ? currentLatitiude
                : location.lat
            }
            lng={
              marker.lng
                ? marker.lng
                : currentLongitude
                ? currentLongitude
                : location.lng
            }
            text={mapAddress}
          />
        </GoogleMapReact>
        {showModal && (
          <MultipleJobs
            setShowModal={setShowModal}
            details={multJobs}
            lookingToHire={lookingToHire}
            handleViewJob={handleViewJob}
            handleViewProfile={handleViewProfile}
          />
        )}
        {showZoom && (
          <div className="absolute text-xs font-bold bottom-28 right-2 bg-zoom rounded-xs p-1 h-11 w-11 text-center">
            <div className="text-center">{zoom}</div>
            zoom
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Map);
