import React, { useContext, useEffect, useState } from "react";
import usePostData from "../../hooks/usePostData";
import moment from "moment";
import _ from "lodash";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import { RefreshContext } from "../../App";

const RecentInterview = React.memo(({ showInvites }) => {
  const user_id = localStorage.getItem("user_id");
  const [url, setUrl] = useState(null);
  const [interviewUrl, setInterviewUrl] = useState(null);
  const [formdata, setFormData] = useState(null);
  const [inviteToDataFiltered, setInviteToDataFiltered] = useState([]);
  const [invitUrl, setInviteUrl] = useState(
    "availability/unaccepted-scheduled-interviews"
  );
  const [groupedData, setGroupedData] = useState(null);
  const [filteInvitationData, setFilteInvitationData] = useState(null);

  const { refreshWraper, setRefreshWraper } = useContext(RefreshContext);

  const { data, loading, error } = usePostData(url, formdata);

  const {
    data: inviteToData,
    loading: inviteToDataLoading,
    error: inviteToDataError,
  } = useGetData(invitUrl, "main", refreshWraper);

  const {
    data: invitationData,
    loading: invitationLoading,
    error: invitationError,
  } = useGetData(interviewUrl, "main", refreshWraper);

  // function removeExpiredBookings(data) {
  //   return _.filter(data, (item) => {
  //     let hasBooking = false;
  //     _.forEach(item, (value, key) => {
  //       if (key.startsWith("booking") && isBookingExpired(value)) {
  //         delete item[key];
  //       } else if (key.startsWith("booking")) {
  //         hasBooking = true;
  //       }
  //     });
  //     return hasBooking;
  //   });
  // }

  useEffect(() => {
    const allInterviewSchedule = data?.data.map((obj) => obj.user.id);
    const allInvitedToInterview = _.uniq(
      _.flatMap(inviteToData?.data, (obj) => [obj.from_user.id, obj.to_user.id])
    );
    // const data1 = inviteToData?.data || [];
    // if (data1) {
    //   const updatedData = data1.filter((slot) => {
    //     return !(
    //       allInterviewSchedule?.includes(slot.to_user.id) ||
    //       allInterviewSchedule?.includes(slot.from_user.id)
    //     );
    //   });
    //   setInviteToDataFiltered(updatedData);
    // }

    if (invitationData?.data) {
      const updatedData = invitationData?.data?.filter((slot) => {
        return (
          !allInterviewSchedule?.includes(slot.user.id) &&
          !allInvitedToInterview?.includes(slot.user.id)
        );
      });
      setFilteInvitationData(updatedData);
    }
  }, [inviteToData, data, invitationData]);

  useEffect(() => {
    setUrl(`availability/interviewschedule/${user_id}`);
    setInterviewUrl("availability/interviews-invitation");
    setFormData({
      days_span: "365",
      refreshWraper: refreshWraper,
    });
  }, [refreshWraper]);

  useEffect(() => {
    if (data) {
      const groupedDataTemp = _.chain(data?.data)
        .groupBy("accepted_booking_event_date")
        .map((items, date) => ({
          date,
          items: _.sortBy(items, "event_datetime"),
        }))
        .sortBy((group) => moment(group.date).toDate())
        .value();
      setGroupedData(groupedDataTemp);
    }
  }, [data?.data]);

  return (
    <div className="pb-4">
      {data && groupedData && groupedData.length > 0 && (
        <>
          <h1 className="my-0 underline">Interview Schedule</h1>
          {loading && <h1 className="text-loading_text">Loading...</h1>}
          {error && <h1>Something went wrong.</h1>}
          {groupedData &&
            groupedData.map((group, index) => {
              const dateObject = moment(group.date);
              const formattedDate = dateObject.format("dddd, MMM DD, YYYY");
              return (
                <div key={index} className="mb-4">
                  <p className="font-semibold">{formattedDate}</p>
                  {group?.items?.map((interview, index) => {
                    const formattedTime = moment(
                      interview?.event_datetime
                    ).format("h:mm A");
                    return (
                      <p key={index} className="capitalize">
                        {formattedTime}-
                        <Link
                          className="hover:underline"
                          to={`/${
                            !showInvites
                              ? `job-details/${interview.job}`
                              : `employees/${interview.user.id}?job_id=${interview.job}`
                          }`}
                        >
                          {interview.user.name}
                        </Link>
                      </p>
                    );
                  })}
                </div>
              );
            })}
        </>
      )}
      {showInvites && inviteToData?.data && inviteToData?.data.length > 0 && (
        <div className="pb-4">
          <h1 className="my-0 underline">Invited to Interview</h1>
          {inviteToDataLoading && (
            <h1 className="text-loading_text">Loading...</h1>
          )}
          {inviteToDataError && <h1>Something went wrong.</h1>}

          {inviteToData?.data.map((emp) => {
            return (
              <>
                <h1 className="capitalize hover:underline" key={emp.id}>
                  <Link
                    to={`/employees/${
                      user_id == emp?.to_user?.id
                        ? emp.from_user.id
                        : emp.to_user.id
                    }`}
                  >
                    {user_id == emp?.to_user?.id
                      ? emp.from_user.username
                      : emp.to_user.username}
                  </Link>
                </h1>
                <ul>
                  {emp.booking1 && (
                    <li>
                      {moment(emp.booking1?.from_hour, "HH:mm:ss").format(
                        "hh:mm A"
                      ) + " "}
                      on{" "}
                      {" " +
                        moment(emp.booking1?.event_date, "YYYY-MM-DD").format(
                          "MMM DD, YYYY"
                        )}
                    </li>
                  )}
                  {emp.booking2 && (
                    <li>
                      {moment(emp.booking2?.from_hour, "HH:mm:ss").format(
                        "hh:mm A"
                      ) + " "}
                      on
                      {" " +
                        moment(emp.booking2?.event_date, "YYYY-MM-DD").format(
                          "MMM DD, YYYY"
                        )}
                    </li>
                  )}
                  {emp.booking3 && (
                    <li>
                      {moment(emp.booking3?.from_hour, "HH:mm:ss").format(
                        "hh:mm A"
                      ) + " "}
                      on
                      {" " +
                        moment(emp.booking3?.event_date, "YYYY-MM-DD").format(
                          "MMM DD, YYYY"
                        )}
                    </li>
                  )}
                </ul>
              </>
            );
          })}
        </div>
      )}
      {showInvites &&
        invitationData &&
        filteInvitationData &&
        filteInvitationData.length > 0 && (
          <>
            <h1 className="my-0 underline">Invited to Apply</h1>
            {invitationLoading && (
              <h1 className="text-loading_text">Loading...</h1>
            )}
            {invitationError && <h1>Something went wrong.</h1>}

            {filteInvitationData.map((emp) => {
              return (
                <h1 className="capitalize hover:underline" key={emp?.id}>
                  <Link to={`/employees/${emp.user?.id}`}>
                    {emp.user?.name}
                  </Link>
                </h1>
              );
            })}
          </>
        )}
    </div>
  );
});

export default RecentInterview;
