import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import locationIcon from "../../images/JobSearch/locationIcon.png";
import {
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineEnvironment,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import filterIcon from "../../images/JobSearch/filterIcon.png";
import ads from "../../images/JobSearch/ads.png";
import filterActive from "../../images/JobSearch/filterActive.png";
import defaultlogo from "../../images/JobDetails/hospital-default.jpg";
import Map from "../elements/Map";
import ReactSlider from "react-slider";
import Highlighter from "react-highlight-words";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import Cookies from "universal-cookie";
import { MapContext } from "../../App";
import Select from "react-select";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import RohLogo from "../../images/ROH-logo.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import usePostData from "../../hooks/usePostData";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import HomeJobAlert from "../modals/HomeJobAlert";

const JobSearch = () => {
  const userId = localStorage.getItem("user_id");
  const [markerFlag, setmarkerFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [addressError, setAddressError] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [currentLoc, setCurrentLoc] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showRadius, setShowRadius] = useState(false);
  const [checkedSalaryType, setCheckedSalaryType] = useState(
    new Set(["Hourly"])
  );
  const [fixMinSalary, setFixMinSalary] = useState(false);
  const [fixMaxSalary, setFixMaxSalary] = useState(false);
  const [hourlyMinSalary, setHourlyMinSalary] = useState(false);
  const [hourlyMaxSalary, setHourlyMaxSalary] = useState(false);
  const [erroralert, setErrorAlert] = useState(false);
  const [locationClear, setLocationClear] = useState(false);
  const [positionData, setPositionData] = useState([]);
  const [positionAll, setPositionAll] = useState([{}]);
  // const [tempCoord,setTempCoord]=useState(null)
  const [showJobAlertModal, setShowJobAlertModal] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const cookies = new Cookies();
  let cookieLoc = cookies.get("locationHistory");
  const [locationHistory, setLocationHistory] = useState(
    cookieLoc ? cookieLoc : []
  );

  let cookiePos = cookies.get("positionHistory");
  const [positionHistory, setPositionHistory] = useState(
    cookiePos ? cookiePos : []
  );

  //Check whether the particular user already downloaded the newsletter pdf or not
  const user_id = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const { data: downloadStatus } = useGetData("flag-check");
  const isdownloaded = downloadStatus?.data?.is_downloaded;

  //Cookie function declaration
  const getCookie = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
    null;

  //Check whether the particular user submitted the job alert popup or not
  const isJobAlertSubmitted = downloadStatus?.data?.new_jobs_email_pop_up_flag;

  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
    circles,
    setCircles,
    clusters,
    setClusters,
  ] = useContext(MapContext);
  const [flag, setFlag] = useState(false);
  const [locationFlag, setLocationFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [positionClear, setPositionClear] = useState(tempPostion !== null);
  const history = useHistory();
  const { data: positionList } = useGetData("category-list");

  const [positionListWithSisterUrl, setPositionListWithSister] = useState();
  const [selectSimilarJob, setSelectSimilarJob] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const [similarJobs, setsimilarJobs] = useState();

  const { data: similarJobsData } = useGetData(positionListWithSisterUrl);
  const { data: jobType } = useGetData("job-types");

  useEffect(() => {
    setsimilarJobs(similarJobsData);
  }, [similarJobsData]);

  const selectPositionInputRef = useRef();

  useEffect(() => {
    setPositionData(positionList?.data);
  }, [positionList]);

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");
  const [values, setValues] = useState({
    job_type: [],
    coordinates: homeCoordinates.coordinates,
    salary_type: "",
    is_remote: false,
  });

  const [autoZoomFalse, setAutoZoomFalse] = useState(true);

  useEffect(() => {
    setAutoZoomFalse(true);
  }, [values]);

  const { optional } = useParams();

  function toTitleCase(str) {
    if (str) {
      var wordsArray = str.toLowerCase().split(/\s+/);
      var upperCased = wordsArray.map(function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1);
      });
      return upperCased.join(" ");
    }
  }

  const url_category = optional?.split("jobs")[0]?.replace(/-/g, " ")?.trim();

  const url_address = optional?.split("jobs-in-")[1]?.replace(/-/g, ", ");

  const routeChange = (add) => {
    if (!url_category && !filterValues?.position?.name) {
      let path = `/jobsearch/jobs-in-${add
        .replace(/,\s+/g, "-")
        .replace(/\s+/g, "-")}`;
      if (optional) {
        history.push(path);
      }
      history.push(path);
    } else {
      let path = `/jobsearch/${
        filterValues?.position?.name
          ? filterValues?.position?.name.replace(/\s+/g, "-").replace(" ", "")
          : url_category.replace(/\s+/g, "-").replace(" ", "")
      }-jobs-in-${add.replace(/,\s+/g, "-").replace(/\s+/g, "-")}`;
      if (optional) {
        history.push(path);
      }
      history.push(path);
    }
  };

  const [countApiValues, setCountApiValues] = useState({
    job_type: [],
    coordinates: homeCoordinates.coordinates,
    step:
      zoomlvl == 4
        ? 2.5
        : zoomlvl == 5
        ? 1.5
        : zoomlvl == 6
        ? 0.8
        : zoomlvl == 7
        ? 0.4
        : null,
  });
  const [data, setData] = useState(null);
  const [isJobsApi, setIsJobsApi] = useState(true);

  //Access control API call
  const [accessUrl, setAccessUrl] = useState(`access/1009`);
  const { data: AccessData, loading: filterLoad } = useGetData(accessUrl);

  //for Parallax effect checking if mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  const [filter, setFilter] = useState(!isMobile);

  function featureFilter() {
    let sample = false;
    AccessData?.data?.map((single_access_item) => {
      if (single_access_item.feature === 3 && userId) {
        sample = true;
      }
    });
    return sample;
  }

  //check queries in url
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    if (tempCoord) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: tempCoord.lat,
        lng: tempCoord.lan,
      }));
      setTempCoord(null);
    }
    if (url_category) {
      setPositionClear(true);
      setTempPosition({ id: 1, name: url_category });
    }
    if (url_address) {
      setLocationClear(true);
    }
  }, []);
  //check for position query in url
  useEffect(() => {
    // if (!userId) {
    //   window.location.href = "/login";
    // }

    if (url_address) {
      setAddress(url_address);
      handleSelect(url_address);
    } else if (query.get("location")) {
      setAddress(query.get("location"));
    }

    if (query.get("position")) {
      const positionStr = query.get("position").replace(/_/g, " ");
      setFilterValues((prev) => ({
        ...prev,
        position: { id: null, name: positionStr },
      }));
    }
    setmarkerFlag(true);

    return () => {
      setFilterValues((prev) => ({
        ...prev,
        position: null,
        min: 1,
        max: 50,
        jobType: [],
        radius: 0,
        salary: false,
        salary_type: "Hourly",
        is_remote: false,
      }));
    };
  }, []);

  useEffect(() => {
    if (homeCoordinates?.coordinates) {
      setFlag(true);
    }

    if (homeCoordinates?.position) {
      setFilterValues({
        ...filterValues,
        position: homeCoordinates.position,
      });
    }
  }, []);

  // set full time as default
  // useEffect(() => {
  //   if (jobType) {
  //     const types = jobType?.data?.response_data;
  //     const filteredType = types.filter((type) => type.name === "Full Time");

  //     if (filteredType?.length > 0) {
  //       setFilterValues({
  //         ...filterValues,
  //         jobType: [filteredType[0].id],
  //       });
  //     }
  //   }
  // }, [jobType]);

  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  useEffect(() => {
    if (Object.keys(homeCoordinates).length !== 0) {
      let token = localStorage.getItem("accessToken");

      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: marker.lat,
        lng: marker.lng,
      }));
      setCoordinates(homeCoordinates);

      const sendData = {
        coordinates: homeCoordinates.coordinates,
        position_name: homeCoordinates.position_name,
      };
      let url;
      if (zoomlvl <= 7) {
        sendData.job_type = [];
        sendData.step =
          zoomlvl == 4
            ? 2.5
            : zoomlvl == 5
            ? 1.5
            : zoomlvl == 6
            ? 0.8
            : zoomlvl == 7
            ? 0.4
            : null;
        url = `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`;
        setIsJobsApi(false);
      } else {
        let jobsApi = true;
        return axios
          .post(`${process.env.REACT_APP_SEARCH_URL}/alljobs/count`, sendData, {
            headers: headerData,
          })
          .then((res) => {
            if (res?.data?.total_count > 1000 || zoomlvl <= 7) {
              sendData.step = zoomlvl < 10 ? 0.2 : 0.1;
              setIsJobsApi(false);
              jobsApi = false;
              url = `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`;
            } else {
              setIsJobsApi(true);
              url = `${process.env.REACT_APP_SEARCH_URL}/jobs`;
            }

            axios
              .post(url, sendData, {
                headers: headerData,
              })
              .then((res) => {
                if (res?.data && jobsApi) {
                  let jobs = [...res?.data];
                  //sorting premium jobs
                  jobs.sort((a, b) => b.is_premium - a.is_premium);
                  setGroupData([]);
                  // setSameLocationData(obj);
                  setListingData(jobs);
                  setLoading(false);
                  // for clustering replacing lng for longitude and lat for latitude
                  jobs = jobs.map(
                    ({ longtitude: lng, latitude: lat, ...rest }) => ({
                      lng,
                      lat,
                      ...rest,
                    })
                  );
                  setMapData(jobs);
                  setFlag(false);
                } else if (res?.data && !jobsApi) {
                  setSameLocationData([]);
                  setGroupData(res.data?.data);
                  setMapData([]);
                  setLoading(false);
                  setFlag(false);
                }
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          });
      }
      console.log("sendData :>> ", sendData);
      axios
        .post(url, sendData, {
          headers: headerData,
        })
        .then((res) => {
          if (res.data && zoomlvl > 7) {
            setFlag(false);
            let jobs = [...res?.data];

            // //Add data with same locations in arr
            // let arr = [];

            // for (let i = 0; i < jobs.length; i++) {
            //   for (let j = i + 1; j < jobs.length; j++) {
            //     if (
            //       jobs[i].latitude === jobs[j].latitude &&
            //       jobs[i].longtitude === jobs[j].longtitude
            //     ) {
            //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
            //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
            //     }
            //   }
            // }

            // //seperate same locations into different arrays
            // let obj = {};
            // let jobData = res.data;
            // for (let i = 0; i < jobData.length; i++) {
            //   obj[i] = [];
            //   for (let j = i + 1; j < jobData.length; j++) {
            //     if (
            //       jobData[i].latitude === jobData[j].latitude &&
            //       jobData[i].longtitude === jobData[j].longtitude
            //     ) {
            //       if (!obj[i].includes(jobData[i])) {
            //         obj[i].push(jobData[i]);
            //       }

            //       obj[i].push(jobData[j]);
            //       jobData.splice(j, 1);
            //       j = j - 1;
            //     }
            //   }
            // }

            // // deleting empty arrays in object
            // Object.keys(obj).forEach((i) => {
            //   if (obj[i].length === 0) {
            //     delete obj[i];
            //   } else {
            //     obj[i].sort((a, b) => b.is_premium - a.is_premium);
            //   }
            // });

            // //group same location data by company_name
            // Object.keys(obj).forEach((i) => {
            //   let key = "company_name";

            //   obj[i].modal_data = obj[i].reduce((hash, obj) => {
            //     if (obj[key] === undefined) return hash;
            //     return Object.assign(hash, {
            //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
            //     });
            //   }, {});
            // });

            // //Remove same location data from other datas
            // const filteredJobData = jobs.filter(
            //   (i) => !arr.find((f) => f.id === i.id)
            // );

            //sorting premium jobs
            jobs.sort((a, b) => b.is_premium - a.is_premium);
            // setSameLocationData(obj);
            setListingData(jobs);

            // for clustering replacing lng for longitude and lat for latitude
            jobs = jobs.map(({ longtitude: lng, latitude: lat, ...rest }) => ({
              lng,
              lat,
              ...rest,
            }));

            setMapData(jobs);
            setGroupData([]);
            setLoading(false);
          } else if (res?.data && zoomlvl <= 7) {
            setSameLocationData([]);
            setGroupData(res.data?.data);
            setMapData([]);
            setLoading(false);
            setFlag(false);
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  }, []);

  const handleGeolocationMarker = (lat, lng) => {
    if (!homeCoordinates || Object.keys(homeCoordinates).length === 0) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: lat,
        lng: lng,
      }));
    }
  };

  const updateSalary = (value) => {
    setFilterValues({ ...filterValues, min: value[0], max: value[1] });
  };
  console.log(filterValues, "data...");

  //Remote function
  const updateRemote = (e) => {
    setFilterValues({
      ...filterValues,
      is_remote: e.target.checked ? true : false,
    });
    setValues((prev) => ({
      ...prev,
      coordinates: coordinates.coordinates,
      is_remote: e.target.checked ? true : false,
    }));
    setCountApiValues((current) => {
      const { salary, ...rest } = current;
      return rest;
    });
  };

  const updateSalaryFilter = () => {
    setValues({
      ...values,
      coordinates: coordinates.coordinates,
      salary: {
        min: filterValues.min,
        max: filterValues.max,
      },
      salary_type: filterValues.salary_type,
      is_remote: filterValues.is_remote,
    });
    setCountApiValues({
      ...countApiValues,
      coordinates: coordinates.coordinates,
      salary: {
        min: filterValues.min,
        max: filterValues.max,
      },
    });
  };

  const updateType = (e, id) => {
    const check = e.target.checked;
    if (check) {
      setFilterValues({
        ...filterValues,
        jobType: [...filterValues.jobType, id],
      });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        job_type: [...values.job_type, id],
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        job_type: [...values.job_type, id],
      });
    } else {
      const filterData = filterValues.jobType.filter((item) => {
        return item !== id;
      });
      setFilterValues({
        ...filterValues,
        jobType: filterData,
      });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        job_type: filterData,
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        job_type: filterData,
      });
    }
  };

  const updateRadius = (value) => {
    setFilterValues({ ...filterValues, radius: value });
    setShowRadius(true);
  };

  const updateRadiusFilter = (value) => {
    setTimeout(() => {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        radius: value,
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        radius: value,
      });
      setRefresh(!refresh);
    }, 1000);
  };

  const updateSalaryShow = (e) => {
    if (!e.target.checked) {
      // checkedSalaryType.clear();
    } else {
      checkedSalaryType.clear();
      checkedSalaryType.add("Hourly");
    }
    setFilterValues({
      ...filterValues,
      salary: !filterValues.salary,
      salary_type: "Hourly",
    });
    setValues((prev) => ({
      ...prev,
      coordinates: coordinates.coordinates,
      salary_type: e.target.checked ? "Hourly" : null,
      salary: e.target.checked
        ? {
            min: filterValues.min,
            max: filterValues.max,
          }
        : null,
    }));
    setCountApiValues((current) => {
      const { salary, ...rest } = current;
      return rest;
    });
    // setValues({
    //   ...values,
    //   salary_type:"Hourly"
    // })
  };

  const calculateAnnualPay = useCallback((pay) => {
    let yearlyPay = pay * 261 * 8;
    return yearlyPay;
  }, []);

  useEffect(() => {
    if (tempPostion) {
      setFilterValues({ ...filterValues, position: tempPostion });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        position_name: tempPostion.name,
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        position_name: tempPostion.name,
      });
    }
    if (url_category) {
      setFilterValues({
        ...filterValues,
        position: { id: 1, name: url_category },
      });
      setValues({
        ...values,
        position_name: url_category,
      });
      setCountApiValues({
        ...countApiValues,
        position_name: url_category,
      });
    }
  }, []);

  const updatePositionSelectValue = (value) => {
    if (value) {
      setSelectSimilarJob([]);
      let filtered = positionData.filter((ele) => {
        return ele.name != value.name;
      });
      setPositionData(filtered);
    }
    if (value) {
      if (
        !positionHistory.some((ele) => ele.name === value.name) &&
        positionHistory.length !== 3
      ) {
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (
        !positionHistory.some((ele) => ele.name === value.name) &&
        positionHistory.length === 3
      ) {
        let temp = positionHistory.pop();
        if (!positionData.some((ele) => ele.name === temp.name)) {
          setPositionData((oldArray) => [temp, ...oldArray]);
        }
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (positionHistory.some((ele) => ele.name === value.name)) {
        let filtered = positionHistory.filter((ele) => {
          return ele.name != value.name;
        });
        setPositionHistory(filtered);
        setPositionHistory((oldArray) => [value, ...oldArray]);
      }
    }

    if (address && value) {
      let path = `/jobsearch/${value.name.replace(
        /\s+/g,
        "-"
      )}-jobs-in-${address.replace(/,\s+/g, "-").replace(/\s+/g, "-")}`;
      history.push(path);
    } else if (address && !value) {
      let path = `/jobsearch/jobs-in-${address
        .replace(/,\s+/g, "-")
        .replace(/\s+/g, "-")}`;
      history.push(path);
    } else if (!value) {
      let path = `/jobsearch`;
      history.push(path);
    } else {
      let path = `/jobsearch/${value.name.replace(/\s+/g, "-")}-jobs`;
      history.push(path);
    }
    // routeChange()
    setTempPosition(value);
    setFilterValues({ ...filterValues, position: value });
    if (value) {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        position_name: value.name,
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        position_name: value.name,
      });
    } else {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
      });
      setCountApiValues({
        ...countApiValues,
        coordinates: coordinates.coordinates,
        position_name: [],
      });
    }
    if (value) setPositionClear(true);
  };

  const updateListing = (arr) => {
    if (arr) {
      //sorting premium jobs
      arr.sort((a, b) => b.is_premium - a.is_premium);
      setListingData(arr);
      setLoading(false);
    }
  };

  const handleChange = (address) => {
    setAddress(address);
    setLocationClear(true);
    if (locationFlag) {
      setLocationFlag(false);
    }
  };

  useEffect(() => {
    let json_str = JSON.stringify(locationHistory);
    cookies.set("locationHistory", json_str);
  }, [locationHistory]);

  useEffect(() => {
    let json_str = JSON.stringify(positionHistory);
    cookies.set("positionHistory", json_str);
  }, [positionHistory]);
  const handleClickLoc = (address) => {
    setAutoZoomFalse(true);
    setAddress(address);
    setLocationClear(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const removeLocationHistory = (address) => {
    setLocationHistory(locationHistory.filter((item) => item !== address));
  };

  const handleSelect = (address) => {
    setAutoZoomFalse(true);
    if (!locationHistory.includes(address) && locationHistory.length < 5) {
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (
      !locationHistory.includes(address) &&
      locationHistory.length === 5
    ) {
      locationHistory.pop();
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (locationHistory.includes(address)) {
      const index = locationHistory.indexOf(address);
      if (index > -1) {
        locationHistory.splice(index, 1);
      }
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    }
    routeChange(address);
    setAddress(address);
    setLocationFlag(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };
  // useEffect(()=>{
  //   let cleanTimeout = setTimeout(()=>{
  //     console.log('API called');
  //   },2000);

  //   return ()=>{
  //     console.log('clean up function');
  //     clearTimeout(cleanTimeout);
  //   }

  // },[values]);

  let apiTimeout;

  useEffect(() => {
    setPositionListWithSister(
      `employers/sistercategory/${tempPostion?.id}/data`
    );
    setLoading(true);
  }, [homeCoordinates, tempPostion]);

  useEffect(() => {
    if (selectSimilarJob.length > 0) {
      const jobarray = selectSimilarJob.map((obj) => obj.name);
      setValues({
        ...values,
        position_name: filterValues.position?.name,
        coordinates: coordinates.coordinates,
        sister_category: jobarray,
      });
      setFilterValues({
        ...filterValues,
        sister_category: jobarray,
      });
    } else {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        sister_category: [],
      });
    }
  }, [selectSimilarJob]);

  // debounced function to prevent unnecessary api calls
  const getJobData = async (coordinates, cb) => {
    if (
      !flag &&
      coordinates?.coordinates?.ne?.latitude !=
        coordinates?.coordinates?.sw?.latitude
    ) {
      // clear home coorinate data since it is not required as the map has changed
      if (homeCoordinates) {
        setHomeCoordinates({});
      }

      let token = localStorage.getItem("accessToken");
      clearTimeout(apiTimeout);

      if (filterValues.position) {
        coordinates.position_name = filterValues.position?.name;
      }
      if (filterValues.jobType) {
        coordinates.job_type = filterValues.jobType;
      }
      if (filterValues.is_remote) {
        coordinates.is_remote = filterValues.is_remote;
      }
      if (filterValues.sister_category) {
        coordinates.sister_category = filterValues.sister_category;
      }
      if (filterValues.salary) {
        coordinates.salary = {
          min: filterValues.min,
          max: filterValues.max,
        };

        coordinates.salary_type = filterValues.salary_type;
      } else {
        coordinates.salary = null;
      }

      if (zoomlvl == 4) {
        coordinates.step = 2.5;
      } else if (zoomlvl == 5) {
        coordinates.step = 1.5;
      } else if (zoomlvl == 6) {
        coordinates.step = 0.8;
      } else if (zoomlvl == 7) {
        coordinates.step = 0.4;
      }
      setCoordinates(coordinates);
      if (zoomlvl > 7) {
        axios
          .post(
            `${process.env.REACT_APP_SEARCH_URL}/alljobs/count`,
            coordinates,
            {
              headers: headerData,
            }
          )
          .then((res) => {
            if (res?.data?.total_count > 1000) {
              coordinates.step = zoomlvl < 10 ? 0.2 : 0.1;
              setIsJobsApi(false);
            } else {
              setIsJobsApi(true);
            }
            console.log("res", res);
            apiTimeout =
              res?.data?.total_count > 1000
                ? setTimeout(() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          setSameLocationData([]);
                          setGroupData([...res.data.data]);
                          setMapData([]);
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500)
                : setTimeout(() => {
                    axios
                      // first job call
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          let jobs = [...res.data];

                          // //Add data with same locations in arr
                          // let arr = [];

                          // for (let i = 0; i < jobs.length; i++) {
                          //   for (let j = i + 1; j < jobs.length; j++) {
                          //     if (
                          //       jobs[i].latitude === jobs[j].latitude &&
                          //       jobs[i].longtitude === jobs[j].longtitude
                          //     ) {
                          //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
                          //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
                          //     }
                          //   }
                          // }

                          // //seperate same locations into different arrays
                          // let obj = {};
                          // let jobData = res.data;
                          // for (let i = 0; i < jobData.length; i++) {
                          //   obj[i] = [];
                          //   for (let j = i + 1; j < jobData.length; j++) {
                          //     if (
                          //       jobData[i].latitude === jobData[j].latitude &&
                          //       jobData[i].longtitude === jobData[j].longtitude
                          //     ) {
                          //       if (!obj[i].includes(jobData[i])) {
                          //         obj[i].push(jobData[i]);
                          //       }

                          //       obj[i].push(jobData[j]);
                          //       jobData.splice(j, 1);
                          //       j = j - 1;
                          //     }
                          //   }
                          // }

                          // // deleting empty arrays in object
                          // Object.keys(obj).forEach((i) => {
                          //   if (obj[i].length === 0) {
                          //     delete obj[i];
                          //   }
                          // });

                          // //group same location data by company_name
                          // Object.keys(obj).forEach((i) => {
                          //   let key = "company_name";

                          //   obj[i].modal_data = obj[i].reduce((hash, obj) => {
                          //     if (obj[key] === undefined) return hash;
                          //     return Object.assign(hash, {
                          //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
                          //     });
                          //   }, {});
                          // });

                          // //Remove same location data from other datas
                          // const filteredJobData = jobs.filter(
                          //   (i) => !arr.find((f) => f.id === i.id)
                          // );

                          //sorting premium jobs
                          jobs.sort((a, b) => b.is_premium - a.is_premium);

                          // setSameLocationData(obj);
                          setListingData(jobs);

                          // for clustering replacing lng for longitude and lat for latitude
                          jobs = jobs.map(
                            ({ longtitude: lng, latitude: lat, ...rest }) => ({
                              lng,
                              lat,
                              ...rest,
                            })
                          );
                          console.log("jobs", jobs);
                          setMapData(jobs);
                          setGroupData([]);
                          setLoading(false);

                          // callback to execute when a circle is drawn
                          if (cb) cb();
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500);
          });
      } else {
        setIsJobsApi(false);
        apiTimeout = setTimeout(() => {
          axios
            .post(
              `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
              coordinates,
              {
                headers: headerData,
              }
            )
            .then((res) => {
              if (res.data) {
                setGroupData([...res.data.data]);
                setMapData([]);
                setLoading(false);
                setSameLocationData([]);
              }
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        }, 500);
      }
      console.log(coordinates, "cordinated");
    }
  };

  const handleBlur = () => {
    if (!locationFlag) {
      setAddressError("Please select location from the list");
      setTimeout(() => {
        setAddressError("");
      }, 3000);
    }
  };

  const upgradePlan = () => {
    localStorage.setItem("path", window.location.href);
    window.location.href = "/profile/my-plan";

    let path = localStorage.setItem("path", window.location.href);
    console.log("path", path);
  };

  const setRedirect = () => {
    localStorage.setItem("redirectToJobSh", true);
    history.push("/profile/my-plan");
  };
  let filterTimeout;

  const initRef = useRef(true);

  useEffect(() => {
    console.log("apple11");
    let coordinates = { ...values };
    if (zoomlvl == 4) {
      coordinates.step = 2.5;
    } else if (zoomlvl == 5) {
      coordinates.step = 1.5;
    } else if (zoomlvl == 6) {
      coordinates.step = 0.8;
    } else if (zoomlvl == 7) {
      coordinates.step = 0.4;
    }
    // if (initRef.current) {
    //   initRef.current = false;
    // } else {
    if (!values?.coordinates) return;
    // if (zoomlvl > 7 && !values?.coordinates) return;
    // if (zoomlvl <= 7 && (!countApiValues.coordinates)) return;
    axios
      .post(`${process.env.REACT_APP_SEARCH_URL}/alljobs/count`, values, {
        headers: headerData,
      })
      .then((res) => {
        let url;
        if (res?.data?.total_count > 1000 || zoomlvl <= 7) {
          url = `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`;
        } else {
          url = `${process.env.REACT_APP_SEARCH_URL}/jobs`;
        }
        axios
          .post(url, zoomlvl <= 7 ? coordinates : values, {
            headers: headerData,
          })
          .then((res) => {
            console.log("res", res);
            setData(res?.data);
          });
      });
  }, [values, countApiValues]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterTimeout = setTimeout(() => {
      if (data && zoomlvl > 7) {
        let jobs = [...data];
        // //Add data with same locations in arr
        // let arr = [];

        // for (let i = 0; i < jobs.length; i++) {
        //   for (let j = i + 1; j < jobs.length; j++) {
        //     if (
        //       jobs[i].latitude === jobs[j].latitude &&
        //       jobs[i].longtitude === jobs[j].longtitude
        //     ) {
        //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
        //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
        //     }
        //   }
        // }

        // //seperate same locations into different arrays
        // let obj = {};
        // let jobData = data;
        // for (let i = 0; i < jobData.length; i++) {
        //   obj[i] = [];
        //   for (let j = i + 1; j < jobData.length; j++) {
        //     if (
        //       jobData[i].latitude === jobData[j].latitude &&
        //       jobData[i].longtitude === jobData[j].longtitude
        //     ) {
        //       if (!obj[i].includes(jobData[i])) {
        //         obj[i].push(jobData[i]);
        //       }

        //       obj[i].push(jobData[j]);
        //       jobData.splice(j, 1);
        //       j = j - 1;
        //     }
        //   }
        // }

        // // deleting empty arrays in object
        // Object.keys(obj).forEach((i) => {
        //   if (obj[i].length === 0) {
        //     delete obj[i];
        //   } else {
        //     obj[i].sort((a, b) => b.is_premium - a.is_premium);
        //   }
        // });

        // //group same location data by company_name
        // Object.keys(obj).forEach((i) => {
        //   let key = "company_name";

        //   obj[i].modal_data = obj[i].reduce((hash, obj) => {
        //     if (obj[key] === undefined) return hash;
        //     return Object.assign(hash, {
        //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
        //     });
        //   }, {});
        // });

        // //Remove same location data from other datas
        // const filteredJobData = jobs.filter(
        //   (i) => !arr.find((f) => f.id === i.id)
        // );

        //sorting premium jobs
        jobs.sort((a, b) => b.is_premium - a.is_premium);
        // console.log("filteredJobData", filteredJobData);
        // setSameLocationData(obj);
        setListingData(jobs);
        if (data.length > 0) {
          setLoading(false);
        } else if (data.length === 0) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        // for clustering replacing lng for longitude and lat for latitude
        jobs = jobs.map(({ longtitude: lng, latitude: lat, ...rest }) => ({
          lng,
          lat,
          ...rest,
        }));

        setMapData(jobs);
        setGroupData([]);
        setIsJobsApi(true);
      } else if (data && zoomlvl <= 7) {
        setGroupData(data?.data);
        setSameLocationData([]);
        setMapData([]);
        setLoading(false);
      }
      if (showRadius) {
        let arr = [];
        let allData = [];
        // let sameArr = [];

        // Object.values(sameLocationData).forEach((item) => {
        //   sameArr = [...sameArr, ...item];
        // });
        if (zoomlvl <= 7) {
          allData = [...data?.data];
        } else {
          allData = [...data];
        }
        const center = {
          lat: marker.lat,
          lng: marker.lng,
        };
        allData.forEach((item) => {
          const resultColor = window.google.maps.geometry.poly.containsLocation(
            { lat: item.latitude, lng: item.longtitude },
            circles
          );
          if (resultColor) {
            arr.push({ ...item });
          }
        });

        arr.forEach((item, index) => {
          const dist =
            window.google.maps.geometry.spherical.computeDistanceBetween(
              { lat: item.latitude, lng: item.longtitude },
              center
            );
          arr[index].distance = dist;
        });

        arr.sort((a, b) => a.distance - b.distance);
        updateListing(arr);
      }
    }, 100);

    return () => {
      clearTimeout(filterTimeout);
    };
  }, [data]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lan: position.coords.longitude,
        });
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  const handleClickCurrentLoc = () => {
    setAutoZoomFalse(true);
    if (currentLocation) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: currentLocation.lat,
        lng: currentLocation.lan,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lan}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          setCurrentLoc(false);
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          // handleSelect(route?.formatted_address)
          setAddress("Current Location");
          //     let path = `/jobsearch/${value.name.replace(/\s+/g, "-")}-jobs`;
          //      history.push(path);
          if (filterValues.position) {
            let path = `/jobSearch/${filterValues.position.name.replace(
              /\s+/g,
              "-"
            )}-jobs`;
            history.push(path);
          } else {
            let path = `/jobSearch`;
            history.push(path);
          }
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const currentLocModalref = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        currentLoc &&
        currentLocModalref.current &&
        !currentLocModalref.current.contains(e.target)
      ) {
        setCurrentLoc(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [currentLoc]);
  const openCurrentLoc = (e) => {
    if (e.target.localName !== "img") {
      setCurrentLoc(true);
    }
  };

  const handleCheck = ({ id, checked }) => {
    console.log(id, checked, "click");
    if (checked) {
      checkedSalaryType.clear();
      checkedSalaryType.add(id);
    } else {
      checkedSalaryType.delete(id);
    }
    setCheckedSalaryType(new Set(checkedSalaryType));
  };

  useEffect(() => {
    if (parseInt(filterValues?.max) <= parseInt(filterValues?.min)) {
      setErrorAlert(true);
    } else {
      setErrorAlert(false);
    }
  }, [filterValues]);

  const updateMinInputField = (e) => {
    if (e.target.value <= 99999) {
      setFilterValues({
        ...filterValues,
        min: parseInt(e.target.value, 10),
      });
      setValues({
        ...values,
        salary: {
          min: parseInt(e.target.value, 10),
          max: filterValues.max,
        },
      });
    }
    if (!e.target.value || e.target.value.length === 0) {
      setFilterValues({
        ...filterValues,
        min: 0,
      });
      setValues({
        ...values,
        salary: {
          min: 0,
          max: filterValues.max,
        },
      });
    }
  };
  const updateMaxInputField = (e) => {
    if (e.target.value <= 99999) {
      setFilterValues({
        ...filterValues,
        max: parseInt(e.target.value, 10),
      });
      setValues({
        ...values,
        salary: {
          max: parseInt(e.target.value, 10),
          min: filterValues.min,
        },
      });
    }
    if (!e.target.value || e.target.value.length === 0) {
      setFilterValues({
        ...filterValues,
        max: 0,
      });
      setValues({
        ...values,
        salary: {
          max: 0,
          min: filterValues.min,
        },
      });
    }
  };

  function formatOptionLabel({ name }, { inputValue }) {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={name}
        highlightClassName="text-red-500  border-red-500 bg-transparent"
      />
    );
  }

  const customFilter = (option, searchText) => {
    const getSearctextPos = (string) => {
      if (!positionHistory.some((ele) => ele.name === string.name)) {
        return string.name.toLowerCase().indexOf(searchText.toLowerCase());
      }
    };
    let temp = positionAll?.sort((a, b) => {
      return getSearctextPos(a) - getSearctextPos(b);
    });
    setPositionAll(temp);

    if (
      (option?.data?.name &&
        option?.data?.name?.toLowerCase().includes(searchText.toLowerCase())) ||
      (option?.data?.alias &&
        option?.data?.alias.includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (positionData) {
      setPositionAll([
        ...positionHistory,
        ...positionData
          .sort((a, b) => {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          })
          .filter(
            (x) => !positionHistory.filter((y) => y.name === x.name).length
          ),
      ]);
    }
  }, [positionData, positionHistory]);

  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "15px",
      marginBottom: "2px",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      color: positionHistory.some((ele) => ele.name === state.data.name)
        ? "#047cec"
        : "black",
      backgroundColor: state.isFocused ? "#deebff" : "#fff",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
  };
  const SelectStylesMobile = {
    control: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? "grey" : "red",
      boxShadow: "none",
      border: 0,
      marginTop: "2px",
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "-6px",
      marginTop: "-4px",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      color: positionHistory.some((ele) => ele.name === state.data.name)
        ? "#047cec"
        : "black",
      backgroundColor: state.isFocused ? "#deebff" : "#fff",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
  };

  const Indicator = () => {
    return (
      <div className="rounded w-10 h-10 right-0 blue_box bg-main top-1 p-1 siftright">
        <div>
          <MdOutlineKeyboardArrowDown
            className="mx-auto w-7 h-full"
            color="#fff"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
  }, [values]);

  //Job Alert popup for logged in users
  useEffect(() => {
    setShowJobAlertModal(false);
    const isJobAlertShow = getCookie("isJobAlertShow");

    if (!isJobAlertShow && isJobAlertSubmitted == false) {
      setTimeout(() => {
        setShowJobAlertModal(true);
      }, 5000);
    }
  }, [isJobAlertSubmitted]);

  //Job Alert popup for non logged in users
  useEffect(() => {
    if (!user_id) {
      const isJobAlertShow = getCookie("isJobAlertShow");

      if (!isJobAlertShow) {
        setTimeout(() => {
          setShowJobAlertModal(true);
        }, 5000);
      }
    }
  }, []);

  return (
    <>
      {showJobAlertModal && (
        <HomeJobAlert
          setShowJobAlertModal={setShowJobAlertModal}
          formatOptionLabel={formatOptionLabel}
        />
      )}
      <div>
        {/* Above Mobile Devices*/}
        {!isMobile ? (
          <div className="hidden md:flex lg:flex jobSearchContainer relative">
            {/* map container start */}
            <div
              className={`relative w-full mr-0 mapContainer ${
                filter ? "hidden" : "block"
              } md:block`}
            >
              <div className="absolute flex items-center justify-between h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
                <div className="relative flex items-center justify-start w-full md:items-center md:flex-nowrap">
                  <button
                    onClick={() => {
                      setFilter(!filter);
                    }}
                  >
                    <img
                      src={filter ? filterActive : filterIcon}
                      alt=""
                      width="100px"
                      className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                    />
                  </button>
                  <div
                    // style={{ width: "70%" }}
                    className="relative w-full lg:w-3/5 xl:w-3/4 my-1 shadow-md search_input_box md:my-0 z-10 flex md:mx-2 home_banner_input_box"
                  >
                    <div className="flex-1 relative">
                      <Select
                        ref={selectPositionInputRef}
                        formatOptionLabel={formatOptionLabel}
                        styles={SelectStyles}
                        // className="react-select-container"
                        className="react-select-container w-full pr-2"
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={positionAll}
                        components={{ DropdownIndicator: Indicator }}
                        placeholder={
                          <h2 className="flex justify-start">Position</h2>
                        }
                        name="position"
                        id="position"
                        value={filterValues.position}
                        onChange={(e) => {
                          updatePositionSelectValue(e);
                        }}
                        filterOption={customFilter}
                      />
                      <AiOutlineSearch
                        className="absolute top-5 left-4"
                        color="gray"
                        size="1.3em"
                      />
                      {positionClear && (
                        <AiOutlineClose
                          className="absolute top-4 right-14 cursor-pointer"
                          onClick={() => {
                            selectPositionInputRef.current.clearValue();
                            setPositionClear(false);
                            setValues({
                              ...values,
                              coordinates: coordinates.coordinates,
                              position_name: null,
                              sister_category: [],
                            });
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="relative shadow-md home_banner_input_box md:my-0 flex-1"
                      onClick={openCurrentLoc}
                      ref={currentLocModalref}
                    >
                      <AiOutlineSearch
                        className="absolute top-4 left-4"
                        color="gray"
                        size="1.3em"
                      />
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        onError={(err) => console.log(err)}
                        // searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                type: "text",
                                autoComplete: "off",
                                className:
                                  "w-full pl-10 p-4 rounded outline-none  truncate auto-address h-18",
                                placeholder: "Address",
                                // onBlur: handleBlur,
                                // onChange={(e) => updateValue(e)
                              })}
                            />
                            <div className="absolute w-full">
                              {loading && <div>Loading...</div>}
                              {currentLoc && !address && (
                                <div className="overflow-y-auto h-72">
                                  <h2
                                    className="text-left px-4 pt-3 bg-white cursor-pointer flex "
                                    style={{ color: "#007FEE" }}
                                    onClick={handleClickCurrentLoc}
                                  >
                                    <span>
                                      <AiOutlineEnvironment
                                        className="relative top-0"
                                        color="#007FEE"
                                        size={28}
                                      />
                                    </span>

                                    <h3 className="relative px-3 text-lg">
                                      Current Location
                                    </h3>
                                  </h2>
                                  {locationHistory && (
                                    <h2
                                      className="text-left py-3 bg-white flex flex-col"
                                      style={{ color: "#007FEE" }}
                                    >
                                      {locationHistory.map((loc) => (
                                        <div className="px-4 flex flex-row relative justify-between cursor-pointer hover:bg-hover_bg">
                                          <h3
                                            className="overflow-hidden truncate relative px-3 text-lg py-2"
                                            onClick={() => {
                                              handleClickLoc(loc);
                                            }}
                                          >
                                            {loc}
                                          </h3>
                                          <AiOutlineClose
                                            className="absolute right-4 h-5 w-5 cursor-pointer my-auto top-0 bottom-0"
                                            onClick={() => {
                                              removeLocationHistory(loc);
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </h2>
                                  )}
                                </div>
                              )}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "bg-gray-700 p-2"
                                  : "p-2";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#deebff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                if (suggestion.terms.length > 1) {
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {locationClear && (
                        <AiOutlineClose
                          className="absolute top-4 right-12 cursor-pointer"
                          onClick={() => {
                            setAddress("");
                            setLocationClear(false);
                          }}
                        />
                      )}
                      <img
                        className="absolute w-10 h-10 rounded right-1 blue_box bg-main top-2 cursor-pointer"
                        src={locationIcon}
                        alt="location icon"
                        onClick={handleClickCurrentLoc}
                      />
                    </div>
                  </div>
                </div>
                {/* <button type="submit" className="hidden w-24 md:block z-10">
              <img src={expandIcon} alt="" width="100px" />
            </button> */}
              </div>
              <div
                style={{ top: "4.5rem" }}
                className="form_error_message z-40 font-semibold block absolute left-24"
              >
                {addressError}
              </div>
              <div className="minHeight z-0 h-screen">
                {/* <img src={map} alt="" width="100%" /> */}
                <Map
                  zoomLevel={zoomlvl}
                  page="jobs"
                  geolocationMarker={handleGeolocationMarker}
                  // markerFlag={markerFlag}
                  updateListing={updateListing}
                  filters={filterValues}
                  refresh={refresh}
                  getJobData={getJobData}
                  // handleMapAddress={handleMapAddress}
                  // geolocationError={handleGeolocationError}
                  loading={loading}
                  showRadius={showRadius}
                  autoZoomFalse={autoZoomFalse}
                  setAutoZoomFalse={setAutoZoomFalse}
                />
              </div>
              {/* map container below section button container start */}
              <div
                style={{ width: "94%" }}
                className="absolute flex-col hidden lg:flex bottom-7"
              >
                <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                  {/* <button
                type="submit"
                className="p-2 bg-white rounded-md shadow-md"
              >
                <img src={plusSymbol} className="" alt="" width="auto" />
              </button> */}
                </div>
                {/* <div className="">
              <div className="flex justify-between px-4 left-5 bottom-5">
                <button
                  type="submit"
                  className="flex items-center justify-between btn btn-main"
                >
                  <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                  Search as map moves
                </button>
                <div className="flex justify-between space-x-3">
                  <button
                    type="submit"
                    className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                  >
                    Map
                    <div>
                      <MdKeyboardArrowDown
                        color="gray"
                        size="1.4em"
                      ></MdKeyboardArrowDown>
                    </div>
                  </button>
                 <button
                    type="submit"
                    className="p-2 bg-white rounded-md shadow-md"
                  >
                    <img src={minusSymbol} className="" alt="" width="auto" />
                  </button> 
                </div>
              </div>
            </div> */}
              </div>
              {/* map container below section button container end */}
            </div>
            {/* map container end */}

            {isJobsApi && (
              <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
                <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
                  <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h1>
                            Jobs Listing -{" "}
                            <span className="italic align-middle">
                              Ring of Hires
                            </span>
                          </h1>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {/* <h2 className="text-xl font-semibold capitalize">jobs Listing</h2> */}
                        <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                          <p>showing {listingData.length} results</p>
                          <p>
                            sort by:{" "}
                            <span className=" text-main">newest post</span>
                            <button className="px-1">
                              <MdKeyboardArrowDown
                                color="gray"
                                size="1em"
                              ></MdKeyboardArrowDown>
                            </button>
                          </p>
                        </div>
                        <div
                          style={{ height: "50vh" }}
                          className="grid grid-cols-1 gap-2 capitalize md:grid-cols-2 overflow-y-scroll custom-sidebar"
                        >
                          {listingData.map((item, index) => {
                            let salarySubType = Object.values(
                              item.salary_subtype ? item.salary_subtype : {}
                            );
                            let salaryType = Object.values(
                              item.salary_type ? item.salary_type : {}
                            );

                            return (
                              <Link to={`/job-details/${item.id}`}>
                                <div
                                  key={index}
                                  className="rounded shadow-md profileCards relative flex flex-col"
                                >
                                  <div className="flex p-2 justify-between">
                                    {!item.image &&
                                    !item.category_unverified_image &&
                                    !item.category_verified_image ? (
                                      <img
                                        src={defaultlogo}
                                        alt=""
                                        className="w-20 h-20"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          item.image
                                            ? item.image
                                            : item.emp_id == null
                                            ? item.category_unverified_image
                                            : item.category_verified_image
                                        }
                                        alt=""
                                        className="w-20 h-20"
                                      />
                                    )}

                                    {item.is_premium == true && (
                                      <div>
                                        <img
                                          src={RohLogo}
                                          className="w-full h-7"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="p-2">
                                    <p className="my-2 font-semibold ">
                                      {item?.position}
                                    </p>
                                    <p className="mx-1 my-2 text-gray-500 ">
                                      {item?.company_name}
                                    </p>
                                    <div className="flex items-top">
                                      <div className="mt-0">
                                        <GoLocation
                                          color="gray"
                                          size="1em"
                                          className="self-start mt-1"
                                        />
                                      </div>

                                      <p className="mx-1 my-0 text-gray-500 ">
                                        {item?.address}
                                      </p>
                                    </div>
                                    <p className="my-2 font-bold text-main mb-12 text-sm">
                                      {salaryType[0] === "Fixed" && (
                                        <p className="my-2 font-bold text-main">
                                          {item?.max_salary
                                            ? "$" +
                                              item.max_salary.toLocaleString() +
                                              "  "
                                            : ""}
                                          {item?.max_salary ? "/" : ""}
                                          {salarySubType?.[0]}
                                        </p>
                                      )}

                                      {salaryType[0] !== "Fixed" && (
                                        <p className="font-bold text-main">
                                          {item?.min_salary ||
                                          item?.max_salary ? (
                                            <>
                                              {item?.min_salary
                                                ? "$" +
                                                  parseFloat(item?.min_salary)
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "") +
                                                  "/Hr"
                                                : ""}
                                              {item?.min_salary &&
                                                item?.max_salary &&
                                                "-"}
                                              {item?.max_salary
                                                ? "$" +
                                                  parseFloat(item?.max_salary)
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "") +
                                                  "/Hr"
                                                : ""}
                                            </>
                                          ) : item?.min_yearly_pay ||
                                            item?.max_yearly_pay ? (
                                            <>
                                              {item?.min_yearly_pay
                                                ? "$" +
                                                  item?.min_yearly_pay.toLocaleString() +
                                                  "/Yr"
                                                : ""}
                                              {item?.min_yearly_pay &&
                                                item?.max_yearly_pay &&
                                                "-"}
                                              {item?.max_yearly_pay
                                                ? "$" +
                                                  item?.max_yearly_pay.toLocaleString() +
                                                  "/Yr"
                                                : ""}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      )}
                                    </p>
                                    <div
                                      className={
                                        listingData?.length > 2
                                          ? "absolute bottom-0 my-2 w-11/12"
                                          : "my-2 w-11/12"
                                      }
                                    >
                                      <button
                                        type="submit"
                                        className="w-full py-1 font-semibold rounded btn-main"
                                        onClick={() =>
                                          history.push(
                                            `/job-details/${item.id}`
                                          )
                                        }
                                      >
                                        View Job
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            )}

            {/* filter popup start*/}
            {!filterLoad &&
              (featureFilter() ? (
                <div
                  className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer ${
                    filter
                      ? "block overflow-x-hidden overflow-y-scroll custom-filter h-96"
                      : "hidden"
                  }`}
                >
                  <div className="relative">
                    <div className="relative w-[100%] h-10 block md:hidden">
                      <AiOutlineClose
                        className="absolute top-3 right-3 text-gray-700 cursor-pointer hover:text-black"
                        size="1.3em"
                        onClick={() => setFilter(false)}
                      />
                    </div>

                    <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                      <p
                        className="text-sm text-gray-700 cursor-pointer hover:text-black"
                        onClick={() => {
                          if (address && address !== "Current Location") {
                            let path = `/jobSearch/jobs-in-${address
                              .replace(/,\s+/g, "-")
                              .replace(/\s+/g, "-")}`;
                            history.push(path);
                          } else {
                            let path = `/jobSearch`;
                            history.push(path);
                          }
                          setShowRadius(false);
                          setFilterValues({
                            position: null,
                            min: 1,
                            max: 50,
                            jobType: [],
                            radius: 0,
                            salary: false,
                          });

                          setCountApiValues({
                            coordinates: coordinates.coordinates,
                            job_type: [],
                            step:
                              zoomlvl == 4
                                ? 2.5
                                : zoomlvl == 5
                                ? 1.5
                                : zoomlvl == 6
                                ? 0.8
                                : zoomlvl == 7
                                ? 0.4
                                : null,
                          });
                          setValues({
                            coordinates: coordinates.coordinates,
                            job_type: [],
                          });
                          setSelectSimilarJob([]);
                          setsimilarJobs();
                          setPositionClear(false);
                        }}
                      >
                        Clear Filter
                      </p>
                    </div>

                    <div className="mt-5 flex flex-row items-center gap-4">
                      <p className="font-semibold">Salary</p>
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                        name="salary"
                        checked={filterValues.salary}
                        onChange={updateSalaryShow}
                      />
                    </div>

                    <div
                      className={
                        !filterValues.salary
                          ? "pointer-events-none opacity-40"
                          : ""
                      }
                    >
                      <div className="my-4">
                        <label>
                          <input
                            id="Hourly"
                            type="checkbox"
                            checked={checkedSalaryType.has("Hourly")}
                            onChange={(e) => {
                              handleCheck(e.target);
                              setFilterValues({
                                ...filterValues,
                                min: 0,
                                max: 50,
                                salary_type: "Hourly",
                              });
                              setValues({
                                ...values,
                                salary: { max: 50, min: 0 },
                                salary_type: "Hourly",
                              });
                            }}
                            className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                          />
                          Hourly
                        </label>

                        <label>
                          <input
                            id="Fixed"
                            type="checkbox"
                            checked={checkedSalaryType.has("Fixed")}
                            onChange={(e) => {
                              handleCheck(e.target);
                              setFilterValues({
                                ...filterValues,
                                min: 0,
                                max: 3200,
                                salary_type: "Fixed",
                              });
                              setValues({
                                ...values,
                                salary: { max: 3200, min: 0 },
                                salary_type: "Fixed",
                              });
                            }}
                            className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                          />
                          Fixed
                        </label>
                      </div>

                      {checkedSalaryType.has("Hourly") && (
                        <div>
                          <div className="flex my-5 salaryContainer justify-between w-full">
                            <p className="text-gray-500 rounded  text-center flex bg-gray-50 ">
                              $
                              {!hourlyMinSalary && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => {
                                    setHourlyMinSalary(true);
                                  }}
                                >
                                  {filterValues.min}
                                </span>
                              )}
                              {hourlyMinSalary && (
                                <input
                                  type="tel"
                                  pattern="[0-9]*"
                                  style={{ width: `6.5ch` }}
                                  className="mx-1  border-2 px-1"
                                  value={filterValues.min}
                                  onChange={(e) => {
                                    updateMinInputField(e);
                                  }}
                                />
                              )}
                            </p>
                            <p className="mr-2">-</p>
                            <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                              $
                              {!hourlyMaxSalary && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => {
                                    setHourlyMaxSalary(true);
                                  }}
                                >
                                  {filterValues.max}
                                </span>
                              )}
                              {hourlyMaxSalary && (
                                <input
                                  type="tel"
                                  pattern="[0-9]*"
                                  style={{ width: `6.5ch` }}
                                  className="mx-1 border-2 px-1"
                                  value={filterValues.max}
                                  onChange={(e) => {
                                    updateMaxInputField(e);
                                  }}
                                />
                              )}
                              <p className="normal-case">/hr</p>
                            </p>
                          </div>
                          {erroralert && (
                            <h1 className="text-red-600 text-sm normal-case">
                              {" "}
                              Max Salary should be greater than Min Salary{" "}
                            </h1>
                          )}
                          <ReactSlider
                            step={1}
                            min={1}
                            max={250}
                            minDistance={1}
                            name="desired_pay"
                            id="desired_pay"
                            className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                            trackClassName="track-color"
                            withTracks={true}
                            defaultValue={[filterValues.min, filterValues.max]}
                            value={[filterValues.min, filterValues.max]}
                            onChange={(value) => {
                              updateSalary(value);
                            }}
                            onAfterChange={updateSalaryFilter}
                          />

                          <div className="flex justify-between w-full my-5 md:my-2 xl:my-5">
                            <div className="flex my-5 salaryContainer justify-between w-full">
                              <p className="text-gray-500 bg-gray-50 px-1">
                                $
                                {calculateAnnualPay(
                                  filterValues.min
                                )?.toLocaleString()}
                              </p>
                              <p className="mr-2">-</p>
                              <p className="text-gray-500 bg-gray-50 px-1">
                                $
                                {calculateAnnualPay(
                                  filterValues.max
                                )?.toLocaleString()}
                                <span>/yr</span>
                              </p>
                              {/* <p className=""></p> */}
                            </div>
                          </div>
                        </div>
                      )}

                      {checkedSalaryType.has("Fixed") && (
                        <div>
                          <div className="flex my-5 salaryContainer justify-between">
                            <div>
                              <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                $
                                {!fixMinSalary && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setFixMinSalary(true);
                                    }}
                                  >
                                    {filterValues.min}
                                  </span>
                                )}
                                {fixMinSalary && (
                                  <input
                                    type="tel"
                                    pattern="[0-9]*"
                                    style={{ width: `6.5ch` }}
                                    className="mx-2  border-2 px-1"
                                    value={filterValues.min}
                                    onChange={(e) => {
                                      updateMinInputField(e);
                                    }}
                                  />
                                )}
                              </p>
                            </div>

                            <p className="mr-2">-</p>
                            <div>
                              <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                $
                                {!fixMaxSalary && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setFixMaxSalary(true);
                                    }}
                                  >
                                    {filterValues.max}
                                  </span>
                                )}
                                {fixMaxSalary && (
                                  <input
                                    type="tel"
                                    pattern="[0-9]*"
                                    style={{ width: `6.5ch` }}
                                    className="mx-2  border-2 px-1"
                                    value={filterValues.max}
                                    onChange={(e) => {
                                      updateMaxInputField(e);
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                          <ReactSlider
                            step={10}
                            min={0}
                            max={15000}
                            minDistance={1}
                            name="desired_pay"
                            id="desired_pay"
                            className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                            trackClassName="track-color"
                            withTracks={true}
                            //defaultValue={[100, 500]}
                            value={[filterValues.min, filterValues.max]}
                            onChange={(value) => {
                              updateSalary(value);
                            }}
                            onAfterChange={updateSalaryFilter}
                          />
                          {erroralert && (
                            <h1 className="text-red-600 text-sm normal-case">
                              {" "}
                              Max Salary should be greater than Min Salary{" "}
                            </h1>
                          )}
                        </div>
                      )}
                    </div>
                    <p className="my-5 md:my-2 xl:my-5 font-semibold">
                      Job Type
                    </p>
                    {jobType?.data?.response_data.map((item) => {
                      return (
                        <p
                          key={item.id}
                          className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                        >
                          {" "}
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                              name="Job Type"
                              checked={filterValues.jobType.includes(item.id)}
                              onChange={(e) => updateType(e, item.id)}
                            />
                          </span>
                          {item.name}
                        </p>
                      );
                    })}

                    <div className="mt-5 flex flex-row items-center gap-4">
                      <p className="font-semibold">Remote</p>
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                        name="is_remote"
                        checked={filterValues.is_remote}
                        onChange={updateRemote}
                      />
                    </div>

                    <p className="mt-5 font-semibold">Radius</p>
                    <div className="flex justify-start space-x-5 radiusContainer">
                      <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                        {filterValues.radius} miles
                      </p>
                      {/* <p className="my-5 text-sm text-gray-500">150 miles</p> */}
                    </div>
                    <ReactSlider
                      step={1}
                      min={1}
                      max={150}
                      minDistance={1}
                      name="desired_pay"
                      id="desired_pay"
                      className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                      thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                      trackClassName="bg-main"
                      value={filterValues.radius}
                      onChange={(value) => {
                        updateRadius(value);
                      }}
                      onAfterChange={updateRadiusFilter}
                    />
                    <>
                      {similarJobs &&
                        similarJobs?.data?.[0]?.sister_category.length > 0 && (
                          <>
                            <h1 className="my-5 md:my-3 xl:my-5 font-semibold">
                              Similar Positions
                            </h1>
                            {similarJobs.data?.[0]?.sister_category.map(
                              (e, i) => {
                                return (
                                  <div key={i} className="flex flex-row">
                                    <input
                                      type="checkbox"
                                      checked={selectSimilarJob.find(
                                        (obj) => obj.name === e.name
                                      )}
                                      name="type"
                                      onChange={() => {
                                        if (
                                          selectSimilarJob.find(
                                            (obj) => obj.name === e.name
                                          )
                                        ) {
                                          setSelectSimilarJob(() =>
                                            selectSimilarJob.filter(
                                              (obj) => obj.name !== e.name
                                            )
                                          );
                                        } else {
                                          setSelectSimilarJob((prev) => [
                                            ...prev,
                                            e,
                                          ]);
                                        }
                                      }}
                                      className="p-4 mr-4 rounded-lg checked:bg-blue-600 checked:border-transparent"
                                    />
                                    <h1>{e.name}</h1>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                    </>

                    {/* <div className="w-full line h-0.5 bg-gray-300 mb-5"></div> */}
                    {/* <div
                className="py-2 mt-16 md:mt-8 xl:mt-16 font-semibold text-center rounded btn-main"
                onClick={() => {
                  setFilter(false);
                  getJobData(coordinates);
                }}
              >
                apply
              </div> */}
                  </div>
                </div>
              ) : (
                <div
                  className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer ${
                    filter ? "block" : "hidden"
                  }`}
                >
                  <p className="font-normal">
                    Make a FREE Profile For Advanced Filters
                  </p>
                  <button
                    onClick={setRedirect}
                    className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                  >
                    Upgrade
                  </button>
                </div>
              ))}
            {/* filter popup end */}
          </div>
        ) : (
          /* Mobile Devices*/
          <div className="block md:hidden jobSearchContainer relative">
            {/* map container start */}
            <ParallaxProvider>
              <Parallax speed={-20}>
                <div className="fast">
                  <div
                    className={`relative w-full mr-0 mapContainer block`}
                    style={{ filter: `blur(${filter ? "4px" : "0px"})` }}
                  >
                    <div className="absolute flex items-center justify-between  h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
                      <div className="relative flex justify-start w-full items-center md:flex-nowrap">
                        <button
                          onClick={() => {
                            setFilter(!filter);
                          }}
                          style={{ paddingTop: "30px" }}
                        >
                          <img
                            src={filter ? filterActive : filterIcon}
                            alt=""
                            width="100px"
                            className="relative w-20 md:w-24 z-10"
                          />
                        </button>
                        <div className="relative flex flex-col home_banner_input_box text-md w-full pr-4 pt-6">
                          <div className="relative py-1 pb-2">
                            <Select
                              ref={selectPositionInputRef}
                              formatOptionLabel={formatOptionLabel}
                              styles={SelectStylesMobile}
                              // className="react-select-container"
                              className="react-select-container w-full h-full cursor-pointer"
                              classNamePrefix="react-select"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              options={positionAll}
                              components={{ DropdownIndicator: Indicator }}
                              placeholder={
                                <h2 className="flex justify-start text-gray-400">
                                  Position
                                </h2>
                              }
                              name="position"
                              id="position"
                              value={filterValues.position}
                              onChange={(e) => {
                                updatePositionSelectValue(e);
                              }}
                              filterOption={customFilter}
                              onFocus={(e) => {
                                setCurrentLoc(false);
                              }}
                            />
                            {positionClear && (
                              <AiOutlineClose
                                className="absolute top-0 bottom-0 m-auto right-12 cursor-pointer"
                                onClick={() => {
                                  selectPositionInputRef.current.clearValue();
                                  setPositionClear(false);
                                  setValues({
                                    ...values,
                                    coordinates: coordinates.coordinates,
                                    // job_type: [],
                                    position_name: null,
                                  });
                                }}
                              />
                            )}
                          </div>

                          <div
                            className="relative home_banner_input_box md:my-0 bg-white rounded border-2 focus:border-red-100"
                            style={{ width: "100%", height: "100%" }}
                            onClick={openCurrentLoc}
                            ref={currentLocModalref}
                          >
                            <PlacesAutocomplete
                              value={address}
                              onChange={handleChange}
                              onSelect={handleSelect}
                              onError={(err) => console.log(err)}
                              // searchOptions={searchOptions}
                              className="focus:outline-none"
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      type: "text",
                                      autoComplete: "off",
                                      className:
                                        "w-4/5 rounded p-3 pl-4 truncate auto-address focus:outline-none",
                                      placeholder: "Address",
                                      // onBlur: handleBlur,
                                      // onChange={(e) => updateValue(e)
                                    })}
                                  />
                                  <div className="absolute w-full">
                                    {loading && <div>Loading...</div>}
                                    {currentLoc && !address && (
                                      <div className="overflow-y-auto h-72">
                                        <h2
                                          className="text-left px-1 pt-3 bg-white cursor-pointer flex"
                                          style={{ color: "#007FEE" }}
                                          onClick={handleClickCurrentLoc}
                                        >
                                          <h3 className="relative px-3 text-md  truncate auto-address">
                                            Current Location
                                          </h3>
                                        </h2>
                                        {locationHistory && (
                                          <h2
                                            className="text-left py-3 bg-white flex flex-col"
                                            style={{ color: "#007FEE" }}
                                          >
                                            {locationHistory.map((loc) => (
                                              <div className="px-4 flex flex-row relative justify-between cursor-pointer hover:bg-hover_bg">
                                                <h3
                                                  className="overflow-hidden truncate relative text-md py-2"
                                                  onClick={() => {
                                                    handleClickLoc(loc);
                                                  }}
                                                >
                                                  {loc}
                                                </h3>
                                                <AiOutlineClose
                                                  className="absolute right-2 h-5 w-5 cursor-pointer my-auto top-0 bottom-0"
                                                  onClick={() => {
                                                    removeLocationHistory(loc);
                                                  }}
                                                />
                                              </div>
                                            ))}
                                          </h2>
                                        )}
                                      </div>
                                    )}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "bg-gray-700 p-2"
                                        : "p-2";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      if (suggestion.terms.length > 1) {
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {locationClear && (
                              <AiOutlineClose
                                className="absolute top-4 right-11 cursor-pointer"
                                onClick={() => {
                                  setAddress("");
                                  setLocationClear(false);
                                }}
                              />
                            )}
                            <img
                              className="absolute w-10 h-10 rounded right-1 blue_box bg-main top-1 cursor-pointer"
                              src={locationIcon}
                              alt="location icon"
                              onClick={handleClickCurrentLoc}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <button type="submit" className="hidden w-24 md:block z-10">
                      <img src={expandIcon} alt="" width="100px" />
                      </button> */}
                    </div>
                    <div
                      style={{ top: "4.5rem" }}
                      className="form_error_message z-40 font-semibold block absolute left-24"
                    >
                      {addressError}
                    </div>
                    <div className="minHeight z-0 md:h-screen h-60">
                      {/* <img src={map} alt="" width="100%" /> */}
                      <Map
                        zoomLevel={zoomlvl}
                        page="jobs"
                        geolocationMarker={handleGeolocationMarker}
                        // markerFlag={markerFlag}
                        updateListing={updateListing}
                        filters={filterValues}
                        refresh={refresh}
                        getJobData={getJobData}
                        // handleMapAddress={handleMapAddress}
                        // geolocationError={handleGeolocationError}
                        loading={loading}
                        showRadius={showRadius}
                        autoZoomFalse={autoZoomFalse}
                        setAutoZoomFalse={setAutoZoomFalse}
                      />
                    </div>
                    {/* map container below section button container start */}
                    <div
                      style={{ width: "94%" }}
                      className="absolute flex-col hidden lg:flex bottom-7"
                    >
                      <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                        {/* <button
                type="submit"
                className="p-2 bg-white rounded-md shadow-md"
              >
                <img src={plusSymbol} className="" alt="" width="auto" />
              </button> */}
                      </div>
                      {/* <div className="">
              <div className="flex justify-between px-4 left-5 bottom-5">
                <button
                  type="submit"
                  className="flex items-center justify-between btn btn-main"
                >
                  <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                  Search as map moves
                </button>
                <div className="flex justify-between space-x-3">
                  <button
                    type="submit"
                    className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                  >
                    Map
                    <div>
                      <MdKeyboardArrowDown
                        color="gray"
                        size="1.4em"
                      ></MdKeyboardArrowDown>
                    </div>
                  </button>
                 <button
                    type="submit"
                    className="p-2 bg-white rounded-md shadow-md"
                  >
                    <img src={minusSymbol} className="" alt="" width="auto" />
                          </button> 
                  </button> 
                          </button> 
                </div>
              </div>
            </div> */}
                    </div>
                    {/* map container below section button container end */}
                  </div>
                </div>
              </Parallax>
              {/* map container end */}

              {/* filter popup start*/}
              {!filterLoad &&
                (featureFilter() ? (
                  <div
                    className={`backdrop-blur-md backdrop-opacity-60 bg-gray-500/30 h-full w-full absolute top-0 font-semibold transition-all duration-300 rounded-lg shadow-lg filterPopupContainer ${
                      filter
                        ? "block overflow-x-hidden overflow-y-scroll custom-filter h-96"
                        : "hidden"
                    }`}
                  >
                    <div className="w-5/6 p-4 px-5 pb-6 bg-white ">
                      <div className="relative">
                        <div className="relative block md:hidden">
                          <AiOutlineClose
                            className="absolute top-1 right-3 text-gray-700 cursor-pointer hover:text-black"
                            size="1.3em"
                            onClick={() => setFilter(false)}
                          />
                        </div>

                        <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                          <p
                            className="text-sm text-gray-700 cursor-pointer hover:text-black"
                            onClick={() => {
                              setShowRadius(false);
                              setFilterValues({
                                position: null,
                                min: 1,
                                max: 50,
                                jobType: [],
                                radius: 0,
                                salary: false,
                              });

                              setCountApiValues({
                                coordinates: coordinates.coordinates,
                                job_type: [],
                                step:
                                  zoomlvl == 4
                                    ? 2.5
                                    : zoomlvl == 5
                                    ? 1.5
                                    : zoomlvl == 6
                                    ? 0.8
                                    : zoomlvl == 7
                                    ? 0.4
                                    : null,
                              });
                              setValues({
                                coordinates: coordinates.coordinates,
                                job_type: [],
                              });
                            }}
                          >
                            Clear Filter
                          </p>
                        </div>

                        <div className="mt-5 flex flex-row items-center gap-4">
                          <p className="font-semibold">Salary</p>
                          <input
                            type="checkbox"
                            className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                            name="salary"
                            checked={filterValues.salary}
                            onChange={updateSalaryShow}
                          />
                        </div>

                        <div
                          className={
                            !filterValues.salary
                              ? "pointer-events-none opacity-40"
                              : ""
                          }
                        >
                          <div className="my-4">
                            <label>
                              <input
                                id="Hourly"
                                type="checkbox"
                                checked={checkedSalaryType.has("Hourly")}
                                onChange={(e) => {
                                  handleCheck(e.target);
                                  setFilterValues({
                                    ...filterValues,
                                    min: 0,
                                    max: 50,
                                    salary_type: "Hourly",
                                  });
                                  setValues({
                                    ...values,
                                    salary: { max: 50, min: 0 },
                                    salary_type: "Hourly",
                                  });
                                }}
                                className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                              />
                              Hourly
                            </label>

                            <label>
                              <input
                                id="Fixed"
                                type="checkbox"
                                checked={checkedSalaryType.has("Fixed")}
                                onChange={(e) => {
                                  handleCheck(e.target);
                                  setFilterValues({
                                    ...filterValues,
                                    min: 0,
                                    max: 3200,
                                    salary_type: "Fixed",
                                  });
                                  setValues({
                                    ...values,
                                    salary: { max: 3200, min: 0 },
                                    salary_type: "Fixed",
                                  });
                                }}
                                className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                              />
                              Fixed
                            </label>
                          </div>

                          {checkedSalaryType.has("Hourly") && (
                            <div>
                              <div className="flex my-5 salaryContainer justify-between w-full">
                                <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                  $
                                  {!hourlyMinSalary && (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setHourlyMinSalary(true);
                                      }}
                                    >
                                      {filterValues.min}
                                    </span>
                                  )}
                                  {hourlyMinSalary && (
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      style={{ width: `6.5ch` }}
                                      className="mx-2  border-2 px-1"
                                      value={filterValues.min}
                                      onChange={(e) => {
                                        updateMinInputField(e);
                                      }}
                                    />
                                  )}
                                </p>
                                <p className="mr-2">-</p>
                                <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                  $
                                  {!hourlyMaxSalary && (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setHourlyMaxSalary(true);
                                      }}
                                    >
                                      {filterValues.max}
                                    </span>
                                  )}
                                  {hourlyMaxSalary && (
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      style={{ width: `6.5ch` }}
                                      className="mx-2  border-2 px-1"
                                      value={filterValues.max}
                                      onChange={(e) => {
                                        updateMaxInputField(e);
                                      }}
                                    />
                                  )}
                                  <p className="">/hr</p>
                                </p>
                              </div>
                              {erroralert && (
                                <h1 className="text-red-600 text-sm normal-case">
                                  {" "}
                                  Max Salary should be greater than Min Salary{" "}
                                </h1>
                              )}
                              <ReactSlider
                                step={1}
                                min={1}
                                max={250}
                                minDistance={1}
                                name="desired_pay"
                                id="desired_pay"
                                className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                                thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                                trackClassName="track-color"
                                withTracks={true}
                                defaultValue={[
                                  filterValues.min,
                                  filterValues.max,
                                ]}
                                value={[filterValues.min, filterValues.max]}
                                onChange={(value) => {
                                  updateSalary(value);
                                }}
                                onAfterChange={updateSalaryFilter}
                              />

                              <div className="flex justify-between w-full my-5 md:my-2 xl:my-5">
                                <div className="flex my-5 salaryContainer justify-between w-full">
                                  <p className="text-gray-500 bg-gray-50 px-1">
                                    $
                                    {calculateAnnualPay(
                                      filterValues.min
                                    )?.toLocaleString()}
                                  </p>
                                  <p className="mr-2">-</p>
                                  <p className="text-gray-500 bg-gray-50 px-1">
                                    $
                                    {calculateAnnualPay(
                                      filterValues.max
                                    )?.toLocaleString()}
                                    <span>/yr</span>
                                  </p>
                                  {/* <p className=""></p> */}
                                </div>
                              </div>
                            </div>
                          )}

                          {checkedSalaryType.has("Fixed") && (
                            <div>
                              <div className="flex my-5 salaryContainer justify-between">
                                <div>
                                  <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                    $
                                    {!fixMinSalary && (
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setFixMinSalary(true);
                                        }}
                                      >
                                        {filterValues.min}
                                      </span>
                                    )}
                                    {fixMinSalary && (
                                      <input
                                        type="tel"
                                        pattern="[0-9]*"
                                        style={{ width: `6.5ch` }}
                                        className="mx-2  border-2 px-1"
                                        value={filterValues.min}
                                        onChange={(e) => {
                                          updateMinInputField(e);
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>

                                <p className="mr-2">-</p>
                                <div>
                                  <p className="text-gray-500 rounded  text-center flex bg-gray-50 px-1">
                                    $
                                    {!fixMaxSalary && (
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setFixMaxSalary(true);
                                        }}
                                      >
                                        {filterValues.max}
                                      </span>
                                    )}
                                    {fixMaxSalary && (
                                      <input
                                        type="tel"
                                        pattern="[0-9]*"
                                        style={{ width: `6.5ch` }}
                                        className="mx-2  border-2 px-1"
                                        value={filterValues.max}
                                        onChange={(e) => {
                                          updateMaxInputField(e);
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                              <ReactSlider
                                step={10}
                                min={0}
                                max={15000}
                                minDistance={1}
                                name="desired_pay"
                                id="desired_pay"
                                className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                                thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                                trackClassName="track-color"
                                withTracks={true}
                                //defaultValue={[100, 500]}
                                value={[filterValues.min, filterValues.max]}
                                onChange={(value) => {
                                  updateSalary(value);
                                }}
                                onAfterChange={updateSalaryFilter}
                              />
                              {erroralert && (
                                <h1 className="text-red-600 text-sm normal-case">
                                  {" "}
                                  Max Salary should be greater than Min Salary{" "}
                                </h1>
                              )}
                            </div>
                          )}
                        </div>
                        <p className="my-5 md:my-2 xl:my-5 font-semibold">
                          Job Type
                        </p>
                        {jobType?.data?.response_data.map((item) => {
                          return (
                            <p
                              key={item.id}
                              className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                            >
                              {" "}
                              <span>
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                                  name="Job Type"
                                  checked={filterValues.jobType.includes(
                                    item.id
                                  )}
                                  onChange={(e) => updateType(e, item.id)}
                                />
                              </span>
                              {item.name}
                            </p>
                          );
                        })}
                        <div className="mt-5 flex flex-row items-center gap-4">
                          <p className="font-semibold">Remote</p>
                          <input
                            type="checkbox"
                            className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                            name="is_remote"
                            checked={filterValues.is_remote}
                            onChange={updateRemote}
                          />
                        </div>
                        <p className="mt-5 font-semibold">Radius</p>
                        <div className="flex justify-start space-x-5 radiusContainer">
                          <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                            {filterValues.radius} miles
                          </p>
                        </div>
                        <ReactSlider
                          step={1}
                          min={1}
                          max={150}
                          minDistance={1}
                          name="desired_pay"
                          id="desired_pay"
                          className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                          thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                          trackClassName="bg-main"
                          value={filterValues.radius}
                          onChange={(value) => {
                            updateRadius(value);
                          }}
                          onAfterChange={updateRadiusFilter}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`absolute top-0 lg:left-4 md:left-7 w-5/6 md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer ${
                      filter ? "block" : "hidden"
                    }`}
                  >
                    <div className="flex flex-row">
                      <p className="font-normal">
                        Make a FREE Profile For Advanced Filters
                      </p>
                      <div className="relative">
                        <AiOutlineClose
                          className=" top-1 right-1 text-gray-700 cursor-pointer hover:text-black"
                          size="1.3em"
                          onClick={() => setFilter(false)}
                        />
                      </div>
                    </div>

                    <button
                      onClick={setRedirect}
                      className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                    >
                      Upgrade
                    </button>
                  </div>
                ))}
              {/* filter popup end */}

              <Parallax speed={5}>
                <div className="fast">
                  {zoomlvl > 6 && (
                    <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
                      <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
                        <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                          <AccordionItem uuid="a">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h1>
                                  Jobs Listing -{" "}
                                  <span className="italic align-middle">
                                    Ring of Hires
                                  </span>
                                </h1>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              {/* <h2 className="text-xl font-semibold capitalize">jobs Listing</h2> */}
                              <div className="block">
                                <button
                                  onClick={() => {
                                    setFilter(!filter);
                                  }}
                                >
                                  <img
                                    src={filter ? filterActive : filterActive}
                                    alt=""
                                    width="100px"
                                    className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                                  />
                                </button>
                              </div>
                              <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                                <p>showing {listingData.length} results</p>
                                <p>
                                  sort by:{" "}
                                  <span className=" text-main">
                                    newest post
                                  </span>
                                  <button className="px-1">
                                    <MdKeyboardArrowDown
                                      color="gray"
                                      size="1em"
                                    ></MdKeyboardArrowDown>
                                  </button>
                                </p>
                              </div>
                              <div
                                style={{ height: "50vh" }}
                                className="grid grid-cols-1 gap-2 capitalize md:grid-cols-2 overflow-y-scroll custom-sidebar"
                              >
                                {listingData.map((item, index) => {
                                  return (
                                    <Link to={`/job-details/${item.id}`}>
                                      <div
                                        key={index}
                                        className="rounded shadow-md profileCards relative flex flex-col"
                                      >
                                        <div className="flex p-2 items-center">
                                          <div className="flex-none w-20">
                                            {!item.image &&
                                            !item.category_unverified_image &&
                                            !item.category_verified_image ? (
                                              <img
                                                src={defaultlogo}
                                                alt=""
                                                className="w-20 h-20"
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  item.image
                                                    ? item.image
                                                    : item.emp_id == null
                                                    ? item.category_unverified_image
                                                    : item.category_verified_image
                                                }
                                                alt=""
                                                className="w-20 h-20"
                                              />
                                            )}
                                          </div>
                                          <div className="flex-auto pl-2">
                                            {item.is_premium == true && (
                                              <div>
                                                <img
                                                  src={RohLogo}
                                                  className="w-auto h-7 mx-auto"
                                                />
                                              </div>
                                            )}
                                            <p className="my-2 font-semibold text-center">
                                              {item.position}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="p-2">
                                          <p className="mx-1 my-2 text-gray-500 ">
                                            {item?.company_name}
                                          </p>
                                          <div className="flex items-top">
                                            <div className="mt-0">
                                              <GoLocation
                                                color="gray"
                                                size="1em"
                                                className="self-start mt-1"
                                              />
                                            </div>

                                            <p className="mx-1 my-0 text-gray-500 ">
                                              {item.address}
                                            </p>
                                          </div>
                                          <p className="my-2 font-bold text-main mb-12">
                                            {item?.max_salary &&
                                            item?.min_salary
                                              ? `$${item.min_salary.toLocaleString()}/Hr - $${item.max_salary.toLocaleString()}/Hr`
                                              : item?.max_yearly_pay &&
                                                item?.min_yearly_pay
                                              ? `$${item.min_yearly_pay.toLocaleString()}/Yr - $${item.max_yearly_pay.toLocaleString()}/Yr`
                                              : ""}
                                          </p>
                                          <div
                                            className={
                                              listingData?.length > 2
                                                ? "absolute bottom-0 my-2 w-11/12"
                                                : "my-2 w-11/12"
                                            }
                                          >
                                            <button
                                              type="submit"
                                              className="w-full py-1 font-semibold rounded btn-main"
                                              onClick={() =>
                                                history.push(
                                                  `/job-details/${item.id}`
                                                )
                                              }
                                            >
                                              View Job
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  )}
                </div>
              </Parallax>
            </ParallaxProvider>
          </div>
        )}
      </div>
    </>
  );
};

export default JobSearch;
