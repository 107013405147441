import { useEffect, useState, useContext } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useGetData from "../../../hooks/useGetData";
import { JobContext } from "./EmployerDashboard";
import { FaFileDownload } from "react-icons/fa";
import DefaultEmp from "../../../images/Dashboard/employee-default.png";
import { RefreshContext } from "../../../App";
import ReactTimeago from "react-timeago";
function PotentialHires({ updateRecent, setUpdateRecent }) {
  const user_id = localStorage.getItem("user_id");
  // We are recall the potential hire API by using
  // the 'refresh' state after the invite API call
  const [refresh, setRefresh] = useState(false);
  const loader = (
    <span className="text-base p-5 block font-semibold bg-white border rounded-xl mt-5">
      Loading...
    </span>
  );

  const { refreshWraper, setRefreshWraper } = useContext(RefreshContext);

  //Hire list API starts here..
  const [jobIdC, setJobIdC] = useContext(JobContext);
  const job_id = jobIdC ? jobIdC : null;
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      `employers/${user_id}/${job_id}/potential-hire/list?page_size=10000`
    );
    setSingleInvInfo(null);
  }, [job_id]);

  const { data: PotentialData, loading: potentialLoading } = useGetData(
    url,
    "main",
    refresh
  );
  //Hire List API ends here..

  //Invite API starts here..
  const [singleInvInfo, setSingleInvInfo] = useState("");
  const [inviteUrl, setInviteUrl] = useState("");

  const { data: InviteData, loading: inviteLoading } = useGetData(inviteUrl);

  useEffect(() => {
    if (singleInvInfo) {
      setInviteUrl(
        `employers/${user_id}/${job_id}/${singleInvInfo}/potential-hire/send-email`
      );
    }
  }, [singleInvInfo]);
  //Invite API ends here..

  // Call the Potential hire list API again after the invite API call
  useEffect(() => {
    if (InviteData && InviteData?.code == "200") {
      setTimeout(() => {
        setRefresh(true);
        setRefreshWraper();
      }, 2000);
    }
    setUpdateRecent((prev) => !prev);
  }, [InviteData]);

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        setRefresh(false);
      });
    }
  }, [refresh]);

  function CustomTimeFormat({ dateTime }) {
    const now = new Date();
    const timeDifference = now - new Date(dateTime);

    if (timeDifference < 24 * 60 * 60 * 1000) {
      return <span>Today</span>;
    } else {
      return <ReactTimeago date={dateTime} />;
    }
  }

  return (
    <>
      <div className="mt-3">
        <h1 className="text-lg font-semibold text-black">Potential Hires</h1>
        <div>
          {potentialLoading ? (
            loader
          ) : PotentialData?.count > 0 ? (
            <body className="flex items-center justify-center">
              <div className="container">
                <table className="sortable w-full flex flex-row flex-nowrap rounded-lg sm:overflow-y-scroll sm:shadow-lg my-5 custom-table">
                  {/* <tbody className="flex-1 hidden sm:flex-none potential-custom-height activeTable border-b">
                    <tr className="custom-row hidden sm:flex flex-col sm:flex-row mb-3 sm:mb-0 rounded-lg ">
                     
                    </tr>
                  </tbody> */}
                  <thead class="text-gray-500 text-sm xl:text-base">
                    <tr class="hidden flex-col flex-nowrap lg:table-row rounded-xl bg-gray-200">
                      <th className="text-center md:w-1/12 py-2">Name</th>
                      <th className="text-center md:w-1/12">Age</th>
                      <th className="text-center md:w-1/12">Position</th>
                      <th className="text-center md:w-1/12">Distance</th>
                      <th className="text-center md:w-1/12">Last Active</th>
                      <th className="text-center md:w-1/12">Resume</th>
                      <th className="text-center md:w-1/12">Action button</th>
                    </tr>
                  </thead>
                  <tbody class="flex-1 lg:flex-none custom-height activeTable border-b">
                    {PotentialData?.results?.map((singleRow, row) => (
                      <tr
                        className={`custom-row flex flex-col flex-nowrap lg:table-row mb-3 lg:mb-0 cursor-pointer bg-white border rounded-lg lg:border-none ${
                          singleRow?.id == singleInvInfo
                            ? "bg-gray-300"
                            : "bg-white"
                        }`}
                        key={row}
                      >
                        <td className="text-left p-3 border-grey-light border lg:text-center lg:w-1/12 ">
                          <div className="inline-block lg:hidden font-bold mr-4">
                            Name :
                          </div>{" "}
                          <div className="flex gap-2 flex-row justify-start">
                            {!singleRow?.profile_url ? (
                              <div className="flex-none">
                                <a href={`/employees/${singleRow?.id}`}>
                                  <img
                                    src={DefaultEmp}
                                    className="w-7 h-auto rounded-full"
                                  />
                                </a>
                              </div>
                            ) : (
                              <div className="flex-none">
                                <a href={`/employees/${singleRow?.id}`}>
                                  <img
                                    src={singleRow?.profile_url}
                                    className="w-7 h-auto rounded-full"
                                  />
                                </a>
                              </div>
                            )}

                            <a
                              href={`/employees/${singleRow?.id}`}
                              className="hover:text-main text-left"
                            >
                              {singleRow?.full_name}
                            </a>
                          </div>
                        </td>
                        <td className="text-left lg:text-center p-3 border-grey-light border lg:w-1/12">
                          <div className="inline-block lg:hidden font-bold">
                            Age :
                          </div>{" "}
                          {singleRow?.age}
                        </td>
                        <td className="text-left lg:text-center p-3 border-grey-light border lg:w-1/12">
                          <div className="inline-block lg:hidden font-bold">
                            Position :
                          </div>{" "}
                          {singleRow?.position_label ?? "N/A"}
                        </td>
                        <td className="text-left lg:text-center p-3 border-grey-light border lg:w-1/12">
                          <div className="inline-block lg:hidden font-bold">
                            Distance :
                          </div>{" "}
                          {singleRow?.distance_miles} miles
                        </td>
                        <td className="text-left lg:text-center p-3 border-grey-light border lg:w-1/12">
                          <div className="inline-block lg:hidden font-bold">
                            Last Active :
                          </div>{" "}
                          {singleRow?.last_login && (
                            <CustomTimeFormat
                              dateTime={singleRow?.last_login}
                            />
                          )}
                        </td>
                        <td className="text-left p-3 border-grey-light border lg:text-center lg:w-1/12">
                          <div className="inline-block lg:hidden font-bold">
                            Resume :
                          </div>{" "}
                          <div className="flex justify-start lg:justify-center">
                            {singleRow?.resume_url != null ? (
                              <a target="_blank" href={singleRow?.resume_url}>
                                <FaFileDownload
                                  className="text-main hover:text-main mx-auto"
                                  size="1.2em"
                                />
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </td>
                        <td className="text-left lg:text-center p-3 border-grey-light border lg:w-1/12">
                          {singleRow?.id == singleInvInfo ? (
                            inviteLoading ? (
                              <button
                                className="p-1 font-semibold rounded btn-main w-1/4 md:w-full lg:w-5/6 2xl:w-2/3 pointer-events-none"
                                disabled={true}
                              >
                                Sending
                              </button>
                            ) : (
                              <button
                                className="p-1 font-semibold rounded btn-green w-1/4 md:w-full lg:w-5/6 2xl:w-2/3 pointer-events-none"
                                disabled={true}
                              >
                                Sent
                              </button>
                            )
                          ) : (
                            <button
                              className="p-1 font-semibold rounded btn-sub w-1/4 lg:w-full lg:w-5/6 2xl:w-2/3"
                              onClick={() => {
                                setSingleInvInfo(singleRow?.id);
                              }}
                            >
                              Invite
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </body>
          ) : (
            <div className="bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
              Nothing to display
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PotentialHires;
