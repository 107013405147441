import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { AiOutlineEllipsis } from "react-icons/ai";
import CancelApplication from "../modals/Employee/CancelApplication";
import { RejectModal } from "../pages/EmployeeDetails";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CandidatePagePopup({ emp_id, setRefresh }) {
  const dropdownRef = useRef();
  const [cancelPopup, setCancelPopup] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const { jobid } = useParams();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        cancelPopup &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setCancelPopup(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [cancelPopup]);
  return (
    <div>
      {cancelModal && jobid && (
        <RejectModal
          appCancel={cancelModal}
          setAppCancel={setCancelModal}
          jobId={jobid}
          setRefresh={() => {
            setRefresh((prev) => !prev);
          }}
          to_user={emp_id}
        />
      )}
      <AiOutlineEllipsis
        className=" text-main shadow-2xl bg-white text-xl font-extrabold h-7 cursor-pointer"
        size="2em"
        onClick={() => setCancelPopup(true)}
      />
      <div
        className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
          cancelPopup ? "block" : "hidden"
        }`}
        ref={dropdownRef}
      >
        <div className="divide-y rounded-md shadow-lg">
          <button
            onClick={() => {
              setCancelPopup(false);
              console.log(emp_id, jobid, "apple");
              setCancelModal(true);
            }}
            className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
          >
            Cancel Application
          </button>
        </div>
      </div>
    </div>
  );
}
