import { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import useGetData from "../../hooks/useGetData";
import usePostData from "../../hooks/usePostData";

import NotifyImg1 from "../../images/JobSearch/docImg1.png";
import NotifyImg2 from "../../images/JobSearch/leslie.png";
import NotifyImg3 from "../../images/JobSearch/staffNurse.png";
import defaultEmployerImg from "../../images/JobDetails/hospital-default.jpg";
import defaultEmployeeImg from "../../images/employee-default.png";

import { FaRegImage } from "react-icons/fa";
import { AiOutlinePaperClip } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { AiOutlineEllipsis } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { MdDoneAll, MdArrowBack } from "react-icons/md";

import Picker from "emoji-picker-react";
import DeleteMessage from "../modals/DeleteContact";
import copy from "copy-to-clipboard";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { ChatContext } from "../../App";
import { useContext } from "react";
import SingleMessage from "../elements/SingleMessage";
import TimeAgo from "react-timeago";

const MailBox = () => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [senderDropdown, setSenderDropdown] = useState(false);
  const [receiverDropdown, setReceiverDropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileupload, setFileUpload] = useState(false);
  const [imageupload, setImageUpload] = useState(false);

  const [showPicker, setShowPicker] = useState(false);

  const [show, setShow] = useState(7);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [copyText, setCopyText] = useState("");
  const [file_error, setFileError] = useState(null);
  const [messageTime, setmessageTime] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [displaymessage, setDisplaymessage] = useState(false);
  // console.log('chosenEmoji...', chosenEmoji?.emoji);

  ////////////////////////////////////////////////////////////
  // for temp use

  //check queries in url
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [
    connectedUser,
    setConnectedUser,
    userList,
    setUserList,
    messageNotification,
    setMessageNotification,
  ] = useContext(ChatContext);

  //const [connectedUser, setConnectedUser] = useState(null);
  const [historyUrl, setHistoryUrl] = useState(null);
  //const [sendMessageDataApi, sendMessageApi] = useState(null);
  const token = localStorage.getItem("accessToken");
  const myId = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const inputStr = useRef("");
  const lastMessageRef = useRef();

  const [socketUrl, setSocketUrl] = useState(
    `${process.env.REACT_APP_CHAT_SOCKET}chat/?to_id=${connectedUser?.user_id}&my_token=${token}`
  );
  const [messageHistory, setMessageHistory] = useState([]);

  const { data: chatHistory } = useGetData(historyUrl, "chat");
  //const { data: messageSentApi } = usePostData(`chat/${connectedUser?.user_id}`,sendMessageDataApi,"application/json","chat");

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  //Function to convert the message time to local format
  function dateTime(msgtime) {
    const msg = new Date(msgtime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return msg;
  }

  useEffect(() => {
    console.log(query.get("user"));
    if (query.get("user") && !connectedUser) {
      const findUser = userList?.find(
        (user) => user.user_id == query.get("user")
      );
      setConnectedUser(findUser);
    }
  }, [userList]);

  useEffect(() => {
    if (connectedUser) {
      setHistoryUrl(`chat/messages/${connectedUser.user_id}?page=1`);
      handleClickChangeSocketUrl(connectedUser.user_id);

      const userIndex = userList.findIndex(
        (user) => user.user_id == connectedUser.user_id
      );
      let newList = [...userList];
      if (userIndex > -1) {
        newList[userIndex]["unread"] = false;
        setUserList(newList);
      }
      //setUserList([{ ...connectedUser, unread: false }, ...newList]);
    }
  }, [connectedUser]);

  useEffect(() => {
    if (chatHistory) {
      setMessageHistory(chatHistory.messages.reverse());
      //lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatHistory]);

  const { sendJsonMessage, sendMessage, lastMessage, readyState } =
    useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage?.data) {
      const message = JSON.parse(lastMessage.data);
      setMessageHistory((currentMessageHistory) => [
        ...currentMessageHistory,
        {
          created_at: message.created_at,
          sender: message.send_id,
          sender__name: message.sender_name,
          text: message.message,
        },
      ]);
      //  lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
      //const msgForm = new FormData();
      //msgForm.append("message", message.message);
      //sendMessageApi(msgForm);
    }
  }, [lastMessage]);

  const handleClickChangeSocketUrl = useCallback(
    (to_id) =>
      setSocketUrl(
        `${process.env.REACT_APP_CHAT_SOCKET}chat/?to_id=${to_id}&my_token=${token}`
      ),
    []
  );

  const handleClickSendMessage = useCallback(() => {
    const messageText = inputStr.current.value.trim();
    if (messageText && messageText != "") {
      sendJsonMessage({ message: inputStr.current.value, username: "user" });
      inputStr.current.value = "";
    }
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClickSendMessage();
    }
  };

  // clear the connected user in contect when leving chat page
  useEffect(() => {
    setMessageNotification(false);
    return () => {
      setConnectedUser(null);
    };
  }, []);

  ///////////////////////////////////////////////////////////

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };
  console.log("Selected emoji...", chosenEmoji?.emoji);

  useEffect(() => {
    if (chosenEmoji) {
      inputStr.current.value += chosenEmoji?.emoji;
    }
  }, [chosenEmoji]);

  //trigger click on hidden input field when clicking on img upload icon
  const hiddenImgInput = useRef(null);
  const handleImgClick = (event) => {
    hiddenImgInput.current.click();
  };

  const hiddenFileInput = useRef(null);
  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  // convert the uploaded file from bytes to KB, MB, GB
  const bytes = selectedFile?.size;
  const formatFileSize = function (bytes) {
    const sufixes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
  };

  // Display the file size
  var fileSize = formatFileSize(bytes);
  //console.log(`fileSize`, fileSize);

  // image upload check
  const handleImgChange = (e) => {
    if (e.target.files[0].size > 15e6) {
      setFileError("Image should be less than 15 MB");
    } else {
      let allowedExtension = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/bmp",
      ];
      let type = e.target.files[0].type;
      if (allowedExtension.indexOf(type) > -1) {
        setFileError("");
        setSelectedImage(e.target.files[0]);
      } else {
        setFileError("Please chose an image file (jpeg/jpg/png/bmp)");
      }
    }
  };

  // file upload check
  const handleFileChange = (e) => {
    if (e.target.files[0].size > 15e6) {
      setFileError("File should be less than 15 MB");
    } else {
      let allowedExtension = ["application/msword", "application/pdf"];
      let type = e.target.files[0].type;
      if (allowedExtension.indexOf(type) > -1) {
        setFileError("");
        setSelectedFile(e.target.files[0]);
      } else {
        setFileError("Please upload pdf or doc file");
      }
    }
  };
  console.log(userList, displaymessage, "sima");

  return (
    <div className="w-full block xl:flex xl:flex-row xl:space-x-2 xl:space-y-0 space-y-2">
      {/* <div>

        <button
          onClick={handleClickSendMessage}
          disabled={readyState !== ReadyState.OPEN}
        >
          Click Me to send 'Hello'
        </button><br></br>
        <span>The WebSocket is currently {connectionStatus}</span><br></br>
        {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
        <ul>
          {messageHistory
            .map((message, idx) => <span key={idx}>{message ? message.data : null}</span>)}
        </ul>
      </div> */}

      {/* Contact col starts here */}
      {!displaymessage && (
        <div className="bg-white rounded-lg shadow-lg pb-1 md:pb-5 xl:flex-initial w-full">
          <h2 className="font-semibold text-2xl px-5 pt-10">Contacts</h2>
          <div className="relative flex items-center px-5">
            {/* <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 1024 1024"
            className="global-class-name absolute left-8 text-gray-400"
            height="1.3em"
            width="1.3em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
          </svg>
          <input
            type="text"
            name=""
            id=""
            className="p-3 pl-10 rounded-lg outline-none w-full bg-gray-200 text-gray-400"
            placeholder="Search"
          /> */}
          </div>
          <div
            className="overflow-auto max-h-screen"
            style={{ height: "570px" }}
          >
            {/* Loop this */}
            {!userList?.length && (
              <div
                className="relative h-1/2"
                style={{
                  textAlign: "center",
                  padding: "0 20px",
                  margin: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 className="text-2xl font-bold">No Message Yet</h1>
              </div>
            )}

            {userList &&
              userList.map((ChatMember, index) => (
                <div
                  className={`cursor-pointer ${
                    !ChatMember?.unread && "border-b-2 border-gray-200"
                  } my-6 ${
                    ChatMember?.unread && "border-2 border-main rounded-md"
                  } py-2 `}
                  onClick={() => {
                    setDisplaymessage(true);
                    setShow(ChatMember.user_id);
                    setConnectedUser(ChatMember);
                  }}
                >
                  <div className="flex" key={index}>
                    <div
                      className={`flex-none w-2 h-auto ${
                        show === ChatMember.user_id
                          ? "bg-main mr-3"
                          : "bg-white mr-3"
                      }`}
                    ></div>

                    <div className="flex-none">
                      {user_role === "employer" ? (
                        <img
                          className="rounded-md w-16 h-16"
                          alt={ChatMember.user_name}
                          title={ChatMember.user_name}
                          loading="lazy"
                          src={
                            ChatMember?.user_image
                              ? ChatMember.user_image
                              : defaultEmployeeImg
                          }
                        />
                      ) : (
                        <img
                          className="rounded-md w-16 h-16"
                          alt={ChatMember.user_name}
                          title={ChatMember.user_name}
                          loading="lazy"
                          src={
                            ChatMember?.user_image
                              ? ChatMember.user_image
                              : defaultEmployerImg
                          }
                        />
                      )}
                    </div>
                    <div className="flex-auto pl-6 md:mt-0 pr-3">
                      <h4
                        className={`capitalize mb-2 text-xl ${
                          ChatMember?.unread && "font-bold text-black"
                        } ${
                          !ChatMember?.unread && "font-semibold text-gray-700"
                        }`}
                      >
                        {ChatMember.user_name}
                      </h4>
                      <p
                        className={`mb-2 text-gray-600 ${
                          ChatMember?.unread && "font-semibold"
                        }`}
                      >
                        {ChatMember.latest_message.length < 5
                          ? ChatMember.latest_message
                          : ChatMember.latest_message.substring(0, 10) + "..."}
                      </p>
                    </div>
                    {ChatMember?.unread && (
                      <div className="relative">
                        <div className="absolute w-3 h-3 bg-main rounded-full right-3 top-0"></div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {/* Contact col ends here */}

      {/* Chat box outer starts here */}
      {displaymessage && (
        <div className="p-5 bg-white rounded-lg shadow-lg xl:flex-auto  w-full relative flex flex-col justify-between">
          {connectedUser && (
            <div className="flex items-center border-b-2 pb-3">
              <button
                className="py-4 px-6 shadow-sm"
                onClick={() => {
                  setDisplaymessage(false);
                }}
              >
                <MdArrowBack className="text-black" size="24px" />
              </button>
              <div className="flex-none">
                {user_role === "employer" ? (
                  <img
                    onClick={() => {
                      window.open(
                        `/employees/${connectedUser.user_id}`,
                        "_blank"
                      );
                    }}
                    className="rounded-md w-16 h-16 cursor-pointer"
                    alt={connectedUser?.user_name}
                    title={connectedUser?.user_name}
                    loading="lazy"
                    src={
                      connectedUser.user_image
                        ? connectedUser.user_image
                        : defaultEmployeeImg
                    }
                  />
                ) : (
                  <img
                    className="rounded-md w-16 h-16"
                    alt={connectedUser?.user_name}
                    title={connectedUser?.user_name}
                    loading="lazy"
                    src={
                      connectedUser.user_image
                        ? connectedUser.user_image
                        : defaultEmployerImg
                    }
                  />
                )}
              </div>
              <div className="flex-auto pl-6 md:mt-0">
                {user_role === "employer" ? (
                  <h4
                    className="capitalize font-bold mb-2 text-xl cursor-pointer"
                    onClick={() => {
                      window.open(
                        `/employees/${connectedUser.user_id}`,
                        "_blank"
                      );
                    }}
                  >
                    {connectedUser?.user_name}
                  </h4>
                ) : (
                  <h4 className="capitalize font-bold mb-2 text-xl">
                    {connectedUser?.user_name}
                  </h4>
                )}
              </div>

              {/* <div className="flex-none w-20 relative hidden md:block">
              <div class="search-container relative inline-block m-0 h-12 w-12">
                <form action="/search" method="get">
                  <input
                    class="search expandright rounded-lg bg-gray-200 left-auto right-12 absolute outline-none border-none p-0 w-0 h-full z-10 transition-all"
                    id="searchright"
                    type="search"
                    name="q"
                    placeholder="Search"
                  />
                  <label
                    class="button searchbutton absolute text-2xl leading-6 w-full m-0 p-0"
                    for="searchright"
                  >
                    <span class="mglass text-main inline-block pointer-events-none">
                      &#9906;
                    </span>
                  </label>
                </form>
              </div>
            </div> */}
              {/* <div className="flex-initial">
              <div className="relative inline-block text-left">
                <DeleteMessage
                  showModal={deleteModal}
                  setShowModal={setDeleteModal}
                />
                <div>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full text-xl font-bold text-main"
                    id="menu-button"
                    onClick={() => {
                      setIsDropdown(!isDropdown);
                    }}
                  >
                    <AiOutlineEllipsis className=" text-main" size="1.5em" />
                  </button>
                </div>

                <div
                  className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                    isDropdown ? "block" : "hidden"
                  }`}
                >
                  <div className="divide-y rounded-md shadow-lg">
                    <button
                      onClick={() => {
                        setIsDropdown(false);
                        setDeleteModal(true);
                      }}
                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                    >
                      Delete Contact
                    </button>
                    <button
                      onClick={() => {}}
                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                    >
                      Report
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          )}

          {/* <div className="outer-scroll md:mt-5 overflow-y-scroll pr-5 h-[100%] mt-5 pb-5"> */}
          <div
            className=" flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch h-screen"
            style={{ maxHeight: "460px" }}
          >
            {/* Chat box oponent message starts here */}
            {messageHistory &&
              messageHistory.map((message, index) => {
                if (index === messageHistory.length - 1) {
                  lastMessageRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
                }
                return (
                  <>
                    {message.sender == myId ? (
                      <div className="flex items-end justify-end">
                        <div className="w-4/12"></div>
                        <div className="w-8/12  bg-blue-300 p-4 rounded-lg mt-2 justify-end">
                          <p className="break-words max-w-full">
                            <SingleMessage message={message} />
                          </p>
                          <span className="block text-right text-xs text-gray-500">
                            {/* {dateTime(message?.created_at)}{" "}
                          {message.created_at?.slice(0, 10)} */}
                            {message?.created_at ? (
                              <TimeAgo
                                date={message?.created_at}
                                formatter={(value, unit, suffix) => {
                                  if (unit === "second") {
                                    return "just now";
                                  } else {
                                    return (
                                      value +
                                      " " +
                                      unit +
                                      (value > 1 ? "s" : "") +
                                      " " +
                                      suffix
                                    );
                                  }
                                }}
                              />
                            ) : (
                              "just now"
                            )}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-start justify-start">
                        <div className="w-8/12   bg-gray-400 p-4 rounded-lg mt-2  flex">
                          {/* <div className=" w-1/12"> */}
                          <img
                            className="w-auto h-7 rounded-full"
                            src={
                              connectedUser?.user_image
                                ? connectedUser?.user_image
                                : user_role === "employer"
                                ? defaultEmployeeImg
                                : defaultEmployerImg
                            }
                            alt={connectedUser?.user_name}
                            title={connectedUser?.user_name}
                            loading="lazy"
                          ></img>
                          {/* </div> */}
                          <div className="pl-2 w-10/12">
                            <p className="break-words max-w-full">
                              <SingleMessage message={message} />
                            </p>
                            <span className="block text-right text-xs text-gray-500">
                              {/* {dateTime(message?.created_at)}{" "}
                            {message.created_at?.slice(0, 10)} */}
                              {message?.created_at ? (
                                <TimeAgo
                                  date={message?.created_at}
                                  formatter={(value, unit, suffix) => {
                                    if (unit === "second") {
                                      return "just now";
                                    } else {
                                      return (
                                        value +
                                        " " +
                                        unit +
                                        (value > 1 ? "s" : "") +
                                        " " +
                                        suffix
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                "just now"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="w-4/12"></div>
                      </div>
                    )}
                  </>
                );
              })}
            <div className=" pt-12" ref={lastMessageRef}></div>

            {/* Image Upload hidden input */}
            <div>
              <input
                accept="image/png, image/jpg, image/jpeg"
                style={{ display: "none" }}
                ref={hiddenImgInput}
                type="file"
                onChange={handleImgChange}
              />
            </div>

            {/* File Upload hidden input */}
            <div>
              <input
                accept=".pdf,.doc"
                style={{ display: "none" }}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
              />
            </div>

            {/* file upload UI */}
            {selectedFile && (
              <div className="mt-12">
                <div className="md:flex md:justify-end relative">
                  <div className="lg:flex lg:w-7/12 p-5 bg-main text-white rounded-lg break-words">
                    <div>
                      <AiOutlineFile className="mr-3 text-main bg-white rounded-full w-10 h-10 p-2" />
                    </div>
                    <div className="">
                      <p>{selectedFile.name}</p>
                      <p>{fileSize}</p>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          setSelectedFile(null);
                        }}
                        className="p-1 text-gray-600 absolute top-1 right-1 bg-white rounded-full opacity-30 hover:opacity-50"
                      >
                        <AiOutlineClose className="text-black" size="1em" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* image upload UI */}
            {selectedImage && (
              <div className="mt-12">
                <div className="flex justify-end">
                  <div className="flex-initial md:w-7/12  text-white rounded-lg">
                    <div className="block relative">
                      <img
                        className="w-full"
                        src={URL.createObjectURL(selectedImage)}
                        alt="Upload image via chat"
                        title="Media upload"
                        loading="lazy"
                      />
                      <button
                        onClick={() => {
                          setSelectedImage(null);
                        }}
                        className="p-1 text-white absolute top-1 right-1 bg-black rounded-full opacity-80 hover:opacity-60"
                      >
                        <AiOutlineClose className="text-white" size="1em" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Chat input box */}
          <div className="mt-10">
            {file_error && (
              <div className="text-left my-2 text-red-500"> {file_error} </div>
            )}
            <div className="flex">
              <div className="mb-4 flex-auto md:mr-5 md:mb-0 mx-1">
                <div className="picker-container relative w-full">
                  {showPicker && (
                    <Picker
                      pickerStyle={{ width: "100%" }}
                      onEmojiClick={onEmojiClick}
                    />
                  )}
                  <input
                    className="input-style p-3 pl-10 rounded-lg outline-none w-full bg-gray-200 text-gray-600"
                    ref={inputStr}
                    placeholder="Enter your message here"
                    onKeyDown={handleKeyDown}
                  />
                  {/* <img
                  className="emoji-icon cursor-pointer absolute left-3 top-4"
                  src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                  onClick={() => setShowPicker((val) => !val)}
                /> */}
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="w-full p-3 pl-10 rounded-lg outline-none btn btn-main mb-4 md:mb-0 mx-2"
                  onClick={() => handleClickSendMessage()}
                >
                  Send
                </button>
              </div>
            </div>

            {/* action icons */}
            {/* <div className="flex mt-6">
            <div>
              <button onClick={handleImgClick}>
                <FaRegImage className="mr-5 text-gray-400" size="1.5em" />
              </button>
            </div>
            <div>
              <button onClick={handleFileClick}>
                <AiOutlinePaperClip className=" text-gray-400" size="1.5em" />
              </button>
            </div>
          </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MailBox;
