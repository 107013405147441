import { useState, useRef, useEffect, useContext, useCallback } from "react";
import usePost from "../../hooks/usePostData";
import { UserContext } from "../../App";
import { fields, checkFields } from "../../helpers/JobPostingSchema";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { MdDoneAll } from "react-icons/md";

const ImageUploaderJobPost = ({ image, fileData, setClearMe }) => {
  const [values, setValue] = useState(fields);
  const { userId } = useContext(UserContext);
  const filePreview = "/images/user.png";

  const [crop, setCrop] = useState({
    aspect: 16 / 16,
    x: 10,
    y: 10,
    width: 75,
    unit: "%",
  });
  const [file_src, setFileSrc] = useState(null);
  const [fileObj, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [imageToCrop, setCropper] = useState(null);

  const [file_error, setFileError] = useState(null);
  const [file_form, setFileForm] = useState(null);

  const [uploadButton, enableUpload] = useState(false);
  const [doneUpload, setDoneUpload] = useState(false);

  //trigger click on hidden input field when
  //clicking on preview
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  //for upload image
  const uploadFile = () => {
    // console.log(fileObj);
    const temp_form = new FormData();
    temp_form.append("image", result);
    setFileForm(temp_form);
  };

  useEffect(() => {
    if (result) {
      uploadFile();
    }
  }, [result]);

  //file selected event
  const handleChange = (e) => {
    if (
      !e.target.files[0]?.name?.match(/\.(jpg|jpeg|png|bmp|JPG|JPEG|PNG|BMP)$/)
    ) {
      setFileError("Please choose an image file (jpeg/jpg/png/bmp)");
      return;
    }
    if (e.target.files[0]?.size > 15e6) {
      setFileError("File should be less than 15 MB");
    } else {
      let allowedExtension = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/bmp",
      ];
      let type = e.target.files[0].type;
      if (allowedExtension.indexOf(type) > -1) {
        setFileError("");
        setCropper(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        e.target.value = null;
      } else {
        setFileError("Please choose an image file (jpeg/jpg/png/bmp)");
      }
    }
    setDoneUpload(false);
  };

  //for reset file
  const resetFile = () => {
    setFile(null);
    setFileForm(null);
    setCropper(null);
    enableUpload(false);
    setFileError("");
    setDoneUpload(false);
  };

  const cancelUpload = () => {
    setFileSrc(null);
    setFile(null);
    setFileForm(null);
    setCropper(null);
    enableUpload(false);
    setDoneUpload(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setResult(croppedImage);
  };

  //for cropping iimage
  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = fileObj.naturalWidth / fileObj.width;
      const scaleY = fileObj.naturalHeight / fileObj.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        fileObj,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const image_url = canvas.toDataURL("image/png");
      setFileSrc(image_url);
      setCropper(null);
      const reader = new FileReader();
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          dataURLtoFile(reader.result, "cropped.jpg");
        };
      });
    } catch (e) {
      console.log("crop the image");
    }
  };

  useEffect(() => {
    fileData(file_data?.data);
    if (fileObj) {
      enableUpload(true);
    }
  }, [fileObj]);

  const {
    data: file_data,
    error: image_error,
    loading: loading_image,
  } = usePost(`employers/job/images`, file_form, "multipart/form-data");

  // for rerendering search component / Dashbord when upload is completed
  useEffect(() => {
    if (file_data) {
      resetFile();
      setDoneUpload(true);
    }
  }, [file_data]);
  useEffect(() => {
    if (!image) {
      cancelUpload();
    }
  }, [image]);

  useEffect(() => {
    if (setClearMe) {
      setFileSrc(null);
      setDoneUpload(false);
    }
  }, [setClearMe]);

  return (
    <div className="flex flex-col items-center justify-center">
      <input
        accept="image/png, image/jpg, image/jpeg"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        onChange={handleChange}
      />
      {imageToCrop && (
        <div className="fixed  inset-0 bg-gray-600 bg-opacity-100 overflow-y-auto w-5/12 z-50 m-auto h-96">
          <div classNmae="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
            <div className="text-center">
              <div className="flex items-center justify-center w-auto h-auto mx-auto bg-gray-100">
                <ReactCrop
                  src={imageToCrop}
                  onImageLoaded={setFile}
                  crop={crop}
                  onChange={setCrop}
                />
              </div>
              <div className="py-3 mt-2 px-7">
                <p className="text-sm text-white font-normal normal-case">
                  Select a suitable area for profile picture and click on crop
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button onClick={getCroppedImg} className="w-full btn btn-main">
                  Confirm
                </button>
                <button
                  className="w-full mt-2 cropButton btn btn-sub"
                  onClick={resetFile}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-main w-40 rounded-full  p-2 relative">
        <img
          onClick={handleClick}
          className="absolute right-0 w-10 top-5 cursor-pointer"
          src="/images/user_cam.png"
          alt="User upload pic icon"
          title="User profile pic"
          loading="lazy"
        />
        <div className="overflow-hidden">
          <img
            onClick={handleClick}
            className="w-full rounded-full cursor-pointer image-upload-preview "
            src={file_src ? file_src : image ? image : filePreview}
            alt="Job image"
            title="Job image"
            loading="lazy"
          />
        </div>
      </div>

      {doneUpload && <MdDoneAll className="text-xl text-green-600" />}
      {file_error && (
        <div className="my-2 text-center text-red-500 normal-case">
          {" "}
          {file_error}{" "}
        </div>
      )}

      {uploadButton && (
        <>
          <button onClick={() => uploadFile()} className="w-full mt-2 btn">
            {loading_image ? "Uploading..." : "Upload"}
          </button>
          {!loading_image && (
            <button onClick={() => cancelUpload()} className="w-full mt-2 btn">
              Reset
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ImageUploaderJobPost;
