import { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import usePostData from "../../hooks/usePostData";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";

//Google client ID created via Google developer console
const clientId =
  "264360647624-s4adpfi3n8giq2hcogg6pc47klepqh81.apps.googleusercontent.com";

function GLogin() {
  const [formdata, setFormData] = useState(null);
  const [popup, setPopup] = useState(false);
  const [userRole, setUserRole] = useState("employee");
  const [companyName, setCompanyName] = useState("");
  const [errorEmptyFiled, setErrorEmptyField] = useState(false);
  const history = useHistory();

  const url = "social/google-login";
  const { data, error, loading, code } = usePostData(url, formdata);

  //When the Google login throwing 400 error, popup should show
  useEffect(() => {
    if (code === 400) {
      setPopup(true);
    }
  }, [code]);

  //Google success case function trigger here..
  const onLoginSuccess = (res) => {
    console.log("Login Success:", res);
    setFormData({
      email: res?.profileObj?.email,
      imageUrl: res?.profileObj?.imageUrl,
      user_role: "",
      id_token: res?.tokenId,
    });
  };

  //Google failure case function trigger here..
  const onLoginFailure = (res) => {
    console.log("Login failed:", res);
  };

  //Call the API at the second time by selecting the user type
  const upgradeMe = () => {
    if (userRole === "employer" && companyName.trim() <= 0) {
      setErrorEmptyField(true);
    } else {
      setFormData((prev) => ({
        ...prev,
        user_role: userRole,
        ...(userRole === "employer" && { company_name: companyName }),
      }));
      setPopup(false);
    }
  };

  //Action take place once the Google login success and when it has data
  useEffect(() => {
    if (data) {
      localStorage.setItem("accessToken", data.data.token);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("user_id", data.data.user_id);
      localStorage.setItem("user_role", data.data.user_role);
      localStorage.setItem("latitude", data.data["latitude "]);
      localStorage.setItem("longitude", data.data["longitude "]);
      localStorage.setItem("plan_value", data.data.plan_value);

      const route = localStorage.getItem("path");
      const job_id = localStorage.getItem("job_id");

      if (job_id) {
        window.location.href = `/job-details/${job_id}`;
        localStorage.removeItem("job_id");
      } else {
        window.location.href = route || "/profile/edit";
      }
    }
  }, [data]);

  //gapi library used for generate the response object from Google
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      {popup && (
        <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
          <div className="relative w-11/12 md:w-1/2 lg:w-5/12 2xl:w-1/4 max-w-4xl mx-auto my-6 social-log">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                <h3 className="text-xl font-semibold md:text-2xl">
                  Choose Your Account Type
                </h3>

                <button
                  className="flex items-center justify-center font-semibold text-gray-500 w-auto"
                  onClick={() => {
                    setPopup(false);
                    history.go(0);
                  }}
                >
                  <AiOutlineClose className="text-gray-600" size="1.2em" />
                </button>
              </div>

              <div className="flex flex-col md:flex-row gap-4 px-4 justify-center pt-6 social-but">
                <button
                  value="employee"
                  onClick={(e) => setUserRole(e.target.value)}
                  className={`btn ${userRole === "employee" ? "btn-main" : ""}`}
                >
                  {" "}
                  Job Seekers
                </button>
                <button
                  value="employer"
                  onClick={(e) => setUserRole(e.target.value)}
                  className={`btn ${userRole === "employer" ? "btn-main" : ""}`}
                >
                  {" "}
                  Looking For Hire
                </button>
              </div>
              {userRole === "employer" && (
                <div className="flex flex-col justify-center pt-6 px-4 social-but ">
                  <label
                    className="pblock mb-2 text-sm font-semibold text-gray-500 text-left"
                    htmlFor="email"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    id="company_name"
                    value={companyName}
                    autoComplete="off"
                    className="w-full px-3 py-3 capitalize mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    placeholder="Ring Of Hires"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  {errorEmptyFiled && (
                    <span className="text-left form_error_message">
                      This field is required
                    </span>
                  )}
                </div>
              )}

              <div className="p-4">
                <button
                  className="w-full mt-3 md:mt-0 btn btn-main"
                  onClick={() => {
                    upgradeMe();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        autoLoad={false}
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        // cookiePolicy={'single_host_origin'}
        // isSignedIn={true}
      />
    </>
  );
}

export default GLogin;
