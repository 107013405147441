import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { GoLocation } from "react-icons/go";
import { FaWindowClose } from "react-icons/fa";
import checkCircle from "../../../images/JobApply/check-circle.png";
import PhoneInput from "react-phone-input-2";
import useGetData from "../../../hooks/useGetData";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";

let old_phoneformat = "";
let new_phoneformat = "";

const JobApply = ({
  showModal,
  setShowModal,
  jobDetails,
  isApplied,
  applyRefresh,
}) => {
  // localStorage
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const { jobid } = useParams();

  // states
  const [employeeData, setEmployeeData] = useState({});
  const [modalNo, setModalNo] = useState(1);
  const [resume, setResume] = useState([]);
  const [isDefaultResume, setIsDefaultResume] = useState(true);
  const [formdata, setFormData] = useState(null);
  const [errors, setErrors] = useState({});
  const url = `employees/${user_id}/job/${jobid ?? jobDetails?.jobId}`;
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState("");
  const [doubleClick, setDoubleClick] = useState(false);
  const [values, setValues] = useState({
    phone: "",
    email: "",
    // resume: null,
    default_resume: "true",
  });
  console.log("resume", resume);
  //hooks
  const { data: employeesData } = useGetData(`employees/${user_id}`);

  // const { data, error, loading } = usePostData(url, formdata);

  const { data, error, loading } = usePostData(
    url,
    formdata,
    "multipart/form-data"
  );

  useLayoutEffect(() => {
    setEmployeeData(employeesData?.data);
  }, [employeesData]);

  useEffect(() => {
    if (data) {
      setModalNo(4);
      setIsDefaultResume(true);
      setResume({});
      applyRefresh(true);

      setTimeout(() => {
        setModalNo(1);
        setShowModal(false);
      }, 2000);
    } else if (error) {
      setModalNo(6);
      setTimeout(() => {
        setModalNo(3);
      }, 2000);
    }
  }, [data, error]);

  //job apply
  const jobApply = () => {
    const temp_form = new FormData();

    for (var key in values) {
      temp_form.append(key, values[key]);
    }
    setFormData(temp_form);
    // setModalNo(4);
    setValues((prev) => ({
      ...prev,
      // resume: null,
      default_resume: "true",
    }));

    setDoubleClick(true);
  };

  //field validation
  const validate = async () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isvalid = true;
    let error = {};
    if (!values.email) {
      isvalid = false;
      error["email"] = "Please provide your email";
    } else if (!emailRegex.test(values.email)) {
      isvalid = false;
      error["email"] = "Please provide a valid email";
    }
    if (!values.phone) {
      isvalid = false;
      error["phone"] = "Please provide your phone no";
    }
    setErrors({ ...error });
    return isvalid;
  };

  const updatePhone = (value, country) => {
    setValues((prev) => ({ ...prev, phone: value }));
  };

  const updateValues = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (resume?.newResume) {
      setValues((prev) => ({
        ...prev,
        resume: resume?.newResume,
        default_resume: "false",
      }));
      setIsDefaultResume(false);
      console.log("Resume Details..", resume?.newResume);
    }
  }, [resume?.newResume]);

  const updateResume = (e) => {
    let allowedExtension = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    let type = e.target.files[0]?.type;
    if (allowedExtension.indexOf(type) > -1) {
      if (e.target.files[0].size < 15e6) {
        setResume((prev) => ({
          ...prev,
          newResume: e.target.files[0],
          path: URL.createObjectURL(e.target.files[0]),
        }));
        setMessage("");
      } else {
        setMessage("File should be less than 15 MB");
      }
    } else {
      setMessage("Please select a PDF/Doc/Docx file");
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  };

  const selectResume = (type) => {
    if (type === "newResume") {
      setValues((prev) => ({
        ...prev,
        resume: resume?.newResume,
        default_resume: "false",
      }));
      setIsDefaultResume(false);
    } else if (type === "defaultResume") {
      setValues((prev) => ({
        ...prev,
        // resume: null,
        default_resume: "true",
      }));
      setIsDefaultResume(true);
    } else {
      setIsDefaultResume(true);
      setValues((prev) => ({
        ...prev,
        // resume: null,
        default_resume: "true",
      }));
      setResume({});
    }
  };

  useEffect(() => {
    setValues({
      phone: employeeData?.phone
        ? String(employeeData?.phone)
        : employeeData?.phone,
      email: employeeData?.email,
      // resume: null,
      default_resume: "true",
    });
  }, [employeeData]);

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  const checkIsLoggedIn = () => {
    if (!user_id) {
      localStorage.setItem("job_id", jobid);
      window.location.href = "/login";
      return;
    }
  };

  if (employeeData) {
    old_phoneformat = employeeData?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  return (
    <div>
      {showModal === true && checkIsLoggedIn()}
      {user_id && showModal ? (
        user_role === "employee" ? (
          <>
            {modalNo === 1 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 md:flex md:items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Apply to {jobDetails?.company_name}
                      </h3>
                      <p className="text-red-600 font-base-semibold">1/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <p className="">Contact Info</p>
                          <div className="gap-2 md:flex">
                            <img
                              className="rounded-md"
                              src={
                                employeeData?.image
                                  ? employeeData.image
                                  : "/images/employee-default.png"
                              }
                              alt=""
                              width="100px"
                            />
                            <div className="space-y-2">
                              <h2 className="text-black font-xl-semibold">
                                {employeeData?.first_name
                                  ? `${employeeData?.first_name} ${employeeData?.last_name}`
                                  : "User Name"}
                              </h2>
                              <p>{employeeData?.position?.name}</p>
                              <p className="flex text-sm">
                                <span className="mt-1 md:mt-0">
                                  <GoLocation
                                    color="007fee"
                                    className="mr-1"
                                    size="1.2em"
                                  />
                                </span>
                                {`${employeeData?.address}, ${employeeData?.city}, ${employeeData?.state}`}
                                {employeeData?.zipcode === null
                                  ? ""
                                  : `, ${employeeData?.zipcode}`}
                              </p>
                            </div>
                          </div>
                          <div className="block w-full gap-3 md:flex">
                            <div className="flex flex-col w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="company_name"
                              >
                                Email Address
                              </label>
                              <input
                                type="text"
                                name="email"
                                id="company_name"
                                autoComplete="off"
                                onChange={(e) => {
                                  updateValues(e);
                                }}
                                value={values?.email}
                                className="text-sm form_input"
                                placeholder="Enter your email"
                              />
                              <span className="form_error_message">
                                {errors?.email}
                              </span>
                            </div>
                            <div className="w-full">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="phone"
                              >
                                Phone no
                              </label>
                              <PhoneInput
                                inputClass="max-h-11 p-0 m-0 "
                                containerClass="h-11 p-0"
                                country={"us"}
                                value={values?.phone}
                                onChange={(phone, country) =>
                                  updatePhone(phone, country)
                                }

                                // placeholder="Enter your phone"
                                //country={values.country_code}
                                // value={values.phone}
                                // onChange={(phone, country) =>
                                //   updatePhone(phone, country)
                                // }
                                // onBlur={() => savePhone()}
                              />
                              <span className="form_error_message">
                                {errors?.phone}
                              </span>
                            </div>
                          </div>
                          <p className="text-sm">
                            Submitting this application won’t change your Ring
                            of Hires profile. Application powered by Ring of
                            Hires |
                            <a href="/help-center" target="_blank">
                              <span className="ml-1 text-main">
                                Help Center
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            // setShowModal(false);
                            setModalNo(5);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={async () => {
                            if (await validate()) {
                              setModalNo(2);
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 2 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 md:flex md:items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-11 md:mt-0">
                    {/*header*/}
                    <div className="flex items-start justify-between px-6 py-5 md:px-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Apply to {jobDetails?.company_name}
                      </h3>
                      <p className="text-red-600 font-base-semibold">2/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <div className="space-y-2">
                            <p className="text-black">Resume</p>
                            <p className="font-normal">
                              Be sure to include an updated resume{" "}
                            </p>
                          </div>
                          <div className="space-y-6">
                            {employeeData?.resume && (
                              <div className="inline-flex flex-col md:flex-row h-auto border-2 border-gray-300 rounded-md md:h-20">
                                <div className="flex-none items-center justify-center bg-red-500 border-gray-300 rounded-md md:rounded-r-none">
                                  <button className="font-bold text-white flex w-full md:w-12 md:h-full h-10 justify-center items-center">
                                    PDF
                                  </button>
                                </div>
                                <div className="flex-auto px-5 my-auto">
                                  <h2 className="text-black font-lg-semibold break-all text-center md:break-normal md:text-left">
                                    {
                                      employeeData?.resume.split("/")[
                                        employeeData?.resume.split("/").length -
                                          1
                                      ]
                                    }
                                  </h2>
                                  <p className="mt-1 text-gray-500 font-normal text-sm text-center md:text-left">
                                    Uploaded on{" "}
                                    {employeeData?.resume_uploaded_date}
                                  </p>
                                </div>
                                <div className="flex-none items-center justify-center h-auto md:border-l-2 md:border-gray-300">
                                  <button
                                    onClick={() => {
                                      selectResume("defaultResume");
                                    }}
                                    className="px-2 font-bold text-main flex justify-center items-center h-full w-full"
                                  >
                                    {isDefaultResume ? "selected" : "select"}
                                  </button>
                                </div>
                              </div>
                            )}

                            {resume.newResume ? (
                              <div className="block">
                                <div className="inline-flex flex-col md:flex-row h-auto border-2 border-gray-300 rounded-md md:h-20 relative">
                                  <div className="flex-none items-center justify-center bg-red-500 border-gray-300 rounded-md md:rounded-r-none">
                                    <button className="font-bold text-white flex w-full md:w-12 md:h-full h-10 justify-center items-center">
                                      PDF
                                    </button>
                                  </div>
                                  <div className="flex-auto px-5 my-auto">
                                    <h2 className="text-black font-lg-semibold">
                                      {resume?.newResume.name}
                                    </h2>
                                    {/* <p className="font-sm-light">
                                Uploaded on 13 Sep 2021
                              </p> */}
                                  </div>
                                  <div className="flex-none items-center justify-center h-auto md:border-l-2 md:border-gray-300">
                                    <button
                                      onClick={() => {
                                        selectResume("newResume");
                                      }}
                                      className="px-2 font-bold text-main flex justify-center items-center h-full w-full"
                                    >
                                      {isDefaultResume ? "select" : "selected"}
                                    </button>
                                  </div>
                                  <div
                                    onClick={() => {
                                      selectResume("removeResume");
                                    }}
                                    className="absolute w-5 h-5 cursor-pointer -right-6"
                                  >
                                    <FaWindowClose size="1.5em" color="red" />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {resume.newResume ? (
                              ""
                            ) : (
                              <div className="">
                                <label className="flex flex-col items-center justify-center w-44 h-3 px-4 py-6 tracking-wide uppercase bg-white border border-gray-300 rounded-lg cursor-pointer text-blue hover:border-main hover:text-main">
                                  <span className="text-sm normal-case text-center text-black hover:text-main">
                                    Upload Resume
                                  </span>
                                  <input
                                    type="file"
                                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    className="hidden"
                                    name="resume"
                                    onChange={updateResume}
                                  />
                                </label>
                                <p className="mt-2 text-gray-400 text-xs font-normal">
                                  PDF, DOC, DOCX (Up to 2MB)
                                </p>
                                <p className="text-red-500 font-medium">
                                  {message}
                                </p>
                              </div>
                            )}

                            <p className="text-sm">
                              Submitting this application won’t change your Ring
                              of Hires profile. Application powered by Ring of
                              Hires |
                              <a href="/help-center" target="_blank">
                                <span className="ml-1 text-main">
                                  Help Center
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setModalNo(1);
                          }}
                        >
                          Back
                        </button>
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            if (employeeData?.resume || resume?.newResume) {
                              setModalNo(3);
                            } else {
                              setMessage("Please upload a resume.");
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 3 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div
                  style={{ height: "95vh" }}
                  className="relative w-auto max-w-3xl mx-auto my-6 md:flex md:items-center mt-16 md:mt-0"
                >
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg outline-none custom_shadow focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Apply to {jobDetails?.company_name}
                      </h3>
                      <p className="text-red-600 font-base-semibold">3/3</p>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <p className="text-black">
                                Review your application
                              </p>
                              {/* <button className="text-black font-base-bold">
                              Edit
                            </button> */}
                            </div>
                            <p className="font-normal">
                              The employer will also receive a copy of your
                              profile.
                            </p>
                          </div>
                          <div className="gap-2 md:flex">
                            <img
                              className="rounded-md"
                              src={
                                employeeData.image
                                  ? employeeData?.image
                                  : "/images/employee-default.png"
                              }
                              alt=""
                              width="150px"
                            />
                            <div className="space-y-2">
                              <h2 className="text-black font-xl-semibold">
                                {employeeData?.first_name
                                  ? `${employeeData?.first_name} ${employeeData.last_name}`
                                  : "User Name"}
                              </h2>
                              <p>{employeeData.position?.name}</p>
                              <p className="flex text-sm pt-1">
                                <span className="mt-1 md:mt-0">
                                  <GoLocation
                                    color="007fee"
                                    className="mr-1"
                                    size="1.2em"
                                  />
                                </span>
                                {`${employeeData?.address}, ${employeeData?.city}, ${employeeData?.state}`}
                                {employeeData?.zipcode === null
                                  ? ""
                                  : `, ${employeeData?.zipcode}`}
                              </p>
                              <p className="flex text-sm py-2">
                                <span>
                                  <HiOutlinePhone
                                    color="007fee"
                                    className="mr-1"
                                    size="1.2em"
                                  />
                                </span>
                                {new_phoneformat}
                              </p>
                              <p className="flex text-sm">
                                <span>
                                  <HiOutlineMail
                                    color="007fee"
                                    className="mr-1"
                                    size="1.2em"
                                  />
                                </span>
                                {employeeData?.email}
                              </p>
                            </div>
                          </div>
                          {/* {line} */}
                          <div className="w-full bg-gray-200 h-0.5"></div>
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <p className="text-black">Resume</p>
                              {/* <button
                              onClick={() => {
                                setModalNo(2);
                              }}
                              className="text-black font-base-bold"
                            >
                              Edit
                            </button> */}
                            </div>
                            <p className="font-normal">
                              Be sure to include an updated resume{" "}
                            </p>
                          </div>

                          {isDefaultResume ? (
                            <div className="inline-flex flex-col md:flex-row h-auto border-2 border-gray-300 rounded-md md:h-20">
                              <div className="flex-none items-center justify-center bg-red-500 border-gray-300 rounded-md rounded-r-none">
                                <button className="font-bold text-white flex w-full md:w-12 md:h-full h-10 justify-center items-center">
                                  PDF
                                </button>
                              </div>
                              <div className="flex-auto px-5 my-auto">
                                <h2 className="text-black font-lg-semibold break-all text-center md:break-normal md:text-left">
                                  {
                                    employeeData.resume.split("/")[
                                      employeeData.resume.split("/").length - 1
                                    ]
                                  }
                                </h2>
                                {/* <p className="font-sm-light">
                                Uploaded on 13 Sep 2021
                              </p> */}
                              </div>
                              <div className="flex-none items-center justify-center h-auto md:border-l-2 md:border-gray-300">
                                <a
                                  href={employeeData.resume}
                                  target="_blank"
                                  className="px-2 font-bold text-white flex justify-center items-center h-10 md:h-full bg-main"
                                >
                                  View
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="inline-flex flex-col md:flex-row h-auto border-2 border-gray-300 rounded-md md:h-20">
                              <div className="flex-none items-center justify-center bg-red-500 border-gray-300 rounded-md md:rounded-r-none">
                                <button className="font-bold text-white flex w-full md:w-12 md:h-full h-10 justify-center items-center">
                                  PDF
                                </button>
                              </div>
                              <div className="flex-auto px-5 my-auto">
                                <h2 className="text-black font-lg-semibold break-all text-center md:break-normal md:text-left">
                                  {resume?.newResume?.name}
                                </h2>
                                {/* <p className="font-sm-light">
                                Uploaded on 13 Sep 2021
                              </p> */}
                              </div>
                              <div className="flex-none items-center justify-center h-auto md:border-l-2 md:border-gray-300">
                                <a
                                  href={resume?.path}
                                  target="_blank"
                                  className="px-2 font-bold text-white flex justify-center items-center h-10 md:h-full w-full bg-main"
                                >
                                  View
                                </a>
                              </div>
                            </div>
                          )}

                          {/* {line} */}
                          <div className="w-full bg-gray-200 h-0.5"></div>
                          <p className="text-sm">
                            Submitting this application won’t change your Ring
                            of Hires profile. Application powered by Ring of
                            Hires |
                            <a href="/help-center" target="_blank">
                              <span className="ml-1 text-main">
                                Help Center
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      {!loading && (
                        <div className="flex space-x-2">
                          <button
                            className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                            onClick={() => {
                              setModalNo(2);
                            }}
                          >
                            Back
                          </button>
                          <button
                            disabled={doubleClick}
                            onClick={jobApply}
                            className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                      {loading && <>Sending Job Application...</>}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 4 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full border-0 rounded-lg outline-none bg-main custom_shadow focus:outline-none">
                    <div className="flex flex-col items-center justify-center gap-4 p-6 border-t border-solid rounded-b border-blueGray-200">
                      <img src={checkCircle} alt="" width="" />
                      <h2 className="text-3xl font-semibold text-center text-white">
                        Application Submitted!
                      </h2>
                      <p className="w-10/12 text-xl text-center text-white ">
                        Your application has been submitted to{" "}
                        {jobDetails?.company_name}
                      </p>
                      <div className="flex space-x-2">
                        {/* <button
                        className="flex items-center justify-center w-full mt-3 bg-white text-main md:w-auto md:mt-0 btn btn-base"
                        onClick={() => {
                          setModalNo(2);
                        }}
                      >
                        ok
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 5 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl">
                        Discard application?
                      </h3>
                      {/* <button className="relative -right-11 -top-6">
                        <FaWindowClose size="1.4em" />
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-6">
                      <div className="flex w-full font-semibold text-gray-500 md:items-center details font_small_light">
                        <div className="space-y-6">
                          <h1>
                            Your changes will not be saved by closing this
                            application prior to submitting it.
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          onClick={() => {
                            setModalNo(1);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            setShowModal(false);
                            setModalNo(1);
                          }}
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {modalNo === 6 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-5/6 mx-auto md:w-full border-0 rounded-lg outline-none custom_shadow focus:outline-none bg-red-600">
                    <div className="flex flex-col items-center justify-center gap-4 p-6 border-t border-solid rounded-b border-blueGray-200">
                      <img src={checkCircle} alt="" width="" />
                      <h2 className="text-3xl font-semibold text-center text-white">
                        Oops! Try Again
                      </h2>
                      <p className="w-10/12 text-xl text-center text-white ">
                        Your application hasn't been submitted to{" "}
                        {jobDetails?.company_name}
                      </p>
                      <div className="flex space-x-2">
                        {/* <button
                        className="flex items-center justify-center w-full mt-3 bg-white text-main md:w-auto md:mt-0 btn btn-base"
                        onClick={() => {
                          setModalNo(2);
                        }}
                      >
                        ok
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
              {/*content*/}
              <div className="relative flex flex-col w-4/5 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-xl">
                    Only job seekers can apply for this job
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="ml-4 text-red-600 font-base-semibold "
                  >
                    <FaWindowClose size="1.4em" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default JobApply;
