import { useState, useEffect } from "react";
import LeftImg from "../../images/PartnerWithUs/left-img.jpg";
import RightImg from "../../images/PartnerWithUs/hand-ball.jpg";
import usePostData from "../../hooks/usePostData";

function PartnerWithUs() {
  const url = "partner-inquiry";
  const loader = <span className="text-blue-500">Sending...</span>;

  const [formdata, setFormdata] = useState(null);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { data, error, loading } = usePostData(url, formdata);

  const processForm = (e) => {
    e.preventDefault();
    setFormdata({
      name: name,
      email: email,
    });
  };

  useEffect(() => {
    if (data) {
      setMessage({ message: data.message, class: "success" });
      setName("");
      setEmail("");
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
    if (error?.message) {
      setMessage({ message: error.message, class: "error" });
    }
  }, [error]);

  return (
    <div className="partner-page">
      <h1 className="text-3xl lg:text-4xl font-semibold mb-12 xl:w-5/6 lg:mx-auto my-10 p-6 ">
        Partner With Us -{" "}
        <span className="italic text-xl align-middle">Ring of Hires</span>
      </h1>
      <h2 className="text-lg md:text-2xl font-medium mb-3 xl:w-5/6 lg:mx-auto p-6">
        Corporate Level Pricing
        <br />
        Partnerships with Medical Recruiters, Hospital Systems and Medical
        Practices
      </h2>

      {/* form section */}
      <div className="xl:w-5/6 lg:mx-auto p-6 ">
        <div className="lg:flex justify-between space-y-8 lg:space-x-10 lg:space-y-0 items-center">
          <div className="w-full lg:w-1/2">
            <img
              src={LeftImg}
              className="w-full lg:w-auto"
              alt="Handshake pic"
              title="Partnership"
              loading="lazy"
              width="100%"
              height="auto"
            />
          </div>
          <div className="flex-auto space-y-8 w-full lg:w-1/2">
            <h3 className="text-lg md:text-4xl text-center font-medium mb-3 xl:w-5/6 lg:mx-auto p-6">
              up to 75% off
            </h3>
            <div className="mt-8 md:mx-12 lg:w-5/6 items-center justify-center">
              <form
                onSubmit={(e) => {
                  processForm(e);
                }}
              >
                <div className="grid grid-cols-1 gap-2">
                  <div className="mb-3">
                    <label
                      class="block mb-2 font-semibold text-gray-500"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      class="block w-full rounded-md border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
                    />
                    <span className="form_error_message block mb-1">
                      {errors?.name}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      class="block mb-2 font-semibold text-gray-500"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="block w-full rounded-md border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
                    />
                    <span className="form_error_message block mb-1">
                      {errors?.email}
                    </span>
                  </div>
                  <div class="mt-5">
                    <button class="w-full btn btn-main">
                      {loading ? loader : "Contact Us Now"}
                    </button>
                  </div>
                  <div>
                    {commonMessage && (
                      <p className={commonMessage.class}>
                        {commonMessage.message}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 lg:my-10 w-full lg:mx-auto p-6">
        <div className="mt-5 lg:flex w-full justify-between space-y-8 lg:space-x-10 lg:space-y-0 items-center">
          <h3 className="text-xl md:text-4xl text-center font-medium md:mb-10 lg:mx-auto p-6">
            Partner with Us
          </h3>
        </div>
        <div class="lg:flex mx-auto w-full lg:w-5/6 items-center">
          <div class="lg:w-1/2 w-full mb-8 lg:mb-0">
            <div>
              <h4 className="text-lg">Pricing Available Upon Request</h4>
              <p className="my-2 mt-4">Corporate Level 1</p>
              <ul className="list-disc ml-4">
                <li>15-50 Staff Accounts </li>
                <li>
                  Bonfire Level Memberships - Access for all members of the
                  staff with concierge service/postings
                </li>
                <li>Unlimited Job Posts</li>
                <li> Premium Listings</li>
                <li>Daily Email Updates of New Potential Matched Employees</li>
                <li>Exclusive Job Recruitment Services</li>
              </ul>
              <p className="mt-4 my-2">Corporate Level 2</p>
              <ul className="list-disc mt-2 ml-4">
                <li>51-100 Staff Accounts </li>
                <li>
                  {" "}
                  Bonfire Level Memberships - Access for all members of the
                  staff with concierge service/postings
                </li>
                <li> Unlimited Job Posts</li>
                <li>Premium Listings</li>
                <li>Daily Email Updates of New Potential Matched Employees</li>
                <li> Exclusive Job Recruitment Services</li>
              </ul>
              <p className="mt-4 my-2">Corporate Level 3 </p>
              <ul className="list-disc mt-2 mb-4 ml-4">
                <li> 101-500 Staff Accounts </li>
                <li>
                  {" "}
                  Bonfire Level Memberships - Access for all members of the
                  staff with concierge service/postings
                </li>
                <li> Unlimited Job Posts</li>
                <li> Premium Listings</li>
                <li> Daily Email Updates of New Potential Matched Employees</li>
                <li> Exclusive Job Recruitment Services</li>
              </ul>
            </div>
          </div>
          <div class="lg:w-1/2 w-full lg:ml-24">
            <img
              src={RightImg}
              alt="Globe on hand"
              title="Partner with us"
              loading="lazy"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerWithUs;
