import defaultlogo from "../../images/JobDetails/Rectangle.png";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import { RiMenu5Line } from "react-icons/ri";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import mapImg from "../../images/Home/map.png";
import { useState, useEffect, useMemo } from "react";
import { IoMdGlobe } from "react-icons/io";
import useGetData from "../../hooks/useGetData";
import { useHistory, useParams } from "react-router-dom";
import JobApply from "../modals/jobDetails/JobApply";
import { useLayoutEffect, useRef } from "react";
import usePostData from "../../hooks/usePostData";
import useDelete from "../../hooks/useDelete";
import JobReport from "../modals/jobDetails/JobReport";
import { FiUsers } from "react-icons/fi";
import { MdModeEditOutline, MdCalendarToday } from "react-icons/md";
import { AiOutlineDelete, AiOutlineClockCircle } from "react-icons/ai";
import { FaWindowClose } from "react-icons/fa";
import ProfileErrorModal from "../modals/jobDetails/ProfileErrorModal";
import DeletePost from "../modals/Employer/DeletePost";
import Map from "../elements/ProfileMap";
import RohLogo from "../../images/ROH-logo.png";
import { BiMessageSquareError } from "react-icons/bi";
import ClaimJob from "../modals/jobDetails/ClaimJob";
import { UserContext } from "../../App";
import { useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import defaultBuilding from "../../images/JobDetails/hospital-default.jpg";
import ExpiredandDeletedJob from "../elements/ExpiredandDeletedJob";
import InterviewSchedule from "../elements/InterviewSchedule";
import moment from "moment";
import axios from "axios";
import usePatch from "../../hooks/usePatch";
import useToken from "../../hooks/useToken";
import InterViewAvailability from "./EmployeeDashboard/InterViewAvailability";
import AllSlots from "../elements/AllSlots";
import TimeAgo from "react-timeago";

let old_phoneformat = "";
let new_phoneformat = "";
const JobDetails = ({ setJobTitle }) => {
  const [applyModal, setApplyModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [saveFormData, setSaveFormData] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [delJobFormData, setDelJobFormData] = useState(null);
  const [singleJobDetails, setSingleJobDetails] = useState("");
  const [jobUnsaveUrl, setUnsaveUrl] = useState(null);
  const [jobDeleteUrl, setJobDeleteUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveModal, setSaveModal] = useState(false);
  const [profileError, setProfileError] = useState(false);

  const [myJobsModal, setMyJobsModal] = useState(false);
  const [claimJob, setClaimJob] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshLog, setRefreshLog] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [scheduInterview, setScheduInterview] = useState(false);
  const [acceptDataFiltered, setAcceptDataFiltered] = useState(null);

  const history = useHistory();
  const { username } = useContext(UserContext);

  const user_id = localStorage.getItem("user_id");
  const { jobid } = useParams();
  const user_role = localStorage.getItem("user_role");

  const [url, setUrl] = useState(`jobs/${jobid}`);
  const { data: jobDetails, error: jobErr } = useGetData(url, "main", refresh);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [rejectSlot, setRejectSlot] = useState(false);
  const [rejectAcceptedSlot, setRejectAcceptedSlot] = useState(false);
  const [openBooking, setOpenBooking] = useState(false);

  const InterViewAvailabilityRef = useRef(null);

  // const { data: savedJobs } = useGetData(
  //   `employees/${user_id}/saved-job-posts`
  // );

  // useEffect(() => {
  //   if (jobDetails?.status === "NOT_FOUND") {
  //     // window.location.href = "/jobsearch";
  //   }
  // }, [jobDetails]);

  const { data: appliedJobsList } = useGetData(
    `employees/${user_id}/job-applications`
  );
  const { data: savedJobsList } = useGetData(
    `employees/${user_id}/saved-job-posts`
  );

  const { data: profileCheck } = useGetData(
    `employees/${user_id}/profile-check`
  );

  const {
    data: savedJobsPostData,
    loading: saveJobLoading,
    error: err,
  } = usePostData(`employees/${user_id}/saved-job-posts`, saveFormData);
  const { data: delJobsData, loading: unsaveJobLoading } =
    useDelete(jobUnsaveUrl);

  // const { data: employersData } = useGetData(`employers/${user_id}`);

  //Delete Job Post
  const { data: deleteJobPost } = useDelete(jobDeleteUrl);

  let posted_date = singleJobDetails?.created_at;
  let date_only = "";

  try {
    let full_date = new Date(posted_date);
    date_only =
      full_date.getFullYear() +
      "-" +
      (parseInt(full_date.getMonth()) + 1).toString() +
      "-" +
      full_date.getDate();
  } catch (err) {
    date_only = "N/A";
  }

  //let utc = new Date(posted_date+' Z');
  //let offset = utc.getTimezoneOffset();

  let format_min_ypay = singleJobDetails?.min_yearly_pay;
  let result_min_ypay = format_min_ypay?.toLocaleString();
  let format_max_ypay = singleJobDetails?.max_yearly_pay;
  let result_max_ypay = format_max_ypay?.toLocaleString();

  const [isReportButton, setIsReportButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setJobTitle(
      jobDetails?.data?.job_title
        ? jobDetails?.data?.job_title
        : jobDetails?.data?.position_label
    );
  }, [jobDetails]);

  useLayoutEffect(() => {
    setSingleJobDetails(jobDetails?.data);
    setIsSaved(jobDetails?.data?.is_saved);
  }, [jobDetails]);
  console.log("singleJobDetails :>> ", singleJobDetails);
  useLayoutEffect(() => {
    if (jobErr === "Not found.") {
      window.location.href = `/404`;
    }
  }, [jobErr]);

  const strToUrl = (str) => {
    return str.replace(/\s+/g, "-");
  };

  useLayoutEffect(() => {
    if (jobDetails?.code === 307) {
      setTimeout(() => {
        window.location.href = `/jobSearch/${strToUrl(
          jobDetails.data?.position
        )}-jobs-in-${strToUrl(jobDetails.data?.address).replace(",", "")}`;
      }, 2000);
    }
    if (jobDetails?.status === "NOT_FOUND") {
      setTimeout(() => {
        window.location.href = `/jobSearch`;
      }, 2000);
    }
  }, [jobDetails]);

  useEffect(() => {
    if (!err && savedJobsPostData?.code === 200) {
      setIsSaved(true);
    }
  }, [savedJobsPostData]);

  useEffect(() => {
    if (!err && delJobsData?.code === 200) {
      setIsSaved(false);
    }
  }, [delJobsData]);

  const saveJob = () => {
    if (user_role === "employee") {
      setSaveFormData({
        job_id: jobid,
      });
    } else {
      console.log("saveJog button clicked");
      localStorage.setItem("path", window.location.href);
    }
  };

  useEffect(() => {
    if (jobUnsaveUrl) {
      setUnsaveUrl(null);
    }
  }, [jobUnsaveUrl]);

  const delJob = () => {
    setUnsaveUrl(`employees/${user_id}/saved-job-posts/${jobid}`);
  };

  // const delJob = () => {
  //   setUnsaveUrl(`employees/${user_id}/saved-job-posts/${jobid}`);
  //   setIsSaved(false);
  //   setUrl(url);

  // };

  // useLayoutEffect(() => {
  //   const findIsApplied = appliedJobsList?.results?.find((item) => {
  //     return item?.job === singleJobDetails?.jobid;
  //   });
  //   if (findIsApplied) {
  //     setIsApplied(true);
  //   }
  // }, [appliedJobsList, isApplied, singleJobDetails]);

  // useLayoutEffect(() => {
  //   const findIsSaved = savedJobsList?.data?.find((item) => {
  //     return item?.jobpost === singleJobDetails?.id;
  //   });
  //   if (findIsSaved) {
  //     setIsSaved(true);
  //   }
  // }, [savedJobsList, isSaved, singleJobDetails]);

  if (singleJobDetails) {
    old_phoneformat = singleJobDetails?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  // useEffect(() => {
  //   if (savedJobsPostData) {
  //     setIsSaved(true);
  //   }
  // }, [savedJobsPostData]);

  // useEffect(() => {
  //   if (delJobsData?.code === 200) {
  //     setIsSaved(false);
  //   }
  // }, [delJobsData]);

  const deleteJob = (jobid) => {
    // setJobDeleteUrl(`employers/${user_id}/jobs/${jobid}`);
  };

  const applyRefresh = (isApplied) => {
    setIsApplied(isApplied);
    setRefreshLog((prev) => !prev);
  };

  useEffect(() => {
    if (jobDetails?.code === 200) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (jobDetails?.code === 307 || jobDetails?.status === "NOT_FOUND") {
      setRedirect(true);
    }
  }, [jobDetails]);

  // useEffect(() => {
  //   if (saveModal === true) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [saveModal]);

  const customSave = () => {
    if (user_id) {
      setSaveModal(true);
    } else {
      localStorage.setItem("path", window.location.href);
      window.location.href = "/login";
    }
  };
  const customClose = () => {
    setSaveModal(false);
  };

  const customLogin = () => {
    console.log("login....");
    localStorage.setItem("path", window.location.href);
    window.location.href = "/login";
  };

  // useEffect(() => {
  //   if (jobUnsaveUrl != null) {
  //     setIsSaved(false);
  //   }
  // }, [jobUnsaveUrl, jobDetails]);

  // open the second claim modal after the successful redirection
  useEffect(() => {
    const success_redirect = localStorage.getItem("success_redirect");
    if (success_redirect == "true") {
      setClaimJob(true);
      setShowSecondModal(true);
      localStorage.removeItem("success_redirect");
    }
  }, []);
  // activity
  const LogUrl = useMemo(() => {
    if (jobDetails?.data)
      return `availability/applicationlog/${jobid}/${jobDetails.data.emp_id}`;
  }, [jobDetails]);

  const { data: aplicationLog } = useGetData(LogUrl, "main", refreshLog);

  const currentJobStatus = useMemo(() => {
    if (jobDetails?.data)
      return aplicationLog?.data.at(aplicationLog.data.length - 1)
        ?.application_action;
  }, [aplicationLog]);

  const { defaultOptions } = useToken("application/json");

  const mainRef = useRef(null);

  useEffect(() => {
    if (!openBooking) {
      mainRef.current.style.scrollMargin = "200px";
      mainRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [openBooking]);

  const scrollToBooking = () => {
    if (InterViewAvailabilityRef)
      InterViewAvailabilityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  };

  function compareDates(dateStr1, dateStr2) {
    const dateA = new Date(dateStr1);
    const dateB = new Date(dateStr2);
    return dateA - dateB;
  }

  useEffect(() => {
    if (aplicationLog) {
      let temp = aplicationLog.data.sort((a, b) =>
        compareDates(a.created_at, b.created_at)
      );
      setAcceptDataFiltered(temp);
    }
  }, [aplicationLog]);

  return (
    <div className="minHeight mb-5 container-wrapper relative" ref={mainRef}>
      {!loading ? (
        <>
          <JobApply
            showModal={applyModal}
            setShowModal={setApplyModal}
            jobDetails={singleJobDetails}
            applyRefresh={applyRefresh}
          ></JobApply>
          <JobReport
            showModal={reportModal}
            setShowModal={setReportModal}
          ></JobReport>

          {profileError && (
            <ProfileErrorModal
              showModal={profileError}
              setShowModal={setProfileError}
            ></ProfileErrorModal>
          )}

          <div className="my-5 custom_shadow jobTitleContainer">
            <div className="flex flex-col justify-between p-5 space-y-6 md:space-y-0 md:flex-row">
              <div className="flex flex-col space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
                <div>
                  {!singleJobDetails.image &&
                  !singleJobDetails.category_unverified_image &&
                  !singleJobDetails.category_verified_image ? (
                    <img
                      src={defaultBuilding}
                      alt={singleJobDetails.id}
                      title={singleJobDetails.id}
                      loading="lazy"
                      className="block w-28 lg:block lg:w-16 xl:w-24"
                      width="100px"
                      height="auto"
                    />
                  ) : (
                    <img
                      src={
                        singleJobDetails.image
                          ? singleJobDetails.image
                          : singleJobDetails.emp_id == null
                          ? singleJobDetails.category_unverified_image
                          : singleJobDetails.category_verified_image
                      }
                      alt={singleJobDetails.id}
                      title={singleJobDetails.id}
                      loading="lazy"
                      className="block w-28 lg:block lg:w-16 xl:w-24 rounded-md"
                      width="100px"
                      height="auto"
                    />
                  )}
                </div>
                <div className="space-y-2 details md:max-w-md lg:max-w-lg">
                  <h2
                    className={`relative items-center font-xl-semibold ${
                      singleJobDetails?.is_owner_premium == true &&
                      "job-details-title"
                    }`}
                  >
                    {singleJobDetails?.job_title
                      ? singleJobDetails?.job_title
                      : singleJobDetails?.position_label}
                  </h2>
                  <p className="text-gray-500 font-sm-semibold">
                    Date Posted: {date_only}
                  </p>
                  {Object.values(singleJobDetails?.salary_type)[0] ===
                    "Fixed" && (
                    <p className="flex flex-wrap font-semibold">
                      {Object.values(singleJobDetails?.salary_subtype)[0]}{" "}
                      Salary :{" $"}
                      {singleJobDetails?.max_salary?.toLocaleString()}
                    </p>
                  )}

                  {Object.values(singleJobDetails?.salary_type)[0] !==
                    "Fixed" && (
                    <p>
                      {(singleJobDetails?.min_salary ||
                        singleJobDetails?.max_salary) && (
                        <p className="flex flex-wrap font-semibold">
                          Hourly Salary:{" "}
                          {singleJobDetails?.min_salary
                            ? "$" +
                              parseFloat(singleJobDetails?.min_salary)
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            : ""}
                          {singleJobDetails?.min_salary &&
                            singleJobDetails?.max_salary &&
                            "-"}
                          {singleJobDetails?.max_salary
                            ? "$" +
                              parseFloat(singleJobDetails?.max_salary)
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            : ""}
                        </p>
                      )}
                      {(format_min_ypay > 0 || format_max_ypay > 0) && (
                        <p className="font-semibold">
                          Annual Salary:{" "}
                          {format_min_ypay ? "$" + result_min_ypay + "-" : ""}{" "}
                          {format_max_ypay ? "$" + result_max_ypay : ""}
                        </p>
                      )}
                    </p>
                  )}
                  {singleJobDetails.is_remote && (
                    <p className="text-black font-semibold">Remote</p>
                  )}
                </div>
              </div>
              <div className="flex font-semibold text-gray-500  md:text-right md:items-center details font_small_light md:max-w-md 2xl:max-w-none">
                <div className="space-y-1">
                  {singleJobDetails?.emp_id == null ? (
                    <Link
                      to={`/employers-details/${singleJobDetails?.company_name
                        ?.replaceAll(" ", "-")
                        ?.toLowerCase()}`}
                    >
                      <h1 className="text-black font-xl-semibold md:text-right">
                        {singleJobDetails?.company_name &&
                          singleJobDetails?.company_name}
                      </h1>
                    </Link>
                  ) : (
                    <Link to={`/employers/${singleJobDetails?.emp_id}`}>
                      <h1 className="text-black font-xl-semibold md:text-right">
                        {singleJobDetails?.company_name &&
                          singleJobDetails?.company_name}
                      </h1>
                    </Link>
                  )}
                  {singleJobDetails?.location_details?.address ? (
                    <p className="flex justify-start md:justify-end break-words">
                      {/* <span className="mt-1 md:mt-0">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span> */}
                      <p className="jobAddress">
                        {singleJobDetails?.location_details?.address}
                      </p>
                    </p>
                  ) : (
                    <p className="flex justify-start md:justify-end">
                      <span className="mt-1 md:mt-0">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {singleJobDetails?.city
                        ? `, ${singleJobDetails?.city}`
                        : ""}
                      <br />
                      {singleJobDetails?.country}
                      {singleJobDetails?.zipcode
                        ? `, ${singleJobDetails?.zipcode}`
                        : ""}
                    </p>
                  )}
                  {/* <p class="ml-6 md:ml-0">
                {singleJobDetails?.city}, {singleJobDetails?.country}, {singleJobDetails?.zipcode}
              </p> */}
                  {/* {user_role === "employer" ? ( */}
                  <div className="flex flex-col">
                    {singleJobDetails?.phone && (
                      <p className="flex items-top justify-start md:justify-end break-all">
                        <span>
                          <HiOutlinePhone
                            color="007fee"
                            className="mr-1 mt-1"
                            size="1.2em"
                          />
                        </span>
                        <div>{new_phoneformat}</div>
                      </p>
                    )}
                    {singleJobDetails?.email && (
                      <p className="flex items-top justify-start md:justify-end break-all">
                        <span>
                          <HiOutlineMail
                            color="007fee"
                            className="mr-1 mt-1"
                            size="1.2em"
                          />
                        </span>
                        <div>{singleJobDetails?.email}</div>
                      </p>
                    )}
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                  {singleJobDetails?.website && (
                    <p className="flex items-top justify-start md:justify-end break-all">
                      <span>
                        <IoMdGlobe
                          color="007fee"
                          className="mr-1 mt-1"
                          size="1.2em"
                        />
                      </span>
                      <div>{singleJobDetails?.website}</div>
                    </p>
                  )}
                  <p>
                    Current Job Openings - {singleJobDetails?.job_post_count}
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-5 line"></div>
            <div className="flex flex-col-reverse justify-between p-3 md:pr-5 md:flex-row">
              {!singleJobDetails?.owner ? (
                user_role === "employee" ? (
                  <>
                    {console.log(
                      isApplied,
                      currentJobStatus,
                      currentJobStatus !== "invited",
                      "apple"
                    )}
                    {(currentJobStatus && currentJobStatus !== "invited") ||
                    (singleJobDetails?.emp_id == null &&
                      singleJobDetails?.is_applied) ||
                    isApplied ? (
                      <div className="flex flex-row">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                          disabled
                        >
                          <span>
                            <RiCheckboxMultipleFill
                              className="mr-2"
                              size="1.2em"
                            />
                          </span>
                          Applied
                        </button>
                        {/* <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main mx-4"
                          onClick={() => setScheduInterview((prev) => !prev)}
                        >
                          Schedule Interview
                        </button> */}
                      </div>
                    ) : (
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={() => {
                          profileCheck?.data?.profile_completed
                            ? setApplyModal(true)
                            : setProfileError(true);
                        }}
                      >
                        <span>
                          <FaTelegramPlane className="mr-2" size="1.2em" />
                        </span>
                        Apply Now
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {user_role === "employer" ? (
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={() => {
                          setApplyModal(true);
                        }}
                      >
                        <span>
                          <FaTelegramPlane className="mr-2" size="1.2em" />
                        </span>
                        Apply Now
                      </button>
                    ) : (
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={customLogin}
                      >
                        <span>
                          <FaTelegramPlane className="mr-2" size="1.2em" />
                        </span>
                        Apply Now
                      </button>
                    )}
                  </>
                )
              ) : (
                <button
                  className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main font-bold"
                  onClick={() => {
                    history.push({
                      pathname: `/applications/${jobid}`,
                      jobDetails: singleJobDetails,
                    });
                  }}
                >
                  <span>
                    <FiUsers className="mr-2" size="1.2em" />
                  </span>
                  Candidates ({singleJobDetails?.job_applications_count})
                </button>
              )}

              <div className="md:flex justify-between space-y-3 md:space-y-0 md:space-x-5">
                {username &&
                  singleJobDetails?.is_claimed == false &&
                  singleJobDetails?.is_scrapped == true &&
                  singleJobDetails?.is_rejected == false &&
                  user_role === "employer" && (
                    <button
                      onClick={() => {
                        setClaimJob(true);
                      }}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <BiMessageSquareError className="mr-1" size="1.2em" />
                      </span>
                      Claim This Job
                    </button>
                  )}

                {!username &&
                  singleJobDetails?.is_claimed == false &&
                  singleJobDetails?.is_scrapped == true &&
                  singleJobDetails?.is_rejected == false && (
                    <button
                      onClick={customLogin}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400 sample"
                    >
                      <span>
                        <BiMessageSquareError className="mr-1" size="1.2em" />
                      </span>
                      Claim This Job
                    </button>
                  )}

                {singleJobDetails?.is_claimed == true &&
                  singleJobDetails?.is_scrapped == true &&
                  user_role !== "employee" && (
                    <button className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400">
                      <span>
                        <BiMessageSquareError className="mr-1" size="1.2em" />
                      </span>
                      Claimed
                    </button>
                  )}

                {!singleJobDetails?.owner ? (
                  <>
                    {user_role === "employee" ? (
                      isSaved ? (
                        <button
                          onClick={!unsaveJobLoading ? delJob : () => {}}
                          className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                        >
                          <span>
                            <AiFillHeart className="mr-1" size="1.2em" />
                          </span>
                          {unsaveJobLoading
                            ? "Unsaving job....."
                            : "Unsave This Job"}
                        </button>
                      ) : (
                        <button
                          onClick={!saveJobLoading ? saveJob : () => {}}
                          className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                        >
                          <span>
                            <AiOutlineHeart className="mr-1" size="1.2em" />
                          </span>
                          {saveJobLoading
                            ? "Saving job......"
                            : "Save This Job"}
                        </button>
                      )
                    ) : (
                      <>
                        {!username && (
                          <button
                            onClick={customLogin}
                            className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                          >
                            <span>
                              <AiOutlineHeart className="mr-1" size="1.2em" />
                            </span>
                            Save This Job
                          </button>
                        )}
                      </>

                      // <>
                      //   <button
                      //     onClick={() => customSave()}
                      //     className="emp flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                      //   >
                      //     <span>
                      //       <AiOutlineHeart className="mr-1" size="1.2em" />
                      //     </span>
                      //     Save This Job
                      //   </button>
                      //   {saveModal ? (
                      //     <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                      //       <div className="relative w-auto max-w-3xl mx-auto my-6">
                      //         {/*content*/}
                      //         <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                      //           {/*header*/}
                      //           <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      //             <h3 className="text-xl font-semibold md:text-xl">
                      //               Only job seekers can save this job
                      //             </h3>
                      //             <button
                      //               onClick={() => customClose()}
                      //               className="ml-4 text-red-600 font-base-semibold "
                      //             >
                      //               <FaWindowClose size="1.4em" />
                      //             </button>
                      //           </div>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   ) : (
                      //     ""
                      //   )}
                      // </>
                      // ""
                    )}
                    <button
                      onClick={() => {
                        setReportModal(true);
                      }}
                      className="flex justify-center items-center text-white bg-red-500 md:flex hover:bg-red-600 btn-base w-full"
                    >
                      <span>
                        <MdErrorOutline className="mr-1" size="1.2em" />
                      </span>
                      Report
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        history.push(`/profile/job-edit/${jobid}`);
                      }}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <MdModeEditOutline className="mr-1" size="1.2em" />
                      </span>
                      Edit Post
                    </button>

                    <button
                      onClick={() => {
                        setMyJobsModal(true);
                        // deleteJob(jobid);
                      }}
                      className="items-center hidden text-white bg-red-500 md:flex hover:bg-red-600 btn-base"
                    >
                      <span>
                        <AiOutlineDelete className="mr-1" size="1.2em" />
                      </span>
                      Delete Post
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* activity */}
            {user_role === "employee" && (
              <>
                <div className="mx-5 line"></div>
                <div className="flex flex-col ">
                  {acceptDataFiltered?.length > 0 && (
                    <div className="w-full px-5 py-2 relative ">
                      <h2 className="my-4 font-xl-semibold">
                        Application Activity
                      </h2>
                      <div className="flex flex-col-reverse ">
                        {acceptDataFiltered &&
                          acceptDataFiltered.map((e, index) => {
                            //
                            let offset = new Date().getTimezoneOffset();

                            const centralTimestamp = new Date(e?.created_at);

                            centralTimestamp.setMinutes(
                              centralTimestamp.getMinutes() - offset
                            );

                            if (e.application_action === "slot_booked")
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="flex flex-row mb-4"
                                  >
                                    <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                    <div className="w-full">
                                      <AllSlots
                                        allSlots={e}
                                        employeeId={user_id}
                                        employerId={jobDetails?.data?.emp_id}
                                        jobId={jobid}
                                        next={acceptDataFiltered[index + 1]}
                                        setRejectSlot={setRejectSlot}
                                        from={"job-page"}
                                        to_user={jobDetails?.data?.emp_id}
                                        setRefresh={() => {
                                          setRefreshLog((prev) => !prev);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                            else if (
                              e.application_action === "application_accepted"
                            )
                              return (
                                <>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      Application Accepted
                                    </div>
                                    <div className="text-gray-600">
                                      (
                                      <spam className="text-blue-500 capitalize">
                                        {e.from_user.first_name}
                                      </spam>
                                      ){" "}
                                      <TimeAgo
                                        date={centralTimestamp}
                                        locale="en_US"
                                        formatter={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            return "just now";
                                          } else {
                                            return (
                                              value +
                                              " " +
                                              unit +
                                              (value > 1 ? "s" : "") +
                                              " " +
                                              suffix
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                            else if (
                              e.application_action === "application_rejected"
                            )
                              return (
                                <>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      Application Rejected
                                    </div>
                                    <div className="text-gray-600">
                                      (
                                      <spam className="text-blue-500 capitalize">
                                        {e.from_user.first_name}
                                      </spam>
                                      ){" "}
                                      <TimeAgo
                                        date={centralTimestamp}
                                        formatter={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            return "just now";
                                          } else {
                                            return (
                                              value +
                                              " " +
                                              unit +
                                              (value > 1 ? "s" : "") +
                                              " " +
                                              suffix
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                            else if (
                              e.application_action === "booking_cancelled"
                            )
                              return (
                                <>
                                  <div className="mb-4">
                                    <div className="flex justify-between">
                                      <div className="flex">
                                        <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                        <h1>
                                          {!acceptDataFiltered[index + 1] &&
                                          e.from_user.id == user_id
                                            ? "Interview Rejected(Book new slots)"
                                            : "Interview Rejected"}
                                        </h1>
                                      </div>
                                      <div className="text-gray-600">
                                        <span className="capitalize">
                                          (
                                          <spam className="text-blue-500 capitalize">
                                            {e.from_user.first_name}
                                          </spam>
                                          ){" "}
                                        </span>
                                        <TimeAgo
                                          date={centralTimestamp}
                                          formatter={(value, unit, suffix) => {
                                            if (unit === "second") {
                                              return "just now";
                                            } else {
                                              return (
                                                value +
                                                " " +
                                                unit +
                                                (value > 1 ? "s" : "") +
                                                " " +
                                                suffix
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {!acceptDataFiltered[index + 1] &&
                                      e.from_user.id == user_id && (
                                        <div className="p-4">
                                          <button
                                            className="border-2 border-blue-500 px-2  text-white rounded-sm bg-blue-500 hover:bg-blue-600"
                                            onClick={() => {
                                              setOpenBooking(true);
                                              scrollToBooking();
                                            }}
                                          >
                                            Schedule Interview
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                            else if (e.application_action === "hired")
                              return (
                                <>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      Hired
                                    </div>
                                    <div className="text-gray-600">
                                      (
                                      <spam className="text-blue-500 capitalize">
                                        {e.from_user.first_name}
                                      </spam>
                                      ){" "}
                                      <TimeAgo
                                        date={centralTimestamp}
                                        formatter={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            return "just now";
                                          } else {
                                            return (
                                              value +
                                              " " +
                                              unit +
                                              (value > 1 ? "s" : "") +
                                              " " +
                                              suffix
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                            else
                              return (
                                <>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative "></div>
                                      <span className="capitalize">
                                        {e.application_action == "invited"
                                          ? "Invited"
                                          : e.application_action == "applied"
                                          ? "Application Submitted"
                                          : "Application Rejected"}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-gray-600">
                                        <span className="capitalize">
                                          (
                                          <spam className="text-blue-500 capitalize">
                                            {e.from_user.first_name}
                                          </spam>
                                          ){" "}
                                        </span>
                                        <TimeAgo
                                          date={centralTimestamp}
                                          formatter={(value, unit, suffix) => {
                                            if (unit === "second") {
                                              return "just now";
                                            } else {
                                              return (
                                                value +
                                                " " +
                                                unit +
                                                (value > 1 ? "s" : "") +
                                                " " +
                                                suffix
                                              );
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <hr class="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </>
                              );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div ref={InterViewAvailabilityRef}>
            <div className="h-1"></div>
            {openBooking && (
              <InterViewAvailability
                emp_id={jobDetails?.data?.emp_id}
                job_id={jobid}
                refresh={refresh}
                setRefresh={() => {
                  setRefreshLog((prev) => !prev);
                }}
                to_user={jobDetails?.data?.emp_id}
                onClose={() => setOpenBooking(false)}
              />
            )}
          </div>

          {/* Delete job post */}
          <DeletePost
            myJobsModal={myJobsModal}
            setMyJobsModal={setMyJobsModal}
            singleJobId={jobid}
            // customReload={customReload}
            // updateJobList={updateJobList}
          />

          {/* Claim Job */}
          <ClaimJob
            claimJob={claimJob}
            setClaimJob={setClaimJob}
            singleJobDetails={singleJobDetails}
            setRefresh={setRefresh}
            setUrl={setUrl}
            showSecondModal={showSecondModal}
            setShowSecondModal={setShowSecondModal}
            jobid={jobid}
          />

          {/* bottom part */}
          {!scheduInterview ? (
            <div className="flex flex-col md:flex-row md:space-x-3 lg:space-x-5">
              <div className="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
                <h2 className="my-4 font-xl-semibold">Job Description</h2>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: singleJobDetails?.description,
                  }}
                /> */}
                <div
                  className="unreset"
                  dangerouslySetInnerHTML={{
                    __html: singleJobDetails?.description,
                  }}
                />
                <h2 className="my-4 font-xl-semibold">Benefits</h2>
                {singleJobDetails?.benefit.length !== 0 ? (
                  <div className="space-y-3 benefitList">
                    {singleJobDetails?.benefit_label?.map((single_benefit) => (
                      <p className="flex" key={single_benefit}>
                        <span className="mt-1">
                          <GoPrimitiveDot color="#EB5757" className="mr-2" />
                        </span>
                        {single_benefit}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-400">Not yet mentioned!</p>
                )}
                <h2 className="my-4 font-xl-semibold">Education</h2>
                {singleJobDetails?.qualification_label.length !== 0 ? (
                  <div className="space-y-3">
                    {singleJobDetails?.qualification_label?.map(
                      (single_education) => (
                        <p className="flex" key={single_education}>
                          <span className="mt-1">
                            <GoPrimitiveDot color="#EB5757" className="mr-2" />
                          </span>
                          {single_education.name}
                        </p>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-gray-400">Not yet mentioned!</p>
                )}
              </div>
              <div className="md:w-6/12 lg:w-5/12 jobDescriptionSidebar">
                <div className="px-5 py-3 jobDescription custom_shadow">
                  <h2 className="my-4 font-xl-semibold">Summary</h2>
                  <div className="space-y-4 capitalize">
                    <div className="flex justify-between">
                      <p className="mr-4 whitespace-nowrap">Job Id</p>
                      <p className="font-semibold text-right break-all ">
                        {singleJobDetails?.id}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="mr-4">Company</p>
                      <p className="font-semibold text-right">
                        {singleJobDetails?.company_name
                          ? singleJobDetails?.company_name
                          : "NA"}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="mr-4">Location</p>
                      <p className="font-semibold text-right">
                        {singleJobDetails?.location_details?.address}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="mr-4">Job type</p>
                      {/* <p className="font-semibold text-right" key={single_jobtype}>
                    {single_jobtype.name}
                  </p>     */}
                      <ul className="jobdetail-type">
                        {singleJobDetails?.job_type_label?.map(
                          (single_jobtype) => (
                            <li
                              className="font-semibold text-right"
                              key={single_jobtype}
                            >
                              {single_jobtype.name}
                            </li>
                          )
                        )}
                        {/* {singleJobDetails?.job_type_label[0].name} */}
                      </ul>
                    </div>
                    {/* <div className="flex justify-between">
                    <p className="mr-4">Employment status</p>
                    <p className="font-semibold text-right">
                      {singleJobDetails?.employment_status}
                    </p>
                  </div> */}
                    <div className="flex justify-between">
                      <p className="mr-4">No of hires</p>
                      <p className="font-semibold text-right">
                        {singleJobDetails?.no_of_hires}
                      </p>
                    </div>
                    {/* <div className="flex justify-between">
                    <p className="mr-4">Career level</p>
                    <p className="font-semibold text-right">
                      {singleJobDetails?.career_level}
                    </p>
                  </div> */}
                    {/* <div className="flex justify-between">
                    <p className="mr-4">Experience</p>
                    <p className="font-semibold text-right">
                      {singleJobDetails?.min_experience_years}-
                      {singleJobDetails?.max_experience_years} years
                    </p>
                  </div> */}
                    <div className="flex justify-between">
                      <p className="mr-4">Supplemental pay</p>
                      {singleJobDetails?.supplimental_pay_label?.map(
                        (single_sup_pay) => (
                          <p
                            className="font-semibold text-right"
                            key={single_sup_pay}
                          >
                            {single_sup_pay.name}
                          </p>
                        )
                      )}
                    </div>
                  </div>

                  {!singleJobDetails?.owner ? (
                    user_role === "employee" ? (
                      <>
                        {(currentJobStatus && currentJobStatus !== "invited") ||
                        (singleJobDetails?.emp_id == null &&
                          singleJobDetails?.is_applied) ||
                        isApplied ? (
                          <button
                            className="flex items-center justify-center w-full mt-8 btn btn-main"
                            disabled
                          >
                            <span>
                              <RiCheckboxMultipleFill
                                className="mr-2"
                                size="1.2em"
                              />
                            </span>
                            Applied
                          </button>
                        ) : (
                          <button
                            className="flex items-center justify-center w-full mt-8 btn btn-main"
                            onClick={() => {
                              profileCheck?.data?.profile_completed
                                ? setApplyModal(true)
                                : setProfileError(true);
                            }}
                          >
                            <span>
                              <FaTelegramPlane className="mr-2" size="1.2em" />
                            </span>
                            Apply Now
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {user_role === "employer" ? (
                          <button
                            className="flex items-center justify-center w-full mt-8 btn btn-main"
                            onClick={() => {
                              setApplyModal(true);
                            }}
                          >
                            <span>
                              <FaTelegramPlane className="mr-2" size="1.2em" />
                            </span>
                            Apply Now
                          </button>
                        ) : (
                          <button
                            className="flex items-center justify-center w-full mt-8 btn btn-main"
                            onClick={customLogin}
                          >
                            <span>
                              <FaTelegramPlane className="mr-2" size="1.2em" />
                            </span>
                            Apply Now
                          </button>
                        )}
                      </>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        history.push(`/profile/job-edit/${jobid}`);
                      }}
                      className="flex items-center justify-center w-full mt-8 text-white bg-yellow-300 btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <MdModeEditOutline className="mr-1" size="1.2em" />
                      </span>
                      Edit Post
                    </button>
                  )}
                </div>

                <div className="px-5 py-3 mt-5 locaionMap custom_shadow">
                  <h2 className="my-4 font-xl-semibold">Location Map</h2>
                  <div className="h-48">
                    <Map
                      zoomLevel={12}
                      marker={{
                        lat: singleJobDetails?.location_details?.latitude,
                        lng: singleJobDetails?.location_details?.longtitude,
                      }}
                      address={singleJobDetails?.location_details?.address}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <InterviewSchedule jobEmpId={jobDetails?.data?.emp_id} />
            </>
          )}
        </>
      ) : redirect ? (
        <ExpiredandDeletedJob />
      ) : (
        <>
          <div className="my-5 custom_shadow">
            <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
          </div>
          <div className="push"></div>
        </>
      )}
    </div>
  );
};

export default JobDetails;
