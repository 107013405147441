import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import usePostData from "../../../hooks/usePostData";
import useGetData from "../../../hooks/useGetData";

const ClaimJobPost = ({
  claimModal,
  setClaimModal,
  singleJobDetail,
  listRefresh,
}) => {
  const emp_id = localStorage.getItem("user_id");
  const url = `employers/${emp_id}/claim-jobs`;
  const [claimFormData, setClaimFormData] = useState(null);

  const { data: claimedPostData } = usePostData(url, claimFormData);

  const claimThis = () => {
    setClaimFormData({
      company_name: singleJobDetail?.company_name,
      claimed_jobs: [
        {
          job: singleJobDetail?.id,
          status: 1,
        },
      ],
    });
  };

  useEffect(() => {
    if (claimedPostData) {
      listRefresh(singleJobDetail?.id);
    }
  }, [claimedPostData]);

  return (
    <div>
      {claimModal ? (
        <>
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-2xl">
                    Claim This Job?
                  </h3>
                  {/* <p className="text-red-600 font-base-semibold">1/3</p> */}
                  <button
                    className="flex items-center justify-center font-semibold text-gray-500 w-auto"
                    onClick={() => {
                      setClaimModal(false);
                    }}
                  >
                    <AiOutlineClose className="text-gray-600" size="1.2em" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto px-6 pt-6">
                  <div className="flex w-full text-gray-500 md:items-center details font_small_light normal-case">
                    <p>Are you sure you want to claim this job?</p>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b border-blueGray-200">
                  <div className="flex space-x-2">
                    <button
                      className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                      onClick={() => {
                        setClaimModal(false);
                        claimThis();
                      }}
                    >
                      Claim Job
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClaimJobPost;
