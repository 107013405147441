import { useEffect } from "react";
import Overview from "./Overview";
import JobStatistics from "./JobStatistics";
import JobSeekersMap from "./JobSeekersMap";
import ActiveJobs from "./ActiveJobs";
import { createContext, useContext, useState } from "react";
import PotentialHires from "./PotentialHires";
import useGetData from "../../../hooks/useGetData";
import { Link } from "react-router-dom";
import HireLists from "./HireLists";
import JobSeekersDetails from "./JobSeekersDetails";
import RecentInterview from "../../elements/RecentInterview";
export const JobContext = createContext();

function EmployerDashboard() {
  const user_id = localStorage.getItem("user_id");
  const [jobIdC, setJobIdC] = useState(null);
  const [url, setUrl] = useState(`employers/${user_id}/job-application/count`);
  const { data: CountData, loading, error } = useGetData(url);
  const [positionId, setPositionId] = useState("");
  const [positionlabel, setPositionLabel] = useState("");
  const [updateRecent, setUpdateRecent] = useState(false);
  const [clicks, setClicks] = useState();

  const loader = (
    <span className="text-main text-lg font-semibold">Loading...</span>
  );

  useEffect(() => {
    console.log("CountData", error?.status);
  }, [error]);

  const customLogin = () => {
    localStorage.setItem("from-dashboard-path", window.location.href);
    window.location.href = "/profile/my-plan";
  };

  // const current_plan = localStorage.getItem("plan_value");
  // console.log('current_plan', current_plan)
  const redirectMe = () => {
    window.location.href = "/plans-and-pricing";
    localStorage.setItem("emp-dashboard-path", window.location.href);
  };

  //User active plan API
  const activePlanUrl = "user-active-plan";
  const { data: ActivePlanData, loading: activePlanLoad } =
    useGetData(activePlanUrl);
  const userCurrentPlan = ActivePlanData?.data?.plan;
  console.log("userCurrentPlan", userCurrentPlan);

  return (
    <>
      {activePlanLoad ? (
        <div className="mx-5">{loader}</div>
      ) : userCurrentPlan === null ? (
        redirectMe()
      ) : (
        <JobContext.Provider
          value={[
            jobIdC,
            setJobIdC,
            positionId,
            setPositionId,
            positionlabel,
            setPositionLabel,
          ]}
        >
          <div className="w-full p-5 rounded-md shadow-lg border">
            {loading ? (
              loader
            ) : error?.status == "400" ? (
              <>
                <div className="flex justify-center items-center blursection relative">
                  <div className="p-8 bg-main text-white border rounded-lg border-main upgrade-box">
                    <h3 className="text-xl font-semibold capitalize text-center">
                      Upgrade your plan to access this Dashboard
                    </h3>
                    <button
                      class="bg-red-500 rounded-md text-white px-10 py-3 mt-4 text-center cursor-pointer mx-auto block w-40"
                      onClick={customLogin}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full my-5">
                <div className="mb-10">
                  <Overview setClickData={(data) => setClicks(data)} />
                </div>
                <div className="mb-10">
                  <div className="xl:flex xl:gap-4">
                    <div className="w-full">
                      {/* <JobStatistics /> */}
                      <ActiveJobs clicks={clicks} />
                    </div>
                  </div>
                  <div className="mb-10">
                    <HireLists
                      updateRecent={updateRecent}
                      setUpdateRecent={setUpdateRecent}
                    />
                  </div>
                  <div className="mb-10">
                    <PotentialHires
                      updateRecent={updateRecent}
                      setUpdateRecent={setUpdateRecent}
                    />
                  </div>
                  <div className="flex md:hidden flex-col md:flex-row mb-4 bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
                    <RecentInterview showInvites={true} />
                  </div>
                  <div className="flex flex-col md:flex-row ">
                    <div className="w-full md:w-1/2">
                      <JobSeekersMap />
                    </div>
                    <div className="w-full md:w-1/2 ml-0 md:ml-2">
                      <JobSeekersDetails />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </JobContext.Provider>
      )}
    </>
  );
}

export default EmployerDashboard;
