import PaulImg1 from "../../images/AboutUs/Paul_Toomey_Intro.jpg";
import PaulImg2 from "../../images/AboutUs/Paul_Toomey_Robotic_Pic.jpg";
import KimImg from "../../images/AboutUs/Kim_Vigil.jpg";
import AbbasImg from "../../images/AboutUs/Abbas.jpg";
import DinoopImg from "../../images/AboutUs/Dinoop.jpg";
import ChinthuImg from "../../images/AboutUs/Chinthu.png";
import AthiraImg from "../../images/AboutUs/Athira.jpg";
import VijiImg from "../../images/AboutUs/Viji.jpg";
import SimantaImg from "../../images/AboutUs/Simanta.jpg";
import SanjitImg from "../../images/AboutUs/Sanjit.jpg";
import IntroVideo from "../../images/files/Intro_video.mp4";
import ReactPlayer from "react-player";

function AboutUs() {
  const teamMembers = [
    {
      name: "Dinoop Nath",
      designation: "Project Manager",
      profilePic: DinoopImg,
      alt: "Dinoop Nath profile pic",
      title: "Dinoop Nath",
    },
    {
      name: "Chinthu K Deepu",
      designation: "Lead Full Stack Developer",
      profilePic: ChinthuImg,
      alt: "Chinthu K Deepu profile pic",
      title: "Chinthu K Deepu",
    },
    {
      name: "Vijayalekshmi J",
      designation: "UI Developer",
      profilePic: VijiImg,
      alt: "Vijayalekshmi J profile pic",
      title: "Vijayalekshmi J",
    },
    {
      name: "Athira Mohan",
      designation: "Quality Analyst",
      profilePic: AthiraImg,
      alt: "Athira Mohan profile pic",
      title: "Athira Mohan",
    },
    {
      name: "Abbas Basheer",
      designation: "UI Developer",
      profilePic: AbbasImg,
      alt: "Abbas Basheer profile pic",
      title: "Abbas Basheer",
    },
    {
      name: "Sanjit Mathew",
      designation: "UI Developer",
      profilePic: SanjitImg,
      alt: "Sanjit Mathew profile pic",
      title: "Sanjit Mathew",
    },
    {
      name: "Simanta Ray",
      designation: "UI Developer",
      profilePic: SimantaImg,
      alt: "Simanta Ray profile pic",
      title: "Simanta Ray",
    },
  ];

  return (
    <div className="about-page">
      <h2 className="text-3xl lg:text-4xl font-semibold mb-12 xl:w-5/6 lg:mx-auto my-10 p-6 ">
        About Us
      </h2>

      {/* Top Intro section */}
      <div className="md:flex justify-between md:space-x-10 items-center xl:w-5/6 lg:mx-auto my-10 p-6">
        <div className="mb-12 md:mb-0">
          <h1 className="text-3xl font-medium mb-3 align-middle">CEO Statement - <span className="italic text-xl align-middle">Ring of Hires</span></h1>
          <hr style={{ width: "130px", height: "5px", background: "red" }} />
          <h2 className="relative my-5 italic font-bold text-xl md:text-right">
            Paul Toomey, MD 
          </h2>
          <hr style={{ height: "2px", background: "black" }} />

          <p className="my-6">Why I founded Ring of Hires:</p>

          <div className="space-y-3">
            <p>
              <strong>Setting:</strong> July 2021 in my office
            </p>
            <p>
              <strong>Circumstance:</strong> Frustrated as we had not
              found/hired a front desk medical receptionist for over 6 months
              with conventional job postings
            </p>
            <p>
              <strong>Thought:</strong> I wish I just had all of the medical
              receptionists within 5 miles on a map and I would contact them all
            </p>
            <p>
              <strong>Research:</strong> No map-based job search platforms
              existed for the healthcare industry for employers or employees
            </p>
            <p>
              <strong>Action:</strong> Ring of Hires, LLC formed in August 2021
            </p>
          </div>
        </div>
        <div>
          <img src={PaulImg1} className="w-full md:w-80" alt="Paul Toomey profile pic" title="Paul Toomey" loading="lazy" width="100%" height="auto" />
        </div>
      </div>

      {/* Intro Video section */}
      <div className="video-sec flex justify-center flex-col space-y-9 py-36">
        <div className="text-center">
          <span className="italic text-red-500 text-xl md:text-2xl lg:text-3xl font-semibold">
            Ring of Hires
          </span>
          <h2 className="text-3xl md:text-4xl lg:text-6xl text-white font-bold  my-3 block">
            Intro Video to Ring of Hires
          </h2>
          <h3 className="text-xl md:text-2xl lg:text-3xl text-white font-semibold">
            - Paul Toomey, MD
          </h3>
        </div>

        <div className="mx-auto">
          <ReactPlayer
            controls
            url={IntroVideo}
            className="intro-video"
          />
        </div>

        <div className="space-y-5">
          <span className="text-xl md:text-2xl lg:text-3xl font-semibold text-center block text-white">
            What we do{" "}
          </span>
          <span className="text-white text-center block text-lg">
            Duration 1m 33s
          </span>
        </div>
      </div>

      {/* Paul profile section */}
      <div className="my-20 xl:w-5/6 lg:mx-auto p-6 ">
        <h3 className="text-xl md:text-3xl font-bold md:font-medium mb-3">
          Paul Toomey, MD - CEO/Founder
          <br />
          Contact:{" "}
          <a href="mailto:paultoomeymd@ringofhires.com">
            paultoomeymd@ringofhires.com
          </a>
        </h3>
        <hr style={{ width: "250px", height: "5px", background: "#007FEE" }} />

        <div className="mt-10 lg:flex justify-between space-y-10 lg:space-x-10 lg:space-y-0 items-center">
          <div className="flex-auto space-y-8 w-full lg:w-3/5 paul-profile">
            <p>
              Dr. Toomey arrived in Bradenton, Florida in 2015 after completing
              his surgical training. He is originally from Hagerstown, Maryland.
              He completed his undergraduate degree at{" "}
              <a href="https://duke.edu/" target="_blank" rel="noreferrer">
                Duke University
              </a>{" "}
              in 2004 majoring in Biomedical Engineering. He did his medical
              school and General Surgery training at the{" "}
              <a href="https://www.usf.edu/" target="_blank" rel="noreferrer">
                University of South Florida
              </a>{" "}
              and is fellowship trained in Hepatopancreatobiliary & Foregut
              Advanced Laparoscopic & Robotic Surgery. He completed his
              fellowship in 2013 at Florida Hospital Tampa.
            </p>
            <p>
              Dr. Toomey is a board-certified General Surgeon and an academician
              at heart, with 24 publications and 50 national/regional
              presentations. He did a research fellowship from 2011-2012 at the
              <a href="https://moffitt.org/" target="_blank" rel="noreferrer">
                {" "}
                Moffitt Cancer Center
              </a>
              , focusing on cancer immunology for melanoma and pancreatic
              cancer. He is married to Dr. Karen Matta-Toomey, an Obstetrician &
              Gynecologist and has two wonderful daughters. He is currently the
              President of the{" "}
              <a href="https://sls.org/" target="_blank" rel="noreferrer">
                Society of Laparoscopic and Robotic Surgeons
              </a>
              .
            </p>
            <p>
              Dr. Toomey started his own surgical practice,{" "}
              <a
                href="https://floridasurgicalspecialists.com/"
                target="_blank"
                rel="noreferrer"
              >
                Florida Surgical Specialists
              </a>{" "}
              in 2015 and Florida Surgical Specialists has grown to a
              multispecialty group, including General Surgery, Colorectal
              Surgery and Urogynecology specialists.{" "}
              <a
                href="https://floridasurgicalspecialists.com/"
                target="_blank"
                rel="noreferrer"
              >
                Florida Surgical Specialists
              </a>{" "}
              has 18 employees to date. He continues his private practice as
              well as growing Ring of Hires, which was founded in 2021 and the
              website beta version was launched March, 2022. There are currently
              13 employees at Ring of Hires, including seven software engineers
              contracted with{" "}
              <a
                href="https://www.hashtag-ca.com/"
                target="_blank"
                rel="noreferrer"
              >
                Hashtag Systems Inc
              </a>
              . , UX/UI design and development services with{" "}
              <a
                href="https://innovationsync.com"
                target="_blank"
                rel="noreferrer"
              >
                InnovationSync
              </a>{" "}
              , two social media marketers/content designers and a digital
              marketing partnership.
            </p>
          </div>

          <div>
            <img
              src={PaulImg2}
              style={{ width: "550px" }}
              alt="Paul at his clinic"
              title="Paul Toomey at his office"
              loading="lazy"
              width="100%" 
              height="auto"
            />
          </div>
        </div>
      </div>

      {/* Kim profile section */}
      {/* <div className="my-20 xl:w-5/6 lg:mx-auto p-6 ">
        <h3 className="text-xl md:text-3xl font-bold md:font-medium mb-3">
          Kim Vigil - Global Sales and Business Development Director
          <br />
          Contact: <a href="mailto:kim@ringofhires.com">Kim@ringofhires.com</a>
        </h3>
        <hr style={{ width: "250px", height: "5px", background: "#007FEE" }} />

        <div className="mt-5 lg:flex justify-between space-y-8 lg:space-x-10 lg:space-y-0 items-center">
          <div>
            <img src={KimImg} style={{ width: "550px" }} alt="Kim Vigil profile pic" title="Kim Vigil" loading="lazy" width="100%" height="auto" />
          </div>
          <div className="flex-auto space-y-8 w-full lg:w-3/5 paul-profile">
            <p>
              Kim currently resides in the Phoenix, Arizona area. She is an
              entrepreneur, creating two prior startups, SCS Productions Inc and
              Uniform Consignment. She then sold Uniform Consignment and
              transitioned into roles such as realty marketing/mortgage
              originator, a digital sales manager for startups, and a sales
              executive before spending the last 5 years as the global sales and
              business development director for{" "}
              <a href="https://www.emedevents.com/">eMedEvents</a> , which is
              the largest online CME search portal for healthcare professionals
              across the globe. Kim transitioned to Ring of Hires in 2022.
            </p>
            <p>
              Kim loves working with B2C, B2B, and everyone from the gatekeeper
              to C-Level. She has experience in business development, digital
              sales, SEO/SMM, residential/commercial finance, fundraising, built
              and sold a successful school uniform business. Travel is a passion
              for Kim, especially the oversea experiences in Paris, London,
              Australia, and Japan.
            </p>
          </div>
        </div>
      </div> */}

      {/* Our Engineers */}
      <div className="my-20 xl:w-5/6 lg:mx-auto p-6 ">
        <h3 className="text-xl md:text-3xl font-bold md:font-medium mb-3">
          Meet Our Team
        </h3>
        <hr style={{ width: "150px", height: "5px", background: "#007FEE" }} />

        <div className="grid md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-5 mt-8">
          {teamMembers.map((team, index) => (
            <div className="mb-5">
              <img src={team.profilePic} className="mb-2" alt={team.alt} title={team.title} loading="lazy" width="100%" height="auto" />
              <h4 className="font-semibold text-xl">{team.name}</h4>
              <p className="prof-designation italic font-medium text-main">
                {team.designation}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
