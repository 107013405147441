import React from "react";
import { MdError } from "react-icons/md";

export default function ExpiredandDeletedJob() {
  return (
    <div className="text-center flex flex-col px-4 py-24">
      <div className="mt-24">
        <MdError
          className="absolute m-auto right-0 left-0"
          size="100px"
          color="#ff0000"
        />
        <h1 className="text-3xl md:text-4xl font-bold pt-24 mt-4">
          This Job is Deleted or Expired
        </h1>
        <h1 className="text-sx md:tert-xl  py-2">
          Don't worry, you will be redirected to a Similar Job page
        </h1>
      </div>
    </div>
  );
}
