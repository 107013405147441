import React from "react";
import { useContext, useState, useEffect } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { JobContext } from "./EmployerDashboard";
import useGetData from "../../../hooks/useGetData";

function JobSeekersDetails() {
  const [jobIdC, setJobIdC] = useContext(JobContext);

  let user = localStorage.getItem("user_id");
  const loader = (
    <span className="text-base p-5 block font-semibold">Loading...</span>
  );

  const [url, setUrl] = useState("");

  useEffect(() => {
    if (jobIdC) {
      setUrl(`employers/${user}/${jobIdC}/unique-region-vice-jobs/list`);
    } else {
      setUrl("");
    }
  }, [jobIdC]);

  const { data: stateSec, loading } = useGetData(url);

  if (stateSec?.length != 0 && stateSec?.code != 404) {
    let result = stateSec?.map((a) => a.pageviews);
    let total = result?.reduce((a, b) => Number(a) + Number(b));
    stateSec?.forEach((v) => {
      v.percentage = v.pageviews / total;
    });
  }

  //Sort result array based on accending order of pageviews
  let filterState = [];
  if (stateSec?.length != 0 && stateSec?.code != 404) {
    filterState = stateSec?.sort((p1, p2) =>
      p1.pageviews < p2.pageviews ? 1 : p1.pageviews > p2.pageviews ? -1 : 0
    );
  }

  return (
    <div className="m-4">
      <h1 className="text-lg font-semibold text-black px-4">
        Region Wise Clicks
      </h1>
      {loading ? (
        loader
      ) : stateSec?.length > 0 ? (
        <body class="flex items-center justify-center">
          <div class="container">
            <table class="relative w-full flex flex-row flex-nowrap sm:overflow-y-scroll mt-5 custom-table px-2 md:px-0">
              <thead class="text-gray-500">
                <tr class="hidden flex-col flex-nowrap sm:table-row bg-gray-200">
                  <th className="py-3 px-2 text-center md:w-2/6 text-sm">
                    State
                  </th>
                  <th className="py-3 px-2 text-center md:w-3/6 text-sm">
                    Percentage
                  </th>
                  <th className="py-3 px-2 text-center md:w-1/6 text-sm">
                    Clicks
                  </th>
                </tr>
              </thead>

              <tbody class="flex-1 sm:flex-none percentage-height border-b">
                {filterState?.map((singleRow, row) => {
                  if (singleRow.region !== "(not set)") {
                    return (
                      <>
                        <tr
                          class="flex flex-col md:flex-row mb-3 sm:mb-0 bg-white border rounded-lg md:border-none"
                          key={row}
                        >
                          <td class="border-grey-light border p-3 w-full text-center md:w-2/6">
                            <div className="inline-block md:hidden font-bold">
                              State :
                            </div>{" "}
                            {singleRow.region}
                          </td>
                          <td class="border-grey-light border p-3 w-full text-center md:w-3/6">
                            <div className="inline-block md:hidden font-bold">
                              Percentage :
                            </div>
                            <div className="flex items-center">
                              <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div
                                  class="bg-blue-600 h-2.5 rounded-full"
                                  style={{
                                    width: `${singleRow.percentage * 100}%`,
                                  }}
                                ></div>
                              </div>
                              <h1 className="text-sm pl-2">
                                {(singleRow.percentage * 100).toFixed(2)}%
                              </h1>
                            </div>
                          </td>
                          <td class="border-grey-light border p-3 w-full text-center md:w-1/6">
                            <div className="inline-block md:hidden font-bold">
                              Clicks :
                            </div>{" "}
                            {singleRow.pageviews}
                          </td>
                        </tr>
                      </>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </body>
      ) : (
        <div className="bg-gray-200 w-full p-5 mt-5 font-semibold text-gray-500 border rounded-md text-center">
          Nothing to display
        </div>
      )}
    </div>
  );
}

export default JobSeekersDetails;
