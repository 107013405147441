import { AiOutlineSearch } from "react-icons/ai";
import LocationMap from "../../pages/EmployerDashboard/LocationMap";
import JobSeekersDetails from "./JobSeekersDetails";

function JobSeekersMap() {
  return (
    <>
      <div className="mb-6 bg-white border rounded-xl">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-col 2xl:flex-row p-4 2xl:items-center">
          <h1 className="text-lg font-semibold text-black mb-3 2xl:mb-0">
            Job Seekers Available
          </h1>
          {/* <div className="relative">
            <input
              className="border rounded-xl px-3 py-2 outline-none bg-white flex-1 w-full 2xl:w-64"
              placeholder="10001"
            />
            <AiOutlineSearch
              className="mx-auto w-5 absolute top-3 right-2"
              color="#007FEE"
            />
          </div> */}
        </div>

        <div className="z-0 px-0 md:px-5 mb-4">
          <LocationMap />
        </div>
        {/* <div className="h-48 overflow-auto"> */}
      </div>
    </>
  );
}

export default JobSeekersMap;
