import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import usePostData from "../../hooks/usePostData";
import useDelete from "../../hooks/useDelete";
import HireModal from "../modals/EmployeeDetails/HireModal";
import ContactModal from "../modals/EmployeeDetails/ContactModal";
import HireReport from "../modals/EmployeeDetails/HireReport";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillHeart } from "react-icons/ai";
import { MdRestore } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { BiDownload } from "react-icons/bi";
import { FaWindowClose } from "react-icons/fa";
import RohLogo from "../../images/ROH-logo.png";
import InterViewAvailability from "./EmployeeDashboard/InterViewAvailability";
import { MdModeEditOutline, MdCalendarToday } from "react-icons/md";
import { AiOutlineDelete, AiOutlineClockCircle } from "react-icons/ai";
import moment from "moment";
import AllSlots from "../elements/AllSlots";
import TimeAgo from "react-timeago";
import axios from "axios";
import useToken from "../../hooks/useToken";

let old_phoneformat = "";
let new_phoneformat = "";
const EmployeeDetails = () => {
  //useParams
  const { emp_id } = useParams();

  //get queries from URL
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const { defaultOptions } = useToken("application/json");
  const [empDetails, setEmpDetails] = useState([]);
  console.log("empDetails", empDetails?.skill);
  const [position, setPosition] = useState("");
  const [hireModal, setHireModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [saveFormData, setSaveFormData] = useState(null);
  const [unsaveUrl, setUnsaveUrl] = useState(null);
  const [isHired, setIsHired] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [employerHired, setEmployerHired] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [formDatalog, setFormDatalog] = useState(null);
  const [scheduleInterviewSection, setScheduleInterviewSection] =
    useState(true);
  const [rejectSlot, setRejectSlot] = useState(false);
  const [openBooking, setOpenBooking] = useState(false);
  const [getUrl, setGetUrl] = useState(
    `employees/${emp_id}/public${
      query.get("job_id") ? `/${query.get("job_id")}` : ""
    }`
  );
  const [reportModal, setReportModal] = useState(false);
  const [appCancel, setAppCancel] = useState(false);
  // localStorage
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");

  const [customTime, setCustomTime] = useState(false);
  const [customId, setCustomId] = useState(null);
  const [acceptDataFiltered, setAcceptDataFiltered] = useState(null);

  const mainRef = useRef(null);

  useEffect(() => {
    if (!openBooking) {
      mainRef.current.style.scrollMargin = "200px";
      mainRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [openBooking]);

  //hooks get
  const {
    data: employeeData,
    loading: loading,
    error: error,
  } = useGetData(getUrl);

  const { data: positionList } = useGetData("category-list");

  const {
    data: savedJobsPostData,
    loading: saveUserLoading,
    error: errlog,
  } = usePostData(`employers/${user_id}/saved-employees`, saveFormData);

  const { data: delJobsData, loading: unsaveUserLoading } =
    useDelete(unsaveUrl);

  useEffect(() => {
    window.scrollTo(0, 0);
    setGetUrl(null);
    // if (query.get("hired") === "true") {
    //   setIsHired(true);
    // }

    // if (user_role === "employee" || !user_role) {
    //   window.location.href = "/";
    // }
  }, []);

  useEffect(() => {
    if (error === "Not found.") {
      window.location.href = "/404";
    }
  }, [error]);

  useLayoutEffect(() => {
    if (employeeData?.data) {
      setEmpDetails(employeeData.data);
      setIsSaved(employeeData.data.is_saved);
      setIsHired(employeeData.data.hired);
      setIsCancel(employeeData.data.job_application_cancelled);
      setEmployerHired(employeeData.data.employer_hired);
    }
  }, [employeeData]);

  // Deleted employee account redirection
  useEffect(() => {
    if (error?.non_field_error === "Something went wrong") {
      window.location.href = "/404";
    }
  }, [error]);

  useEffect(() => {
    if (!err && savedJobsPostData?.code === 200) {
      setIsSaved(true);
    }
  }, [savedJobsPostData]);

  useEffect(() => {
    if (!err && delJobsData?.code === 200) {
      setIsSaved(false);
    }
  }, [delJobsData]);

  useEffect(() => {
    if (positionList?.data?.response_data && employeeData?.data) {
      const positions = positionList.data.response_data;
      const findPostion = positions.find(
        (item) => item.id === employeeData.data.position
      );
      setPosition(findPostion?.name);
    }
  }, [positionList, employeeData]);

  const hireRefresh = () => {
    setGetUrl(
      `employees/${emp_id}/public${
        query.get("job_id") && `/${query.get("job_id")}`
      }`
    );
  };

  const contactRefresh = (isContact) => {
    setIsContact(isContact);
  };

  const checkUser = () => {
    if (!user_id) {
      window.location.href = "/login";
      return;
    }
  };

  const saveEmp = () => {
    checkUser();
    if (user_role === "employer") {
      setSaveFormData({
        employee: emp_id,
      });
    }
    // savedRefresh(true);
  };

  useEffect(() => {
    if (unsaveUrl) {
      setUnsaveUrl(null);
    }
  }, [unsaveUrl]);

  const delEmp = () => {
    checkUser();
    setUnsaveUrl(`employers/${user_id}/saved-employees/${emp_id}`);
    // savedRefresh(false);
  };

  function dateFormat(d) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let t = new Date(d);
    return t.getDate() + " " + monthNames[t.getMonth()] + " " + t.getFullYear();
  }

  if (empDetails) {
    old_phoneformat = empDetails?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const customClose = () => {
    setDownloadModal(false);
  };
  const customDownload = () => {
    setDownloadModal(true);
  };

  const { data: aplicationLog } = useGetData(
    `availability/applicationlog/${query.get("job_id")}/${emp_id}`,
    "main",
    refresh
  );

  const { data: acceptData, error: err } = usePostData(
    `availability/applicationlog`,
    formDatalog
  );

  function compareDates(dateStr1, dateStr2) {
    const dateA = new Date(dateStr1);
    const dateB = new Date(dateStr2);
    return dateA - dateB;
  }

  useEffect(() => {
    if (aplicationLog) {
      let temp = aplicationLog.data.sort((a, b) =>
        compareDates(a.created_at, b.created_at)
      );
      setAcceptDataFiltered(temp);
    }
  }, [aplicationLog]);

  useEffect(() => {
    if (acceptData) setRefresh((prev) => !prev);
  }, [acceptData]);

  const InterViewAvailabilityRef = useRef(null);

  const customTimeAdd = (e) => {
    const bookings = Object.keys(e)
      .filter((key) => key.startsWith("booking"))
      .map((key) => e[key]);

    const acceptedBooking = bookings.find(
      (booking) => booking.booking_status === "accepted"
    );
    const acceptedBookingId = acceptedBooking ? acceptedBooking.id : null;

    setCustomId(acceptedBookingId);
  };

  const scrollToBooking = () => {
    if (InterViewAvailabilityRef)
      InterViewAvailabilityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  };

  return (
    <div className="minHeight mb-5 container-wrapper" ref={mainRef}>
      {!loading && !error ? (
        <>
          <HireModal
            showModal={hireModal}
            setShowModal={setHireModal}
            empDetails={empDetails}
            position={position}
            phone={new_phoneformat}
            hireRefresh={hireRefresh}
          ></HireModal>
          <ContactModal
            showModal={contactModal}
            setShowModal={setContactModal}
            empDetails={empDetails}
            position={position}
            phone={new_phoneformat}
            contactRefresh={contactRefresh}
          ></ContactModal>
          <HireReport
            showModal={reportModal}
            setShowModal={setReportModal}
          ></HireReport>

          <div className="my-5 custom_shadow jobTitleContainer">
            <div className="flex flex-col justify-between py-3 pt-6 space-y-6 md:px-5 md:space-y-0 md:flex-row">
              <div className="block space-x-5 font-semibold text-gray-500 md:flex md:whitespace-pre md:justify-center md:text-left md:items-center details font_small_light">
                <img
                  src={
                    empDetails?.image
                      ? empDetails?.image
                      : "/images/employee-default.png"
                  }
                  alt="Profile Image"
                  className="block ml-5 md:ml-0"
                  width="150px"
                />

                <div className="space-y-1 lg:mr-5">
                  {empDetails?.premium == true && (
                    <div className="block sm:hidden">
                      <img src={RohLogo} className="w-5 h-full" />
                    </div>
                  )}
                  <h2 className="text-black font-xl-semibold flex items-center">
                    {empDetails?.first_name
                      ? `${empDetails?.first_name} ${empDetails.last_name}`
                      : "User Name"}
                    {empDetails?.premium == true && (
                      <div className="hidden sm:block ml-2">
                        <img src={RohLogo} className="w-5 h-full" />
                      </div>
                    )}
                  </h2>
                  <p className="pb-5 font-normal text-gray-500 ">{position}</p>
                  <p className="flex items-center justify-start md:justify-start">
                    <span>
                      <MdRestore color="007fee" className="mr-1" size="1.2em" />
                    </span>
                    {empDetails?.created_at &&
                      `Member Since, ${dateFormat(empDetails.created_at)}`}
                  </p>
                  <div className="flex flex-col justify-start gap-0 md:gap-2 md:flex-row md:justify-start md:flex-wrap md:space-x-3">
                    <p className="flex justify-start md:items-center">
                      <span style={{ marginTop: "2px" }} className="self-start">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <span className="whitespace-pre-wrap">
                        {empDetails?.location}
                      </span>
                    </p>
                    <p
                      style={{ marginLeft: "0" }}
                      className="flex items-center m-0"
                    >
                      <span>
                        <HiOutlinePhone
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {new_phoneformat}
                    </p>
                    <p
                      style={{ marginLeft: "0" }}
                      className="flex items-center m-0"
                    >
                      <span>
                        <HiOutlineMail
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {empDetails.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-5 line"></div>
            <div className="flex flex-col justify-between p-3 pr-3 lg:flex-row">
              <div className="flex flex-col justify-between gap-2 mt-3 lg:flex-row">
                {isContact ? (
                  <button
                    onClick={() => setContactModal(true)}
                    disabled
                    className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                  >
                    <span>
                      <HiOutlineMail
                        color="white"
                        className="mr-1"
                        size="1.2em"
                      />
                    </span>
                    Contacted
                  </button>
                ) : (
                  <button
                    onClick={() => setContactModal(true)}
                    className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                  >
                    <span>
                      <HiOutlineMail
                        color="white"
                        className="mr-1"
                        size="1.2em"
                      />
                    </span>
                    Contact Now
                  </button>
                )}

                {/* {!isCancel &&
                user_role === "employer" &&
                query.get("job_id") ? (
                  !employerHired ? (
                    <button
                      onClick={() => setHireModal(true)}
                      className="flex items-center justify-center w-full text-white md:w-auto md:mt-0 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hire Now
                    </button>
                  ) : (
                    <button
                      disabled
                      className="flex items-center justify-center w-full text-white md:w-auto md:mt-0 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hired
                    </button>
                  )
                ) : null} */}
              </div>
              {/* <div className="flex flex-col justify-between gap-2 mt-3 lg:flex-row"> */}

              {/* </div> */}
              <div className="flex flex-col justify-between gap-2 mt-3 lg:flex-row">
                {empDetails?.resume && (
                  <>
                    {user_id ? (
                      user_role === "employer" ? (
                        <button className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-gray-800 hover:text-black ">
                          <span>
                            <BiDownload className="mr-1" size="1.2em" />
                          </span>
                          <a
                            href={empDetails?.resume}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download Resume
                          </a>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => customDownload()}
                            className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-gray-800 hover:text-black "
                          >
                            <span>
                              <BiDownload className="mr-1" size="1.2em" />
                            </span>
                            {/* <a href="#">Download CV</a> */}
                            Download Resume
                          </button>
                          {downloadModal ? (
                            <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                              <div className="relative w-auto max-w-3xl mx-auto my-6">
                                {/*content*/}
                                <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                  {/*header*/}
                                  <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                                    <h3 className="text-xl font-semibold md:text-xl">
                                      Only an employer is able to download the
                                      CV
                                    </h3>
                                    <button
                                      onClick={() => customClose()}
                                      className="ml-4 text-red-600 font-base-semibold "
                                    >
                                      <FaWindowClose size="1.4em" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    ) : (
                      <button className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-white hover:text-black ">
                        <span>
                          <BiDownload className="mr-1" size="1.2em" />
                        </span>
                        <a href="/login">Download Resume</a>
                      </button>
                    )}
                  </>
                )}

                {user_role === "employer" ? (
                  isSaved ? (
                    <button
                      onClick={!unsaveUserLoading ? delEmp : () => {}}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <AiFillHeart className="mr-1" size="1.2em" />
                      </span>
                      {!unsaveUserLoading ? "Unsave User" : "Unsaving..."}
                    </button>
                  ) : (
                    <button
                      onClick={!saveUserLoading ? saveEmp : () => {}}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <AiOutlineHeart className="mr-1" size="1.2em" />
                      </span>
                      {!saveUserLoading ? "Save User" : "Saving..."}
                    </button>
                  )
                ) : null}
                <button
                  onClick={() => {
                    setReportModal(true);
                  }}
                  className="flex items-center justify-center text-white bg-red-500 hover:bg-red-600 btn-base"
                >
                  <span>
                    <MdErrorOutline className="mr-1" size="1.2em" />
                  </span>
                  Report
                </button>
              </div>
            </div>

            {user_role === "employer" && query.get("job_id") && (
              <>
                <div className="line mx-4"></div>
                {acceptDataFiltered?.length > 0 && (
                  <div className="flex flex-col md:flex-row md:space-x-3 lg:space-x-5">
                    <div className="w-full px-5 py-2">
                      <h2 className="my-4 font-xl-semibold">Activity</h2>
                      {appCancel && (
                        <RejectModal
                          appCancel={appCancel}
                          setAppCancel={setAppCancel}
                          jobId={query.get("job_id")}
                          setRefresh={() => {
                            setRefresh((prev) => !prev);
                          }}
                          to_user={emp_id}
                        />
                      )}
                      <div className="flex flex-col-reverse">
                        {acceptDataFiltered &&
                          acceptDataFiltered.map((e, index) => {
                            let offset = new Date().getTimezoneOffset();

                            const centralTimestamp = new Date(e?.created_at);

                            centralTimestamp.setMinutes(
                              centralTimestamp.getMinutes() - offset
                            );

                            if (e.application_action === "slot_booked") {
                              let temp = [];
                              temp.push(e.booking1.booking_status);
                              temp.push(e.booking2.booking_status);
                              temp.push(e.booking3.booking_status);
                              return (
                                <div>
                                  <div
                                    key={index}
                                    className="flex flex-row mb-4"
                                  >
                                    <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                    <div className="w-full">
                                      <AllSlots
                                        allSlots={e}
                                        employeeId={user_id}
                                        employerId={emp_id}
                                        jobId={query.get("job_id")}
                                        next={acceptDataFiltered[index + 1]}
                                        setRejectSlot={setRejectSlot}
                                        from={"emp-page"}
                                        to_user={emp_id}
                                        setRefresh={() => {
                                          setRefresh((prev) => !prev);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {/* {!aplicationLog.data[index + 1] &&
                                    temp.includes("accepted") && (
                                      <div className="flex my-2 mx-8">
                                        {!employerHired ? (
                                          <button
                                            onClick={() => setHireModal(true)}
                                            className="border-2 border-blue-500 px-2  text-white rounded-sm bg-blue-500 mx-2 py-1"
                                          >
                                            Hire Now
                                          </button>
                                        ) : (
                                          <button
                                            disabled
                                            className="border-2 border-blue-500 px-2  text-white rounded-sm bg-blue-500 mx-2 py-1"
                                          >
                                            Hired
                                          </button>
                                        )}
                                        <button
                                          className="border-2 border-red-500 px-2  text-white rounded-sm bg-red-500 mx-2 py-1"
                                          onClick={() => {
                                            setAppCancel(true);
                                          }}
                                        >
                                          Cancel Application
                                        </button>
                                      </div>
                                    )} */}
                                  {!acceptDataFiltered[index + 1] &&
                                    temp.includes("accepted") && (
                                      <div className="flex flex-col md:flex-row my-2 gap-2">
                                        <div className="flex ">
                                          {!employerHired ? (
                                            <button
                                              onClick={() => setHireModal(true)}
                                              className="border-2 border-blue-500 px-2 hover:bg-blue-600 text-white rounded-sm bg-blue-500 mx-2 py-1"
                                            >
                                              Hire Now
                                            </button>
                                          ) : (
                                            <button
                                              disabled
                                              className="border-2 border-blue-500 px-2 hover:bg-blue-600 text-white rounded-sm bg-blue-500 mx-2 py-1"
                                            >
                                              Hired
                                            </button>
                                          )}
                                          <button
                                            className="w-auto border-2 border-blue-500 hover:bg-blue-600 px-2  text-white rounded-sm bg-blue-500 mx-2 py-1 active:bg-gray-600"
                                            onClick={() => {
                                              setOpenBooking(true);
                                              setCustomTime(true);
                                              customTimeAdd(e);
                                              scrollToBooking();
                                            }}
                                          >
                                            Update Schedule
                                          </button>
                                        </div>
                                        <div className="flex ">
                                          <button
                                            className="w-auto border-2 border-red-500 hover:bg-red-600 px-2 text-white rounded-sm bg-red-500 mx-2 py-1"
                                            onClick={() => {
                                              setAppCancel(true);
                                            }}
                                          >
                                            Cancel Application
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  <hr className="h-px my-2  boreder-gray-200 border-1 border-dashed" />
                                </div>
                              );
                            } else if (
                              e.application_action === "application_accepted"
                            )
                              return (
                                <div className="mb-4">
                                  <div className="flex justify-between ">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      Application Accepted
                                    </div>
                                    <div className="text-gray-600">
                                      (
                                      <spam className="text-blue-500 capitalize">
                                        {e.from_user.first_name}
                                      </spam>
                                      ){" "}
                                      <TimeAgo
                                        date={centralTimestamp}
                                        formatter={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            return "just now";
                                          } else {
                                            return (
                                              value +
                                              " " +
                                              unit +
                                              (value > 1 ? "s" : "") +
                                              " " +
                                              suffix
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {!acceptDataFiltered[index + 1] &&
                                    e.from_user.id == user_id && (
                                      <div className="p-4">
                                        <button
                                          className="border-2 border-blue-500 hover:bg-blue-600 px-2 text-white rounded-sm bg-blue-500"
                                          onClick={() => {
                                            setOpenBooking(true);
                                            setCustomTime(false);
                                          }}
                                        >
                                          Schedule Interview
                                        </button>
                                      </div>
                                    )}
                                </div>
                              );
                            else if (
                              e.application_action === "application_rejected"
                            )
                              return (
                                <div className="flex justify-between mb-4">
                                  <div className="flex">
                                    <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                    Application Rejected
                                  </div>
                                  <div className="text-gray-600">
                                    (
                                    <spam className="text-blue-500 capitalize">
                                      {e.from_user.first_name}
                                    </spam>
                                    ){" "}
                                    <TimeAgo
                                      date={centralTimestamp}
                                      formatter={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          return "just now";
                                        } else {
                                          return (
                                            value +
                                            " " +
                                            unit +
                                            (value > 1 ? "s" : "") +
                                            " " +
                                            suffix
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            else if (e.application_action === "hired")
                              return (
                                <div className="flex justify-between mb-4">
                                  <div className="flex">
                                    <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                    Hired
                                  </div>
                                  <div className="text-gray-600">
                                    (
                                    <spam className="text-blue-500 capitalize">
                                      {e.from_user.first_name}
                                    </spam>
                                    ){" "}
                                    <TimeAgo
                                      date={centralTimestamp}
                                      formatter={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          return "just now";
                                        } else {
                                          return (
                                            value +
                                            " " +
                                            unit +
                                            (value > 1 ? "s" : "") +
                                            " " +
                                            suffix
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            else if (
                              e.application_action === "booking_cancelled"
                            )
                              return (
                                <div>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      <h1>
                                        {!acceptDataFiltered[index + 1] &&
                                        e.from_user.id === user_id
                                          ? "Interview Rejected(book new slots)"
                                          : "Interview Rejected"}
                                      </h1>
                                    </div>
                                    <div className="text-gray-600">
                                      (
                                      <spam className="text-blue-500 capitalize">
                                        {e.from_user.first_name}
                                      </spam>
                                      ){" "}
                                      <TimeAgo
                                        date={centralTimestamp}
                                        formatter={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            return "just now";
                                          } else {
                                            return (
                                              value +
                                              " " +
                                              unit +
                                              (value > 1 ? "s" : "") +
                                              " " +
                                              suffix
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {!acceptDataFiltered[index + 1] &&
                                    e.from_user.id == user_id && (
                                      <div className="px-4">
                                        <button
                                          className="border-2 border-blue-500 hover:bg-blue-600 px-2 text-white rounded-sm bg-blue-500"
                                          onClick={() => {
                                            setOpenBooking(true);
                                            setCustomTime(false);
                                            scrollToBooking();
                                          }}
                                        >
                                          Schedule Interview
                                        </button>
                                      </div>
                                    )}
                                </div>
                              );
                            else
                              return (
                                <div>
                                  <div className="flex justify-between mb-4">
                                    <div className="flex capitalize">
                                      <div className="w-3 h-3 my-1.5 mr-2 bg-blue-500 rounded-full relative"></div>
                                      {e.application_action == "invited"
                                        ? "Invited"
                                        : e.application_action == "applied"
                                        ? "Application Submitted"
                                        : "Application Rejected"}
                                    </div>
                                    <div>
                                      <span className="text-gray-600">
                                        (
                                        <spam className="text-blue-500 capitalize">
                                          {e.from_user.first_name}
                                        </spam>
                                        ){" "}
                                        <TimeAgo
                                          date={centralTimestamp}
                                          formatter={(value, unit, suffix) => {
                                            if (unit === "second") {
                                              return "just now";
                                            } else {
                                              return (
                                                value +
                                                " " +
                                                unit +
                                                (value > 1 ? "s" : "") +
                                                " " +
                                                suffix
                                              );
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  {e.application_action === "applied" &&
                                    !acceptDataFiltered[index + 1] && (
                                      <>
                                        <div className="py-2">
                                          <button
                                            className="bg-blue-500 hover:bg-blue-600 px-3 rounded-sm mx-2 text-white"
                                            onClick={() => {
                                              if (query.get("job_id")) {
                                                setFormDatalog({
                                                  job: query.get("job_id"),
                                                  to_user: emp_id,
                                                  application_action:
                                                    "application_accepted",
                                                  application: null,
                                                });
                                              }
                                            }}
                                          >
                                            Accept
                                          </button>
                                          <button
                                            className="bg-red-500 hover:bg-red-600 px-3 rounded-sm text-white mx-2"
                                            onClick={() => {
                                              if (query.get("job_id")) {
                                                setFormDatalog({
                                                  job: query.get("job_id"),
                                                  to_user: emp_id,
                                                  application_action:
                                                    "application_rejected",
                                                  application: null,
                                                });
                                              }
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </div>
                                      </>
                                    )}
                                </div>
                              );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={InterViewAvailabilityRef}>
            <div className="h-1"></div>
            {openBooking && (
              <InterViewAvailability
                emp_id={user_id}
                job_id={query.get("job_id")}
                refresh={refresh}
                setRefresh={() => {
                  setRefresh((prev) => !prev);
                }}
                to_user={emp_id}
                onClose={() => setOpenBooking(false)}
                customTime={customTime}
                customId={customId}
              />
            )}
          </div>
          <div className="flex flex-col md:flex-row md:space-x-3 lg:space-x-5">
            {scheduleInterviewSection ? (
              <div className="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
                <h2 className="my-4 font-xl-semibold">About Me</h2>
                <p>{empDetails.about_me}</p>

                {/* <div className="mt-5 space-y-3">
                <p className="flex" key="">
                  <span className="mt-1">
                    <GoPrimitiveDot color="#EB5757" className="mr-2" />
                  </span>
                  asdsada
                </p>
              </div> */}
              </div>
            ) : (
              <div className="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
                <InterViewAvailability
                  emp_id={user_id}
                  job_id={query.get("job_id")}
                  refresh={refresh}
                  setRefresh={(a) => {}}
                />
              </div>
            )}
            <div className="md:w-6/12 lg:w-5/12 jobDescriptionSidebar">
              <div className="px-5 py-3 jobDescription custom_shadow">
                <h2 className="my-4 font-xl-semibold">Candidate Details</h2>
                <div className="space-y-4 capitalize">
                  {/* <div className="flex justify-between">
                    <p className="mr-4">Experience</p>
                    <p className="font-semibold text-right">Akjkjge</p>
                  </div> */}
                  <div className="flex justify-between">
                    <p className="mr-4">Age</p>
                    <p className="font-semibold text-right">
                      {getAge(empDetails.dob)}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="mr-4">Expected Salary</p>
                    <p className="font-semibold text-right">
                      {empDetails.desired_pay != null
                        ? `$${empDetails.desired_pay}`
                        : "$0"}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="mr-4">Education Levels</p>
                    {/* <p className="font-semibold text-right" key={single_jobtype}>
                    {single_jobtype.name}
                  </p>     */}
                    {/* <ul className="jobdetail-type"> */}
                    <p className="inline font-semibold text-right">
                      {empDetails.education_level_label}
                    </p>
                    {/* </ul> */}
                  </div>
                </div>
                {!isCancel &&
                user_role === "employer" &&
                query.get("job_id") ? (
                  !employerHired ? (
                    <button
                      onClick={() => setHireModal(true)}
                      className="flex items-center justify-center w-full text-white mt-4 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hire Now
                    </button>
                  ) : (
                    <button
                      disabled
                      className="flex items-center justify-center w-full text-white mt-4 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hired
                    </button>
                  )
                ) : null}
              </div>
              <div className="px-5 py-3 mt-5 locaionMap custom_shadow">
                <h2 className="my-4 font-xl-semibold">Skills</h2>

                <div className="flex flex-wrap gap-2">
                  {empDetails?.skill?.map((single_skill, ind) => (
                    <div
                      className="border border-blue-500 text-main p-3 text-base rounded-lg font-semibold block"
                      key={ind}
                    >
                      {single_skill?.skill_name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="my-5 custom_shadow">
            <h5 className="p-3 text-gray-600 font-semibold">
              {error?.non_field_error ? error.non_field_error : "Loading..."}
            </h5>
          </div>
          <div className="push"></div>
        </>
      )}
    </div>
  );
};

export default EmployeeDetails;

export function RejectModal({
  appCancel,
  setAppCancel,
  jobId,
  setRefresh,
  to_user,
}) {
  const [formData, setFormData] = useState();

  const { data: rejectData, error: err } = usePostData(
    `availability/applicationlog`,
    formData
  );

  useEffect(() => {
    if (rejectData) {
      setRefresh();
      setAppCancel(false);
    }
  }, [rejectData]);

  return (
    <>
      <div className="fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to Cancel the Job Application ?
              </h3>
              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={() => {
                  setFormData({
                    job: jobId,
                    to_user: to_user,
                    application_action: "rejected",
                    application: null,
                  });
                }}
              >
                Yes, I'm sure
              </button>
              <button
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={() => {
                  setAppCancel(false);
                }}
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
