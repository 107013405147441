import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import useGetData from "../../hooks/useGetData";
import ReactSlider from "react-slider";
import { UserContext } from "../../App";
import { fields, checkFields } from "../../helpers/JobPostingSchema";
import usePostData from "../../hooks/usePostData";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import Select from "react-select";
import ImageUploaderJobPost from "../elements/ImageUploaderJobPost";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import usePutData from "../../hooks/usePutData";
import BenefitSelect from "../elements/BenefitSelect";
import { IoIosClose } from "react-icons/io";
import TextEditor from "../elements/TextEditor";
import Highlighter from "react-highlight-words";

const JobPosting = () => {
  const { jobslug } = useParams("jobslug");
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [formdata, setFormData] = useState(null);
  const [editFormdata, setEditFormdata] = useState(null);
  const [fullTime, setFullTime] = useState(true);
  const [values, setValue] = useState(fields);
  const { userId } = useContext(UserContext);
  const [isFullTime, setIsFullTime] = useState(false);
  const [isPartTime, setisPartTime] = useState(false);
  const [isTenens, setIsTenens] = useState(false);
  const [jobImage, setJobImage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [file_data, setFile_data] = useState("");
  const [job_type_schedule, setJob_type_schedule] = useState([]);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [benefit, setBenefit] = useState("");
  const [benefitArray, setBenefitArray] = useState([]);
  console.log('newwwww', benefitArray)
  const [benefitErr, setBenefitErr] = useState("");
  const [markerLocation, setMarkerLocation] = useState({
    lat: "",
    lng: "",
  });
  const [address, setAddress] = useState("");
  const loader = <span className="text-white">Sending...</span>;

  const url = `employers/${userId}/jobs`;
  const jobPostEditUrl = `employers/${userId}/jobs/${jobslug}`;
  const [count, setCount] = useState(5);
  const history = useHistory();
  const [clearMe, setClearMe] = useState(false);
  const [textValue, setTextValue] = useState("<h1></h1>");
  const [titleValue, setTitleValue] = useState("");

  const [checkedSalaryType, setCheckedSalaryType] = useState(
    new Set(["Hourly"])
  );
  const [checkedFix, setCheckedFix] = useState(new Set(["Daily"]));
  const [editFixSalary, setEditFixSalary] = useState(false);
  const [editFixValue, setEditFixValue] = useState(false);
  const [hourlyMinSalary, setHourlyMinSalary] = useState(false);
  const [hourlyMaxSalary, setHourlyMaxSalary] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [positionData, setPositionData] = useState([]);

  const jobUrl = jobslug ? `employers/${userId}/jobs/${jobslug}` : null;

  const { data: jobData, loading: load, error: err } = useGetData(jobUrl);

  const { data: qualificationList, loading: positionloading } = useGetData(
    `qualifications?category_id=${values.position?.id}`
  );

  const { data: subcategoryList, loading: subcategoryloading } = useGetData(
    `employers/sistercategory/${values.position?.id}/data`
  );
  console.log("subcategoryList", subcategoryList?.data?.[0]?.sister_category);

  const { data: positionList } = useGetData("category-list");
  const { data: supplementalList } = useGetData("supplemental-pays");
  const { data: benefitsList } = useGetData("benefits");
  const { data: jobTypesList } = useGetData("job-types");
  const { data: schedulesList } = useGetData("schedules");
  const { data: employersData } = useGetData(`employers/${userId}`);
  const { data: profileCheck } = useGetData(
    `employers/${userId}/profile-check`
  );

  const benefitSelectRef = useRef();

  useEffect(() => {
    setPositionData(positionList?.data);
  }, [positionList]);

  const customFilter = (option, searchText) => {
    const getSearctextPos = (string) =>
      string.name.toLowerCase().indexOf(searchText.toLowerCase());
    let temp = positionData?.sort(
      (a, b) => getSearctextPos(a) - getSearctextPos(b)
    );
    setPositionData(temp);

    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data?.alias.includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  //Access control - Create job post
  const [accessUrl, setAccessUrl] = useState(`access/1019`);
  const { data: AccessData, loading: mulJobLoading } = useGetData(accessUrl);

  //Access control - Edit job post
  const [editPostUrl, setEditPostUrl] = useState(`access/1020`);
  const { data: EditPostData } = useGetData(editPostUrl);

  function allowMulJob() {
    let multjobpost = false;
    AccessData?.data?.map((single_access_item) => {
      if (single_access_item.feature === 4) {
        multjobpost = true;
      }
    });
    return multjobpost;
  }

  /* Get the job count */
  const [myposturl, setMyPostUrl] = useState(
    `employers/${userId}/jobs?page=1&page_size=6`
  );
  const { data: jobPostsCount } = useGetData(myposturl);

  const [detailUrl, setDetailUrl] = useState(null);
  const {
    data: jobDetailData,
    loading: detailLoad,
    error: jobErr,
  } = useGetData(detailUrl);

  // const { data: jobData, error: Customredirect } = useGetData(
  //   `employers/${userId}/jobs/${jobslug}`
  // );

  const jobDetails = jobData?.data;

  //Remove tick mark on the image upload once the job created
  const [uploadDone, setUploadDone] = useState(false);

  useLayoutEffect(() => {
    if (err === "Not found.") {
      setDetailUrl(`jobs/${jobslug}`);
      // window.location.href = `/job-details/${jobslug}`;
    }
  }, [err]);

  useLayoutEffect(() => {
    if (jobDetailData) {
      history.push(`/job-details/${jobslug}`);
      // window.location.href = `/job-details/${jobslug}`;
    } else if (jobErr === "Not found.") {
      // history.push(`/404`);
      window.location.href = `/404`;
      // history.push(`/job-details/${jobslug}`);
    }
  }, [jobErr, jobDetailData]);

  useLayoutEffect(() => {
    if (profileCheck?.data?.profile_completed === false) {
      setIsProfileComplete(false);
    } else {
      setIsProfileComplete(true);
    }
  }, [profileCheck]);

  if (jobDetails) {
    job_type_schedule.map((item, key) => {
      if (item.job_type) {
        if (!item.schedule) {
          item["schedule"] = [];
        }
      }
    });
  }

  //Function to bring the customer to my-plan page to choose from
  const setRedirect = () => {
    localStorage.setItem("redirected_jobpost", true);
    history.push("/profile/my-plan");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const pos = positionList?.data?.filter((item) => {
      return item.id === jobDetails?.position;
    });

    if (jobDetails?.position && pos) {
      setValue((prev) => ({
        ...prev,
        position: { id: jobDetails?.position, name: pos[0]?.name },
      }));
    }
  }, [positionList, jobDetails]);

  // console.log(checkedSalaryType)
  useEffect(() => {
    if (jobDetails) {
      // setValue(jobDetails);
      setJob_type_schedule(jobDetails.job_type_schedule);

      if (jobDetails.salary_type) {
        let salaryType = Object.keys(jobDetails.salary_type).map((key) => {
          return jobDetails.salary_type[key];
        });
        checkedSalaryType.clear();
        checkedSalaryType.add(salaryType[0]);
      }
      setCheckedSalaryType(new Set(checkedSalaryType));

      if (jobDetails.salary_subtype) {
        let salarySubType = Object.keys(jobDetails.salary_subtype).map(
          (key) => {
            return jobDetails.salary_subtype[key];
          }
        );
        checkedFix.clear();
        checkedFix.add(salarySubType[0]);
      }
      setCheckedFix(new Set(checkedFix));

      setValue((prev) => ({
        ...prev,
        description: jobDetails.description,
        min_salary: jobDetails.min_salary,
        max_salary: jobDetails.max_salary,
        no_of_hires: jobDetails.no_of_hires,
        supplemental_pay: jobDetails.supplemental_pay,
        benefit: jobDetails.benefit,
        image: jobDetails.image,
        qualification: jobDetails.qualification,
        work_location: {
          address: jobDetails.location_details.address,
          latitude: jobDetails.location_details.latitude,
          longtitude: jobDetails.location_details.longtitude,
        },
        is_remote: jobDetails.is_remote,
        job_title: jobDetails.job_title,
        sister_category_associated: jobDetails.sister_category_associated,
      }));
      setBenefitArray(jobDetails.benefit);
      if (jobDetails.location_details) {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${jobDetails.location_details.latitude},${jobDetails.location_details.longtitude}&key=${process.env.REACT_APP_MAP_KEY}`
          )
          .then((res) => {
            const data = res.data.results;

            const code = data.find((item) => item.types.includes("plus_code"));
            const route = data.find((item) => item.types.includes("route"));
            const country = data.find((item) => item.types.includes("country"));
            const postcode = data.find((item) =>
              item.types.includes("postal_code")
            );
            const state = data.find((item) =>
              item.types.includes("administrative_area_level_1")
            );
            const city = data.find((item) => item.types.includes("locality"))
              ? data.find((item) => item.types.includes("locality"))
              : data.find((item) =>
                  item.types.includes("administrative_area_level_2")
                );

            let addressVal = "";
            if (code?.address_components.length > 1) {
              const data = code.address_components.filter((item) => {
                return (
                  item.types.includes("locality") ||
                  item.types.includes("administrative_area_level_2") ||
                  item.types.includes("administrative_area_level_1") ||
                  item.types.includes("country")
                );
              });
              let str = "";
              addressVal = data.map((item) => {
                return item.long_name + str;
              });
              addressVal = [...new Set(addressVal)];
              addressVal = addressVal.join(", ");
              // addressVal=addressVal.slice(0, -2);
              setAddress(addressVal ? addressVal : "");
            } else if (route) {
              addressVal = route.formatted_address;
              setAddress(addressVal ? addressVal : "");
            } else {
              addressVal =
                city?.address_components[0].long_name +
                " " +
                state?.address_components[0].long_name;
              setAddress(city && state ? addressVal : "");
            }
          });
      }
    }
  }, [jobDetails]);

  const updateQualification = (e, data) => {
    if (e.target.checked) {
      setValue((prev) => ({
        ...prev,
        qualification: [...prev.qualification, data],
      }));
    } else {
      let filteredqualification = values.qualification.filter(
        (item, i) => item !== data
      );
      setValue((prev) => ({ ...prev, qualification: filteredqualification }));
    }
  };

  const updateSubcategory = (e, data) => {
    if (e.target.checked) {
      setValue((prev) => ({
        ...prev,
        sister_category_associated: [...prev.sister_category_associated, data],
      }));
    } else {
      let filteredsubcategory = values.sister_category_associated.filter(
        (item, i) => item !== data
      );
      setValue((prev) => ({
        ...prev,
        sister_category_associated: filteredsubcategory,
      }));
    }
  };

  // const addBenefits = () => {
  //   if (!benefit) return;
  //   let isFound = benefitsList?.data?.response_data.find((item) => {
  //     return item.name.toLowerCase() === benefit?.name.toLowerCase();
  //   });

  //   let isFindNew = benefitArray.find((item) => {
  //     return item.name.toLowerCase() === benefit?.name.toLowerCase();
  //   });

  //   if (isFound || isFindNew) {
  //     setBenefit("");
  //     setBenefitErr("Please add a new benefit");
  //     setTimeout(() => {
  //       setBenefitErr("");
  //     }, 3000);
  //     return;
  //   }
  //   setBenefitArray((prev) => [...prev, { name: benefit?.name }]);
  //   setBenefit("");
  // };

  

  const updateBenefit = (e, data) => {
    if (e.target.checked) {
      setValue((prev) => ({
        ...prev,
        benefit: [...prev.benefit, { name: data }],
      }));
    } else {
      let filteredBenefits = values.benefit.filter(
        (item, i) => item.name !== data
      );
      setValue((prev) => ({ ...prev, benefit: filteredBenefits }));
    }
  };

  // set Value to send to api when benefit is updated
  useEffect(() => {
    setValue((prev) => ({ ...prev, benefit: benefitArray }));
  }, [benefitArray]);

  // update benefits array
  const selectBenefit = (benefit) => {
    if (!benefitArray?.map((a) => a.name).includes(benefit.name)) {
      setBenefitArray([...benefitArray, ...benefit]);
    }
    // if (benefit) {
    //   setBenefit(benefit);

    //   // let isFound = benefitsList?.data?.response_data.find((item) => {
    //   //   return item.name.toLowerCase() === benefit?.name.toLowerCase();
    //   // });

    //   let isFound = benefitArray.find((item) => {
    //     return item.name.toLowerCase() === benefit?.name.toLowerCase();
    //   });

    //   if (isFound) {
    //     setBenefit("");
    //     setBenefitErr("Please add a new benefit");
    //     setTimeout(() => {
    //       setBenefitErr("");
    //     }, 3000);
    //     return;
    //   }

    //   if (benefit.id) {
    //     setBenefitArray((prev) => [
    //       ...prev,
    //       { id: benefit.id, name: benefit?.name },
    //     ]);
    //     return;
    //   }

    //   setBenefitArray((prev) => [...prev, { name: benefit?.name }]);
    //   setBenefit("");
    //   // skillName.current.value = skill.skill_name;
    // } else {
    //   setBenefit(null);
    //   // skillName.current.value = "";
    // }
  };
  const deleteBenefit = (name) => {
    benefitSelectRef.current.clearValue();
    setBenefitArray(benefitArray.filter((item) => item.name !== name));
  };
  const [refresh, setRefresh] = useState(false);

  const updateSchedule = (e, job_type, id) => {
    job_type_schedule.map((item, key) => {
      if (item.job_type === job_type) {
        if (!item.schedule) {
          item["schedule"] = [];
        }
      }
    });
    if (e.target.checked) {
      setRefresh(!refresh);
      job_type_schedule.map((item, key) => {
        if (item.job_type === job_type) {
          if (item.schedule) {
            item["schedule"] = [...item.schedule, id];
          }
        }
      });
    } else {
      setRefresh(!refresh);
      job_type_schedule.map((item, key) => {
        if (item.job_type === job_type) {
          if (item.schedule) {
            let filter = item.schedule.filter((item) => item !== id);
            item["schedule"] = filter;
          }
        }
      });
    }
  };
  const updateJobType = (e, data) => {
    if (e.target.checked) {
      setJob_type_schedule((prev) => [
        ...prev,
        { job_type: data, schedule: [] },
      ]);
    } else {
      const filter = job_type_schedule.filter((ele) => ele.job_type !== data);
      setJob_type_schedule((prev) => filter);
    }
  };

  const updataNoOfHires = (e, data) => {
    setValue({ ...values, no_of_hires: data });
  };

  const updateSupplementalPay = (e, data) => {
    if (e.target.checked) {
      setValue((prev) => ({
        ...prev,
        supplemental_pay: [...prev.supplemental_pay, data],
      }));
    } else {
      let filteredsupplemental_pay = values.supplemental_pay.filter(
        (item, i) => item !== data
      );
      setValue((prev) => ({
        ...prev,
        supplemental_pay: filteredsupplemental_pay,
      }));
    }
  };

  const calculateAnnualPay = useCallback((pay) => {
    let yearlyPay = pay * 261 * 8;
    return yearlyPay;
  }, []);

  // const mx_h_pay = calculateAnnualPay(values.max_salary?.toLocaleString());

  const updateValue = (e) => {
    setValue({ ...values, job_title: e.target.value });
  };

  const saveSingleValue = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  };

  // const saveSingleValue = (e) => {
  //   // setFormData({ [e.target.name]: e.target.value });
  //   setValue({ ...values, job_title: e.target.value });
  // };

  useEffect(() => {
    setValue({ ...values, description: textValue });
  }, [textValue]);

  useEffect(() => {
    setValue({ ...values, job_title: titleValue });
  }, [titleValue]);

  const updatePay = (value) => {
    if (editFixSalary) {
      setValue({ ...values, min_salary: 0, max_salary: value });
    }
    if (typeof value == "number") {
      setValue({ ...values, min_salary: 0, max_salary: value });
    } else {
      setValue({ ...values, min_salary: value[0], max_salary: value[1] });
    }
  };
  // const updateMaxPay = (value) => {
  //   setValue({ ...values, max_salary: value });
  // };

  const updatePositionSelectValue = (value) => {
    setValue({ ...values, position: value });
  };

  // Clear the image preview once the job posted successfully.
  // const clearSourceImg = () => {
  //   setFileSrc(null);
  // }

  const processForm = (e) => {
    e.preventDefault();
    setFile_data(null);
    const errList = checkFields(values);
    setErrors(errList);
    // console.log("create post global");
    if (!errList) {
      let finalData = { ...values };
      finalData.position = {
        id: finalData.position.id,
        name: finalData.position.name,
      };
      finalData.min_yearly_pay = calculateAnnualPay(values.min_salary);
      finalData.max_yearly_pay = calculateAnnualPay(values.max_salary);
      finalData.job_type_schedule = job_type_schedule;
      finalData.position = values.position.id;
      if (!file_data) {
        finalData.image = "";
      }
      // finalData.job_title = values.job_title;
      finalData.user = userId;
      if (!jobslug) {
        setFormData(finalData);
        //  console.log("successfully submitted");
        setTimeout(() => {
          window.location.href = "/profile/joblist";
        }, [2000]);
      } else {
        setEditFormdata(finalData);
        setTimeout(() => {
          // window.location.href = `/job-details/${jobslug}`;
        }, [2000]);
      }
      setClearMe(true);
    }
  };

  const scheduleMap = (id) => {
    const data = schedulesList?.data?.meta_values;
    const finalData = data.find((ele) => ele[0] === id)[1];
    const splitData = finalData.split("_").join(" ");
    return splitData;
  };

  const { data, error, loading } = usePostData(url, formdata);

  const {
    data: editData,
    error: editError,
    loading: editLoading,
  } = usePutData(jobPostEditUrl, editFormdata);

  const updateRemote = (e) => {
    setValue((prev) => ({
      ...prev,
      is_remote: e.target.checked,
    }));
  };

  useEffect(() => {
    if (editData) {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }
    if (!editError) {
      setSuccessMsg(editData?.message);
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);
    }
  }, [editData, editError]);

  useEffect(() => {
    if (!error) {
      setSuccessMsg(data?.message);
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);

      setAddress("");
      setisPartTime(false);
      setIsFullTime(false);
      setIsTenens(false);
      setValue(fields);
      setBenefitArray([]);
      setBenefit("");
      setJob_type_schedule([]);

      var clist = document.getElementsByTagName("input");
      for (var i = 0; i < clist.length; ++i) {
        clist[i].checked = false;
      }
    }
  }, [error, loading]);

  const handleSelectLocation = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        const result = results[0].address_components;
        const country = result.find((item) => item.types.includes("country"));
        const postcode = result.find((item) =>
          item.types.includes("postal_code")
        );
        const state = result.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        const city = result.find((item) => item.types.includes("locality"))
          ? result.find((item) => item.types.includes("locality"))
          : result.find((item) =>
              item.types.includes("administrative_area_level_2")
            );
        // const suburb = result.find((item) => item.types.includes("locality"));
        // const street = result.find((item) =>
        //   item.types.includes("sublocality")
        // );
        // const addressString = `${values.city} ${values.state} ${values.country}`;
        setValue((currentVal) => ({
          ...currentVal,
          work_location: {
            address: address,
            city: city?.long_name.includes("County")
              ? city?.long_name.replace("County", "")
              : city?.long_name,
            country: country?.long_name,
            state: state?.long_name,
            postal_code: postcode ? postcode.long_name : null,
          },
        }));

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        getSelectedLocation(latLng);
        setValue((currentVal) => ({
          ...currentVal,
          work_location: {
            ...currentVal.work_location,
            latitude: latLng ? latLng.lat : "",
            longtitude: latLng ? latLng.lng : "",
          },
        }));
      })
      .catch((error) => console.error("Error", error));
  };
  const getSelectedLocation = (location) => {
    // setCurrentLatitude(location.lat);
    // setCurrentLongitude(location.lng);
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
    // setMarkerLat(location.lat);
    // setMarkerLng(location.lng);
  };
  const handleChangeLocation = (address) => {
    setAddress(address);
    setValue((currentVal) => ({
      ...currentVal,
      work_location: {
        address: address,
        latitude: "",
        longtitude: "",
      },
    }));
  };
  const displayBenefits = () => {
    const temp =
      benefitArray &&
      benefitArray.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );
    const benefitData = temp.reverse().map((item, index) => {
      return (
        <div key={index} className="bg-main rounded-lg flex items-center">
          <span className="p-1 pl-2 text-white">{item.name}</span>
          <IoIosClose
            color="white"
            size={24}
            className="cursor-pointer pr-1"
            onClick={() => deleteBenefit(item.name)}
          />
        </div>
      );
    });

    return benefitData;
  };

  const memoizedBenefits = useMemo(() => displayBenefits(), [benefitArray]);

  // useEffect(() => {

  //   // setAddress(employersData?.data.address);
  //   if (!jobslug) {
  //     setValue((currentVal) => ({
  //       ...currentVal,
  //       work_location: {
  //         address: employersData?.data.state ?? "",
  //         latitude: employersData?.data.location_latitude ?? "",
  //         longtitude: employersData?.data.location_longitude ?? "",
  //       },
  //     }));
  //   }
  // }, [employersData]);

  const fileData = (file_data) => {
    setFile_data(file_data);
    setValue((prev) => ({ ...prev, image: file_data ?? "" }));
  };

  // useEffect(() => {
  //   if(Customredirect) {
  //     console.log('error');
  //     window.location.href = `/job-details/${jobslug}`
  //   }
  // }, [Customredirect]);

  /* Slice benefit */
  const readMore = () => {
    setCount(count + 5);
  };

  //sort positions list
  const sortedPositionData = positionList?.data.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const handleCheck = ({ id, checked }) => {
    if (checked) {
      checkedSalaryType.clear();
      checkedSalaryType.add(id);
    } else {
      checkedSalaryType.delete(id);
    }
    setCheckedSalaryType(new Set(checkedSalaryType));
  };
  const handleFixCheck = ({ id, checked }) => {
    if (checked) {
      checkedFix.clear();
      checkedFix.add(id);
    } else {
      checkedFix.delete(id);
    }
    setCheckedFix(new Set(checkedFix));
  };
  useEffect(() => {
    setEditFixSalary(false);
  }, []);

  useEffect(() => {
    let salary_type_value;
    switch ({ ...[...checkedSalaryType] }[0]) {
      case "Hourly":
        salary_type_value = "0";
        break;
      case "Fixed":
        salary_type_value = "1";
        break;
      default:
        salary_type_value = "0";
    }

    setValue((prev) => ({
      ...prev,
      salary_type: salary_type_value,
    }));
  }, [checkedSalaryType]);

  useEffect(() => {
    let salary_sub_type_value;
    switch ({ ...[...checkedFix] }[0]) {
      case "Daily":
        salary_sub_type_value = "0";
        break;
      case "Weekly":
        salary_sub_type_value = "1";
        break;
      case "Bi-Weekly":
        salary_sub_type_value = "2";
        break;
      case "Monthly":
        salary_sub_type_value = "3";
        break;
      case "Lump-Sum":
        salary_sub_type_value = "4";
        break;
      default:
        salary_sub_type_value = "0";
    }

    setValue((prev) => ({
      ...prev,
      salary_subtype: salary_sub_type_value,
    }));
  }, [checkedFix]);

  useEffect(() => {
    if (parseInt(values.max_salary) <= parseInt(values.min_salary)) {
      setErrorAlert(true);
    } else {
      setErrorAlert(false);
    }
  }, [values]);

  function formatOptionLabel({ name }, { inputValue }) {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={name}
        highlightClassName="text-red-500  border-red-500 bg-transparent"
      />
    );
  }

  const regex = /^[0-9\b]+$/;

  // const current_plan = localStorage.getItem("plan_value");
  const redirectMe = () => {
    window.location.href = "/plans-and-pricing";
    localStorage.setItem("job-post-path", window.location.href);
  };

  //User active plan API
  const activePlanUrl = "user-active-plan";
  const { data: ActivePlanData, loading: activePlanLoad } =
    useGetData(activePlanUrl);
  const userCurrentPlan = ActivePlanData?.data?.plan;
  console.log("userCurrentPlan", userCurrentPlan);

  const activeLoader = (
    <span className="text-main text-lg font-semibold">Loading...</span>
  );

  return (
    <>
      {activePlanLoad ? (
        <div className="mx-5">{activeLoader}</div>
      ) : userCurrentPlan === null ? (
        redirectMe()
      ) : !isProfileComplete ? (
        <div className="flex items-center justify-center w-full h-full text-center ">
          <h2 className="mb-5 text-xl font-semibold capitalize ">
            please complete your profile
          </h2>
        </div>
      ) : !load && !detailLoad ? (
        <div className="w-full">
          {!mulJobLoading ? (
            jobPostsCount?.count == 0 || allowMulJob() || jobslug ? (
              <div className="block w-full space-y-5 lg:flex jobPostContainer ">
                <div className="flex flex-col rounded lg:flex-row filtercontainer lg:w-full">
                  {/* filter card start */}

                  <div className="top-0 w-full py-0 lg:py-5 px-4  font-semibold capitalize transition-all duration-300 bg-white rounded shadow-lg lg:w-8/12 md:top-24 md:left-6 filterPopupContainer ">
                    <div className="relative ml-2">
                      <h2 className="my-5 text-xl font-semibold capitalize lg:hidden">
                        job posting
                      </h2>
                      <div className="mb-10">
                        <ImageUploaderJobPost
                          image={jobData ? jobData.data.image : null}
                          fileData={fileData}
                          setClearMe={clearMe}
                        />
                      </div>

                      <p className="mt-5 font-semibold">
                        Number of hires for this role
                      </p>
                      <div className="flex justify-start gap-5 noOfHires">
                        <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                              name="no_of_hires"
                              value="1"
                              checked={values.no_of_hires === 1}
                              onChange={(e) => {
                                updataNoOfHires(e, 1);
                              }}
                            />
                          </span>
                          1
                        </p>
                        <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                              name="no_of_hires"
                              value="2"
                              checked={values.no_of_hires === 2}
                              onChange={(e) => {
                                updataNoOfHires(e, 2);
                              }}
                            />
                          </span>
                          2
                        </p>
                        <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                              name="no_of_hires"
                              value="3"
                              checked={values.no_of_hires === 3}
                              onChange={(e) => {
                                updataNoOfHires(e, 3);
                              }}
                            />
                          </span>
                          3
                        </p>
                        <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                              name="no_of_hires"
                              value="4"
                              checked={values.no_of_hires === 4}
                              onChange={(e) => {
                                updataNoOfHires(e, 4);
                              }}
                            />
                          </span>
                          4
                        </p>
                        <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                              name="no_of_hires"
                              value="5"
                              checked={values.no_of_hires === 5}
                              onChange={(e) => {
                                updataNoOfHires(e, 5);
                              }}
                            />
                          </span>
                          5+
                        </p>
                      </div>
                      <p className="mb-2 -mt-3 font-normal normal-case form_error_message">
                        {errors?.no_of_hires}
                      </p>
                      <p className="mb-5 font-semibold">job type</p>
                      <div className="justify-start block radiusContainer">
                        {jobTypesList?.data?.response_data
                          .filter((ele) => ele.name === "Full Time")
                          .map((item, key) => {
                            return (
                              <p
                                className="flex items-center gap-1 my-5 text-sm text-gray-900"
                                key={key}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                    name="job_type"
                                    value="1"
                                    checked={job_type_schedule.find(
                                      (items) => items.job_type === item.id
                                    )}
                                    onChange={(e) => {
                                      updateJobType(e, item.id);
                                      setIsFullTime(!isFullTime);
                                    }}
                                  />
                                </span>

                                {item.name}
                              </p>
                            );
                          })}
                      </div>
                      {/* {schedulesList....} */}
                      <div className="justify-start block pl-5 radiusContainer">
                        <div className="">
                          {schedulesList?.data?.response_data
                            .filter((ele) => ele.job_type__name === "Full Time")
                            .map((item, key) => {
                              return (
                                job_type_schedule.find(
                                  (items) => items.job_type === item.job_type
                                ) && (
                                  <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                                    <span>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                        name="schedule"
                                        value="1"
                                        checked={job_type_schedule.find(
                                          (items) =>
                                            items["schedule"]?.includes(item.id)
                                        )}
                                        onChange={(e) => {
                                          updateSchedule(
                                            e,
                                            item.job_type,
                                            item.id
                                          );
                                        }}
                                      />
                                    </span>
                                    {item.min_hour !== 0 && item.min_hour}-
                                    {item.min_hour !== 0 && item.max_hour}{" "}
                                    {item.min_hour !== 0 && `hour work`}{" "}
                                    {scheduleMap(item.schedule_type)}
                                  </p>
                                )
                              );
                            })}
                        </div>
                      </div>
                      <div className="justify-start block radiusContainer">
                        {jobTypesList?.data?.response_data
                          .filter((ele) => ele.name === "Part Time")
                          .map((item, key) => {
                            return (
                              <p
                                className="flex items-center gap-1 my-5 text-sm text-gray-900"
                                key={key}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                    name="job_type"
                                    checked={job_type_schedule.find(
                                      (items) => items.job_type === item.id
                                    )}
                                    value="1"
                                    onChange={(e) => {
                                      updateJobType(e, item.id);
                                      setisPartTime(!isPartTime);
                                    }}
                                  />
                                </span>
                                {item.name}
                              </p>
                            );
                          })}
                      </div>
                      {/* {schedulesList....} */}

                      <div className="justify-start block pl-5 radiusContainer">
                        <div className="">
                          {schedulesList?.data?.response_data
                            .filter((ele) => ele.job_type__name === "Part Time")
                            .map((item, key) => {
                              return (
                                job_type_schedule.find(
                                  (items) => items.job_type === item.job_type
                                ) && (
                                  <p className="flex items-center gap-1 my-5 text-sm text-gray-500">
                                    <span>
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                        name="schedule"
                                        value="1"
                                        checked={job_type_schedule.find(
                                          (items) =>
                                            items["schedule"]?.includes(item.id)
                                        )}
                                        onChange={(e) => {
                                          updateSchedule(
                                            e,
                                            item.job_type,
                                            item.id
                                          );
                                        }}
                                      />
                                    </span>
                                    {item.min_hour !== 0 && item.min_hour}-
                                    {item.min_hour !== 0 && item.max_hour}{" "}
                                    {item.min_hour !== 0 && `hour work`}{" "}
                                    {scheduleMap(item.schedule_type)}
                                  </p>
                                )
                              );
                            })}
                        </div>
                      </div>

                      <div className="justify-start block radiusContainer">
                        {jobTypesList?.data?.response_data
                          .filter(
                            (ele) => ele.name === "Locum Tenens/Traveling"
                          )
                          .map((item, key) => {
                            return (
                              <p
                                className="flex items-center gap-1 my-5 text-sm text-gray-900"
                                key={key}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                    name="job_type"
                                    checked={job_type_schedule.find(
                                      (items) => items.job_type === item.id
                                    )}
                                    value="1"
                                    onChange={(e) => {
                                      updateJobType(e, item.id);
                                      setIsTenens(!isTenens);
                                    }}
                                  />
                                </span>
                                {item.name}
                              </p>
                            );
                          })}
                      </div>

                      <p className="mt-5 font-semibold">Supplemental pay</p>
                      <div className="justify-start block radiusContainer">
                        {supplementalList?.data?.response_data.map(
                          (item, key) => {
                            return (
                              <p
                                className="flex items-center gap-1 my-5 text-sm text-gray-500"
                                key={item.id}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                    name="supplemental_pay"
                                    value="1"
                                    checked={values.supplemental_pay?.includes(
                                      item.id
                                    )}
                                    onChange={(e) => {
                                      updateSupplementalPay(e, item.id);
                                    }}
                                  />
                                </span>
                                {item.name}
                              </p>
                            );
                          }
                        )}
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <p className="mb-4 mt-1 font-semibold">benefits</p>
                        </div>
                        <div className="flex items-center gap-3">
                          <div className="flex-initial w-full my-2">
                            <BenefitSelect
                              benefitList={benefitsList}
                              benefit={benefit}
                              selectBenefit={selectBenefit}
                              benefitArray={benefitArray}
                              setBenefitArray={setBenefitArray}
                              benefitSelectRef={benefitSelectRef}
                            />
                          </div>
                        </div>
                        <div className="text-red-500">{benefitErr}</div>
                        <div className="flex flex-row gap-2 flex-wrap mb-4">
                          {memoizedBenefits}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* filter card end */}
                  {/* posting container start */}
                  <div className="relative w-full py-0 lg:pt-5 px-5 ml-0 mr-0 bg-white lg:ml-2 postingContainer lg:mr-2 job-third-col">
                    <h2 className="hidden text-xl font-semibold capitalize lg:flex">
                      job posting
                    </h2>
                    <div className="space-y-4">
                      <div className="pt-5">
                        <p className="my-0 lg:my-5 mb-5 font-semibold">
                          Job Title{" "}
                        </p>
                        <input
                          type="text"
                          name="job_title"
                          id="job_title"
                          value={values?.job_title}
                          className="form_input"
                          onChange={(e) => updateValue(e)}
                          // onBlur={(e) => saveSingleValue(e)}
                        />
                      </div>
                      <div>
                        <p className="my-0 lg:my-5 mb-5 font-semibold">
                          Position{" "}
                        </p>
                        {/* <input
              type="text"
              className="w-full px-5 py-3 rounded-md form_input"
              placeholder="Speciality"
            /> */}{" "}
                        {sortedPositionData?.length > 0 && (
                          <Select
                            formatOptionLabel={formatOptionLabel}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={positionData?.sort((a, b) => {
                              var x = a.name.toLowerCase();
                              var y = b.name.toLowerCase();
                              return x < y ? -1 : x > y ? 1 : 0;
                            })}
                            name="position"
                            id="position"
                            value={values.position}
                            onChange={updatePositionSelectValue}
                            placeholder="Position"
                            filterOption={customFilter}
                          />
                        )}
                      </div>
                      <p className="normal-case form_error_message">
                        {errors?.position}
                      </p>

                      {positionloading ? (
                        <div>Loading...</div>
                      ) : (
                        <>
                          {true && (
                            <div>
                              <p className="my-5 font-semibold">
                                Qualification{" "}
                              </p>

                              {qualificationList?.data?.response_data.map(
                                (item, key) => {
                                  return (
                                    <p
                                      className="flex items-center gap-1 my-5 text-sm text-gray-500"
                                      key={item.id}
                                    >
                                      <span>
                                        <input
                                          type="checkbox"
                                          className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                          name="qualification"
                                          checked={values?.qualification?.includes(
                                            item.id
                                          )}
                                          value={item.name}
                                          onChange={(e) => {
                                            updateQualification(e, item.id);
                                          }}
                                        />
                                      </span>
                                      {item.name}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </>
                      )}

                      {subcategoryloading ? (
                        <div>Loading...</div>
                      ) : (
                        <>
                          {true && (
                            <div>
                              <p className="my-5 font-semibold">
                                Similar Positions{" "}
                              </p>

                              {subcategoryList?.data?.[0]?.sister_category?.map(
                                (subitem, key) => {
                                  return (
                                    <p
                                      className="flex items-center gap-1 my-5 text-sm text-gray-500"
                                      key={subitem.id}
                                    >
                                      <span>
                                        <input
                                          type="checkbox"
                                          className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent"
                                          name="sister_category_associated"
                                          checked={values?.sister_category_associated?.includes(
                                            subitem.id
                                          )}
                                          value={subitem.name}
                                          onChange={(e) => {
                                            updateSubcategory(e, subitem.id);
                                          }}
                                        />
                                      </span>
                                      {subitem.name}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </>
                      )}

                      <div className="mt-4">
                        <p className="my-5 font-semibold">Salary</p>
                        <div className="mb-4">
                          {/*  */}
                          <label>
                            <input
                              id="Hourly"
                              type="checkbox"
                              checked={checkedSalaryType.has("Hourly")}
                              onChange={(e) => handleCheck(e.target)}
                              className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                            />
                            Hourly
                          </label>

                          <label>
                            <input
                              id="Fixed"
                              type="checkbox"
                              checked={checkedSalaryType.has("Fixed")}
                              onChange={(e) => handleCheck(e.target)}
                              className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                            />
                            Fixed
                          </label>
                          {/*  */}
                        </div>

                        {/* pay hourly */}

                        {checkedSalaryType.has("Hourly") && (
                          <div className="">
                            <div className="flex justify-between mx-2 my-4">
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="desired_pay"
                              >
                                Minimum Desired Pay
                              </label>
                              <label
                                className="block mb-2 text-sm font-semibold text-gray-500"
                                htmlFor="desired_pay"
                              >
                                Maximum Desired Pay
                              </label>
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="text-center flex ml-1">
                                {!hourlyMinSalary && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setHourlyMinSalary(true);
                                    }}
                                  >
                                    {values.min_salary}
                                  </span>
                                )}
                                {hourlyMinSalary && (
                                  <input
                                    type="tel"
                                    pattern="[0-9]*"
                                    style={{ width: `6.5ch` }}
                                    className="mx-2  border-2 px-1"
                                    value={values.min_salary}
                                    onChange={(e) => {
                                      if (
                                        e.target.value <= 99999 &&
                                        regex.test(e.target.value)
                                      ) {
                                        setValue({
                                          ...values,
                                          min_salary: parseInt(
                                            e.target.value,
                                            10
                                          ),
                                        });
                                      }
                                      if (
                                        !e.target.value ||
                                        e.target.value.length === 0
                                      ) {
                                        setValue({ ...values, min_salary: 0 });
                                      }
                                    }}
                                  />
                                )}
                                <span>$ / HR</span>
                              </p>

                              <p className="text-center flex">
                                {!hourlyMaxSalary && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setHourlyMaxSalary(true);
                                    }}
                                  >
                                    {values.max_salary}
                                  </span>
                                )}
                                {hourlyMaxSalary && (
                                  <input
                                    type="tel"
                                    pattern="[0-9]*"
                                    style={{ width: `6.5ch` }}
                                    className="mx-2  border-2 px-1"
                                    value={values.max_salary}
                                    onChange={(e) => {
                                      if (
                                        e.target.value <= 99999 &&
                                        regex.test(e.target.value)
                                      ) {
                                        setValue({
                                          ...values,
                                          max_salary: parseInt(
                                            e.target.value,
                                            10
                                          ),
                                        });
                                      }
                                      if (
                                        !e.target.value ||
                                        e.target.value.length === 0
                                      ) {
                                        setValue({ ...values, max_salary: 0 });
                                      }
                                    }}
                                  />
                                )}
                                <span>$ / HR</span>
                              </p>
                            </div>
                            <ReactSlider
                              step={1}
                              min={1}
                              max={500}
                              minDistance={1}
                              name="desired_pay"
                              id="desired_pay"
                              className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                              thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                              trackClassName="bg-main"
                              // defaultValue={[
                              //   values.min_salary,
                              //   values.max_salary,
                              // ]}
                              value={[values.min_salary, values.max_salary]}
                              onChange={(value) => {
                                updatePay(value);
                              }}
                            />

                            <div className="flex items-center justify-between">
                              <div>
                                <span className="p-2 mr-2 rounded-md">
                                  {calculateAnnualPay(
                                    values.min_salary
                                  )?.toLocaleString()}
                                </span>
                                $/ Year
                              </div>
                              <div>
                                <span className="p-2 mr-2 rounded-md">
                                  {calculateAnnualPay(
                                    values.max_salary
                                  )?.toLocaleString()}
                                </span>
                                $/ Year
                              </div>
                            </div>
                            {errorAlert && (
                              <h1 className="text-red-600 text-sm mt-4 normal-case">
                                {" "}
                                Max Salary should be greater than Min Salary{" "}
                              </h1>
                            )}
                          </div>
                        )}

                        {/* pay fix */}

                        {checkedSalaryType.has("Fixed") && (
                          <div className="my-6">
                            <div className="flex justify-between">
                              <label>
                                <input
                                  id="Daily"
                                  type="checkbox"
                                  checked={checkedFix.has("Daily")}
                                  onChange={(e) => handleFixCheck(e.target)}
                                  className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                                />
                                Daily
                              </label>
                              <label>
                                <input
                                  id="Weekly"
                                  type="checkbox"
                                  checked={checkedFix.has("Weekly")}
                                  onChange={(e) => handleFixCheck(e.target)}
                                  className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                                />
                                Weekly
                              </label>
                              <label>
                                <input
                                  id="Bi-Weekly"
                                  type="checkbox"
                                  checked={checkedFix.has("Bi-Weekly")}
                                  onChange={(e) => handleFixCheck(e.target)}
                                  className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                                />
                                Bi-Weekly
                              </label>
                              <label>
                                <input
                                  id="Monthly"
                                  type="checkbox"
                                  checked={checkedFix.has("Monthly")}
                                  onChange={(e) => handleFixCheck(e.target)}
                                  className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                                />
                                Monthly
                              </label>
                              <label>
                                <input
                                  id="Lump-Sum"
                                  type="checkbox"
                                  checked={checkedFix.has("Lump-Sum")}
                                  onChange={(e) => handleFixCheck(e.target)}
                                  className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                                />
                                Lump-Sum
                              </label>
                            </div>

                            <ReactSlider
                              step={50}
                              max={150000}
                              minDistance={1}
                              name="desired_pay"
                              id="desired_pay"
                              className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab mt-4"
                              thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                              trackClassName="bg-main"
                              // defaultValue={[
                              //   values.min_salary,
                              //   values.max_salary,
                              // ]}
                              value={values.max_salary}
                              onChange={(value) => {
                                updatePay(value);
                              }}
                            />
                            <div className="flex ">
                              {editFixSalary && (
                                <div className="">
                                  <input
                                    type="tel"
                                    pattern="[0-9]*"
                                    style={{ width: `6.5ch` }}
                                    className="mx-2  border-2 px-1"
                                    value={values.max_salary}
                                    onChange={(e) => {
                                      if (
                                        e.target.value <= 99999 &&
                                        regex.test(e.target.value)
                                      ) {
                                        setValue({
                                          ...values,
                                          min_salary: 0,
                                          max_salary: parseInt(
                                            e.target.value,
                                            10
                                          ),
                                        });
                                      }
                                      if (
                                        !e.target.value ||
                                        e.target.value.length === 0
                                      ) {
                                        setValue({
                                          ...values,
                                          min_salary: 0,
                                          max_salary: 0,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              )}

                              <div className="">
                                {!editFixSalary && (
                                  <span
                                    className="p-2 mr-2 rounded-md  cursor-pointer"
                                    onClick={() => setEditFixSalary(true)}
                                  >
                                    {values.max_salary?.toLocaleString()}
                                  </span>
                                )}
                                $/ {checkedFix.values().next().value}
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <span className="form_error_message">
              {errors?.desired_pay}
            </span> */}
                      </div>
                      {/* <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="desired_pay"
              >
                Maximum Desired Pay
              </label>
              <div className="flex items-center justify-between">
                <div>
                  <span className="p-2 mr-2 rounded-md bg-blue-50">
                    {values.max_salary}
                  </span>
                  $ / HR
                </div>
                <div>
                  <span className="p-2 mr-2 rounded-md bg-blue-50">
                    {calculateAnnualPay(values.max_salary)}
                  </span>
                  $/ Year
                </div>
              </div>
              <ReactSlider
                step={1}
                min={values.min_salary}
                max={200}
                name="desired_pay"
                id="desired_pay"
                className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                trackClassName="bg-main"
                value={values.max_salary}
                onChange={(value) => {
                  updateMaxPay(value);
                }}
              />
              <span className="form_error_message">
                {errors?.desired_pay}
              </span>
            </div> */}
                      {/* <div className="">
            <p className="my-0 font-semibold">desired pay</p>
            <div className="flex items-center">
              <div className="flex items-center w-full my-5 salaryContainer">
                <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                  $27
                </p>
                <p className="mr-2 lowercase">/hr</p>
              </div>
              <div className="flex items-center w-full my-5 salaryContainer">
                <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                  $41,000
                </p>
                <p className="mr-2 lowercase">/year</p>
              </div>
            </div>
            <div className="w-full line h-0.5 bg-gray-300 my-5"></div>
          </div> */}
                      <div>
                        <p className="my-5 font-semibold">Work Location </p>
                        <PlacesAutocomplete
                          value={values.work_location.address}
                          onChange={handleChangeLocation}
                          onSelect={handleSelectLocation}
                          onError={(err) => console.log(err)}
                          // searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                placeholder="Location"
                                {...getInputProps({
                                  type: "text",
                                  autoComplete: "off",
                                  className: "form_input",
                                  // onChange={(e) => updateValue(e)
                                })}
                              />
                              <div className="">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "bg-gray-700 p-2"
                                    : "p-2";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  if (suggestion.terms.length > 1) {
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <p className="normal-case form_error_message">
                          {errors?.address}
                        </p>
                        {/* <input
              type="text"
              className="w-full px-5 py-3 rounded-md form_input"
              placeholder="Speciality"
              disabled
            /> */}
                      </div>
                      <div>
                        <label>
                          {/* {values?.is_remote} */}
                          <input
                            id="Remote"
                            type="checkbox"
                            checked={values?.is_remote}
                            onChange={(e) => {
                              updateRemote(e);
                            }}
                            className="h-4 w-4 rounded-lg checked:bg-blue-600 checked:border-transparent mx-2"
                          />
                          Remote
                        </label>
                      </div>
                      <div>
                        <p className="my-5 font-semibold" htmlFor="description">
                          Job Description
                        </p>
                        {/* <textarea
                        name="description"
                        id="description"
                        value={values.description}
                        autoComplete="off"
                        className="form_input"
                        onChange={(e) => updateValue(e)}
                        // onBlur={(e) => saveSingleValue(e)}
                      /> */}
                        <TextEditor
                          textValue={values.description}
                          setTextValue={setTextValue}
                          id="description"
                          name="description"
                        />

                        <span className="form_error_message normal-case">
                          {errors?.description}
                        </span>
                      </div>

                      <div className="block">
                        <div
                          className="py-3 font-semibold text-center normal-case rounded btn-main"
                          disabled={loading ? true : false}
                          onClick={(e) => {
                            processForm(e);
                          }}
                        >
                          {!jobslug
                            ? loading
                              ? loader
                              : "Create Post"
                            : loading
                            ? loader
                            : "Update Post"}
                        </div>
                        <p className="my-3 text-center text-green-500">
                          {successMsg}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* posting container end */}
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                <p className="font-normal">
                  Upgrade your Ring of Hire profile to enable the multiple job
                  posts feature.
                </p>
                <a
                  onClick={setRedirect}
                  className="bg-red-500 rounded-md text-white px-10 py-4 block mt-3 text-center w-min mx-auto cursor-pointer"
                >
                  Upgrade
                </a>
              </div>
            )
          ) : (
            <div className="bg-white rounded-lg shadow-lg p-6">Loading..</div>
          )}
        </div>
      ) : (
        <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
      )}
    </>
  );
};

export default JobPosting;
