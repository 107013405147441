import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import locationIcon from "../../images/JobSearch/locationIcon.png";
import {
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineEnvironment,
} from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import filterIcon from "../../images/JobSearch/filterIcon.png";
import ads from "../../images/JobSearch/ads.png";
import filterActive from "../../images/JobSearch/filterActive.png";
import Map from "../elements/Map";
import ReactSlider from "react-slider";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { MapContext } from "../../App";
import Select from "react-select";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import RohLogo from "../../images/ROH-logo.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import usePostData from "../../hooks/usePostData";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import Highlighter from "react-highlight-words";
import Cookies from "universal-cookie";

const HireSearch = () => {
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const [markerFlag, setmarkerFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [listingData, setListingData] = useState([]);
  const [addressError, setAddressError] = useState("");
  const [locationFlag, setLocationFlag] = useState(false);
  const [values, setValues] = useState({});
  const [currentLoc, setCurrentLoc] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRadius, setShowRadius] = useState(false);
  const [locationClear, setLocationClear] = useState(false);
  const [data, setData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [positionAll, setPositionAll] = useState([{}]);
  const [usersJobId, setUserJobId] = useState();
  const [commonJobList, setCommonJobList] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
    circles,
    setCircles,
    clusters,
    setClusters,
    employerJobs,
    setEmployerJobs,
    allemployerJobs,
    setAllEmployerJobs,
  ] = useContext(MapContext);

  const history = useHistory();

  const { optional } = useParams();

  const { data: positionList } = useGetData("category-list");

  const { data: jobType } = useGetData("job-types");

  useEffect(() => {
    setPositionData(positionList?.data);
  }, [positionList]);

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");

  //Access control API call
  const [accessUrl, setAccessUrl] = useState(`access/1010`);
  const { data: AccessData, loading: filterLoad } = useGetData(accessUrl);

  //for Parallax effect checking if mobile
  const [width, setWidth] = useState(window.innerWidth);

  const cookies = new Cookies();
  let cookieLoc = cookies.get("locationHistory");
  const [locationHistory, setLocationHistory] = useState(
    cookieLoc ? cookieLoc : []
  );

  let cookiePos = cookies.get("positionHistory");
  const [positionHistory, setPositionHistory] = useState(
    cookiePos ? cookiePos : []
  );

  const [positionClear, setPositionClear] = useState(tempPostion !== null);

  const [similarJobs, setSimilarJobs] = useState(null);
  const [selectSimilarJob, setSelectSimilarJob] = useState([]);

  // useEffect(() => {
  //   if (selectSimilarJob) {
  //     updatePositionSelectValue({ id: 1, name: selectSimilarJob });
  //     // setEmployerJobs([]);
  //     setNearestJobUrl(
  //       `${process.env.REACT_APP_SEARCH_URL}/jobs/nearest-jobs/${userId}`
  //     );
  //   }
  // }, [selectSimilarJob]);

  const selectPositionInputRef = useRef();

  const urlIdForUrl = optional?.split("id=")[1];

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  const [filter, setFilter] = useState(!isMobile);

  function featureFilter() {
    let sample = false;
    AccessData?.data?.map((single_access_item) => {
      if (single_access_item.feature === 3 && userId) {
        sample = true;
      }
    });
    return sample;
  }

  const [autoZoomFalse, setAutoZoomFalse] = useState(true);

  useEffect(() => {
    setAutoZoomFalse(true);
  }, [values]);

  //check queries in url
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    if (tempCoord) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: tempCoord.lat,
        lng: tempCoord.lan,
      }));
      setTempCoord(null);
    }
  }, []);
  //check for position query in url
  useEffect(() => {
    // if (!userId) {
    //   window.location.href = "/login";
    // }

    if (query.get("position")) {
      const positionStr = query.get("position").replace(/_/g, " ");
      setFilterValues((prev) => ({
        ...prev,
        position: { id: null, name: positionStr },
      }));
    }

    if (query.get("location")) {
      setAddress(query.get("location"));
    }

    setmarkerFlag(true);

    return () => {
      setFilterValues((prev) => ({
        ...prev,
        position: null,
        min: 1,
        max: 50,
        jobType: [],
        radius: 0,
        salary: false,
      }));
    };
  }, []);

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    // setFilterValues({})
    if (homeCoordinates?.coordinates) {
      setFlag(true);
    }

    if (homeCoordinates?.position) {
      setFilterValues({
        ...filterValues,
        position: homeCoordinates.position,
      });
    }
    if (homeCoordinates.dashboard_location) {
      handleClickLoc(homeCoordinates.dashboard_location);
    }
    if (homeCoordinates.dashboard_position_label) {
      updatePositionSelectValue({
        id: 1,
        name: homeCoordinates.dashboard_position_label,
      });
      // setFilterValues({
      //   ...filterValues,
      //   position: homeCoordinates.dashboard_position_label,
      // });
    }
    setHomeCoordinates({});
  }, []);

  // set full time as default
  // useEffect(() => {
  //   if (jobType) {
  //     const types = jobType?.data?.response_data;
  //     const filteredType = types.filter((type) => type.name === "Full Time");

  //     if (filteredType?.length > 0) {
  //       setFilterValues({
  //         ...filterValues,
  //         jobType: [filteredType[0].id],
  //       });
  //     }
  //   }
  // }, [jobType]);

  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  const clubArray = (arr) => {
    return arr.reduce((acc, val, ind) => {
      const index = acc.findIndex((el) => el.user_id === val.user_id);
      if (index !== -1) {
        const key = Object.keys(val)[1];
        acc[index][key] = val[key];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (Object.keys(homeCoordinates).length !== 0) {
      let token = localStorage.getItem("accessToken");

      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: marker.lat,
        lng: marker.lng,
      }));
      setCoordinates(homeCoordinates);

      const sendData = {
        coordinates: homeCoordinates.coordinates,
        position_name: null,
        salary: null,
      };
      if (userRole === "employer")
        axios
          .get(
            `${process.env.REACT_APP_API_URL}employers/${userId}/jobs`,
            coordinates,
            {
              headers: headerData,
            }
          )
          .then((res) => {
            let jobs = [...res.data.data];
            jobs = jobs.map(({ location_details, ...rest }) => ({
              lng: location_details.longtitude,
              lat: location_details.latitude,
              location_details,
              ...rest,
            }));
            setEmployerJobs(jobs);
            setAllEmployerJobs(jobs);
          })
          .catch((err) => {
            console.log("err ", err);
          });
    }
  }, []);

  useEffect(() => {
    if (userId && userRole === "employer")
      axios
        .get(
          `${process.env.REACT_APP_API_URL}employers/${userId}/jobs`,
          coordinates,
          {
            headers: headerData,
          }
        )
        .then((res) => {
          let jobs = [...res.data.data];
          jobs = jobs.map(({ location_details, ...rest }) => ({
            lng: location_details.longtitude,
            lat: location_details.latitude,
            location_details,
            ...rest,
          }));
          setEmployerJobs(jobs);
          setAllEmployerJobs(jobs);
        })
        .catch((err) => {
          console.log("err ", err);
        });
  }, []);

  const handleGeolocationMarker = (lat, lng) => {
    if (!homeCoordinates || Object.keys(homeCoordinates).length === 0) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: lat,
        lng: lng,
      }));
    }
  };

  const updateSalary = (value) => {
    setFilterValues({ ...filterValues, min: value[0], max: value[1] });
  };

  const updateSalaryFilter = () => {
    setValues({
      ...values,
      coordinates: coordinates.coordinates,
      salary: {
        min: filterValues.min,
        max: filterValues.max,
      },
    });
  };

  const updateType = (e, id) => {
    const check = e.target.checked;
    if (check) {
      setFilterValues({
        ...filterValues,
        jobType: [...filterValues.jobType, id],
      });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        jobType: [...filterValues.jobType, id],
      });
    } else {
      const filterData = filterValues.jobType.filter((item) => {
        return item !== id;
      });
      setFilterValues({
        ...filterValues,
        jobType: filterData,
      });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        jobType: filterData,
      });
    }
  };

  const updatePositionSelectValue = (value) => {
    if (value) {
      let filtered = positionData?.filter((ele) => {
        return ele.name != value.name;
      });
      setPositionData(filtered);
    }
    if (value) {
      if (
        !positionHistory?.some((ele) => ele.name === value.name) &&
        positionHistory?.length !== 3
      ) {
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (
        !positionHistory?.some((ele) => ele.name === value.name) &&
        positionHistory.length === 3
      ) {
        let temp = positionHistory.pop();
        if (!positionData?.some((ele) => ele.name === temp.name)) {
          setPositionData((oldArray) => [temp, ...oldArray]);
        }
        setPositionHistory((oldArray) => [value, ...oldArray]);
      } else if (positionHistory?.some((ele) => ele.name === value.name)) {
        let filtered = positionHistory.filter((ele) => {
          return ele.name != value.name;
        });
        setPositionHistory(filtered);
        setPositionHistory((oldArray) => [value, ...oldArray]);
      }
    }
    setTempPosition(value);
    setFilterValues({ ...filterValues, position: value });
    if (value) {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        position_name: value.name,
      });
    } else {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
      });
    }

    if (value) setPositionClear(true);
  };

  const updateRadius = (value) => {
    setFilterValues({ ...filterValues, radius: value });
    setShowRadius(true);
  };
  const updateRadiusFilter = (value) => {
    setTimeout(() => {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        radius: value,
      });
      setRefresh(!refresh);
    }, 1000);
  };

  const updateSalaryShow = (e) => {
    setFilterValues({ ...filterValues, salary: !filterValues.salary });
    // setValues((current) => {
    //   const { salary, ...rest } = current;
    //   return rest;
    // });
    setValues((prev) => ({
      ...prev,
      coordinates: coordinates.coordinates,
      salary: e.target.checked
        ? {
            min: filterValues.min,
            max: filterValues.max,
          }
        : null,
    }));
  };

  const calculateAnnualPay = useCallback((pay) => {
    let yearlyPay = pay * 261 * 8;
    return yearlyPay;
  }, []);

  useEffect(() => {
    if (tempPostion) {
      // setFilterValues({ ...filterValues, position: tempPostion });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        position_name: tempPostion.name,
      });
    }
  }, []);

  const updateListing = (arr) => {
    if (arr) {
      //sorting premium hire
      arr.sort((a, b) => b.is_premium - a.is_premium);

      setListingData(arr);
    }
  };

  const handleChange = (address) => {
    setAddress(address);
    setLocationClear(true);
    if (locationFlag) {
      setLocationFlag(false);
    }
  };

  useEffect(() => {
    let json_str = JSON.stringify(locationHistory);
    cookies.set("locationHistory", json_str);
  }, [locationHistory]);

  useEffect(() => {
    let json_str = JSON.stringify(positionHistory);
    cookies.set("positionHistory", json_str);
  }, [positionHistory]);

  const handleClickLoc = (address) => {
    setAutoZoomFalse(true);
    setAddress(address);
    setLocationClear(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const removeLocationHistory = (address) => {
    setLocationHistory(locationHistory.filter((item) => item !== address));
  };

  const handleSelect = (address) => {
    setAutoZoomFalse(true);
    if (!locationHistory.includes(address) && locationHistory.length < 5) {
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (
      !locationHistory.includes(address) &&
      locationHistory.length === 5
    ) {
      locationHistory.pop();
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    } else if (locationHistory.includes(address)) {
      const index = locationHistory.indexOf(address);
      if (index > -1) {
        locationHistory.splice(index, 1);
      }
      setLocationHistory(locationHistory);
      setLocationHistory((oldArray) => [address, ...oldArray]);
    }

    setAddress(address);
    setLocationFlag(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  let apiTimeout;

  useEffect(() => {
    setLoading(true);
  }, [homeCoordinates, tempPostion]);

  // debounced function to prevent unnecessary api calls
  const [nearestJobUrl, setNearestJobUrl] = useState(
    `${process.env.REACT_APP_SEARCH_URL}/jobs/nearest-jobs/${userId}`
  );
  const [nearestJobData, setNearestJobData] = useState({
    sister_category: [],
  });

  useEffect(() => {
    const jobarray = selectSimilarJob.map((obj) => obj.name);
    setNearestJobData({
      sister_category: jobarray,
    });
  }, [selectSimilarJob]);

  const getJobData = (coordinates, cb) => {
    if (!flag) {
      // clear home coorinate data since it is not required as the map has changed
      if (homeCoordinates) {
        setHomeCoordinates({});
      }

      let token = localStorage.getItem("accessToken");
      clearTimeout(apiTimeout);

      apiTimeout = setTimeout(() => {
        coordinates.position_name = filterValues.position
          ? filterValues.position?.name
          : null;

        if (filterValues.salary) {
          coordinates.salary = {
            min: filterValues.min,
            max: filterValues.max,
          };
        } else {
          coordinates.salary = null;
        }
        setCoordinates(coordinates);
        // when job id change
        axios
          .post(`${process.env.REACT_APP_SEARCH_URL}/users`, coordinates)
          .then((res) => {
            let allEmp = [...res.data];
            if (res.data && userId && userRole === "employer") {
              axios
                .post(nearestJobUrl, nearestJobData, {
                  headers: headerData,
                })
                .then((res) => {
                  if (res.data) {
                    let hires = clubArray(res.data);

                    hires.sort((a, b) => b.is_premium - a.is_premium);

                    const common = hires.filter((c) =>
                      allEmp.some((s) => s.user_id === c.user_id)
                    );
                    // setSameLocationData(obj);
                    setCommonJobList(common);
                    const rest = [...common, ...allEmp].reduce(
                      (c, n) =>
                        c.find((el) => el.user_id == n.user_id) ? c : [...c, n],
                      []
                    );

                    setListingData(rest);

                    let tempmapData = rest.map(
                      ({ longtitude: lng, latitude: lat, ...rest }) => ({
                        lng,
                        lat,
                        ...rest,
                      })
                    );
                    setMapData(tempmapData);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            } else {
              // setCommonJobList(allEmp)
              let tempmapData = allEmp.map(
                ({ longtitude: lng, latitude: lat, ...rest }) => ({
                  lng,
                  lat,
                  ...rest,
                })
              );
              setMapData(tempmapData);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });
      }, 500);
    }
  };

  const handleBlur = () => {
    if (!locationFlag) {
      setAddressError("Please select location from the list");
      setTimeout(() => {
        setAddressError("");
      }, 3000);
    }
  };

  const setRedirect = () => {
    localStorage.setItem("redirectToJobSh", true);
    history.push("/profile/my-plan");
  };

  // const { data } = usePostData(`/users`, values, "application/json", "search");

  const initRef = useRef(true);
  useEffect(() => {
    // if (initRef.current) {
    //   initRef.current = false;
    // } else {
    if (!values?.coordinates) return;
    coordinates.position_name = filterValues.position
      ? filterValues.position?.name
      : null;
    axios
      .post(`${process.env.REACT_APP_SEARCH_URL}/users`, values)
      .then((res) => {
        let allEmp = [...res.data];
        if (res.data && userId && userRole === "employer") {
          axios
            .post(nearestJobUrl, nearestJobData, {
              headers: headerData,
            })
            .then((res) => {
              if (res.data) {
                let hires = clubArray(res.data);

                hires.sort((a, b) => b.is_premium - a.is_premium);

                const common = hires.filter((c) =>
                  allEmp.some((s) => s.user_id === c.user_id)
                );
                // setSameLocationData(obj);
                setCommonJobList(common);
                const rest = [...common, ...allEmp].reduce(
                  (c, n) =>
                    c.find((el) => el.user_id == n.user_id) ? c : [...c, n],
                  []
                );

                setListingData(rest);

                let tempmapData = rest.map(
                  ({ longtitude: lng, latitude: lat, ...rest }) => ({
                    lng,
                    lat,
                    ...rest,
                  })
                );
                setMapData(tempmapData);
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        } else {
          // setCommonJobList(allEmp)
          let tempmapData = allEmp.map(
            ({ longtitude: lng, latitude: lat, ...rest }) => ({
              lng,
              lat,
              ...rest,
            })
          );
          setMapData(tempmapData);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
    // }
  }, [values, nearestJobData]);

  useEffect(() => {
    setLoading(true);
  }, [values]);

  useEffect(() => {
    if (data) {
      let hires = [...data];
      hires.sort((a, b) => b.is_premium - a.is_premium);

      // setSameLocationData(obj);
      setListingData(hires);
      hires = hires.map(({ longtitude: lng, latitude: lat, ...rest }) => ({
        lng,
        lat,
        ...rest,
      }));
      setMapData(hires);
    }
    if (showRadius) {
      let arr = [];
      let allData = [];
      let sameArr = [];

      Object.values(sameLocationData).forEach((item) => {
        sameArr = [...sameArr, ...item];
      });
      allData = [...data];
      const center = {
        lat: marker.lat,
        lng: marker.lng,
      };
      allData.forEach((item) => {
        const resultColor = window.google.maps.geometry.poly.containsLocation(
          { lat: item.latitude, lng: item.longtitude },
          circles
        );
        if (resultColor) {
          arr.push({
            ...item,
          });
        }
      });

      arr.forEach((item, index) => {
        const dist =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            { lat: item.latitude, lng: item.longtitude },
            center
          );
        arr[index].distance = dist;
      });

      arr.sort((a, b) => a.distance - b.distance);
      updateListing(arr);
    }
  }, [data]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lan: position.coords.longitude,
        });
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  const handleClickCurrentLoc = () => {
    setAutoZoomFalse(true);
    if (currentLocation) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: currentLocation.lat,
        lng: currentLocation.lan,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lan}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          setCurrentLoc(false);
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          // handleSelect(route?.formatted_address)
          setAddress("Current Location");
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const currentLocModalref = useRef();
  const myJobref = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        currentLoc &&
        currentLocModalref.current &&
        !currentLocModalref.current.contains(e.target)
      ) {
        setCurrentLoc(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [currentLoc]);
  const openCurrentLoc = (e) => {
    if (e.target.localName !== "img") {
      setCurrentLoc(true);
    }
  };

  function formatOptionLabel({ name }, { inputValue }) {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={name}
        highlightClassName="text-red-500  border-red-500 bg-transparent"
      />
    );
  }

  const customFilter = (option, searchText) => {
    const getSearctextPos = (string) => {
      if (!positionHistory.some((ele) => ele.name === string.name)) {
        return string.name.toLowerCase().indexOf(searchText.toLowerCase());
      }
    };
    let temp = positionAll?.sort((a, b) => {
      return getSearctextPos(a) - getSearctextPos(b);
    });
    setPositionAll(temp);

    if (
      (option?.data?.name &&
        option?.data?.name?.toLowerCase().includes(searchText.toLowerCase())) ||
      (option?.data?.alias &&
        option?.data?.alias.includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (positionData) {
      setPositionAll([
        ...positionHistory,
        ...positionData
          .sort((a, b) => {
            var x = a?.name;
            var y = b?.name;
            return x < y ? -1 : x > y ? 1 : 0;
          })
          .filter(
            (x) => !positionHistory.filter((y) => y.name === x.name).length
          ),
      ]);
    }
  }, [positionData, positionHistory]);

  let randomStr = "XXXXX XXXX";

  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? "grey" : "red",
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      color: positionHistory.some((ele) => ele.name === state.data.name)
        ? "#047cec"
        : "black",
      backgroundColor: state.isFocused ? "#deebff" : "#fff",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
  };
  const SelectStylesMobile = {
    control: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? "grey" : "red",
      boxShadow: "none",
      border: 0,
      marginTop: "2px",
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "-6px",
      marginTop: "-4px",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      color: positionHistory.some((ele) => ele.name === state.data.name)
        ? "#047cec"
        : "black",
      backgroundColor: state.isFocused ? "#deebff" : "#fff",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
  };
  const Indicator = () => {
    return (
      <div className="rounded w-10 h-10 right-0 blue_box bg-main top-1 p-1 siftright">
        <div className="cursor-pointer">
          <MdOutlineKeyboardArrowDown
            className="mx-auto w-7 h-full"
            color="#fff"
          />
        </div>
      </div>
    );
  };

  const updateMyJob = (e) => {
    if (e) {
      const selectedJob = allemployerJobs.filter((item) => item.id === e.id);
      setEmployerJobs(selectedJob);
      updatePositionSelectValue({ id: 1, name: e.position_label });
    }

    if (e) {
      setNearestJobUrl(
        `${process.env.REACT_APP_SEARCH_URL}/jobs/nearest-jobs/${userId}?job_id=${e.id}`
      );
      // setUserJobId(e.id)
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: e.lat,
        lng: e.lng,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.lng},${e.lat}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const defaultSelectedValue = () => {
    // to update the local state
    if (urlIdForUrl) {
      const selectedJob = allemployerJobs.filter(
        (item) => item.id == urlIdForUrl
      );
      setEmployerJobs(selectedJob);
      return selectedJob;
    }
  };

  useEffect(() => {
    if (urlIdForUrl) {
      let job = defaultSelectedValue();
      updateMyJob(job[0]);
    }
  }, [allemployerJobs]);

  // useEffect(() => {
  //   if (currentJob && !selectSimilarJob) {
  //     updateMyJob(currentJob);
  //   }
  // }, [currentJob, selectSimilarJob]);

  // const current_plan = localStorage.getItem("plan_value");
  const redirectMe = () => {
    window.location.href = "/plans-and-pricing";
    localStorage.setItem("hire-search-path", window.location.href);
  };

  const loader = (
    <span className="text-main text-lg font-semibold">Loading...</span>
  );

  //User active plan API
  const activePlanUrl = "user-active-plan";
  const { data: ActivePlanData, loading: activePlanLoad } =
    useGetData(activePlanUrl);
  const userCurrentPlan = ActivePlanData?.data?.plan;

  return (
    <>
      {activePlanLoad ? (
        <div className="container-wrapper mx-5">{loader}</div>
      ) : userCurrentPlan === null ? (
        redirectMe()
      ) : (
        <div>
          {/* Above Mobile Devices*/}
          {!isMobile ? (
            <div className="hidden md:block jobSearchContainer relative">
              {/* map container start */}
              <div
                className={`relative w-full mr-0 mapContainer ${
                  filter ? "hidden" : "block"
                } md:block`}
              >
                <div className="absolute flex items-center justify-between  h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
                  <div className="relative flex items-center justify-start w-full md:items-center md:flex-nowrap">
                    <button
                      onClick={() => {
                        setFilter(!filter);
                      }}
                    >
                      <img
                        src={filter ? filterActive : filterIcon}
                        alt=""
                        width="100px"
                        className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                      />
                    </button>
                    <div
                      // style={{ width: "70%" }}
                      className="relative w-full lg:w-3/5 xl:w-3/4 my-1 shadow-md search_input_box md:my-0 z-10 flex md:mx-2 home_banner_input_box"
                    >
                      <div className="flex-1 relative">
                        <Select
                          ref={selectPositionInputRef}
                          formatOptionLabel={formatOptionLabel}
                          styles={SelectStyles}
                          // className="react-select-container"
                          className="react-select-container w-full pr-2"
                          classNamePrefix="react-select"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={positionAll}
                          components={{ DropdownIndicator: Indicator }}
                          placeholder={
                            <h2 className="flex justify-start">Position</h2>
                          }
                          name="position"
                          id="position"
                          value={filterValues.position}
                          onChange={(e) => {
                            updatePositionSelectValue(e);
                          }}
                          filterOption={customFilter}
                        />
                        <AiOutlineSearch
                          className="absolute top-5 left-4"
                          color="gray"
                          size="1.3em"
                        />
                        {positionClear && (
                          <AiOutlineClose
                            className="absolute top-4 right-14 cursor-pointer"
                            onClick={() => {
                              selectPositionInputRef.current.clearValue();
                              setPositionClear(false);
                              setValues({
                                ...values,
                                // coordinates: coordinates.coordinates,
                                position_name: null,
                                // job_type: [],
                              });
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="relative shadow-md home_banner_input_box md:my-0 flex-1"
                        onClick={openCurrentLoc}
                        ref={currentLocModalref}
                      >
                        <AiOutlineSearch
                          className="absolute top-4 left-4"
                          color="gray"
                          size="1.3em"
                        />
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleChange}
                          onSelect={handleSelect}
                          onError={(err) => console.log(err)}
                          // searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  type: "text",
                                  autoComplete: "off",
                                  className:
                                    "w-full pl-10 p-4 rounded outline-none  truncate auto-address h-18",
                                  placeholder: "Address",
                                  // onBlur: handleBlur,
                                  // onChange={(e) => updateValue(e)
                                })}
                              />
                              <div className="absolute w-full">
                                {loading && <div>Loading...</div>}
                                {currentLoc && !address && (
                                  <div className="overflow-y-auto h-72">
                                    <h2
                                      className="text-left px-4 pt-3 bg-white cursor-pointer flex "
                                      style={{ color: "#007FEE" }}
                                      onClick={handleClickCurrentLoc}
                                    >
                                      <span>
                                        <AiOutlineEnvironment
                                          className="relative top-0"
                                          color="#007FEE"
                                          size={28}
                                        />
                                      </span>

                                      <h3 className="relative px-3 text-lg">
                                        Current Location
                                      </h3>
                                    </h2>
                                    {locationHistory && (
                                      <h2
                                        className="text-left py-3 bg-white cursor-pointer flex flex-col"
                                        style={{ color: "#007FEE" }}
                                      >
                                        {locationHistory.map((loc) => (
                                          <div className="px-4 flex flex-row relative justify-between cursor-pointer hover:bg-hover_bg">
                                            <h3
                                              className="overflow-hidden truncate relative px-3 text-lg py-2"
                                              onClick={() => {
                                                handleClickLoc(loc);
                                              }}
                                            >
                                              {loc}
                                            </h3>
                                            <AiOutlineClose
                                              className="absolute right-4 h-5 w-5 cursor-pointer my-auto top-0 bottom-0"
                                              onClick={() => {
                                                removeLocationHistory(loc);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </h2>
                                    )}
                                  </div>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "bg-gray-700 p-2"
                                    : "p-2";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#deebff",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  if (suggestion.terms.length > 1) {
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {locationClear && (
                          <AiOutlineClose
                            className="absolute top-4 right-12 cursor-pointer"
                            onClick={() => {
                              setAddress("");
                              setLocationClear(false);
                            }}
                          />
                        )}
                        <img
                          className="absolute w-10 h-10 rounded right-1 blue_box bg-main top-2 cursor-pointer"
                          src={locationIcon}
                          alt="location icon"
                          onClick={handleClickCurrentLoc}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <button type="submit" className="hidden w-24 md:block z-10">
                <img src={expandIcon} alt="" width="100px" />
              </button> */}
                </div>
                <div
                  style={{ top: "4.5rem" }}
                  className="form_error_message z-40 font-semibold block absolute left-24"
                >
                  {addressError}
                </div>
                <div className="minHeight z-0 h-screen">
                  {/* <img src={map} alt="" width="100%" /> */}
                  <Map
                    zoomLevel={zoomlvl}
                    lookingToHire={true}
                    geolocationMarker={handleGeolocationMarker}
                    // markerFlag={markerFlag}
                    page="hires"
                    updateListing={updateListing}
                    filters={filterValues}
                    refresh={refresh}
                    getJobData={getJobData}
                    // handleMapAddress={handleMapAddress}
                    // geolocationError={handleGeolocationError}
                    loading={loading}
                    showRadius={showRadius}
                    usersJobId={nearestJobUrl}
                    autoZoomFalse={autoZoomFalse}
                    setAutoZoomFalse={setAutoZoomFalse}
                  />
                </div>
                {/* map container below section button container start */}
                <div
                  style={{ width: "94%" }}
                  className="absolute flex-col hidden lg:flex bottom-7"
                >
                  <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                    {/* <button
                  type="submit"
                  className="p-2 bg-white rounded-md shadow-md"
                >
                  <img src={plusSymbol} className="" alt="" width="auto" />
                </button> */}
                  </div>
                  {/* <div className="">
                <div className="flex justify-between px-4 left-5 bottom-5">
                  <button
                    type="submit"
                    className="flex items-center justify-between btn btn-main"
                  >
                    <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                    Search as map moves
                  </button>
                  <div className="flex justify-between space-x-3">
                    <button
                      type="submit"
                      className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                    >
                      Map
                      <div>
                        <MdKeyboardArrowDown
                          color="gray"
                          size="1.4em"
                        ></MdKeyboardArrowDown>
                      </div>
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-white rounded-md shadow-md"
                    >
                      <img src={minusSymbol} className="" alt="" width="auto" />
                    </button> 
                  </div>
                </div>
              </div> */}
                </div>
                {/* map container below section button container end */}
              </div>
              {/* map container end */}

              {/* filter popup start*/}
              {!filterLoad &&
                (featureFilter() ? (
                  <div
                    className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                      filter
                        ? "block overflow-x-hidden overflow-y-scroll custom-filter h-4/5"
                        : "hidden"
                    }`}
                  >
                    <div className="relative">
                      <div className="relative w-[100%] h-10 block md:hidden">
                        <AiOutlineClose
                          className="absolute top-3 right-3 text-gray-700 cursor-pointer hover:text-black"
                          size="1.3em"
                          onClick={() => setFilter(false)}
                        />
                      </div>

                      <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                        <p
                          className="text-sm text-gray-700 cursor-pointer hover:text-black"
                          onClick={() => {
                            setNearestJobUrl(
                              `${process.env.REACT_APP_SEARCH_URL}/jobs/nearest-jobs/${userId}`
                            );
                            myJobref.current.clearValue();
                            setEmployerJobs(allemployerJobs);
                            setShowRadius(false);
                            setFilterValues({
                              position: null,
                              min: 1,
                              max: 50,
                              jobType: [],
                              radius: 0,
                              salary: false,
                            });
                            setValues({
                              coordinates: coordinates.coordinates,
                            });
                            // setSimilarJobs();
                            history.push("/hireSearch");
                          }}
                        >
                          Clear Filter
                        </p>
                      </div>
                      <div className="mt-5 flex flex-row items-center gap-4">
                        <p className="font-semibold">Salary</p>
                        <input
                          type="checkbox"
                          className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                          name="salary"
                          checked={filterValues.salary}
                          onChange={updateSalaryShow}
                        />
                      </div>

                      <div
                        className={
                          !filterValues.salary
                            ? "pointer-events-none opacity-40"
                            : ""
                        }
                      >
                        <div className="flex my-5 salaryContainer justify-between">
                          <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                            <span className="">${filterValues.min}</span>
                          </p>
                          <p className="mr-2">-</p>
                          <div className="text-gray-500 rounded  text-center flex bg-blue-50 px-4 py-1 mr-2">
                            <p className=" w-16 text-gray-500 rounded  text-center">
                              ${filterValues.max}
                            </p>
                            <p className=" lowercase">/hr</p>
                          </div>
                        </div>
                        <ReactSlider
                          step={1}
                          min={1}
                          max={250}
                          minDistance={1}
                          name="desired_pay"
                          id="desired_pay"
                          className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                          thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                          trackClassName="track-color"
                          withTracks={true}
                          defaultValue={[filterValues.min, filterValues.max]}
                          value={[filterValues.min, filterValues.max]}
                          onChange={(value) => {
                            updateSalary(value);
                          }}
                          onAfterChange={updateSalaryFilter}
                        />
                        {/* <div className="w-full line h-0.5 bg-gray-300 my-5"></div> */}
                        <div className="flex justify-between w-full my-5 md:my-2 xl:my-5">
                          <div className="flex my-5 salaryContainer ">
                            <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                              $
                              {calculateAnnualPay(
                                filterValues.min
                              )?.toLocaleString()}
                            </p>
                            <p className="mr-2">-</p>
                            <div className="text-gray-500 rounded  text-center flex bg-blue-50 px-4 py-1 mr-2">
                              <p className=" text-gray-500 rounded  text-center">
                                $
                                {calculateAnnualPay(
                                  filterValues.max
                                )?.toLocaleString()}
                              </p>
                              <p className=" lowercase">/yr</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="my-5 md:my-3 xl:my-5 font-semibold">
                        Job Type
                      </p>
                      {jobType?.data?.response_data.map((item) => {
                        return (
                          <p
                            key={item.id}
                            className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                          >
                            {" "}
                            <span>
                              <input
                                type="checkbox"
                                className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                                name="Job Type"
                                checked={filterValues.jobType.includes(item.id)}
                                onChange={(e) => updateType(e, item.id)}
                              />
                            </span>
                            {item.name}
                          </p>
                        );
                      })}
                      <p className="mt-5 font-semibold">Radius</p>
                      <div className="flex justify-start space-x-5 radiusContainer">
                        <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                          {filterValues.radius} miles
                        </p>
                        {/* <p className="my-5 text-sm text-gray-500">150 miles</p> */}
                      </div>
                      <ReactSlider
                        step={1}
                        min={1}
                        max={150}
                        minDistance={1}
                        name="desired_pay"
                        id="desired_pay"
                        className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                        thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                        trackClassName="bg-main"
                        // defaultValue={[
                        //   values.min_hourly_pay,
                        //   values.max_hourly_pay,
                        // ]}
                        value={filterValues.radius}
                        onChange={(value) => {
                          updateRadius(value);
                        }}
                        onAfterChange={updateRadiusFilter}
                      />
                      {userRole === "employer" && (
                        <>
                          <>
                            <h1 className="my-5 md:my-3 xl:my-5 font-semibold">
                              My Jobs
                            </h1>
                            <Select
                              value={
                                employerJobs.length === 1 ? employerJobs : null
                              }
                              ref={myJobref}
                              className="react-select-container w-full"
                              // classNamePrefix="react-select"
                              getOptionLabel={(option) =>
                                option.job_title
                                  ? option.job_title
                                  : option.position_label
                              }
                              getOptionValue={(option) => option.id}
                              options={allemployerJobs}
                              // components={{ DropdownIndicator: Indicator }}
                              placeholder={
                                <h2 className="flex justify-start">My Jobs</h2>
                              }
                              name="position"
                              id="position"
                              onChange={(e) => {
                                updateMyJob(e);
                                setSimilarJobs(e?.sister_category);
                                setSelectSimilarJob([]);
                              }}
                            />
                          </>
                          <>
                            {similarJobs && similarJobs?.length > 0 && (
                              <>
                                <h1 className="my-5 md:my-3 xl:my-5 font-semibold">
                                  Similar Positions
                                </h1>
                                {similarJobs.map((e, i) => {
                                  return (
                                    <div key={i} className="flex flex-row">
                                      <input
                                        type="checkbox"
                                        checked={selectSimilarJob.find(
                                          (obj) => obj.name === e.name
                                        )}
                                        name="type"
                                        onChange={() => {
                                          if (
                                            selectSimilarJob.find(
                                              (obj) => obj.name === e.name
                                            )
                                          ) {
                                            setSelectSimilarJob(() =>
                                              selectSimilarJob.filter(
                                                (obj) => obj.name !== e.name
                                              )
                                            );
                                          } else {
                                            setSelectSimilarJob((prev) => [
                                              ...prev,
                                              e,
                                            ]);
                                          }
                                        }}
                                        className="p-4 mr-4 rounded-lg checked:bg-blue-600 checked:border-transparent"
                                      />
                                      <h1>{e.name}</h1>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                      filter ? "block" : "hidden"
                    }`}
                  >
                    <p className="font-normal">
                      Make a FREE Profile For Advanced Filters
                    </p>
                    <button
                      onClick={setRedirect}
                      className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                    >
                      Upgrade
                    </button>
                  </div>
                ))}
              {/* filter popup end */}

              <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
                <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
                  <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h1>
                            Hires Listing -{" "}
                            <span className="italic align-middle">
                              Ring of Hires
                            </span>
                          </h1>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {/* <h2 className="text-xl font-semibold capitalize">
                      Hires Listing
                    </h2> */}
                        <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                          <p>showing {commonJobList.length} results</p>
                          <p>
                            sort by:{" "}
                            <span className=" text-main">newest post</span>
                            <button className="px-1">
                              <MdKeyboardArrowDown
                                color="gray"
                                size="1em"
                              ></MdKeyboardArrowDown>
                            </button>
                          </p>
                        </div>
                        <div
                          style={{ height: "50vh" }}
                          className="grid grid-cols-1 gap-2 overflow-y-scroll capitalize md:grid-cols-2 custom-sidebar"
                        >
                          {commonJobList.map((item, index) => {
                            return (
                              <Link to={`/employees/${item.user_id}`}>
                                <div
                                  key={index}
                                  className="rounded shadow-md profileCards relative flex flex-col h-full"
                                >
                                  <div className="flex p-2 justify-between">
                                    <img
                                      src={
                                        item.image
                                          ? item.image
                                          : "/images/employee-default.png"
                                      }
                                      alt=""
                                      className={`w-20 h-20 ${
                                        !item.name && "blurimage"
                                      }`}
                                    />
                                    {item.is_premium == true && (
                                      <div>
                                        <img
                                          src={RohLogo}
                                          className="w-full h-7"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="p-2">
                                    <p
                                      className={`my-2 font-semibold ${
                                        !item.name && "blurtext"
                                      }`}
                                    >
                                      {item.name ? item.name : randomStr}
                                    </p>
                                    <p className="my-2 text-gray-500 text-xs">
                                      {item.post}
                                    </p>
                                    <span className="text-gray-700 text-sm">
                                      {item.position}
                                    </span>
                                    <p className="my-2 font-bold text-main mb-12">
                                      {item.desired_pay &&
                                        `$${item.desired_pay}/Hr`}
                                    </p>
                                    <div
                                      className={
                                        listingData?.length > 2
                                          ? "absolute bottom-0 my-2 w-11/12"
                                          : "my-2 w-11/12"
                                      }
                                    >
                                      <button
                                        type="submit"
                                        className="w-full py-1 font-semibold rounded btn-main"
                                        onClick={() =>
                                          history.push(
                                            `/employees/${item.user_id}`
                                          )
                                        }
                                      >
                                        View Profile
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          ) : (
            /* Mobile Devices*/
            <div className="block md:hidden jobSearchContainer relative">
              {/* map container start */}
              <ParallaxProvider>
                <Parallax speed={-20}>
                  <div className="fast">
                    <div
                      className={`relative w-full mr-0 mapContainer block`}
                      style={{ filter: `blur(${filter ? "4px" : "0px"})` }}
                    >
                      <div className="absolute flex items-center justify-between  h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
                        <div className="relative flex justify-start w-full items-center md:flex-nowrap">
                          <button
                            onClick={() => {
                              setFilter(!filter);
                            }}
                            style={{ paddingTop: "30px" }}
                          >
                            <img
                              src={filter ? filterActive : filterIcon}
                              alt=""
                              width="100px"
                              className="relative w-20 md:w-24 z-10"
                            />
                          </button>
                          <div className="relative flex flex-col home_banner_input_box text-md w-full pr-4 pt-6">
                            <div className="relative py-1 pb-2">
                              <Select
                                ref={selectPositionInputRef}
                                formatOptionLabel={formatOptionLabel}
                                styles={SelectStylesMobile}
                                // className="react-select-container"
                                className="react-select-container w-full h-full"
                                classNamePrefix="react-select"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={positionAll}
                                components={{ DropdownIndicator: Indicator }}
                                placeholder={
                                  <h2 className="flex justify-start text-gray-400">
                                    Position
                                  </h2>
                                }
                                name="position"
                                id="position"
                                value={filterValues.position}
                                onChange={(e) => {
                                  updatePositionSelectValue(e);
                                }}
                                filterOption={customFilter}
                                onFocus={(e) => {
                                  setCurrentLoc(false);
                                }}
                              />
                              {positionClear && (
                                <AiOutlineClose
                                  className="absolute top-0 bottom-0 m-auto right-12 cursor-pointer"
                                  onClick={() => {
                                    selectPositionInputRef.current.clearValue();
                                    setPositionClear(false);
                                    setValues({
                                      ...values,
                                      position_name: null,
                                    });
                                  }}
                                />
                              )}
                            </div>

                            <div
                              className="relative home_banner_input_box md:my-0 bg-white rounded focus:border-red-100"
                              style={{ width: "100%" }}
                              onClick={openCurrentLoc}
                              ref={currentLocModalref}
                            >
                              <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                                onError={(err) => console.log(err)}
                                // searchOptions={searchOptions}
                                className="focus:outline-none"
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        type: "text",
                                        autoComplete: "off",
                                        className:
                                          "w-4/5 rounded p-3 pl-4 truncate auto-address focus:outline-none",
                                        placeholder: "Address",
                                        // onBlur: handleBlur,
                                        // onChange={(e) => updateValue(e)
                                      })}
                                    />
                                    <div className="absolute w-full">
                                      {loading && <div>Loading...</div>}
                                      {currentLoc && !address && (
                                        <div className="overflow-y-auto h-72">
                                          <h2
                                            className="text-left px-1 pt-3 bg-white cursor-pointer flex"
                                            style={{ color: "#007FEE" }}
                                            onClick={handleClickCurrentLoc}
                                          >
                                            <h3 className="relative px-3 text-md  truncate auto-address">
                                              Current Location
                                            </h3>
                                          </h2>
                                          {locationHistory && (
                                            <h2
                                              className="text-left py-3 bg-white flex flex-col"
                                              style={{ color: "#007FEE" }}
                                            >
                                              {locationHistory.map((loc) => (
                                                <div className="px-4 flex flex-row relative justify-between cursor-pointer hover:bg-hover_bg">
                                                  <h3
                                                    className="overflow-hidden truncate auto-address relative text-md py-2"
                                                    onClick={() => {
                                                      handleClickLoc(loc);
                                                    }}
                                                  >
                                                    {loc}
                                                  </h3>
                                                  <AiOutlineClose
                                                    className="absolute right-2 h-5 w-5 cursor-pointer my-auto top-0 bottom-0"
                                                    onClick={() => {
                                                      removeLocationHistory(
                                                        loc
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              ))}
                                            </h2>
                                          )}
                                        </div>
                                      )}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "bg-gray-700 p-2"
                                          : "p-2";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        if (suggestion.terms.length > 1) {
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {locationClear && (
                                <AiOutlineClose
                                  className="absolute top-4 right-11 cursor-pointer"
                                  onClick={() => {
                                    setAddress("");
                                    setLocationClear(false);
                                  }}
                                />
                              )}
                              <img
                                className="absolute w-10 h-10 rounded right-1 blue_box bg-main top-1 cursor-pointer"
                                src={locationIcon}
                                alt="location icon"
                                onClick={handleClickCurrentLoc}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <button type="submit" className="hidden w-24 md:block z-10">
                <img src={expandIcon} alt="" width="100px" />
              </button> */}
                      </div>
                      <div
                        style={{ top: "4.5rem" }}
                        className="form_error_message z-40 font-semibold block absolute left-24"
                      >
                        {addressError}
                      </div>
                      <div className="minHeight z-0 h-60">
                        {/* <img src={map} alt="" width="100%" /> */}
                        <Map
                          zoomLevel={zoomlvl}
                          lookingToHire={true}
                          geolocationMarker={handleGeolocationMarker}
                          // markerFlag={markerFlag}
                          page="hires"
                          updateListing={updateListing}
                          filters={filterValues}
                          refresh={refresh}
                          getJobData={getJobData}
                          // handleMapAddress={handleMapAddress}
                          // geolocationError={handleGeolocationError}
                          loading={loading}
                          showRadius={showRadius}
                          usersJobId={usersJobId}
                          autoZoomFalse={autoZoomFalse}
                          setAutoZoomFalse={setAutoZoomFalse}
                        />
                      </div>
                      {/* map container below section button container start */}
                      <div
                        style={{ width: "94%" }}
                        className="absolute flex-col hidden lg:flex bottom-7"
                      >
                        <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                          {/* <button
                  type="submit"
                  className="p-2 bg-white rounded-md shadow-md"
                >
                  <img src={plusSymbol} className="" alt="" width="auto" />
                </button> */}
                        </div>
                        {/* <div className="">
                <div className="flex justify-between px-4 left-5 bottom-5">
                  <button
                    type="submit"
                    className="flex items-center justify-between btn btn-main"
                  >
                    <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                    Search as map moves
                  </button>
                  <div className="flex justify-between space-x-3">
                    <button
                      type="submit"
                      className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                    >
                      Map
                      <div>
                        <MdKeyboardArrowDown
                          color="gray"
                          size="1.4em"
                        ></MdKeyboardArrowDown>
                      </div>
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-white rounded-md shadow-md"
                    >
                      <img src={minusSymbol} className="" alt="" width="auto" />
                    </button> 
                  </div>
                </div>
              </div> */}
                      </div>
                      {/* map container below section button container end */}
                    </div>
                  </div>
                </Parallax>
                {/* map container end */}

                {/* filter popup start*/}
                {!filterLoad &&
                  (featureFilter() ? (
                    <div
                      className={`h-full w-full absolute top-0 font-semibold transition-all duration-300 rounded-lg shadow-lg filterPopupContainer ${
                        filter
                          ? "block overflow-x-hidden overflow-y-scroll custom-filter h-96"
                          : "hidden"
                      }`}
                    >
                      <div className="w-5/6 p-4 px-5 pb-6 bg-white ">
                        <div className="relative">
                          <div className="relative block md:hidden">
                            <AiOutlineClose
                              className="absolute top-1 right-3 text-gray-700 cursor-pointer hover:text-black"
                              size="1.3em"
                              onClick={() => setFilter(false)}
                            />
                          </div>

                          <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                            <p
                              className="text-sm text-gray-700 cursor-pointer hover:text-black"
                              onClick={() => {
                                setShowRadius(false);
                                setFilterValues({
                                  position: null,
                                  min: 1,
                                  max: 50,
                                  jobType: [],
                                  radius: 0,
                                  salary: false,
                                });
                                setValues({
                                  coordinates: coordinates.coordinates,
                                });
                              }}
                            >
                              Clear Filter
                            </p>
                          </div>
                          <div className="mt-5 flex flex-row items-center gap-4">
                            <p className="font-semibold">Salary</p>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                              name="salary"
                              checked={filterValues.salary}
                              onChange={updateSalaryShow}
                            />
                          </div>

                          <div
                            className={
                              !filterValues.salary
                                ? "pointer-events-none opacity-40"
                                : ""
                            }
                          >
                            <div className="flex my-5 salaryContainer justify-between ">
                              <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                                <span className="">${filterValues.min}</span>
                              </p>
                              <p className="mr-2">-</p>
                              <div className="text-gray-500 rounded  text-center flex bg-blue-50 px-4 py-1 mr-2">
                                <p className=" text-center">
                                  ${filterValues.max}
                                </p>
                                <p className="lowercase">/hr</p>
                              </div>
                            </div>
                            <ReactSlider
                              step={1}
                              min={1}
                              max={250}
                              minDistance={1}
                              name="desired_pay"
                              id="desired_pay"
                              className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                              thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                              trackClassName="track-color"
                              withTracks={true}
                              defaultValue={[
                                filterValues.min,
                                filterValues.max,
                              ]}
                              value={[filterValues.min, filterValues.max]}
                              onChange={(value) => {
                                updateSalary(value);
                              }}
                              onAfterChange={updateSalaryFilter}
                            />
                            {/* <div className="w-full line h-0.5 bg-gray-300 my-5"></div> */}

                            <div className="flex my-5 salaryContainer justify-between">
                              <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50 text-center">
                                $
                                {calculateAnnualPay(
                                  filterValues.min
                                )?.toLocaleString()}
                              </p>
                              <p className="">-</p>
                              <div className="text-gray-500 rounded  text-center flex bg-blue-50 px-4 py-1 mr-2">
                                <p className="">
                                  $
                                  {calculateAnnualPay(
                                    filterValues.max
                                  )?.toLocaleString()}
                                </p>
                                <p className="lowercase">/yr</p>
                              </div>
                            </div>
                          </div>
                          <p className="my-5 md:my-3 xl:my-5 font-semibold">
                            Job Type
                          </p>
                          {jobType?.data?.response_data.map((item) => {
                            return (
                              <p
                                key={item.id}
                                className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                              >
                                {" "}
                                <span>
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                                    name="Job Type"
                                    checked={filterValues.jobType.includes(
                                      item.id
                                    )}
                                    onChange={(e) => updateType(e, item.id)}
                                  />
                                </span>
                                {item.name}
                              </p>
                            );
                          })}
                          <p className="mt-5 font-semibold">Radius</p>
                          <div className="flex justify-start space-x-5 radiusContainer">
                            <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                              {filterValues.radius} miles
                            </p>
                            {/* <p className="my-5 text-sm text-gray-500">150 miles</p> */}
                          </div>
                          <ReactSlider
                            step={1}
                            min={1}
                            max={150}
                            minDistance={1}
                            name="desired_pay"
                            id="desired_pay"
                            className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                            trackClassName="bg-main"
                            // defaultValue={[
                            //   values.min_hourly_pay,
                            //   values.max_hourly_pay,
                            // ]}
                            value={filterValues.radius}
                            onChange={(value) => {
                              updateRadius(value);
                            }}
                            onAfterChange={updateRadiusFilter}
                          />
                          {userRole === "employer" && (
                            <>
                              <h1 className="my-5 md:my-3 xl:my-5 font-semibold">
                                My Jobs
                              </h1>
                              <Select
                                value={
                                  employerJobs.length === 1
                                    ? employerJobs
                                    : null
                                }
                                ref={myJobref}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                getOptionLabel={(option) =>
                                  option.job_title
                                    ? option.job_title
                                    : option.position_label
                                }
                                getOptionValue={(option) => option.id}
                                options={allemployerJobs}
                                components={{ DropdownIndicator: Indicator }}
                                placeholder={
                                  <h2 className="flex justify-start">
                                    My Jobs
                                  </h2>
                                }
                                name="position"
                                id="position"
                                onChange={(e) => {
                                  updateMyJob(e);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                        filter ? "block" : "hidden"
                      }`}
                    >
                      <div className="relative">
                        <AiOutlineClose
                          className="absolute top-1 right-1 text-gray-700 cursor-pointer hover:text-black"
                          size="1.3em"
                          onClick={() => setFilter(false)}
                        />
                      </div>
                      <p className="font-normal">
                        Make a FREE Profile For Advanced Filters
                      </p>
                      <button
                        onClick={setRedirect}
                        className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                      >
                        Upgrade
                      </button>
                    </div>
                  ))}
                {/* filter popup end */}

                <Parallax speed={5}>
                  <div className="fast">
                    <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
                      <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
                        <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                          <AccordionItem uuid="a">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h1>
                                  Hires Listing -{" "}
                                  <span className="italic align-middle">
                                    Ring of Hires
                                  </span>
                                </h1>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="block">
                                <button
                                  onClick={() => {
                                    setFilter(!filter);
                                  }}
                                >
                                  <img
                                    src={filter ? filterActive : filterActive}
                                    alt=""
                                    width="100px"
                                    className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                                  />
                                </button>
                              </div>
                              <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                                <p>showing {listingData.length} results</p>
                                <p>
                                  sort by:{" "}
                                  <span className=" text-main">
                                    newest post
                                  </span>
                                  <button className="px-1">
                                    <MdKeyboardArrowDown
                                      color="gray"
                                      size="1em"
                                    ></MdKeyboardArrowDown>
                                  </button>
                                </p>
                              </div>
                              <div
                                style={{ height: "50vh" }}
                                className="grid grid-cols-1 gap-2 overflow-y-scroll capitalize md:grid-cols-2 custom-sidebar"
                              >
                                {listingData.map((item, index) => {
                                  return (
                                    <Link to={`/employees/${item.user_id}`}>
                                      <div
                                        key={index}
                                        className="rounded shadow-md profileCards relative flex flex-col h-full"
                                      >
                                        <div className="flex p-2 items-center">
                                          <div className="flex-none w-20">
                                            <img
                                              src={
                                                item.image
                                                  ? item.image
                                                  : "/images/employee-default.png"
                                              }
                                              alt=""
                                              className={`w-20 h-20 ${
                                                !item.name && "blurimage"
                                              }`}
                                            />
                                          </div>
                                          <div className="flex-auto pl-2">
                                            {item.is_premium == true && (
                                              <div>
                                                <img
                                                  src={RohLogo}
                                                  className="w-auto h-7 mx-auto"
                                                />
                                              </div>
                                            )}
                                            <p
                                              className={`my-2 font-semibold text-center ${
                                                !item.name && "blurtext"
                                              }`}
                                            >
                                              {item.name
                                                ? item.name
                                                : randomStr}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="p-2">
                                          <p className="my-2 text-gray-500 text-xs">
                                            {item.post}
                                          </p>
                                          {/* <div className="flex items-center">
                          <GoLocation color="gray" size="1em" />

                          <p className="mx-1 my-0 text-gray-500 ">location</p>
                        </div> */}
                                          <p className="my-2 font-bold text-main mb-12">
                                            {item.desired_pay &&
                                              `$${item.desired_pay}/Hr`}
                                          </p>
                                          <div
                                            className={
                                              listingData?.length > 2
                                                ? "absolute bottom-0 my-2 w-11/12"
                                                : "my-2 w-11/12"
                                            }
                                          >
                                            <button
                                              type="submit"
                                              className="w-full py-1 font-semibold rounded btn-main"
                                              onClick={() =>
                                                history.push(
                                                  `/employees/${item.user_id}`
                                                )
                                              }
                                            >
                                              View Profile
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </Parallax>
              </ParallaxProvider>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HireSearch;
